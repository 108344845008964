import React from 'react'
import {
  Dialog,
  DefaultButton,
  PrimaryButton,
  TextField,
  DialogFooter,
  DialogType,
  MessageBar,
  MessageBarType,
} from 'office-ui-fabric-react'

interface State {
  fieldValue: string
}

interface InputProps {
  onClose: () => void
  onUpdated: (value: string) => void
  onSubmit: (value: string) => void
  dialogTitle: string
  fieldTitle: string
  confirmButtonText: string
  isLoading: boolean
  errorMessage?: string
  initialValue?: string
}

export class TextPromptDialog extends React.Component<InputProps, State> {
  constructor(props: InputProps) {
    super(props)
    this.state = { fieldValue: props.initialValue || '' }
  }
  render() {
    return (
      <Dialog
        hidden={false}
        modalProps={{
          isDarkOverlay: true,
          isBlocking: true,
        }}
        elementToFocusOnDismiss={undefined}
        onDismiss={this.props.onClose}
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: this.props.dialogTitle,
        }}
      >
        <TextField
          label={this.props.fieldTitle}
          value={this.state.fieldValue}
          name={'textField'}
          autoComplete={'off'}
          onKeyDown={(evt) =>
            evt.keyCode === 13 && this.props.onSubmit(this.state.fieldValue)
          }
          onChange={(evt: any, value?: string) => {
            this.setState({ fieldValue: value! })
            this.props.onUpdated(value!)
          }}
          disabled={this.props.isLoading}
          required
        />
        {this.props.errorMessage && (
          <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
            {this.props.errorMessage}
          </MessageBar>
        )}
        <DialogFooter>
          <DefaultButton
            disabled={this.props.isLoading}
            onClick={this.props.onClose}
            text={'Cancel'}
          />
          <PrimaryButton
            disabled={
              this.props.isLoading ||
              Boolean(this.props.errorMessage) ||
              !this.state.fieldValue.trim()
            }
            onClick={() => this.props.onSubmit(this.state.fieldValue.trim())}
            text={this.props.confirmButtonText}
          />
        </DialogFooter>
      </Dialog>
    )
  }
}
