import React, { useState } from 'react'
import { ChildProps, graphql } from 'react-apollo'
import {
  DefaultButton,
  DialogFooter,
  Link,
  Modal,
  PrimaryButton,
  Separator,
  Stack,
  Text,
} from 'office-ui-fabric-react'
import gql from 'graphql-tag'
import { isAccountManager, isAdmin } from '../Api/session'

interface BillboardModeResponse {
  session: {
    billboardMode: boolean
    groupNames: string[]
  }
}

const withGql = graphql<{}, BillboardModeResponse>(
  gql`
    query {
      session {
        groupNames
        billboardMode
      }
    }
  `,
  { options: () => ({ fetchPolicy: 'network-only' }) }
)

export const DOOHAccountDetectedPopup = withGql(
  ({ data }: ChildProps<{}, BillboardModeResponse>): JSX.Element => {
    const [dismissed, setDismissed] = useState<boolean>(false)
    const canDismiss =
      data &&
      data.session &&
      (isAccountManager(data.session) || isAdmin(data.session))
    return (
      <Modal
        isOpen={
          !dismissed && data && data.session && data.session.billboardMode
        }
      >
        <Stack>
          <Stack
            styles={{
              root: {
                background: 'rgb(2, 151, 220)',
                height: 67,
                paddingLeft: 24,
                paddingTop: 16,
                paddingRight: 48,
                zIndex: 1e6,
              },
            }}
          >
            <Text style={{ color: '#fff', fontWeight: 600, fontSize: 20 }}>
              Important notice
            </Text>
          </Stack>
          <Stack styles={{ root: { padding: 24 } }} gap={10}>
            <Text>
              DOOH users have been migrated to a new domain:{' '}
              <Link href="https://client.visionthing.cz/">
                client.visionthing.cz
              </Link>
              .
            </Text>
            <Text>
              From now on, please use the new domain to access the platform.
            </Text>
            <Separator />
            <DialogFooter>
              <PrimaryButton
                href="https://client.visionthing.cz/"
                text="Take me there!"
              />
              {canDismiss && (
                <DefaultButton
                  onClick={() => {
                    setDismissed(true)
                  }}
                  text="I'm an admin"
                />
              )}
            </DialogFooter>
          </Stack>
        </Stack>
      </Modal>
    )
  }
)
