import React from 'react'
import { CampaignDialogView } from './CampaignDialogView'
import { ChildProps, graphql } from 'react-apollo'
import {
  createCampaign,
  getCampaigns,
  Campaign,
  AssetTagsResponse,
  getAssetTagsQuery_v2,
  CampaignCollectionSummary,
} from '../../../Api/campaigns'
import { ErrorDialog } from '../../../Components/ErrorDialog'
import { defaultWorkingHours } from '../../../Api/campaigns'
import { OverrideSkipBehavior } from '../../../Api/instreamPlacements_v2'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

interface State {
  hasCreationError: boolean
}

interface InputProps {
  collectionId: string
  isTemplateMode?: boolean
  campaignCollections?: CampaignCollectionSummary[]
  initialCampaign?: Campaign
  isAdminOrAccountManager: boolean
  onClose: () => void
  onUpdated: () => void
}

const now = new Date().toISOString()

const getDefaultCampaign = (collectionId: string): Campaign => ({
  id: '',
  collectionId: collectionId,
  asset: {
    vastRedirect: {
      url: '',
    },
    staticVast: null,
    smartclip: null,
    banner: null,
    gamBanner: null,
    customVast3: null,
    headerBiddingSource: null,
    teads: null,
    aniview: null,
    adformStaticTimed: null,
    seznamOpenRtb: null,
  },
  schedule: defaultWorkingHours,
  creationDate: now,
  editedTime: now,
  endDate: null,
  adType: null,
  overrideSkipBehavior: OverrideSkipBehavior.None,
  fixedSkipOffset: null,
  cappingEventType: 'impression',
  lastCappingEventType: 'impression',
  eventCount: 0,
  eventLimit: null,
  eventsPerUser: null,
  eventsPerUserInterval: null,
  timedEventInterval: null,
  timedEventLimit: null,
  lastEventTs: null,
  maximumAdLength: null,
  pathGlobs: [],
  pathBlacklistGlobs: [],
  priority: 5,
  startDate: now,
  status: 'live',
  targetedAssets: [],
  targetingRules: [],
  title: '',
  trackingHooks: [],
  runContext: 'browser',
})

class CreateCampaignDialogView extends React.Component<
  ChildProps<InputProps, AssetTagsResponse>,
  State
> {
  constructor(props: InputProps) {
    super(props)
    this.state = {
      hasCreationError: false,
    }
  }
  render() {
    if (this.props.data!.error) {
      return (
        <ErrorDialog
          onClose={this.props.onClose}
          title="Network error"
          message="Could not fetch asset tags. "
        />
      )
    }
    const isDataAvailable =
      !!this.props.data!.outstreamPlacementCollections_v2 &&
      !!this.props.data!.instreamPlacementCollections_v2
    if (!isDataAvailable) {
      return <React.Fragment />
    }
    return (
      <React.Fragment>
        <ToastContainer />
        <CampaignDialogView
          dialogTitle="Create Campaign"
          actionTitle={
            this.props.isTemplateMode ? 'Create and continue' : 'Create'
          }
          collectionId={this.props.collectionId}
          assetTagInfo={this.props.data! as AssetTagsResponse}
          campaign={
            this.props.initialCampaign ||
            getDefaultCampaign(this.props.collectionId)
          }
          campaignCollections={this.props.campaignCollections}
          isTemplateMode={this.props.isTemplateMode}
          isAdminOrAccountManager={this.props.isAdminOrAccountManager}
          onClose={this.props.onClose}
          onUpdated={async (c) => {
            try {
              const result = await createCampaign(c)
              await getCampaigns(this.props.collectionId)
              toast.success(`campaign ${c.title} was created`)
              this.props.onUpdated()
              if (!this.props.isTemplateMode) {
                this.props.onClose()
              }
              return result.data.campaignCollections.assets.create
            } catch (e) {
              console.warn(e)
              this.setState({ hasCreationError: true })
            }
          }}
          onUndo={async () => {
            await getCampaigns(this.props.collectionId)
            this.props.onUpdated()
          }}
        />
        {this.state.hasCreationError && (
          <ErrorDialog
            onClose={() => this.setState({ hasCreationError: false })}
            title="Network error"
            message="Could not create campaign."
          />
        )}
      </React.Fragment>
    )
  }
}

const withCreateGql = graphql<InputProps, AssetTagsResponse>(
  getAssetTagsQuery_v2,
  { options: () => ({ notifyOnNetworkStatusChange: true }) }
)
export const CreateCampaignDialog = withCreateGql(CreateCampaignDialogView)
