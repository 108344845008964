export function extractDomain(url: string) {
  try {
    const completeUrl = addDefaultScheme(url)
    const parsedUrl = new URL(completeUrl)
    return parsedUrl.hostname
  } catch (e) {
    return null
  }
}

function addDefaultScheme(url: string) {
  if (url.startsWith('http://') || url.startsWith('https://')) {
    return url
  }
  return `http://${url}`
}
