import React from 'react'
import { HourlyDistribution, defaultWorkingHours } from '../../Api/campaigns'
import { IField } from '../../Common/forms'
import {
  TextField,
  MessageBar,
  MessageBarType,
  Stack,
} from 'office-ui-fabric-react'
import isEqual from 'lodash/isEqual'
import { rotateArray } from '../../Utils/arrays'

interface InputProps {
  hourlyDistribution: HourlyDistribution
  weighted: boolean
  label: string
  disabled: boolean
  showErrors: boolean
  isStoredInGMT?: boolean
  onUpdate: (hourlyDistribution: HourlyDistribution, hasError: boolean) => void
}

interface State {
  hourlyDistribution: IField<string>
}

export class HourlyDistributionInput extends React.Component<
  InputProps,
  State
> {
  static unweightedHourlyDistributionRegex = /^\s*(\d{1,2})\s*-\s*(\d{1,2})\s*$/
  static weightedHourlyDistributionRegex =
    /^\s*(\d{1,2})\s*-\s*(\d{1,2})\s+(\d+)\s*$/
  static customHourlyDistributionDayRegex =
    /^#\s*([1-7])(?:\s*-\s*([2-7]))?\s*$/

  static hourlyDistributionToString(
    hourlyDistribution: HourlyDistribution,
    weighted: boolean
  ): string {
    const groupedHours = (hourList: number[]) =>
      hourList.reduce((groups: any[], hourWeight: number, index: number) => {
        if (!hourWeight) {
          return groups
        }
        const groupsLength = groups.length
        if (!groupsLength || hourList[index - 1] !== hourWeight) {
          groups.push({ start: index, end: index + 1, weight: hourWeight })
        } else {
          groups[groupsLength - 1].end = index + 1
        }
        return groups
      }, [])

    const groupedDays = Object.keys(hourlyDistribution)
      .filter(
        (key) =>
          key !== 'default' &&
          !!hourlyDistribution[key as keyof HourlyDistribution]
      )
      .reduce((groups: any[], key: string) => {
        const groupsLength = groups.length
        if (
          !groupsLength ||
          JSON.stringify(groups[groupsLength - 1].hourList) !==
            JSON.stringify(hourlyDistribution[key as keyof HourlyDistribution])
        ) {
          groups.push({
            start: Number(key.slice(1)) + 1,
            end: Number(key.slice(1)) + 1,
            hourList: hourlyDistribution[key as keyof HourlyDistribution],
          })
        } else {
          groups[groupsLength - 1].end = Number(key.slice(1)) + 1
        }
        return groups
      }, [])

    const groupToString = (group: any) =>
      `${group.start}-${group.end}${weighted ? ` ${group.weight}` : ''}`

    return [
      ...groupedHours(hourlyDistribution.default).map(groupToString),
      ...groupedDays.map(
        (group) =>
          `# ${
            group.start !== group.end
              ? `${group.start}-${group.end}`
              : `${group.start}`
          }\n${groupedHours(group.hourList).map(groupToString).join('\n')}`
      ),
    ].join('\n')
  }

  constructor(props: InputProps) {
    super(props)
    const { hourlyDistribution, weighted } = props

    this.state = {
      hourlyDistribution: {
        value: HourlyDistributionInput.hourlyDistributionToString(
          hourlyDistribution,
          weighted
        ),
        errorMessage: '',
      },
    }
    this.updateHourlyDistribution = this.updateHourlyDistribution.bind(this)
  }

  render() {
    const { showErrors, disabled, label, weighted } = this.props
    const { hourlyDistribution } = this.state
    const gap = 28
    return (
      <Stack gap={gap}>
        <TextField
          label={label}
          value={hourlyDistribution.value}
          multiline
          spellCheck={false}
          autoAdjustHeight
          resizable={false}
          autoComplete={'off'}
          onChange={(event: any, value?: string) =>
            value !== undefined && this.updateHourlyDistribution(value)
          }
          errorMessage={(showErrors && hourlyDistribution.errorMessage) || ''}
          disabled={disabled}
        />
        <MessageBar messageBarType={MessageBarType.info} isMultiline={true}>
          {`A ${
            weighted ? 'weighted ' : ''
          }distribution by hour of the day. The format for describing the intervals is the following:`}
          <li>{`<hour_start>-<hour_end>${weighted ? ' <weight>' : ''}`}</li>
          {'hour_start and hour_end need to be between 0-24.'}
          <br />
          {'hour_end is not inclusive of that hour.'}
          <br />
          {
            'If there is any day of the week that requires a different distribution, an alternative schedule can be set by adding it after a line: '
          }
          <li>{'# <day_start>[-<day_end>]'}</li>
        </MessageBar>
      </Stack>
    )
  }
  hourlyDistributionErrorMessage(hourlyDistribution: string) {
    const { weighted } = this.props
    const hourList = hourlyDistribution
      .split('\n')
      .map((it) => it.trim())
      .filter(Boolean)
    const hourlyDistributionRegex = weighted
      ? HourlyDistributionInput.weightedHourlyDistributionRegex
      : HourlyDistributionInput.unweightedHourlyDistributionRegex
    if (hourlyDistribution.trim() === '') {
      return 'hourly distribution cannot be empty'
    }
    if (
      hourList.find(
        (it) =>
          !hourlyDistributionRegex.test(it) &&
          !HourlyDistributionInput.customHourlyDistributionDayRegex.test(it)
      )
    ) {
      return 'does not follow expected format'
    }
    let errorMessage = ''
    for (let i = 0; i < hourList.length; i++) {
      const hourDistributionRegexRes =
        hourlyDistributionRegex.exec(hourList[i]) || []
      if (hourDistributionRegexRes) {
        const startHour = Number(hourDistributionRegexRes[1])
        const endHour = Number(hourDistributionRegexRes[2])
        if (startHour > 24 || endHour > 24) {
          errorMessage = 'hour cannot be bigger than 24'
          break
        }
        if (startHour >= endHour) {
          errorMessage = 'start hour has to be less than end hour'
          break
        }
      } else {
        const customDistributionRegexRes =
          hourlyDistributionRegex.exec(hourList[i]) || []
        const startDay = Number(customDistributionRegexRes[1])
        const endDay = Number(customDistributionRegexRes[2])
        if (startDay >= endDay) {
          errorMessage = 'custom start day has to be less than end day'
          break
        }
      }
    }
    return errorMessage
  }
  updateHourlyDistribution(inputString: string) {
    this.setState((state) => {
      const { onUpdate } = this.props
      const errorMessage = this.hourlyDistributionErrorMessage(inputString)
      const hourlyDistributionLines = inputString
        .split('\n')
        .map((it) => it.trim())
        .filter(Boolean)
      let lastProcessedLineIndex = 0
      const updatedHourlyDistribution: HourlyDistribution = {
        default: new Array(24).fill(0),
      }
      let currentDayHours = updatedHourlyDistribution.default
      while (lastProcessedLineIndex < hourlyDistributionLines.length) {
        lastProcessedLineIndex = this.evaluateHourlyDistributionForDay(
          hourlyDistributionLines,
          currentDayHours,
          lastProcessedLineIndex
        )
        if (lastProcessedLineIndex < hourlyDistributionLines.length) {
          const unprocessedLine =
            hourlyDistributionLines[lastProcessedLineIndex]
          currentDayHours = this.evaluateCustomHourlyDistributionDay(
            unprocessedLine,
            updatedHourlyDistribution
          )
          lastProcessedLineIndex++
        }
      }

      if (this.props.isStoredInGMT) {
        const convertedHourlyDistribution = this.convertHourlyDistributionToGMT(
          updatedHourlyDistribution
        )
        Object.keys(convertedHourlyDistribution).forEach((key) => {
          const _key = key as keyof HourlyDistribution
          updatedHourlyDistribution[_key] = convertedHourlyDistribution[_key]!
        })
      }
      onUpdate(updatedHourlyDistribution, Boolean(errorMessage))

      return {
        hourlyDistribution: {
          value: inputString,
          errorMessage,
        },
      }
    })
  }

  private evaluateHourlyDistributionForDay(
    hourlyDistributionLines: string[],
    currentDayHours: number[],
    startIndex = 0
  ): number {
    const { weighted } = this.props
    const hourlyDistributionRegex = weighted
      ? HourlyDistributionInput.weightedHourlyDistributionRegex
      : HourlyDistributionInput.unweightedHourlyDistributionRegex

    let i = startIndex
    for (i; i < hourlyDistributionLines.length; i++) {
      const line = hourlyDistributionLines[i]
      if (HourlyDistributionInput.customHourlyDistributionDayRegex.test(line)) {
        break
      }
      if (hourlyDistributionRegex.test(line)) {
        const hourlyResults = hourlyDistributionRegex.exec(line) || []
        const startHour = Number(hourlyResults[1])
        const endHour = Number(hourlyResults[2])
        const weight = weighted ? Number(hourlyResults[3]) : 1
        for (let i = startHour; i < endHour; i++) {
          currentDayHours[i] = weight
        }
      }
    }
    return i
  }

  private evaluateCustomHourlyDistributionDay(
    unprocessedLine: string,
    hourlyDistribution: HourlyDistribution
  ): number[] {
    const customHourlyDayResults =
      HourlyDistributionInput.customHourlyDistributionDayRegex.exec(
        unprocessedLine
      ) || []
    const startDay = Number(customHourlyDayResults[1])
    const endDay = customHourlyDayResults[2]
      ? Number(customHourlyDayResults[2])
      : startDay
    const currentDayHours = new Array(24).fill(0)
    for (let i = startDay - 1; i < endDay; i++) {
      hourlyDistribution[`_${i}` as keyof HourlyDistribution] = currentDayHours
    }
    return currentDayHours
  }

  private convertHourlyDistributionToGMT(
    hourlyDistribution: HourlyDistribution
  ) {
    const hourlyDistributionArray =
      convertHourlyDistributionToArray(hourlyDistribution)
    const offset = new Date().getTimezoneOffset() / 60
    const shiftedHourlyDistributionArray = rotateArray(
      hourlyDistributionArray,
      -offset
    )
    const convertedHourlyDistribution = removeDefaultValues(
      convertArrayToHourlyDistribution(
        shiftedHourlyDistributionArray,
        hourlyDistribution,
        -offset
      )
    )
    return convertedHourlyDistribution
  }
}

export function convertGMTToHourlyDistribution(
  hourlyDistribution: HourlyDistribution
) {
  const hourlyDistributionArray =
    convertHourlyDistributionToArray(hourlyDistribution)
  const offset = new Date().getTimezoneOffset() / 60
  const shiftedHourlyDistributionArray = rotateArray(
    hourlyDistributionArray,
    offset
  )
  const convertedHourlyDistribution = removeDefaultValues(
    convertArrayToHourlyDistribution(
      shiftedHourlyDistributionArray,
      hourlyDistribution,
      offset
    )
  )
  return convertedHourlyDistribution
}

function removeDefaultValues(hourlyDistribution: HourlyDistribution) {
  const result = {} as HourlyDistribution
  Object.keys(hourlyDistribution).forEach((key) => {
    const _key = key as keyof HourlyDistribution
    if (_key === 'default') {
      result[_key] = hourlyDistribution[_key]!
    }
    if (isEqual(hourlyDistribution[_key], hourlyDistribution.default)) {
      return
    }
    result[_key] = hourlyDistribution[_key]!
  })
  return result
}

function convertHourlyDistributionToArray(dict: HourlyDistribution) {
  let result = []
  for (let i = 0; i < 7; i++) {
    const key = ('_' + i) as keyof HourlyDistribution
    if (dict.hasOwnProperty(key)) {
      result.push(...dict[key]!)
    } else {
      result.push(...dict.default)
    }
  }
  return result
}

function convertArrayToHourlyDistribution(
  array: number[],
  hourlyDistribution: HourlyDistribution,
  offset: number
): HourlyDistribution {
  const result = {} as HourlyDistribution
  const chunkSize = 24
  for (let i = 0; i < 7; i++) {
    const start = i * chunkSize
    const end = start + chunkSize
    const key = ('_' + i) as keyof HourlyDistribution
    result[key] = array.slice(start, end)
  }
  result.default = rotateArray(hourlyDistribution.default, offset)
  return result
}

export function normalizeHourlyDistribution(
  hourlyDistribution: HourlyDistribution
) {
  const isHour = (hour: number) => hour > 0
  const hasAnHour = (row: number[] = []) => row.some(isHour)
  const isHourlyDistributionEmpty =
    !Object.values(hourlyDistribution).some(hasAnHour)
  if (isHourlyDistributionEmpty) {
    return defaultWorkingHours
  }
  const normalizedHourlyDistribution: HourlyDistribution = {
    ...hourlyDistribution,
  }
  return normalizedHourlyDistribution
}
