import React, { useState, useEffect } from 'react'
import {
  IBasePicker,
  ITag,
  MessageBarType,
  TextField,
  Toggle,
} from 'office-ui-fabric-react'
import InfoBox from './InfoBox'
import { TagInput } from '../../../Components/Input/TagInput'

interface GamBannerFormProps {
  errors: {
    slotId: string | undefined
    sizes: string | undefined
    autoRefreshEverySeconds: string | undefined
  }
  values: {
    slotId: string
    sizes: string[]
    autoRefreshEverySeconds: number | undefined
  }
  onChange: (_: {
    slotId: string
    sizes: string[]
    autoRefreshEverySeconds: number | undefined
  }) => void
  disabled: boolean
}

const SIZES: string[] = unique(
  [
    // Sizes from IAB
    [970, 250],
    [300, 50],
    [320, 50],
    [728, 90],
    [970, 90],
    [300, 1050],
    [160, 600],
    [300, 250],
    [20, 60],
    [120, 60],
    [640, 1136],
    [750, 1334],
    [1080, 1920],
    [120, 20],
    [168, 28],
    [216, 36],
    // Sizes from GAM
    [88, 31],
    [120, 30],
    [120, 60],
    [120, 90],
    [120, 240],
    [120, 600],
    [125, 125],
    [160, 600],
    [168, 28],
    [168, 42],
    [180, 150],
    [188, 141],
    [200, 200],
    [200, 446],
    [216, 36],
    [216, 54],
    [220, 90],
    [234, 60],
    [240, 133],
    [240, 400],
    [250, 250],
    [250, 360],
    [292, 30],
    [300, 31],
    [300, 50],
    [300, 75],
    [300, 100],
    [300, 300],
    [300, 600],
    [300, 1050],
    [320, 50],
    [320, 100],
    [320, 240],
    [320, 480],
    [336, 280],
    [468, 60],
    [480, 320],
    [570, 427],
    [580, 400],
    [728, 90],
    [750, 100],
    [750, 200],
    [750, 300],
    [768, 1024],
    [930, 180],
    [950, 90],
    [960, 90],
    [970, 66],
    [970, 90],
    [970, 250],
    [980, 90],
    [980, 120],
    [1024, 768],
  ]
    .sort((a, b) => {
      const aWidthIsBigger = a[0] > b[0]
      const aWidthIsSmaller = a[0] < b[0]
      if (aWidthIsBigger) {
        return 1
      }
      if (aWidthIsSmaller) {
        return -1
      }
      const aHeightIsBigger = a[1] > b[1]
      return aHeightIsBigger ? 1 : -1
    })
    .map((size) => size.join('x'))
    .concat(['fluid'])
)
const SIZES_TAGS: ITag[] = SIZES.map((size) => ({
  key: size,
  name: size,
}))
const DEFAULT_SIZES: string[] = SIZES.filter((size) => size !== 'fluid')
  .map((size) => size.split('x').map(Number))
  .filter(
    ([width, height]) =>
      width >= 150 &&
      width <= 300 &&
      height <= 300 &&
      width / height >= 2 / 3 &&
      width / height <= 16 / 9
  )
  .map((size) => size.join('x'))

const GamBannerForm: React.FC<GamBannerFormProps> = ({
  errors,
  values,
  onChange,
  disabled,
}: GamBannerFormProps) => {
  const [showAutoRefreshInput, setShowAutoRefreshInput] = useState(
    values.autoRefreshEverySeconds != null
  )
  const [tagInputComponent, setTagInputComponent] =
    useState<IBasePicker<ITag> | null>(null)
  const onResolveSuggestions = (filter: string) =>
    SIZES_TAGS.filter(
      (size) =>
        !values.sizes.includes(size.key) &&
        (size.name.includes(filter) || size.key.includes(filter))
    )
  const selectedSizes = unique(values.sizes).map((size) => ({
    key: size,
    name: size,
  }))
  useEffect(() => {
    if (!!values.sizes.length) {
      return
    }
    onChange({
      ...values,
      sizes: DEFAULT_SIZES,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <TextField
        label="Slot ID"
        errorMessage={errors.slotId}
        value={values.slotId}
        autoComplete="off"
        spellCheck={false}
        onChange={(evt: any, newValue?: string) =>
          onChange({
            ...values,
            slotId: newValue || '',
          })
        }
        disabled={disabled}
        resizable={false}
        required
      />
      <InfoBox type={MessageBarType.info}>
        The slot id is to the id that appears after the{' '}
        <code>googletag.defineSlot{'('}"..."</code> part of the embed script.
        <br />
        It should inputted without the quotes and should start with the /
        character. The player will auto-generate the ideal code to integrate for
        data collection and prioritization logic.
        <br />
        EX: <code>/32143212/abcdef.com/abcdef.com_caroda_slot</code>
      </InfoBox>
      <TagInput
        componentRef={(ref) => {
          setTagInputComponent(ref)
        }}
        label="Allowed Sizes"
        onResolveSuggestions={onResolveSuggestions}
        onChange={async (tags = []) => {
          onChange({
            ...values,
            sizes: tags.map(({ key }) => key),
          })
          // This is required to keep the suggestions panel open
          const tagInputComponentAsAny = tagInputComponent as any
          await waitMs(0)
          tagInputComponentAsAny.setState({ suggestionsVisible: true })
          const suggestions = onResolveSuggestions('')
          const suggestionsMinusLastTags = suggestions.filter(
            (suggestion) => !tags.some((tag) => tag.key === suggestion.key)
          )
          tagInputComponentAsAny.updateSuggestions(suggestionsMinusLastTags)
        }}
        selectedItems={selectedSizes}
        disabled={disabled}
        pickerLabels={{
          suggestionsHeader: 'Suggested sizes',
          noResults: 'No size found',
        }}
        errorMessage={errors.sizes}
        itemLimit={9999}
        required
      />
      <InfoBox type={MessageBarType.info}>
        Note that Caroda will always filter out any size bigger than the maximum
        available width for a position, regardless of the configured filter.
      </InfoBox>
      <Toggle
        label="Auto-Refresh"
        disabled={disabled}
        checked={showAutoRefreshInput}
        onChange={(_, checked: boolean | undefined) => {
          setShowAutoRefreshInput(!!checked)
          if (!checked) {
            onChange({
              ...values,
              autoRefreshEverySeconds: undefined,
            })
          }
        }}
      />
      {showAutoRefreshInput && (
        <TextField
          label="Interval (seconds)"
          type="number"
          errorMessage={errors.autoRefreshEverySeconds}
          value={
            values.autoRefreshEverySeconds
              ? values.autoRefreshEverySeconds.toString()
              : ''
          }
          autoComplete="off"
          spellCheck={false}
          onChange={(_: any, newValue?: string) =>
            onChange({
              ...values,
              autoRefreshEverySeconds: newValue
                ? parseInt(newValue)
                : undefined,
            })
          }
          disabled={disabled}
          resizable={false}
          required
          min={10}
          max={120}
        />
      )}
      {showAutoRefreshInput && (
        <InfoBox type={MessageBarType.info}>
          The position will request a new banner every N seconds. It will not
          refresh more than once when out of view.
        </InfoBox>
      )}
    </>
  )
}

export default GamBannerForm

async function waitMs(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

function unique<T>(array: T[]): T[] {
  return Array.from(new Set(array))
}
