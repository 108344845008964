import React from 'react'
import { Dropdown, MessageBarType, TextField } from 'office-ui-fabric-react'
import InfoBox from './InfoBox'
import { BannerScriptExecutionTiming } from '../../../Api/campaigns'

interface BannerFormProps {
  errors: {
    script: string | undefined
    executionTiming: string | undefined
  }
  values: {
    script: string
    executionTiming: BannerScriptExecutionTiming
  }
  onChange: (_: {
    script: string
    executionTiming: BannerScriptExecutionTiming
  }) => void
  disabled: boolean
}

const BannerForm: React.FC<BannerFormProps> = ({
  errors,
  values,
  onChange,
  disabled,
}: BannerFormProps) => (
  <>
    <TextField
      label="Banner Tag Script"
      errorMessage={errors.script}
      value={values.script}
      autoComplete={'off'}
      spellCheck={false}
      onChange={(evt: any, value?: string) =>
        onChange({
          script: value || '',
          executionTiming: values.executionTiming,
        })
      }
      disabled={disabled}
      multiline
      resizable={false}
      autoAdjustHeight
      required
    />
    <InfoBox>
      A HTML snippet representing a banner. It's activated asynchronously as a
      passback from other campaign types.
      {!usesPassback(values.script) && (
        <>
          <br />
          <br />
          The banner priority is set by default as the lowest as a way of
          avoiding compromising the rest of the priority chain.
          <br />
          <br />
          In order for the campaign to be prioritized arbitrarily, make use of
          the&nbsp;
          <code>passbackToCaroda()</code> function.
        </>
      )}
      {usesPassback(values.script) && (
        <>
          <br />
          <br />
          The banner source should NOT be prioritized among video campaigns,
          only among other banner campaigns.
        </>
      )}
    </InfoBox>
    {usesPassback(values.script) && (
      <InfoBox type={MessageBarType.warning}>
        Using the <code>passbackToCaroda()</code> function incorrectly can
        potentially interrupt the priority chain and prevent other campaigns
        from being delivered. Make sure it’s always called when there is no
        fill.
      </InfoBox>
    )}
    {isGamBanner(values.script) && (
      <InfoBox type={MessageBarType.warning}>
        Warning: the current banner passback seems to be a Google Ad Manager
        embed script. It’s strongly recommended to use the Google Ad Manager ad
        types.
      </InfoBox>
    )}
    <Dropdown
      label="Script Execution Timing"
      errorMessage={errors.executionTiming}
      selectedKey={values.executionTiming && values.executionTiming.toString()}
      onChange={(_, value?) => {
        if (!value) {
          return
        }
        const newExecutionTiming =
          value.key ===
          BannerScriptExecutionTiming.AS_SOON_AS_POSSIBLE.toString()
            ? BannerScriptExecutionTiming.AS_SOON_AS_POSSIBLE
            : BannerScriptExecutionTiming.WHEN_IN_VIEW
        onChange({
          script: values.script,
          executionTiming: newExecutionTiming,
        })
      }}
      options={[
        {
          key: BannerScriptExecutionTiming.AS_SOON_AS_POSSIBLE,
          text: 'As soon as possible',
        },
        { key: BannerScriptExecutionTiming.WHEN_IN_VIEW, text: 'When in view' },
      ]}
      placeholder="Select an option"
      disabled={disabled}
    />
    <InfoBox>
      This setting can only affect the banner campaign set in the last priority.
      <br />
      “When in view“ can only be used when <code>passbackToCaroda()</code>{' '}
      custom passback functionality is not used.
    </InfoBox>
  </>
)

export default BannerForm

function usesPassback(script: string): boolean {
  return script.includes('passbackToCaroda')
}

function isGamBanner(script: string): boolean {
  return (
    /(googletagservices.com|doubleclick.net)(\/tag\/js\/gpt|\/dbm\/ad)/.test(
      script
    ) || /googletag/.test(script)
  )
}
