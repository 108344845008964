import React from 'react'
import { Stack } from 'office-ui-fabric-react'
import { ChildProps, graphql } from 'react-apollo'
import {
  getAllCampaignCollectionSummariesQuery,
  CampaignCollectionSummaryResponse,
  CampaignCollectionSummary,
} from '../../Api/campaigns'
import { DeleteCampaignsCollectionDialog } from './DeleteCampaignsCollectionDialog'
import { RenameCampaignCollectionDialog } from './RenameCampaignCollectionDialog'
import { CreateCampaignDialog } from './CampaignDialog/CreateCampaignDialog'
import { ScreenHeader } from '../../Components/ScreenHeader'
import { ErrorScreen } from '../../Components/ErrorScreen'
import { CollectionIndicationScreen } from '../../Components/CollectionIndicationScreen'
import { CampaignList } from './CampaignsList'
import { get } from 'lodash'
import './CampaignCollectionScreen.css'
import { EditGlobalCampaignSettingsDialog } from './CampaignDialog/EditGlobalCampaignSettingsDialog'

interface State {
  openModal?: {
    type:
      | 'create-campaign'
      | 'create-template'
      | 'rename-collection'
      | 'delete-collection'
      | 'global-campaign-settings'
    campaignId?: string
  }
  selectCampaignMode: boolean
}

interface InputProps {
  collectionId: string
  isAdminOrAccountManager: boolean
}

type Props = ChildProps<InputProps, CampaignCollectionSummaryResponse>

class CampaignCollectionScreenView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      openModal: undefined,
      selectCampaignMode: false,
    }
  }
  render() {
    const { error, loading } = this.props.data!
    let responseError: Error | null = null
    if (
      !error &&
      !this.getCollection() &&
      this.props.data &&
      this.props.data!.campaignCollections
    ) {
      responseError = new Error('Collection not found')
    }
    if (error || responseError) {
      window.console.error(error || responseError)
      return this.renderError()
    }
    const isLoading = loading || !this.getCollection()
    return (
      <Stack
        className="screen"
        horizontalAlign="start"
        verticalAlign="start"
        verticalFill
        style={{ width: '100%' }}
      >
        <ScreenHeader
          path={[
            'Campaigns',
            isLoading ? 'Loading...' : this.getCollection()!.title,
          ]}
          buttons={[
            {
              name: 'Create Campaign',
              icon: 'Add',
              disabled: isLoading,
              onClick: () => this.createCampaign(),
            },
            {
              name: 'Create Campaigns from Template',
              icon: 'BuildQueueNew',
              disabled: isLoading,
              onClick: () => this.createTemplate(),
            },
            {
              name: 'Global campaign settings',
              icon: 'Settings',
              disabled: isLoading,
              onClick: () => this.globalCampaignSettings(),
            },
            {
              name: 'Collection settings',
              icon: 'ChevronDown',
              disabled: isLoading,
              menu: [
                {
                  name: 'Rename collection',
                  icon: 'edit',
                  onClick: () => this.renameCollectionPopup(),
                },
                {
                  name: 'Delete collection',
                  icon: 'delete',
                  onClick: () => this.deleteCollection(),
                },
              ].concat(
                this.props.isAdminOrAccountManager
                  ? [
                      {
                        name: 'Select Campaigns',
                        icon: 'Tab',
                        onClick: () =>
                          this.setState({ selectCampaignMode: true }),
                      },
                    ]
                  : []
              ),
            },
          ]}
        />
        <Stack
          className="campaign-collection-screen"
          horizontalAlign="center"
          verticalAlign="start"
          horizontal
          wrap
          styles={{
            root: {
              width: '100%',
              overflow: 'auto',
            },
            inner: {
              padding: 60,
            },
          }}
        >
          <CampaignList
            selectCampaignMode={this.state.selectCampaignMode}
            collectionId={this.props.collectionId}
            campaignCollections={this.props.data!.campaignCollections}
            isAdminOrAccountManager={this.props.isAdminOrAccountManager}
            onCancel={() => this.setState({ selectCampaignMode: false })}
          />
        </Stack>
        {isLoading ? (
          <></>
        ) : (
          <>
            {this.renderRenameCollectionDialog(this.props.data!.refetch)}
            {this.renderDeleteCollectionDialog(this.props.data!.refetch)}
            {this.renderCampaignCreateDialog(this.props.data!.refetch)}
            {this.renderTemplateCreateDialog(this.props.data!.refetch)}
            {this.renderGlobalCampaignSettingsDialog(this.props.data!.refetch)}
          </>
        )}
      </Stack>
    )
  }
  renderError() {
    return (
      <ErrorScreen
        errorText={`This campaign collection could not be found. Most likely, you've opened an older link of a collection
        that is now deleted. If the error persists and your collection can not be selected from the navigation menu,
        please contact support.`}
      />
    )
  }
  getCollection(): CampaignCollectionSummary | undefined {
    return get(this.props.data!, 'campaignCollections', []).find(
      (c: CampaignCollectionSummary) => c.id === this.props.collectionId
    )
  }
  renderRenameCollectionDialog(refetch: () => void) {
    if (
      !this.state.openModal ||
      this.state.openModal.type !== 'rename-collection'
    ) {
      return <React.Fragment />
    }
    return (
      <RenameCampaignCollectionDialog
        collectionId={this.props.collectionId}
        onUpdated={() => refetch()}
        onClose={() => this.setState({ openModal: undefined })}
      />
    )
  }
  renderDeleteCollectionDialog(refetch: () => void) {
    if (
      !this.state.openModal ||
      this.state.openModal.type !== 'delete-collection'
    ) {
      return <React.Fragment />
    }
    return (
      <DeleteCampaignsCollectionDialog
        collectionId={this.props.collectionId}
        onUpdated={() => refetch()}
        onClose={() => this.setState({ openModal: undefined })}
      />
    )
  }
  renderCampaignCreateDialog(refetch: () => void) {
    if (
      !this.state.openModal ||
      this.state.openModal.type !== 'create-campaign'
    ) {
      return <React.Fragment />
    }
    return (
      <CreateCampaignDialog
        collectionId={this.props.collectionId}
        isAdminOrAccountManager={this.props.isAdminOrAccountManager}
        onUpdated={() => refetch()}
        onClose={() => this.setState({ openModal: undefined })}
      />
    )
  }
  renderTemplateCreateDialog(refetch: () => void) {
    if (
      !this.state.openModal ||
      this.state.openModal.type !== 'create-template'
    ) {
      return <React.Fragment />
    }
    return (
      <CreateCampaignDialog
        collectionId={this.props.collectionId}
        campaignCollections={this.props.data!.campaignCollections}
        isTemplateMode={true}
        isAdminOrAccountManager={this.props.isAdminOrAccountManager}
        onUpdated={() => {}}
        onClose={() => {
          refetch()
          this.setState({ openModal: undefined })
        }}
      />
    )
  }
  renderGlobalCampaignSettingsDialog(refetch: () => void) {
    if (
      !this.state.openModal ||
      this.state.openModal.type !== 'global-campaign-settings'
    ) {
      return <React.Fragment />
    }
    return (
      <EditGlobalCampaignSettingsDialog
        onUpdated={() => refetch()}
        onClose={() => this.setState({ openModal: undefined })}
      />
    )
  }
  renameCollectionPopup() {
    this.setState({ openModal: { type: 'rename-collection' } })
  }
  deleteCollection() {
    this.setState({ openModal: { type: 'delete-collection' } })
  }
  createCampaign() {
    this.setState({ openModal: { type: 'create-campaign' } })
  }
  createTemplate() {
    this.setState({ openModal: { type: 'create-template' } })
  }
  globalCampaignSettings() {
    this.setState({ openModal: { type: 'global-campaign-settings' } })
  }
}

const withGql = graphql<InputProps, CampaignCollectionSummaryResponse>(
  getAllCampaignCollectionSummariesQuery,
  { options: () => ({ notifyOnNetworkStatusChange: true }) }
)
const CampaignCollectionScreenContent = withGql(CampaignCollectionScreenView)

class CampaignCollectionScreen extends React.Component<
  ChildProps<InputProps, CampaignCollectionSummaryResponse>,
  State
> {
  render() {
    if (!this.props.collectionId) {
      return (
        <Stack
          className="screen"
          horizontalAlign="start"
          verticalAlign="start"
          verticalFill
          style={{ width: '100%' }}
        >
          <ScreenHeader path={['Campaigns']} buttons={[]} />
          <CollectionIndicationScreen text="Please select a campaign collection from the navigation menu on the left." />
        </Stack>
      )
    }
    return (
      <CampaignCollectionScreenContent
        collectionId={this.props.collectionId}
        isAdminOrAccountManager={this.props.isAdminOrAccountManager}
      ></CampaignCollectionScreenContent>
    )
  }
}

export { CampaignCollectionScreen }
