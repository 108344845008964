import React from 'react'
import { CampaignDialogView } from './CampaignDialogView'
import { ChildProps, graphql } from 'react-apollo'
import {
  AssetTagsResponse,
  CampaignResponse,
  getCampaignsQuery_v2,
} from '../../../Api/campaigns'
import { ErrorDialog } from '../../../Components/ErrorDialog'

interface State {
  hasEditError: boolean
}

interface InputProps {
  id: string
  collectionId: string
  isAdminOrAccountManager: boolean
  onClose: () => void
  onUpdated: () => void
}

class ViewCampaignDialogView extends React.Component<
  ChildProps<InputProps, CampaignResponse>,
  State
> {
  constructor(props: InputProps) {
    super(props)
    this.state = { hasEditError: false }
  }
  render() {
    if (this.props.data!.error) {
      return (
        <ErrorDialog
          onClose={this.props.onClose}
          title="Network error"
          message="Could not fetch campaign. "
        />
      )
    }
    if (!this.props.data!.campaignCollections) {
      return <React.Fragment />
    }
    const campaign = this.props.data!.campaignCollections[0].assets.find(
      (c) => c.id === this.props.id
    )
    if (!campaign) {
      return (
        <ErrorDialog
          onClose={this.props.onClose}
          title="Network error"
          message="Could not find campaign. "
        />
      )
    }
    return (
      <React.Fragment>
        <CampaignDialogView
          dialogTitle="View Campaign"
          actionTitle="Use as template"
          collectionId={this.props.collectionId}
          disabled={true}
          isSubmitDisabled={['transcoding', 'transcoding_failed'].includes(
            campaign.status
          )}
          assetTagInfo={this.props.data! as AssetTagsResponse}
          campaign={campaign}
          isAdminOrAccountManager={this.props.isAdminOrAccountManager}
          onClose={this.props.onClose}
          onUpdated={async (c) => {
            this.props.onUpdated()
            this.props.onClose()
          }}
        />
      </React.Fragment>
    )
  }
}

const withGql = graphql<InputProps, CampaignResponse>(getCampaignsQuery_v2, {
  options: ({ collectionId }) => ({
    notifyOnNetworkStatusChange: true,
    variables: { collectionId },
  }),
})

export const ViewCampaignDialog = withGql(ViewCampaignDialogView)
