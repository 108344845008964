import React from 'react'
import {
  Text,
  TextField,
  Separator,
  MessageBar,
  MessageBarType,
} from 'office-ui-fabric-react'
import { ChildProps, graphql } from 'react-apollo'
import { ErrorDialog } from '../../Components/ErrorDialog'
import { LargeDialog } from '../../Components/LargeDialog'
import {
  getConnectToWebsiteQueryQuery,
  ConnectToWebsiteResponse,
  VideoCollectionMigrationSettings,
  setVideoCollectionMigrationRules,
} from '../../Api/videoAssets'
import { get } from 'lodash'

interface State {
  form?: {
    allowedExternal: string
    allowedMigration: string
  }
  isSubmitting: boolean
  hasError: boolean
}

interface InputProps {
  isOpen: boolean
  collectionId: string
  onClose: () => void
}

const getInitialState = () => ({
  isSubmitting: false,
  hasError: false,
  form: undefined,
})

class ConnectWithWebsiteDialogView extends React.Component<
  ChildProps<InputProps, ConnectToWebsiteResponse>,
  State
> {
  constructor(props: any) {
    super(props)
    this.state = getInitialState()
  }
  render() {
    if (this.props.data!.error) {
      return (
        <ErrorDialog
          onClose={this.props.onClose}
          title="Network error"
          message="Could not fetch collection parameters. "
        />
      )
    }
    if (!this.props.data!.session) {
      return <React.Fragment />
    }
    const libraryUrl = 'https://publisher.caroda.io/videoPlayer/caroda.min.js'
    const integrationToken = this.props.data!.session.integrationToken
    const configuredUrl = `${libraryUrl}?vcid=${this.props.collectionId}&ctok=${integrationToken}`
    return (
      <LargeDialog
        title="Connect with website"
        buttons={[
          {
            name: 'Cancel',
            action: () => this.close(),
            disabled: this.state.isSubmitting,
          },
          {
            name: 'Save',
            primary: true,
            action: () => this.submit(this.props.data!.refetch),
            disabled: this.state.hasError || this.state.isSubmitting,
          },
        ]}
        onClose={this.props.onClose}
      >
        <h3>Integration</h3>
        <Text>
          Add the following <b>integration script</b> to your website and ask
          for support for an integration to your site. Your videos will be
          migrated and synchronized automatically with this collection. The
          integration can be used to deliver both instream and outstream ads,
          based on your preference. For optimum caching performance, add the{' '}
          <b>
            <i>crossorigin="anonymous"</i>
          </b>{' '}
          attribute to the script tag.
        </Text>
        <TextField readOnly value={configuredUrl} multiline resizable={false} />
        <Separator />
        <h3>Synchronization rules</h3>
        <Text>
          Whitelist of rules for the video source urls that are allowed to be{' '}
          <b>redirected</b> through the player. The rules are in GLOB format,
          with one rule per line. An empty list allows all.
        </Text>
        <TextField
          multiline
          autoAdjustHeight
          resizable={false}
          value={this.getTextField('allowedExternal')}
          onChange={(evt: any, newValue?: string) =>
            this.onChange('allowedExternal', newValue || '')
          }
        />
        <Text>
          Whitelist of rules for the video source urls that are allowed to be{' '}
          <b>transcoded</b> to this collection. The rules are in GLOB format,
          with one rule per line. An empty list allows all.
        </Text>
        <TextField
          multiline
          autoAdjustHeight
          resizable={false}
          value={this.getTextField('allowedMigration')}
          onChange={(evt: any, newValue?: string) =>
            this.onChange('allowedMigration', newValue || '')
          }
        />
        {this.state.hasError && (
          <MessageBar
            messageBarType={MessageBarType.error}
            isMultiline={true}
            styles={{ root: { marginTop: 10 } }}
          >
            Could not update encoding settings. If the problem persist, please
            contact support.
          </MessageBar>
        )}
      </LargeDialog>
    )
  }
  onChange(key: 'allowedExternal' | 'allowedMigration', newValue: string) {
    const defaultForm = {
      allowedExternal: this.getDefaultTextField('allowedExternal'),
      allowedMigration: this.getDefaultTextField('allowedMigration'),
    }
    const form = this.state.form! || defaultForm
    this.setState({ form: { ...form, [key]: newValue } })
  }
  getFieldValue(key: 'allowedExternal' | 'allowedMigration') {
    return this.getTextField(key)
      .split('\n')
      .filter((r) => r.trim().length > 0)
      .map((r) => r.trim())
  }
  getTextField(key: 'allowedExternal' | 'allowedMigration') {
    const form = this.state.form
    if (!form) {
      return this.getDefaultTextField(key)
    }
    return form[key]
  }
  getDefaultTextField(key: 'allowedExternal' | 'allowedMigration') {
    const collection = get(
      this.props.data!,
      'videoCollections[0]'
    ) as VideoCollectionMigrationSettings
    if (!collection) {
      return ''
    }
    return collection[key].filter((r) => r.trim().length > 0).join('\n')
  }
  submit(refetch: () => void) {
    this.setState({ isSubmitting: true })
    setVideoCollectionMigrationRules(
      this.props.collectionId,
      this.getFieldValue('allowedExternal'),
      this.getFieldValue('allowedMigration')
    )
      .then(() => this.close())
      .then(refetch)
      .catch((e) => {
        console.error(e)
        this.setState({ hasError: true, isSubmitting: false })
      })
    return false
  }
  close() {
    this.props.onClose()
  }
}

const withGql = graphql<InputProps, ConnectToWebsiteResponse>(
  getConnectToWebsiteQueryQuery,
  {
    options: ({ collectionId }) => ({
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
      variables: { collectionId },
    }),
  }
)
export const ConnectWithWebsiteDialog = withGql(ConnectWithWebsiteDialogView)
