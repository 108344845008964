import React from 'react'
import { Depths } from '@uifabric/fluent-theme/lib/fluent/FluentDepths'
import {
  DetailsListLayoutMode,
  DetailsList,
  CheckboxVisibility,
  SelectionMode,
  Stack,
  Spinner,
  SpinnerSize,
  IColumn,
  SearchBox,
  IconButton,
  TooltipHost,
  DirectionalHint,
  Selection,
  IObjectWithKey,
  DefaultButton,
} from 'office-ui-fabric-react'
import { debounce } from 'lodash'
import './TableView.css'

interface State<T> {
  cachedRows?: { cacheKey: string; data: T[] }
  sortedColumn?: {
    direction: 'ascending' | 'descending'
    columnKey: string
  }
  textSearch: string
  textSearchShown: boolean
  updateTextSearch: (ev: Event) => void
}

interface InputProps<T> {
  columns: IColumn[]
  rows?: T[]
  onRowClick: (row: T, e: React.MouseEvent<HTMLElement>) => void
  rowToCacheKey: (row: T) => string
  postSort?: (a: T, b: T, rows: T[]) => number
  cacheKey: string
  isSelectable?: boolean
  onSelectionChanged?: (selection: IObjectWithKey[]) => void
  onCancel?: () => void
}

export class TableView<T> extends React.Component<InputProps<T>, State<T>> {
  private selection: Selection
  constructor(params: any) {
    super(params)
    this.selection = new Selection({
      onSelectionChanged: () =>
        this.props.onSelectionChanged
          ? this.props.onSelectionChanged(this.selection.getSelection())
          : null,
    })
    this.state = {
      sortedColumn: undefined,
      textSearch: '',
      textSearchShown: false,
      updateTextSearch: (ev) => {
        if (!ev || !ev.target) {
          return
        }
        if (this.state.textSearch.length) {
          return
        }
        if (
          !(ev.target as HTMLElement).className
            .toString()
            .match(/ms-SearchBox-field/)
        ) {
          this.setState({ textSearchShown: false })
        }
      },
    }
    document.addEventListener('mousedown', this.state.updateTextSearch)
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.state.updateTextSearch)
  }
  componentDidUpdate(): void {
    if (!this.props.isSelectable) {
      this.selection.setAllSelected(false)
    }
  }
  render() {
    const rows = this.getSortedRows()
    if (!rows) {
      return this.renderLoading()
    }
    const filteredRows = rows.filter(
      (r) =>
        JSON.stringify(r)
          .replace(/"[^"]+":/g, '')
          .toLowerCase()
          .indexOf(this.state.textSearch.toLowerCase()) !== -1
    )
    return (
      <React.Fragment>
        <div className="caroda-table-view">
          <DetailsList
            checkboxVisibility={
              this.props.isSelectable
                ? CheckboxVisibility.always
                : CheckboxVisibility.hidden
            }
            items={filteredRows}
            columns={this.getColumns()}
            setKey="set"
            layoutMode={DetailsListLayoutMode.justified}
            enableUpdateAnimations={true}
            onShouldVirtualize={() => filteredRows.length > 100}
            selection={this.selection}
            selectionMode={
              this.props.isSelectable
                ? SelectionMode.multiple
                : SelectionMode.none
            }
            selectionPreservedOnEmptyClick={true}
            ariaLabelForSelectionColumn="Toggle selection"
            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
            checkButtonAriaLabel="select row"
            styles={{ root: { boxShadow: Depths.depth4 } }}
          />
          <div style={{ height: 64 }}></div>
        </div>
        {this.renderFilter()}
        {this.props.isSelectable && this.renderCancelButton()}
      </React.Fragment>
    )
  }
  renderFilter() {
    return (
      <Stack
        horizontal
        horizontalAlign="start"
        styles={{
          root: {
            padding: 5,
            background: this.state.textSearchShown
              ? 'rgba(0, 137, 255, 0.16)'
              : '#fff',
            boxShadow:
              'rgba(0, 0, 0, 0.133) 0px 1.6px 3.6px 0px, rgba(0, 0, 0, 0.11) 0px 0.3px 0.9px 0px;',
            position: 'absolute',
            transition: 'all 0.3s',
            width: this.state.textSearchShown ? 210 : 'auto',
            top: 60,
            right: 26,
          },
        }}
      >
        {this.state.textSearchShown ? (
          <SearchBox
            autoFocus
            placeholder="Filter"
            iconProps={{ iconName: 'Filter' }}
            value={this.state.textSearch}
            spellCheck={false}
            disableAnimation={true}
            autoComplete={'off'}
            styles={{ root: { width: '100%' } }}
            onChange={debounce(
              (value?: string) => this.setState({ textSearch: value || '' }),
              1e3
            )}
            onClear={() => this.setState({ textSearchShown: false })}
          />
        ) : (
          <TooltipHost
            className={'table-tooltip'}
            content="Filter table"
            tooltipProps={{
              delay: 0,
              directionalHint: DirectionalHint.rightCenter,
            }}
          >
            <IconButton
              iconProps={{ iconName: 'filter' }}
              onClick={() => this.setState({ textSearchShown: true })}
            />
          </TooltipHost>
        )}
      </Stack>
    )
  }
  renderCancelButton() {
    return (
      <Stack
        horizontal
        horizontalAlign="start"
        styles={{
          root: {
            padding: 5,
            position: 'absolute',
            transition: 'all 0.3s',
            top: 110,
            right: 20,
          },
        }}
      >
        <DefaultButton
          onClick={() => {
            this.selection.setAllSelected(false)
            if (this.props.onCancel) this.props.onCancel()
          }}
          text={'Cancel'}
          iconProps={{ iconName: 'Cancel' }}
          styles={{ root: { width: 120 } }}
        />
      </Stack>
    )
  }
  renderLoading() {
    return (
      <Stack
        horizontalAlign="center"
        verticalAlign="center"
        verticalFill
        style={{ width: '100%' }}
      >
        <Spinner
          size={SpinnerSize.large}
          labelPosition={'right'}
          label="Loading"
        />
      </Stack>
    )
  }
  getRows(): T[] | undefined {
    const rows = this.props.rows
    if (!rows) {
      return
    }
    if (!rows.length) {
      return rows
    }
    if (this.props.rowToCacheKey(rows[0]) !== this.props.cacheKey) {
      return
    }
    return rows
  }
  getSortedRows(): T[] | undefined {
    const data = this.getRows()
    if (!this.state.sortedColumn || !data) {
      if (data && this.props.postSort) {
        return data.sort((a, b) => this.props.postSort!(a, b, data))
      }
      return data
    }
    const columns = this.getColumns()
    const sortedKey = this.state.sortedColumn!.columnKey
    const sortedDir =
      this.state.sortedColumn!.direction === 'ascending' ? 1 : -1
    const sortedFieldName = columns.find((c) => c.key === sortedKey)!.fieldName!
    const sortableField = (a: any) => {
      let s = a[sortedFieldName]
      if (Array.isArray(s)) {
        s = s
          .map((s) => s.trim())
          .filter(Boolean)
          .join(',')
      }
      if (typeof s === 'string') {
        s = (s as String).toLowerCase()
      }
      return s
    }
    const list = data.slice()
    list
      .sort((a, b) => {
        const aS = sortableField(a)
        const bS = sortableField(b)
        const delta = aS > bS ? 1 : -1
        if (aS === '') {
          return 1
        }
        if (bS === '') {
          return -1
        }
        return delta * sortedDir
      })
      .forEach((i, index) => {
        ;(i as any).index = index
      })
    if (this.props.postSort) {
      console.log(list.map((l: any) => [l.id, l.index, l.parentId]))
      list.sort((a, b) => this.props.postSort!(a, b, list))
      console.log(
        'sorted',
        list.map((l: any) => [l.id, l.index, l.sortKey, l.parentId])
      )
    }
    return list
  }
  getColumns(): IColumn[] {
    return this.props.columns.map((menuItem) => ({
      ...menuItem,
      onColumnClick: (evt, col) => this.sortColumn(evt, col),
      isSorted:
        Boolean(this.state.sortedColumn) &&
        this.state.sortedColumn!.columnKey === menuItem.key,
      isSortedDescending:
        this.state.sortedColumn &&
        this.state.sortedColumn.direction === 'descending' &&
        this.state.sortedColumn.columnKey === menuItem.key,
      onRender: (a: T) => (
        <React.Fragment>
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
            }}
            onMouseDown={(e) => {
              this.props.onRowClick(a, e)
            }}
          ></div>
          {menuItem.onRender
            ? menuItem.onRender(a)
            : (a as any)[menuItem.fieldName as string]}
        </React.Fragment>
      ),
    }))
  }

  sortColumn(ev: React.MouseEvent<HTMLElement>, column: IColumn) {
    const currentSort = this.state.sortedColumn
    if (!currentSort || currentSort.columnKey !== column.key) {
      this.setState({
        sortedColumn: {
          columnKey: column.key,
          direction: 'ascending',
        },
      })
      return
    }
    if (this.state.sortedColumn!.direction === 'ascending') {
      this.setState({
        sortedColumn: {
          columnKey: column.key,
          direction: 'descending',
        },
      })
      return
    }
    this.setState({ sortedColumn: undefined })
  }
}
