import React from 'react'
import {
  AssetTagsResponse,
  Campaign,
  CampaignResponse,
  getAssetTagsQuery_v2,
} from '../../../Api/campaigns'
import { ChildProps, graphql } from 'react-apollo'
import { ITag, Separator, Stack, TextField } from 'office-ui-fabric-react'
import { getAssetTags } from '../../../Functions/assetTags'
import { TagInput } from '../../../Components/Input/TagInput'
import { HalfWidthStyle } from '../../../Components/HalfWidthStyle'
import TemplateTitle from '../../../Components/Input/TemplateTitle'
import {
  renderedTitleToTemplateTitle,
  templateTitleToRenderedTitle,
} from '../../../Functions/templateTitle'

interface Error {
  missingTargetedAssets?: string
  missingTitle?: string
}

export interface CampaignsWithInitialTargetedAssets extends Campaign {
  initialTargetedAssets: string[]
  initialTitle: string
  templateTitle?: string
  error: Error
}

interface Props {
  selectedAccount: string
  isDisabled: boolean
  campaignsToMigrate: CampaignsWithInitialTargetedAssets[]
  originalTags: ITag[]
  originalAssetTagsInfo: AssetTagsResponse
  gap: number
  onUpdated: (campaigns: CampaignsWithInitialTargetedAssets[]) => void
}

const CampaignSelectedFormView: React.FC<
  ChildProps<Props, CampaignResponse>
> = ({
  data,
  isDisabled,
  campaignsToMigrate,
  originalAssetTagsInfo,
  originalTags,
  gap,
  onUpdated,
}) => {
  const { error, loading } = data!
  const assetTagInfo = data as AssetTagsResponse

  if (error || loading) {
    return <React.Fragment />
  }

  const tags = getAssetTags(data! as AssetTagsResponse)
  const onResolveSuggestions = (filter: string, tagList?: ITag[]) => {
    const filterRank = (t: ITag) =>
      t.name.toLowerCase().indexOf(filter.toLowerCase())
    return tags
      .filter((t) => filterRank(t) !== -1)
      .filter(
        (t) =>
          !(tagList || []).find((t2) => t2.key === removeIntegration(t).key)
      )
      .sort((a, b) => filterRank(a) - filterRank(b))
  }

  return (
    <Stack gap={8}>
      {campaignsToMigrate.map((campaign) => {
        const updateCampaign = updateCampaignFn(campaign)
        const originalCampaign = {
          ...campaign,
          targetedAssets: campaign.initialTargetedAssets,
        }
        const templateTitle =
          campaign.templateTitle !== undefined
            ? campaign.templateTitle
            : renderedTitleToTemplateTitle(
                campaign.initialTitle,
                originalCampaign,
                originalAssetTagsInfo
              )
        return (
          <Stack>
            <h3>
              {campaign.initialTitle} / {campaign.title}
            </h3>
            <Stack horizontal>
              <HalfWidthStyle gap={gap}>
                <TextField
                  label="Original Title"
                  value={campaign.initialTitle}
                  disabled={true} // READONLY
                />
              </HalfWidthStyle>
              <div
                style={{
                  width: gap,
                }}
              ></div>
              <HalfWidthStyle gap={gap}>
                <TemplateTitle
                  campaign={campaign}
                  onChange={(templateTitle) => {
                    updateCampaign((campaign) => ({
                      ...campaign,
                      templateTitle: templateTitle,
                      title: templateTitleToRenderedTitle(
                        templateTitle,
                        campaign,
                        assetTagInfo
                      ),
                    }))
                  }}
                  templateTitle={templateTitle}
                  assetTagInfo={assetTagInfo}
                  disabled={isDisabled}
                  gap={32}
                  errorMessage={campaign.error.missingTitle || ''}
                />
              </HalfWidthStyle>
            </Stack>
            <Stack horizontal>
              <HalfWidthStyle gap={gap}>
                <TagInput
                  key={`originalAssets_${campaign.id}`}
                  label={`Original Assets`}
                  pickerLabels={{
                    noResults: 'Placement tags not found',
                    suggestionsHeader: 'Placement tags',
                  }}
                  onResolveSuggestions={onResolveSuggestions}
                  selectedItems={
                    campaign.initialTargetedAssets
                      .map((s) => originalTags.find((t) => t.key === s))
                      .filter(Boolean) as ITag[]
                  }
                  disabled={true} // READONLY
                  onChange={() => {}}
                />
              </HalfWidthStyle>
              <div
                style={{
                  width: gap,
                }}
              ></div>
              <HalfWidthStyle gap={gap}>
                <TagInput
                  key={`newTargetedAssets_${campaign.id}`}
                  label={`New targeted Assets`}
                  pickerLabels={{
                    noResults: 'Placement tags not found',
                    suggestionsHeader: 'Placement tags',
                  }}
                  errorMessage={campaign.error.missingTargetedAssets || ''}
                  required={true}
                  onResolveSuggestions={onResolveSuggestions}
                  onChange={async (value?: ITag[]) => {
                    if (!value) {
                      return
                    }
                    updateCampaign((campaign) => {
                      const targetedAssets = value.map((t) => t.key)
                      const updatedCampaign = {
                        ...campaign,
                        targetedAssets,
                      }
                      const title = templateTitleToRenderedTitle(
                        templateTitle,
                        updatedCampaign,
                        assetTagInfo
                      )
                      return {
                        ...updatedCampaign,
                        title,
                      }
                    })
                  }}
                  itemLimit={50}
                  selectedItems={campaign.targetedAssets.map(
                    (s) => tags.find((t) => t.key === s) as ITag
                  )}
                  disabled={isDisabled}
                />
              </HalfWidthStyle>
            </Stack>
            <Separator styles={{ root: { width: '100%' } }}></Separator>
          </Stack>
        )
      })}
    </Stack>
  )

  function updateCampaignFn(campaign: Campaign) {
    return (callback: (updatedCampaign: Campaign) => {}) => {
      const newCampaigns = campaignsToMigrate.map((c) => {
        if (c.id === campaign.id) {
          return callback(c)
        }
        return c
      })
      onUpdated(newCampaigns as CampaignsWithInitialTargetedAssets[])
    }
  }
}

function removeIntegration(tag: ITag): ITag {
  const containsIntegrationLevel = (key: string) => key.split('/').length > 2
  const removeIntegrationLevel = (key: string) => {
    const chunks = key.split('/')
    return [chunks[0], ...chunks.slice(2)].join('/')
  }
  return {
    ...tag,
    key: containsIntegrationLevel(tag.key)
      ? removeIntegrationLevel(tag.key)
      : tag.key,
  }
}

const withGql = graphql<Props, CampaignResponse>(getAssetTagsQuery_v2, {
  options: ({ selectedAccount }) => ({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    variables: { selectedAccount },
    context: { headers: { impersonate: selectedAccount } },
  }),
})

export default withGql(CampaignSelectedFormView)
