import {
  Modal,
  Separator,
  Stack,
  Text,
  DialogFooter,
  DefaultButton,
  PrimaryButton,
  IButtonProps,
  IStyle,
} from 'office-ui-fabric-react'
import React from 'react'

interface State {}

export interface InputProps {
  title: string
  buttons: {
    name: string
    action: () => void
    disabled?: boolean
    primary?: boolean
  }[]
  width?: 600 | 900
  isBlocking?: boolean
  onClose: () => void
  largeButtons?: boolean
  scrollable?: boolean
}

export class LargeDialog extends React.Component<InputProps, State> {
  render() {
    const width = this.props.width || 600
    const scrollableContent: IStyle | undefined =
      this.props.scrollable === false
        ? {
            overflow: 'hidden',
          }
        : undefined
    return (
      <Modal
        isOpen={true}
        isDarkOverlay={true}
        isBlocking={this.props.isBlocking}
        onDismiss={this.props.onClose}
        styles={{
          scrollableContent: scrollableContent,
          main: {
            width,
            maxWidth: width,
            minWidth: 120,
            borderRadius: 2,
          },
        }}
      >
        <Stack>
          <Stack
            styles={{
              root: {
                background: 'rgb(2, 151, 220)',
                height: 67,
                paddingLeft: 24,
                paddingTop: 16,
                paddingRight: 48,
                zIndex: 1e6,
              },
            }}
          >
            <Text style={{ color: '#fff', fontWeight: 600, fontSize: 20 }}>
              {this.props.title}
            </Text>
          </Stack>
          <Stack styles={{ root: { padding: 24 } }} gap={10}>
            {this.props.children}
            <Separator />
            <DialogFooter>
              {this.props.buttons.map((b, i) => {
                const buttonProps: IButtonProps = {
                  key: i,
                  text: b.name,
                  disabled: b.disabled,
                  onClick: b.action,
                } as IButtonProps
                if (this.props.largeButtons) {
                  buttonProps.styles = { root: { padding: 20 } }
                }
                return b.primary ? (
                  <PrimaryButton {...buttonProps} />
                ) : (
                  <DefaultButton {...buttonProps} />
                )
              })}
            </DialogFooter>
          </Stack>
        </Stack>
      </Modal>
    )
  }
}
