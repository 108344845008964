import React from 'react'
import { LargeDialog } from '../../../Components/LargeDialog'
import debounce from 'lodash/debounce'
import parseVAST from 'iab-vast-parser/lib'
import VastEditor from './VastEditor'
import formatXml from 'xml-formatter'

interface VastEditorDialogProps {
  content: string
  setContent: (content: string) => void
  onClose: () => void
}

interface VastEditorDialogState {
  editContent: string
  isEmpty: boolean
  error: string
}

export class VastEditorDialog extends React.Component<
  VastEditorDialogProps,
  VastEditorDialogState
> {
  constructor(props: VastEditorDialogProps) {
    super(props)
    this.state = {
      editContent: this.props.content,
      isEmpty: !this.props.content.trim(),
      error: '',
    }
  }
  validateVast(vastContent: string): boolean {
    try {
      const xmlVast = new DOMParser().parseFromString(vastContent, 'text/xml')
      if (!!xmlVast.querySelector('parsererror')) {
        throw new Error()
      }
    } catch (err) {
      this.setState({ error: 'Invalid VAST' })
      return false
    }
    try {
      let parsedVast = parseVAST(vastContent)
      if (parsedVast.adPod) {
        parsedVast = parsedVast.adPod
      }
      if (!parsedVast.ads || !parsedVast.ads.length) {
        throw new Error()
      }
      this.setState({ error: '' })
      return true
    } catch (err) {
      this.setState({ error: 'The VAST document must contain at least one ad' })
      return false
    }
  }
  render() {
    const isContentChanged =
      this.state.editContent.trim() !== this.props.content.trim()
    const confirmDisabled =
      !isContentChanged || this.state.isEmpty || !!this.state.error
    const validateVast = debounce(
      (vastContent: string) => this.validateVast(vastContent),
      300
    )
    return (
      <LargeDialog
        title="VAST Content"
        onClose={this.props.onClose}
        width={900}
        buttons={[
          {
            name: 'Cancel',
            primary: false,
            action: this.props.onClose,
          },
          {
            name: 'Save',
            primary: true,
            disabled: confirmDisabled,
            action: () => {
              const isValid = this.validateVast(this.state.editContent)
              if (!isValid) {
                return
              }
              const xmlFormatted = formatXml(this.state.editContent)
              this.props.setContent(xmlFormatted)
              this.props.onClose()
            },
          },
        ]}
        scrollable={false}
      >
        <VastEditor
          value={this.state.editContent}
          onChange={(vastContent: string = '') => {
            validateVast(vastContent)
            this.setState({
              editContent: vastContent,
              isEmpty: !vastContent.trim().length,
            })
          }}
          error={this.state.isEmpty ? 'Content is required' : this.state.error}
        />
      </LargeDialog>
    )
  }
}
