import React from 'react'
import {
  Dialog,
  DefaultButton,
  TextField,
  PrimaryButton,
  DialogFooter,
  DialogType,
} from 'office-ui-fabric-react'
import { ChildProps, graphql } from 'react-apollo'
import {
  getAccountDisplayInfoQuery,
  AccountDisplayInfoResponse,
  setAccountDisplayName,
} from '../Api/session'

interface State {
  isSubmitting: boolean
}

interface InputProps {
  onClose: () => void
}

class ChangeDisplayNameDialogView extends React.Component<
  ChildProps<InputProps, AccountDisplayInfoResponse>,
  State
> {
  constructor(props: any) {
    super(props)
    this.state = {
      isSubmitting: false,
    }
  }
  render() {
    if (this.props.data!.loading) {
      return <React.Fragment />
    }
    let displayName = this.props.data!.session!.accountDisplayName || ''
    return (
      <Dialog
        hidden={false}
        modalProps={{
          isDarkOverlay: true,
          isBlocking: true,
        }}
        onDismiss={this.props.onClose}
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: 'Change Display Name',
        }}
      >
        <TextField
          label="Choose a new display name for your account"
          disabled={this.state.isSubmitting}
          defaultValue={this.props.data!.session!.accountDisplayName}
          onChange={(evt: any, value?: string) => {
            displayName = value || displayName
          }}
          onKeyDown={(evt) => evt.keyCode === 13 && this.submit(displayName)}
        />
        <DialogFooter>
          <DefaultButton
            onClick={() => this.close()}
            disabled={this.state.isSubmitting}
            text={'Cancel'}
          />
          <PrimaryButton
            onClick={() => this.submit(displayName)}
            disabled={this.state.isSubmitting || !displayName}
            text={'Save'}
          />
        </DialogFooter>
      </Dialog>
    )
  }
  submit(newDisplayName: string) {
    if (!newDisplayName) {
      return
    }
    this.setState({ isSubmitting: true })
    setAccountDisplayName(newDisplayName)
      .catch((e) => window.console.error(e))
      .then(() => this.setState({ isSubmitting: false }))
      .then(this.props.onClose)
  }
  close() {
    this.props.onClose()
  }
}

const withGql = graphql<InputProps, AccountDisplayInfoResponse>(
  getAccountDisplayInfoQuery,
  { options: () => ({ notifyOnNetworkStatusChange: true }) }
)
export const ChangeDisplayNameDialog = withGql(ChangeDisplayNameDialogView)
