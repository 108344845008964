import gql from 'graphql-tag'
import { client } from './api'

export interface SchainResponse {
  sellerDomain: {
    domain: string
  }
  sellers: Seller[]
}

export interface Seller {
  seller_id: string
  name: string | null
  domain: string | null
  seller_type: string
  is_confidential: number | null
  status: string
}

export interface SellersByDomainResponse {
  sellersByDomain: Seller[]
}

export interface SellerDomain {
  domain: string
}

export const getSchainQuery = gql`
  {
    sellerDomain {
      domain
    }
    sellers {
      seller_id
      name
      domain
      seller_type
      is_confidential
    }
  }
`

const updateSellerDomainMutation = gql`
  mutation ($domain: String!) {
    sellerDomain {
      update(domain: $domain)
    }
  }
`

export const getSellersByDomainQuery = gql`
  query ($domain: String!) {
    sellersByDomain(domain: $domain) {
      seller_id
      name
      domain
      seller_type
      is_confidential
    }
  }
`

export async function updateSellerDomain(sellerDomain: SellerDomain) {
  return client.mutate({
    mutation: updateSellerDomainMutation,
    variables: {
      ...sellerDomain,
    },
  })
}

export async function deleteSellerDomain() {
  return client.mutate({
    mutation: gql`
      mutation {
        sellerDomain {
          delete
        }
      }
    `,
  })
}
