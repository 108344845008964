import { Stack } from 'office-ui-fabric-react'

import React from 'react'
import { ScreenHeader } from '../../../../Components/ScreenHeader'
import { ChildProps } from 'react-apollo/types'
import { graphql } from 'react-apollo'
import { BlockingLoadingDialog } from '../../../../Components/BlockingLoadingDialog'
import {
  ReferencesResponse,
  getReferencesChains,
  getReferencesChainsQuery,
} from '../../../../Api/references'
import { AddReferenceDialog } from './AddReferenceDialog'
import { ReferencesList } from './ReferencesListScreen'

export interface InputProps {}

const ReferencesScreenView: React.FC<
  ChildProps<InputProps, ReferencesResponse>
> = (props) => {
  const { data } = props
  const isLoading = data && data!.loading

  const [isAddReferenceModalOpen, setIsAddReferenceOpen] = React.useState(false)

  if (isLoading) {
    return <BlockingLoadingDialog label="Loading" />
  }

  const buyerDomains = data!.referencesChains!.map((chain) =>
    chain.references.map((x) => x.buyerDomain)
  )
  const flattenedDomains = buyerDomains.flat()
  const uniquebuyerDomainsSet = new Set(flattenedDomains)
  const uniquebuyerDomainsArray = Array.from(uniquebuyerDomainsSet)

  return (
    <Stack
      className="screen"
      horizontalAlign="start"
      verticalAlign="start"
      verticalFill
      style={{ width: '100%' }}
    >
      <ScreenHeader
        path={['Sellers.json', 'References from 3rd party buyers']}
        buttons={[
          {
            name: 'Add reference',
            icon: 'Add',
            disabled: isLoading,
            onClick: () => setIsAddReferenceOpen(true),
          },
        ]}
      />
      <Stack
        className="references-screen"
        horizontalAlign="center"
        verticalAlign="start"
        horizontal
        wrap
        styles={{
          root: {
            width: '100%',
            overflow: 'auto',
          },
          inner: {
            padding: 60,
          },
        }}
      >
        <ReferencesList
          referencesChains={data!.referencesChains}
          onUpdated={() => getReferencesChains()}
        />
        {isAddReferenceModalOpen && (
          <AddReferenceDialog
            uniqueBuyerDomains={uniquebuyerDomainsArray}
            ownDeclaration={data!.sellerDomain!.domain}
            onClose={() => setIsAddReferenceOpen(false)}
            onUpdated={() => {
              getReferencesChains()
            }}
          />
        )}
      </Stack>
    </Stack>
  )
}

const withGql = graphql<InputProps, ReferencesResponse>(
  getReferencesChainsQuery,
  {
    options: () => ({
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
    }),
  }
)

export const ReferencesScreen = withGql(ReferencesScreenView)
