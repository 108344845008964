import React from 'react'
import {
  Text,
  TextField,
  MessageBar,
  MessageBarType,
} from 'office-ui-fabric-react'
import { ChildProps, graphql } from 'react-apollo'
import { ErrorDialog } from './ErrorDialog'
import { LargeDialog } from './LargeDialog'
import {
  getConnectToWebsiteQueryQuery,
  ConnectToWebsiteResponse,
} from '../Api/placements'

interface State {
  isSubmitting: boolean
  hasError: boolean
}

interface InputProps {
  onClose: () => void
}

const getInitialState = () => ({
  isSubmitting: false,
  hasError: false,
  form: undefined,
})

class ConnectWithWebsiteDialogView extends React.Component<
  ChildProps<InputProps, ConnectToWebsiteResponse>,
  State
> {
  constructor(props: any) {
    super(props)
    this.state = getInitialState()
  }
  render() {
    if (this.props.data!.error) {
      return (
        <ErrorDialog
          onClose={this.props.onClose}
          title="Network error"
          message="Could not fetch collection parameters. "
        />
      )
    }
    if (!this.props.data!.session) {
      return <React.Fragment />
    }
    const libraryUrl = 'https://publisher.caroda.io/videoPlayer/caroda.min.js'
    const integrationToken = this.props.data!.session.integrationToken
    const configuredUrl = `${libraryUrl}?ctok=${integrationToken}`
    return (
      <LargeDialog
        title="Connect with website via integration"
        onClose={this.props.onClose}
        buttons={[
          {
            name: 'Ok',
            action: () => this.close(),
          },
        ]}
      >
        <Text>
          Add the following <b>integration script</b> to your website and ask
          for support for an integration to your site. We can provide placement
          modification without requiring any change to your website.
        </Text>
        <TextField
          multiline
          autoAdjustHeight
          resizable={false}
          readOnly
          value={`<script src="${configuredUrl}" crossorigin="anonymous" defer></script>`}
        />
        {this.state.hasError && (
          <MessageBar
            messageBarType={MessageBarType.error}
            isMultiline={true}
            styles={{ root: { marginTop: 10 } }}
          >
            Could not update encoding settings. If the problem persist, please
            contact support.
          </MessageBar>
        )}
      </LargeDialog>
    )
  }
  close() {
    this.props.onClose()
  }
}

const withGql = graphql<InputProps, ConnectToWebsiteResponse>(
  getConnectToWebsiteQueryQuery,
  { options: () => ({}) }
)
export const ConnectWithWebsiteDialog = withGql(ConnectWithWebsiteDialogView)
