import gql from 'graphql-tag'
import { client } from './api'

export interface ReferencesResponse {
  sellerDomain: { domain: string | null }
  references: Reference[]
  referencesChains: ReferenceChain[]
}

export interface Reference {
  buyerDomain: string
  seller_id: string
  seller_type: string
  name?: string
  domain?: string
  is_confidential?: number
  status?: string
}

export interface ReferenceChain {
  id: string
  references: Reference[]
}

export interface StoredReferenceChain {
  references: { buyerDomain: string; seller_id: string }[]
}

export const getReferencesQuery = gql`
  query {
    references {
      buyerDomain
      seller_id
      seller_type
      name
      domain
      is_confidential
      status
    }
  }
`

export async function getReferences() {
  return client.query({
    query: getReferencesQuery,
    variables: {},
    fetchPolicy: 'network-only',
  })
}

export const getReferencesChainsQuery = gql`
  query {
    sellerDomain {
      domain
    }
    referencesChains {
      id
      references {
        buyerDomain
        seller_id
        name
        domain
        seller_type
        is_confidential
        status
      }
    }
  }
`

export async function getReferencesChains() {
  return client.query({
    query: getReferencesChainsQuery,
    variables: {},
    fetchPolicy: 'network-only',
  })
}

export const updateReferenceChainMutation = gql`
  mutation UpdateReference($references: [ReferenceChainReferenceInputType!]!) {
    referenceChain {
      update(references: $references)
    }
  }
`
export async function updateReferenceChain(references: StoredReferenceChain) {
  return client.mutate({
    mutation: updateReferenceChainMutation,
    variables: {
      ...references,
    },
  })
}

export const deleteReferenceChainMutation = gql`
  mutation DeleteReference($id: String!) {
    referenceChain {
      delete(id: $id)
    }
  }
`
export async function deleteReferenceChain(id: string) {
  return client.mutate({
    mutation: deleteReferenceChainMutation,
    variables: {
      id,
    },
  })
}

export function chainToLabel(chain: ReferenceChain): string {
  const toChainEntry = (reference: Reference, i: number) => {
    const nextReference = chain.references[i + 1]
    return nextReference && nextReference.is_confidential
      ? `${reference.buyerDomain} #${nextReference.seller_id}`
      : reference.buyerDomain
  }
  return chain.references.map(toChainEntry).join(' → ')
}
