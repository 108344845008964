import React from 'react'
import { CampaignDialogView } from './CampaignDialogView'
import { ChildProps, graphql } from 'react-apollo'
import {
  editCampaign,
  getCampaigns,
  AssetTagsResponse,
  CampaignResponse,
  getCampaignsQuery_v2,
} from '../../../Api/campaigns'
import { ErrorDialog } from '../../../Components/ErrorDialog'

interface State {
  hasEditError: boolean
}

interface InputProps {
  id: string
  collectionId: string
  isAdminOrAccountManager: boolean
  onClose: () => void
  onUpdated: () => void
}

class EditCampaignDialogView extends React.Component<
  ChildProps<InputProps, CampaignResponse>,
  State
> {
  constructor(props: InputProps) {
    super(props)
    this.state = { hasEditError: false }
  }
  render() {
    if (this.props.data!.error) {
      return (
        <ErrorDialog
          onClose={this.props.onClose}
          title="Network error"
          message="Could not fetch campaign. "
        />
      )
    }
    if (!this.props.data!.campaignCollections) {
      return <React.Fragment />
    }
    const campaign = this.props.data!.campaignCollections[0].assets.find(
      (c) => c.id === this.props.id
    )
    if (!campaign) {
      return (
        <ErrorDialog
          onClose={this.props.onClose}
          title="Network error"
          message="Could not find campaign. "
        />
      )
    }
    campaign.collectionId = this.props.collectionId
    return (
      <React.Fragment>
        <CampaignDialogView
          dialogTitle="Edit Campaign"
          actionTitle="Save"
          collectionId={this.props.collectionId}
          assetTagInfo={this.props.data! as AssetTagsResponse}
          disabled={campaign.restricted && !this.props.isAdminOrAccountManager}
          isAdminOrAccountManager={this.props.isAdminOrAccountManager}
          campaign={campaign}
          onClose={this.props.onClose}
          onUpdated={async (c) => {
            try {
              await editCampaign(c)
              await getCampaigns(this.props.collectionId)
              this.props.onUpdated()
              this.props.onClose()
            } catch (e) {
              console.warn(e)
              this.setState({ hasEditError: true })
            }
          }}
        />
        {this.state.hasEditError && (
          <ErrorDialog
            onClose={() => this.setState({ hasEditError: false })}
            title="Network error"
            message="Could not edit campaign."
          />
        )}
      </React.Fragment>
    )
  }
}

const withGql = graphql<InputProps, CampaignResponse>(getCampaignsQuery_v2, {
  options: ({ collectionId }) => ({
    notifyOnNetworkStatusChange: true,
    variables: { collectionId },
  }),
})

export const EditCampaignDialog = withGql(EditCampaignDialogView)
