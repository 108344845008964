import { Dropdown, Stack } from 'office-ui-fabric-react'
import React, { useState } from 'react'
import { ShownTagInput } from '../../../../../Components/Input/ShownTagInput'
import { Campaign, Interval } from '../../../../../Api/campaigns'
import { Change } from '../CappingForm_v2'
import { TotalLimit } from './TotalLimit'
import TimedEventsCappingFields from './TimedEvents'
import EventsPerUserCappingFields from './EventsPerUser'

interface LimitByEventProps {
  cappingEventType: string
  errorMessage: string | undefined
  disabled: boolean
  campaign: Campaign
  gap: number
  onChange: (campaignChange: Change) => any
}

type CappingType = 'TotalLimit' | 'TimedLimit' | 'UserLimit'

const eventsTypeOptions = [
  { key: 'impression', text: 'Impression' },
  { key: 'start', text: 'Start' },
  { key: 'first_quartile', text: 'First Quartile' },
  { key: 'midpoint', text: 'Midpoint' },
  { key: 'third_quartile', text: 'Third Quartile' },
  { key: 'complete', text: 'Complete' },
]

const LimitByEvent: React.FC<LimitByEventProps> = (props) => {
  const { onChange, cappingEventType, disabled, campaign, gap, errorMessage } =
    props
  const [selectedCappingTypes, setSelectedCappingTypes] = useState(
    getDefaultSelectedCappingTypes(campaign)
  )
  return (
    <Stack gap={gap}>
      <Dropdown
        label="Event Limit Type"
        options={eventsTypeOptions}
        errorMessage={errorMessage}
        required={true}
        selectedKey={cappingEventType}
        disabled={disabled}
        onChange={async (evt, value?) => {
          if (!value) {
            return
          }
          onChange({
            cappingEventType: value!.key.toString(),
          })
        }}
      />
      <ShownTagInput
        label=""
        selectedKeys={selectedCappingTypes}
        onChange={(value?) => {
          if (!value) return
          const newCappingTypes = value.map(({ key }) => key as CappingType)
          const change = {} as Change
          setSelectedCappingTypes(newCappingTypes)
          if (newCappingTypes.indexOf('TotalLimit') === -1) {
            change.eventLimit = null
          }
          if (newCappingTypes.indexOf('TimedLimit') === -1) {
            change.timedEventLimit = null
            change.timedEventInterval = null
          }
          if (newCappingTypes.indexOf('UserLimit') === -1) {
            change.eventsPerUser = null
            change.eventsPerUserInterval = null
          }
          onChange(change)
        }}
        disabled={disabled}
        options={[
          {
            key: 'TotalLimit',
            name: `Total limit`,
          },
          {
            key: 'TimedLimit',
            name: `Timed limit`,
          },
          {
            key: 'UserLimit',
            name: 'User limit',
          },
        ]}
      />
      {selectedCappingTypes.indexOf('TotalLimit') !== -1 && (
        <TotalLimit {...props} />
      )}
      {selectedCappingTypes.indexOf('TimedLimit') !== -1 && (
        <TimedEventsCappingFields
          {...props}
          limit={campaign.timedEventLimit}
          interval={campaign.timedEventInterval || { unit: 'Day', value: 1 }}
          onChange={(limit: number | null, interval: Interval | null) => {
            onChange({
              timedEventLimit: limit,
              timedEventInterval: interval,
            })
          }}
        />
      )}
      {selectedCappingTypes.indexOf('UserLimit') !== -1 && (
        <EventsPerUserCappingFields
          {...props}
          limit={campaign.eventsPerUser}
          interval={campaign.eventsPerUserInterval || { unit: 'Day', value: 1 }}
          onChange={(limit: number | null, interval: Interval | null) => {
            onChange({
              eventsPerUser: limit,
              eventsPerUserInterval: interval,
            })
          }}
        />
      )}
    </Stack>
  )
}

const getDefaultSelectedCappingTypes = (campaign: Campaign): CappingType[] => {
  const defaultCappingTypes = [] as CappingType[]
  if (
    campaign.eventLimit !== null &&
    defaultCappingTypes.indexOf('TotalLimit') === -1
  ) {
    defaultCappingTypes.push('TotalLimit')
  }
  if (
    campaign.eventsPerUser !== null &&
    defaultCappingTypes.indexOf('UserLimit') === -1
  ) {
    defaultCappingTypes.push('UserLimit')
  }
  if (
    campaign.timedEventLimit !== null &&
    defaultCappingTypes.indexOf('TimedLimit') === -1
  ) {
    defaultCappingTypes.push('TimedLimit')
  }
  return defaultCappingTypes
}

export default LimitByEvent
