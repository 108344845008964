import React from 'react'
import {
  Modal,
  DefaultButton,
  MessageBar,
  MessageBarType,
  Stack,
  Text,
} from 'office-ui-fabric-react'

interface State {}

interface InputProps {
  title: string
  message: string
  onClose: () => void
}

export class ErrorDialog extends React.Component<InputProps, State> {
  render() {
    return (
      <Modal
        isOpen={true}
        isDarkOverlay={true}
        isBlocking={true}
        onDismiss={this.props.onClose}
        elementToFocusOnDismiss={undefined}
        styles={{ main: { maxWidth: 300 } }}
      >
        <Stack
          styles={{
            root: {
              height: 56,
              paddingLeft: 24,
              paddingTop: 18,
              paddingRight: 48,
            },
          }}
        >
          <Text style={{ fontWeight: 600, fontSize: 16 }}>
            {this.props.title}
          </Text>
        </Stack>
        <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
          {this.props.message} If the problem persist, please contact support.
        </MessageBar>
        <Stack
          horizontal
          horizontalAlign="end"
          style={{ padding: 12, paddingRight: 18 }}
        >
          <DefaultButton onClick={this.props.onClose} text={'OK'} />
        </Stack>
      </Modal>
    )
  }
}
