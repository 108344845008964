import React from 'react'
import {
  Dialog,
  DefaultButton,
  PrimaryButton,
  DialogFooter,
  DialogType,
  MessageBar,
  MessageBarType,
} from 'office-ui-fabric-react'

interface State {}

interface InputProps {
  dialogTitle: string
  confirmationText: string
  yesButtonText: string
  noButtonText: string
  isLoading: boolean
  errorMessage?: string
  onCancel: () => void
  onConfirm: () => void
}

export class ConfirmationDialog extends React.Component<InputProps, State> {
  render() {
    return (
      <Dialog
        hidden={false}
        modalProps={{
          isDarkOverlay: true,
          isBlocking: true,
        }}
        onDismiss={this.props.onCancel}
        elementToFocusOnDismiss={undefined}
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: this.props.dialogTitle,
          subText: this.props.confirmationText,
        }}
      >
        {this.props.errorMessage && (
          <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
            {this.props.errorMessage}
          </MessageBar>
        )}
        <DialogFooter>
          <PrimaryButton
            disabled={this.props.isLoading || Boolean(this.props.errorMessage)}
            onClick={this.props.onConfirm}
            text={this.props.yesButtonText}
          />
          <DefaultButton
            disabled={this.props.isLoading}
            onClick={this.props.onCancel}
            text={this.props.noButtonText}
          />
        </DialogFooter>
      </Dialog>
    )
  }
}
