import React from 'react'
import { MessageBar, MessageBarType } from 'office-ui-fabric-react'

const InfoBox: React.FC<{ type?: MessageBarType }> = ({ type, children }) => (
  <MessageBar
    messageBarType={type}
    isMultiline={true}
    styles={{
      root: {
        marginTop: -14,
      },
    }}
  >
    {children}
  </MessageBar>
)

export default InfoBox
