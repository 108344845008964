import isValidDomain from 'is-valid-domain'
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  List,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Stack,
  TextField,
} from 'office-ui-fabric-react'
import React, { useState } from 'react'
import { Integration } from '../../Api/integrations'

interface Props {
  disabled: boolean
  onClose: () => void
  onSubmit: (integrations: Integration[]) => void
  errors: string[]
}

export function CreateMultipleDialog({
  disabled,
  onClose,
  onSubmit,
  errors,
}: Props) {
  const [input, setInput] = useState<string>('')
  const [inputError, setInputError] = useState<string>('')
  return (
    <Dialog
      hidden={false}
      modalProps={{
        isDarkOverlay: true,
        isBlocking: true,
      }}
      className="create-multiple-integrations-dialog"
      onDismiss={onClose}
      dialogContentProps={{
        type: DialogType.largeHeader,
        title: 'Create Multiple Integrations',
      }}
    >
      <Stack gap={16}>
        <TextField
          label="Domain list"
          placeholder="<domain name>"
          value={input}
          multiline
          spellCheck={false}
          autoAdjustHeight
          resizable={false}
          autoComplete={'off'}
          onChange={(_, newInput: string = '') => {
            setInput(newInput)
          }}
          disabled={disabled}
          errorMessage={inputError}
        />
      </Stack>
      <Errors errors={errors} />
      <DialogFooter>
        <DefaultButton onClick={onClose} disabled={disabled} text="Cancel" />
        <PrimaryButton
          onClick={() => {
            const error = validate(input.trim())
            setInputError(error)
            if (!error) {
              const integrations = getIntegrations(input.trim())
              if (integrations.length > 1000) {
                setInputError('no more than 1000 rows are allowed')
                return
              }
              onSubmit(integrations)
            }
          }}
          disabled={disabled}
          text={'Create'}
        />
      </DialogFooter>
    </Dialog>
  )
}

function Errors({ errors }: { errors: string[] }) {
  if (!errors.length) {
    return <React.Fragment />
  }
  return (
    <MessageBar
      messageBarType={MessageBarType.error}
      isMultiline={true}
      styles={{ root: { marginTop: 15 } }}
    >
      <List items={errors} onRenderCell={(error) => <span>{error}</span>} />
    </MessageBar>
  )
}

function validate(input: string): string {
  const getDomain = (integration: Integration) => integration.domain.trim()
  const validateDomain = (domain: string) =>
    isValidDomain(domain) ? '' : `${domain} is not a valid domain`
  const isDefined = (x: any) => !!x

  try {
    const integrations = getIntegrations(input)
    return (
      integrations.map(getDomain).map(validateDomain).filter(isDefined)[0] || ''
    )
  } catch (err) {
    return (err as any).message
  }
}

function getIntegrations(input: string): Integration[] {
  return input.split('\n').map(getIntegration).filter(Boolean)
}

function getIntegration(input: string): Integration {
  const trim = (s: string) => s.trim()

  try {
    const [domain] = input.split(',').map(trim)
    return {
      domain,
    } as Integration
  } catch (err) {
    throw new Error(`${input} has an invalid format`)
  }
}
