import { client } from './api'
import gql from 'graphql-tag'
import { AccountDisplayInfo } from './session'

export const getAllInstreamPlacementCollectionSummariesQuery = gql`
  query {
    instreamPlacementCollections_v2 {
      id
      title
    }
  }
`

export const getInstreamPlacementCollectionTagsQuery = gql`
  query ($collectionId: String) {
    instreamPlacementCollections_v2(byId: $collectionId) {
      tags
    }
    session {
      groupNames
    }
  }
`

export interface InstreamPlacementCollectionSummary {
  id: string
  title: string
}

export type InstreamPlacementMidrollPositionUnit = 'seconds' | 'percentage'

export interface InstreamPlacementMidrollPositionInfo {
  break: number
  unit: InstreamPlacementMidrollPositionUnit
}

export type InstreamPlacementPosition =
  | 'preroll'
  | 'midroll'
  | 'postroll'
  | 'custom'

export enum OverrideSkipBehavior {
  None = 'none',
  Fixed = 'fixed',
  Hide = 'hide',
  OverrideInstream = 'overrideInstream',
}

export interface InstreamPlacementInfo {
  id: string
  title: string
  creationDate: string
  tags: string[]
  integration: string
  position: InstreamPlacementPosition
  midrollPosition?: InstreamPlacementMidrollPositionInfo
  priority: number
  minVideoLength: number
  maxVideoLength: number | null
  overrideSkipBehavior?: OverrideSkipBehavior
  fixedSkipOffset?: number
  whitelistGlobs?: string[]
  blacklistGlobs?: string[]
}

export interface InstreamPlacementCollectionSummaryResponse {
  instreamPlacementCollections_v2: InstreamPlacementCollectionSummary[]
}

export interface InstreamPlacementCollectionTagsResponse {
  instreamPlacementCollections_v2: { tags: string[] }[]
  session: AccountDisplayInfo
}

export const getInstreamPlacementsQuery = gql`
  query ($collectionId: String, $startFromId: String) {
    instreamPlacementCollections_v2(byId: $collectionId) {
      id
      tags
      assets(startFromId: $startFromId) {
        id
        title
        creationDate
        tags
        integration
        position
        midrollPosition {
          break
          unit
        }
        priority
        overrideSkipBehavior
        fixedSkipOffset
        minVideoLength
        maxVideoLength
        whitelistGlobs
        blacklistGlobs
      }
    }
  }
`

export interface InstreamPlacementResponse {
  instreamPlacementCollections_v2: {
    id: string
    assets: InstreamPlacementInfo[]
  }[]
}

const createInstreamPlacementCollectionMutation = gql`
  mutation ($title: String!) {
    instreamPlacementCollections_v2 {
      create(title: $title)
    }
  }
`

const renameInstreamPlacementCollectionMutation = gql`
  mutation ($id: String, $title: String!) {
    instreamPlacementCollections_v2(byId: $id) {
      update(title: $title)
    }
  }
`

const deleteInstreamPlacementCollectionMutation = gql`
  mutation ($id: String) {
    instreamPlacementCollections_v2(byId: $id) {
      delete
    }
  }
`

const deleteInstreamPlacementMutation = gql`
  mutation ($collectionId: String, $id: String) {
    instreamPlacementCollections_v2(byId: $collectionId) {
      assets(byId: $id) {
        delete
      }
    }
  }
`

const createInstreamPlacementMutation = gql`
  mutation (
    $collectionId: String!
    $title: String!
    $integration: String!
    $tags: [String]!
    $position: InstreamPlacementPosition!
    $midrollPosition: MidrollPositionInput
    $priority: Int!
    $overrideSkipBehavior: OverrideSkipBehavior
    $fixedSkipOffset: Int
    $minVideoLength: Int!
    $maxVideoLength: Int
    $whitelistGlobs: [String]!
    $blacklistGlobs: [String]!
  ) {
    instreamPlacementCollections_v2(byId: $collectionId) {
      assets {
        create(
          title: $title
          integration: $integration
          tags: $tags
          position: $position
          midrollPosition: $midrollPosition
          priority: $priority
          overrideSkipBehavior: $overrideSkipBehavior
          fixedSkipOffset: $fixedSkipOffset
          minVideoLength: $minVideoLength
          maxVideoLength: $maxVideoLength
          whitelistGlobs: $whitelistGlobs
          blacklistGlobs: $blacklistGlobs
        )
      }
    }
  }
`

const updateInstreamPlacementMutation = gql`
  mutation (
    $collectionId: String!
    $id: String!
    $title: String!
    $integration: String!
    $tags: [String]!
    $position: InstreamPlacementPosition!
    $midrollPosition: MidrollPositionInput
    $priority: Int!
    $overrideSkipBehavior: OverrideSkipBehavior
    $fixedSkipOffset: Int
    $minVideoLength: Int!
    $maxVideoLength: Int
    $whitelistGlobs: [String]!
    $blacklistGlobs: [String]!
  ) {
    instreamPlacementCollections_v2(byId: $collectionId) {
      assets(byId: $id) {
        update(
          title: $title
          integration: $integration
          tags: $tags
          position: $position
          midrollPosition: $midrollPosition
          priority: $priority
          overrideSkipBehavior: $overrideSkipBehavior
          fixedSkipOffset: $fixedSkipOffset
          minVideoLength: $minVideoLength
          maxVideoLength: $maxVideoLength
          whitelistGlobs: $whitelistGlobs
          blacklistGlobs: $blacklistGlobs
        )
      }
    }
  }
`

export async function createInstreamPlacementCollection(title: string) {
  return client.mutate({
    mutation: createInstreamPlacementCollectionMutation,
    variables: { title },
  })
}

export async function renameInstreamPlacementCollection(
  collectionId: string,
  newTitle: string
) {
  return client.mutate({
    mutation: renameInstreamPlacementCollectionMutation,
    variables: {
      id: collectionId,
      title: newTitle,
    },
  })
}

export async function deleteInstreamPlacementCollection(collectionId: string) {
  return client.mutate({
    mutation: deleteInstreamPlacementCollectionMutation,
    variables: { id: collectionId },
  })
}

export async function createInstreamPlacement(
  collectionId: string,
  title: string,
  integration: string,
  tags: string[],
  position: InstreamPlacementPosition,
  midrollPosition: InstreamPlacementMidrollPositionInfo | null,
  priority: number,
  overrideSkipBehavior: OverrideSkipBehavior,
  fixedSkipOffset: number | null,
  minVideoLength: number,
  maxVideoLength: number | null,
  whitelistGlobs: String[],
  blacklistGlobs: string[]
): Promise<string> {
  const mutationResult = await client.mutate({
    mutation: createInstreamPlacementMutation,
    variables: {
      collectionId,
      title,
      integration,
      tags,
      position,
      midrollPosition: midrollPosition ? midrollPosition : undefined,
      priority,
      overrideSkipBehavior,
      fixedSkipOffset,
      minVideoLength,
      maxVideoLength,
      whitelistGlobs,
      blacklistGlobs,
    },
  })
  return mutationResult.data.instreamPlacementCollections_v2.assets.create
}

export async function updateInstreamPlacement(
  collectionId: string,
  id: string,
  title: string,
  integration: string,
  tags: string[],
  position: InstreamPlacementPosition,
  midrollPosition: InstreamPlacementMidrollPositionInfo | null,
  priority: number,
  overrideSkipBehavior: OverrideSkipBehavior,
  fixedSkipOffset: number | null,
  minVideoLength: number,
  maxVideoLength: number | null,
  whitelistGlobs: String[],
  blacklistGlobs: string[]
): Promise<string> {
  const mutationResult = await client.mutate({
    mutation: updateInstreamPlacementMutation,
    variables: {
      collectionId,
      id,
      title,
      integration,
      tags,
      position,
      midrollPosition: midrollPosition ? midrollPosition : undefined,
      priority,
      overrideSkipBehavior,
      fixedSkipOffset,
      minVideoLength,
      maxVideoLength,
      whitelistGlobs,
      blacklistGlobs,
    },
  })
  return mutationResult.data.instreamPlacementCollections_v2.assets.update
}

export async function deleteInstreamPlacement(
  collectionId: string,
  id: string
) {
  return client.mutate({
    mutation: deleteInstreamPlacementMutation,
    variables: { id, collectionId },
  })
}
