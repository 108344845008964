import React from 'react'
import { TagPicker, ITag, ITagPickerProps } from 'office-ui-fabric-react'
import './TagInput.css'

interface State {}

interface InputProps extends ITagPickerProps {
  label: string
  required?: boolean
  onResolveSuggestions: (
    filter: string,
    selectedItems?: ITag[] | undefined
  ) => ITag[] | PromiseLike<ITag[]>
  onChange: (value?: ITag[]) => any
  selectedItems: ITag[]
  disabled?: boolean
  errorMessage?: string
  pickerLabels: {
    suggestionsHeader: string
    noResults: string
  }
  itemLimit?: number
}

export class TagInput extends React.Component<InputProps, State> {
  render() {
    return (
      <div
        className={'tag-input' + (this.props.errorMessage ? ' has-error' : '')}
      >
        <div
          style={{
            marginTop: 5,
            marginBottom: 5,
            fontWeight: 600,
            color: 'rgb(50, 49, 48)',
          }}
        >
          {this.props.label}
          {this.props.required && (
            <span style={{ color: 'rgb(164, 38, 44)' }}> *</span>
          )}
        </div>
        <TagPicker
          {...this.props}
          onResolveSuggestions={this.props.onResolveSuggestions}
          pickerSuggestionsProps={{
            suggestionsHeaderText: this.props.pickerLabels.suggestionsHeader,
            noResultsFoundText: this.props.pickerLabels.noResults,
          }}
          onEmptyInputFocus={() =>
            this.props.onResolveSuggestions('', this.props.selectedItems)
          }
          onChange={this.props.onChange}
          selectedItems={this.props.selectedItems}
          itemLimit={this.props.itemLimit || 10}
          disabled={this.props.disabled}
          inputProps={{ 'aria-label': this.props.label }}
        />
        {this.props.errorMessage && (
          <span data-automation-id="error-message">
            {this.props.errorMessage}
          </span>
        )}
      </div>
    )
  }
}
