import React, { useEffect, useState } from 'react'
import { Dropdown, IDropdownOption, Stack } from 'office-ui-fabric-react'
import InfoBox from '../InfoBox'
import {
  ReferenceChain,
  ReferencesResponse,
  chainToLabel,
  getReferencesChainsQuery,
} from '../../../../Api/references'
import { ChildProps, graphql } from 'react-apollo'

type FormErrorSet = { [fieldName: string]: string }

interface InputProps {
  gap: number
  disabled: boolean
  errors: FormErrorSet
  thirdPartyReferenceChainId?: string | null
  onChange: (change: Change) => any
}

interface Change {
  thirdPartyReferenceChainId?: string | null
}

const SupplyChainFormView: React.FC<
  ChildProps<InputProps, ReferencesResponse>
> = (props) => {
  const { onChange, gap, disabled, errors, data, thirdPartyReferenceChainId } =
    props
  const { error, loading } = data!
  const [referenceDomains, setReferenceDomains] = useState<IDropdownOption[]>(
    []
  )
  const cryptoAsAny = crypto as any
  const defaultKey = cryptoAsAny.randomUUID()

  useEffect(() => {
    if (loading || !data || !data.referencesChains) {
      return
    }

    const referenceChainToOption = (reference: ReferenceChain) => ({
      key: reference.id,
      text: chainToLabel(reference),
    })
    const sortAlphabetically = (a: IDropdownOption, b: IDropdownOption) =>
      a.text.localeCompare(b.text)

    const options = data.referencesChains
      .map(referenceChainToOption)
      .sort(sortAlphabetically)
    setReferenceDomains(options)

    if (
      thirdPartyReferenceChainId &&
      !options.some((option) => option.key === thirdPartyReferenceChainId)
    ) {
      onChange({ thirdPartyReferenceChainId: null })
    }
  }, [data, thirdPartyReferenceChainId, loading, onChange])

  if (error) {
    return <React.Fragment />
  }

  const defaultOption = {
    key: defaultKey,
    text: 'Own Campaign',
  }

  return (
    <Stack gap={gap}>
      <Dropdown
        style={{
          flexGrow: 1,
          maxWidth: `${gap / 2}px)`,
        }}
        label="Third Party Seller Domain"
        errorMessage={errors['thirdPartyReferenceChainId']}
        options={[defaultOption, ...referenceDomains]}
        required
        selectedKey={thirdPartyReferenceChainId || defaultKey}
        disabled={disabled}
        onChange={(event, value?) => {
          if (!value) {
            return
          }
          const thirdPartyReferenceChainId =
            value.key === defaultKey ? null : value.key
          onChange({
            thirdPartyReferenceChainId: thirdPartyReferenceChainId as
              | string
              | null,
          })
        }}
      />
      <InfoBox>
        Select the entity that controls this ad source be it a SSP account or ad
        server in case it’s received from a 3rd party source. If the seller
        entity is not in this list, add from{' '}
        <b>Sellers.json &gt; References from 3rd Party sellers.</b>
      </InfoBox>
    </Stack>
  )
}

const withGql = graphql<InputProps, ReferencesResponse>(
  getReferencesChainsQuery,
  {
    options: () => ({ notifyOnNetworkStatusChange: true }),
  }
)
export default withGql(SupplyChainFormView)
