import React from 'react'
import moment from 'moment'
import {
  DirectionalHint,
  IColumn,
  Icon,
  IconButton,
} from 'office-ui-fabric-react'
import { Integration } from '../../Api/integrations'
import { TableView } from '../../Components/TableView'
import './IntegrationsTable.css'

interface Props {
  integrations: Integration[]
  onEdit: (integration: Integration) => void
  onDelete: (integration: Integration) => void
}

export function IntegrationsTable({ integrations, onEdit, onDelete }: Props) {
  const columns = useColumns({ onEdit, onDelete })
  return (
    <div className="caroda-integrations-table-container">
      <TableView
        cacheKey={''}
        columns={columns}
        rowToCacheKey={() => ''}
        rows={integrations}
        onRowClick={onEdit}
      />
    </div>
  )
}

function useColumns({
  onEdit,
  onDelete,
}: {
  onEdit: (integration: Integration) => void
  onDelete: (integration: Integration) => void
}): IColumn[] {
  return [
    {
      key: 'type',
      name: '',
      fieldName: 'type',
      minWidth: 20,
      maxWidth: 20,
      isPadded: false,
      isRowHeader: true,
      isIconOnly: true,
      onRender: () => (
        <Icon
          iconName="Globe"
          style={{
            fontSize: 14,
            paddingLeft: 5,
            paddingTop: 3,
          }}
        />
      ),
    },
    {
      key: 'domain',
      name: 'Domain',
      fieldName: 'domain',
      isPadded: true,
      isRowHeader: true,
      minWidth: 150,
      onRender: ({ domain }: Integration) => (
        <span title={domain}>{domain}</span>
      ),
    },
    {
      key: 'publishedDate',
      name: 'Published Date',
      fieldName: 'publishedDate',
      isPadded: true,
      minWidth: 100,
      maxWidth: 100,
      onRender: ({ publishedDate }: Integration) => (
        <span>{moment(publishedDate).format('DD/MM/YYYY')}</span>
      ),
    },
    {
      key: 'menu',
      name: '',
      fieldName: '',
      minWidth: 20,
      maxWidth: 20,
      isIconOnly: true,
      onRender: (integration: Integration) => (
        <RowMenu
          onEdit={() => onEdit(integration)}
          onDelete={() => onDelete(integration)}
        />
      ),
    },
  ]
}

function RowMenu({
  onEdit,
  onDelete,
}: {
  onEdit: () => void
  onDelete: () => void
}) {
  return (
    <IconButton
      styles={{
        root: {
          height: 18,
        },
        menuIcon: {
          fontWeight: 'bold',
        },
      }}
      menuProps={{
        coverTarget: true,
        directionalHint: DirectionalHint.topCenter,
        items: [
          {
            key: 'edit',
            text: 'Edit',
            iconProps: { iconName: 'edit' },
            onClick: onEdit,
          },
          {
            key: 'delete',
            text: 'Delete',
            iconProps: { iconName: 'delete' },
            onClick: onDelete,
          },
        ],
      }}
    />
  )
}
