import React from 'react'

interface Props {
  gap: number
  children: React.ReactNode
}

export const HalfWidthStyle: React.FC<Props> = ({ gap, children }) => {
  return (
    <div
      style={{
        width: getHalfComponentWidthStyle(gap),
      }}
    >
      {children}
    </div>
  )
}

function getHalfComponentWidthStyle(gap: number) {
  return `calc(50% - ${gap / 2}px)`
}
