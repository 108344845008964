import React from 'react'
import {
  Dialog,
  DefaultButton,
  ComboBox,
  IDropdownOption,
  PrimaryButton,
  DialogFooter,
  DialogType,
  MessageBar,
  MessageBarType,
} from 'office-ui-fabric-react'
import { ChildProps, graphql } from 'react-apollo'
import {
  getDelegatedAccountsQuery,
  DelegatedAccountsResponse,
  loginAs,
} from '../Api/session'

interface State {}

interface InputProps {
  onClose: () => void
}

class LoginAsDialogView extends React.Component<
  ChildProps<InputProps, DelegatedAccountsResponse>,
  State
> {
  render() {
    if (this.props.data!.loading) {
      return <React.Fragment />
    }
    const hasError = Boolean(this.props.data!.error)
    let account = ''
    const options = [{ key: '', text: '' }]
    if (!hasError) {
      options.push(
        ...this.props.data!.session!.delegatedAccounts!.map((a) => ({
          text: a.displayName,
          key: a.id,
        }))
      )
    }
    return (
      <Dialog
        hidden={false}
        modalProps={{
          isDarkOverlay: true,
          isBlocking: true,
        }}
        onDismiss={this.props.onClose}
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: 'Switch Account',
        }}
      >
        <ComboBox
          label="Select a customer account to login as:"
          options={options}
          disabled={hasError}
          onChange={(evt: any, value?: IDropdownOption) => {
            if (value) {
              account = String(value.key)
              this.submit(account)
            }
          }}
          allowFreeform={true}
          styles={{
            optionsContainerWrapper: { maxHeight: 400 },
          }}
        />
        {hasError && (
          <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
            Could not load delegated account list.
          </MessageBar>
        )}
        <DialogFooter>
          <DefaultButton onClick={() => this.close()} text={'Cancel'} />
          <PrimaryButton
            onClick={() => this.submit(account)}
            disabled={hasError}
            text={'Login'}
          />
        </DialogFooter>
      </Dialog>
    )
  }
  submit(newAccount: string) {
    if (!newAccount) {
      return
    }
    loginAs({ account: newAccount })
  }
  close() {
    this.props.onClose()
  }
}

const withGql = graphql<InputProps, DelegatedAccountsResponse>(
  getDelegatedAccountsQuery,
  { options: () => ({ notifyOnNetworkStatusChange: true }) }
)
export const LoginAsDialog = withGql(LoginAsDialogView)
