import { Interval } from '../../../../../Api/campaigns'
import IntervalInput from '../../IntervalInput'
import { NumberInput } from '../../../../../Components/Input/NumberInput'
import React from 'react'
import { Stack } from 'office-ui-fabric-react'

interface Props {
  disabled: boolean
  gap: number
  limit: number | null
  interval: Interval | null
  onChange: (limit: number | null, interval: Interval | null) => void
}

const TimedEventsCappingFields: React.FC<Props> = ({
  disabled,
  gap,
  limit,
  interval,
  onChange,
}) => (
  <Stack horizontal>
    <NumberInput
      label={`Timed limit (thousand)`}
      style={{ flexGrow: 1, maxWidth: `calc(50% - ${gap / 2}px)` }}
      name="timedEventLimit"
      value={limit !== null ? limit / 1000 : null}
      onChange={(value?: number) => {
        if (value == null) {
          return
        }
        onChange(value * 1000, interval)
      }}
      min={0}
      max={1e9}
      precision={3}
      disabled={disabled}
      required={true}
    />
    <div style={{ width: gap }} />
    <IntervalInput
      disabled={disabled}
      interval={interval || { value: 1, unit: 'Hour' }}
      onChange={(interval) => onChange(limit, interval)}
    />
  </Stack>
)

export default TimedEventsCappingFields
