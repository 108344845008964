type Fn<T> = (...args: any[]) => T

export function not(fn: Fn<any>): Fn<boolean> {
  return (...args: any[]): boolean => !fn(...args)
}

export function equals<T>(reference: T) {
  return (candidate: T) => reference === candidate
}

export function isDefined(value: any): boolean {
  return !!value
}
