import React from 'react'
import {
  IconButton,
  TooltipHost,
  DirectionalHint,
} from 'office-ui-fabric-react'

function HelpHeaderButton() {
  return (
    <TooltipHost
      className="help-button"
      styles={{ root: { height: '100%' } }}
      content="Help"
      tooltipProps={{
        delay: 0,
        className: 'screen-header-tooltip',
        directionalHint: DirectionalHint.leftCenter,
      }}
    >
      <IconButton
        iconProps={{ iconName: 'Help' }}
        style={{ height: '100%' }}
        href="http://caroda.helpscoutdocs.com/"
        target="_blank"
      />
    </TooltipHost>
  )
}

export default HelpHeaderButton
