import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Stack,
  TextField,
} from 'office-ui-fabric-react'

import React from 'react'
import {
  SellerDomain,
  deleteSellerDomain,
  updateSellerDomain,
} from '../../../../../Api/schain'
import { extractDomain } from '../../../../../Utils/domain'

interface InputProps {
  onClose: () => void
  onUpdated: () => void
  sellerDomain: SellerDomain
}

const SetSellersJSONDomainDialogView: React.FC<InputProps> = (props) => {
  const { onClose, onUpdated, sellerDomain } = props

  const [domain, setDomain] = React.useState(
    sellerDomain ? sellerDomain.domain : ''
  )
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')

  const gap = 16

  return (
    <Dialog
      hidden={false}
      modalProps={{
        isDarkOverlay: true,
        isBlocking: true,
        className: 'set-sellers-json-domain-dialog',
      }}
      onDismiss={onClose}
      dialogContentProps={{
        type: DialogType.largeHeader,
        title: 'Set Sellers.json domain',
      }}
    >
      <Stack gap={gap}>
        <TextField
          label={'Domain'}
          value={domain}
          autoComplete={'off'}
          onChange={(event: any, value?: string) => {
            setDomain(value || '')
          }}
          disabled={isSubmitting}
          required
        />
        {errorMessage && (
          <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
            {errorMessage}
          </MessageBar>
        )}
      </Stack>
      <DialogFooter>
        <DefaultButton
          onClick={onClose}
          disabled={isSubmitting}
          text="Cancel"
        />
        <PrimaryButton
          onClick={resetSellerDomain}
          disabled={isSubmitting || !domain}
          text="Clear"
        />
        <PrimaryButton onClick={submit} disabled={isSubmitting} text="Save" />
      </DialogFooter>
    </Dialog>
  )

  async function submit() {
    setIsSubmitting(true)
    try {
      const validDomain = extractDomain(domain)
      if (!validDomain) {
        setErrorMessage('Invalid domain')
        setIsSubmitting(false)
        return
      }
      await updateSellerDomain({
        domain: validDomain,
      })
      setIsSubmitting(false)
      onUpdated()
      onClose()
    } catch (error) {
      console.error(error)
      setErrorMessage(
        `Couldn't update sellers.json domain. 
         Please check that ${domain}/sellers.json is a valid sellers.json domain.`
      )
      setIsSubmitting(false)
    }
  }

  async function resetSellerDomain() {
    setIsSubmitting(true)
    if (!sellerDomain.domain) {
      setDomain('')
      setIsSubmitting(false)
      return
    }
    try {
      await deleteSellerDomain()
      setIsSubmitting(false)
      onUpdated()
      onClose()
    } catch (error) {
      console.error(error)
      setErrorMessage(
        `Could not delete sellers.json domain. If the problem persists, please contact support.`
      )
      setIsSubmitting(false)
    }
  }
}

export const SetSellersJSONDomainDialog = SetSellersJSONDomainDialogView
