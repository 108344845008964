import React, { useState } from 'react'
import { ConfirmationDialog } from '../../../../Components/ConfirmationDialog'
import { deleteReferenceChain } from '../../../../Api/references'

interface InputProps {
  buyerDomain: string
  concatBuyerDomains: string
  onClose: () => void
  onUpdated: () => void
}

export function DeleteReferenceDialog(props: InputProps) {
  const { concatBuyerDomains, buyerDomain, onClose, onUpdated } = props
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [hasError, setHasError] = useState(false)

  async function submit() {
    setIsSubmitting(true)
    try {
      await deleteReferenceChain(buyerDomain)
      onUpdated()
      onClose()
    } catch (e) {
      console.error(e)
      setHasError(true)
    }
    setIsSubmitting(false)
  }

  return (
    <ConfirmationDialog
      dialogTitle="Delete Reference"
      confirmationText={`Are you sure you want to delete the reference for the domain "${concatBuyerDomains}"?`}
      errorMessage={
        hasError
          ? 'Could not delete reference. If the problem persists, please contact support.'
          : undefined
      }
      yesButtonText="Yes"
      noButtonText="No"
      isLoading={isSubmitting}
      onCancel={onClose}
      onConfirm={submit}
    />
  )
}
