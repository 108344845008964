import React from 'react'
import {
  Icon,
  Stack,
  Text,
  IconButton,
  DirectionalHint,
  IColumn,
} from 'office-ui-fabric-react'
import { ChildProps, graphql } from 'react-apollo'
import moment from 'moment'
import { capitalize } from 'lodash'
import {
  getInstreamPlacementsQuery,
  InstreamPlacementResponse,
  InstreamPlacementInfo,
  deleteInstreamPlacement,
} from '../../Api/instreamPlacements_v2'
import { TableView } from '../../Components/TableView'
import { CreateInstreamPlacementDialog } from './CreateInstreamPlacementDialog'
import { DeletePlacementDialog } from '../../Components/DeletePlacementDialog'
import './InstreamPlacementList.css'

interface State {
  openModal?: {
    modalType: 'delete' | 'update'
    placement: InstreamPlacementInfo
  }
}

interface InputProps {
  collectionId: string
  cursor: string
}

interface PlacementRowItem extends InstreamPlacementInfo {
  tagsView: string[]
  collectionId: string
  timingView: string
  timingSort: string
}

class InstreamPlacementListView extends React.Component<
  ChildProps<InputProps, InstreamPlacementResponse>,
  State
> {
  constructor(params: any) {
    super(params)
    this.state = {
      openModal: undefined,
    }
  }
  render() {
    const { error } = this.props.data!
    if (error) {
      window.console.error(error)
      return this.renderError()
    }
    const assets = this.getAssets()
    const columns = this.getColumns()
    if (assets && assets.length === 0) {
      return this.renderEmpty()
    }
    return (
      <React.Fragment>
        <TableView
          cacheKey={this.props.collectionId}
          columns={columns}
          rowToCacheKey={(row) => row.collectionId}
          rows={assets}
          onRowClick={(row) => this.editPlacement(row)}
        />
        {this.renderUpdatePlacementDialog(this.props.data!.refetch)}
        {this.renderDeletePlacementDialog(this.props.data!.refetch)}
      </React.Fragment>
    )
  }
  renderError() {
    return (
      <Stack
        horizontalAlign="center"
        verticalAlign="center"
        verticalFill
        style={{ width: '100%' }}
      >
        <Text
          variant="xLarge"
          style={{
            maxWidth: 600,
            padding: 20,
            textAlign: 'center',
          }}
        >
          This placement collection could not be found. Most likely, you've
          opened an older link of a collection that is now deleted. If the error
          persists and your collection can not be selected from the navigation
          menu, please contact support.
        </Text>
      </Stack>
    )
  }
  renderEmpty() {
    return (
      <Stack
        horizontalAlign="center"
        verticalAlign="center"
        verticalFill
        style={{ width: '100%' }}
      >
        <Text
          variant="xLarge"
          style={{
            maxWidth: 600,
            padding: 20,
            textAlign: 'center',
          }}
        >
          This placement collection is empty. You can add a placement by
          pressing the <i>Create instream placement</i>{' '}
          <Icon iconName="BuildQueueNew" style={{ verticalAlign: 'middle' }} />
          &nbsp;button.
        </Text>
      </Stack>
    )
  }
  getTimingView(asset: InstreamPlacementInfo): string {
    switch (asset.position) {
      case 'midroll': {
        const unit = asset.midrollPosition!.unit
        const unitView = unit === 'seconds' ? ` ${unit}` : '%'
        return `${asset.midrollPosition!.break}${unitView}`
      }
      default: {
        return capitalize(asset.position)
      }
    }
  }
  getTimingSort(asset: InstreamPlacementInfo): string {
    const priorityString = String(asset.priority || '0').padStart(8, '0')
    switch (asset.position) {
      case 'preroll': {
        return `a-${priorityString}`
      }
      case 'midroll': {
        const { break: breaks, unit } = asset.midrollPosition!
        const breaksString = String(breaks).padStart(8, '0')
        if (unit === 'seconds') {
          return `b-${breaksString}-${priorityString}`
        } else {
          return `c-${breaksString}-${priorityString}`
        }
      }
      case 'postroll': {
        return `d-${priorityString}`
      }
      case 'custom': {
        return `e-${priorityString}`
      }
    }
  }
  getAssets(): PlacementRowItem[] | undefined {
    const placementCollections =
      this.props.data!.instreamPlacementCollections_v2!
    const placementCollection = placementCollections && placementCollections[0]
    const apiAssets = placementCollection && placementCollection.assets
    if (!apiAssets) {
      return
    }
    return apiAssets.map((a) => ({
      ...a,
      tagsView: a.tags.filter((t) => !t.startsWith('ipz')),
      collectionId: placementCollection.id,
      timingView: this.getTimingView(a),
      timingSort: this.getTimingSort(a),
    }))
  }
  getColumns(): IColumn[] {
    return [
      {
        key: 'type',
        name: '',
        fieldName: 'type',
        minWidth: 20,
        maxWidth: 20,
        isRowHeader: true,
        isIconOnly: true,
        isPadded: false,
        onRender: (a: PlacementRowItem) => (
          <Icon
            iconName="ArrangeBringToFront"
            style={{
              fontSize: 14,
              paddingLeft: 5,
              paddingTop: 3,
            }}
          />
        ),
      },
      {
        key: 'integration',
        name: 'Integration',
        fieldName: 'integration',
        isRowHeader: true,
        isPadded: true,
        minWidth: 100,
        maxWidth: 150,
        onRender: (a: PlacementRowItem) => (
          <span title={a.integration}>{a.integration}</span>
        ),
      },
      {
        key: 'name',
        name: 'Name',
        fieldName: 'title',
        isRowHeader: true,
        isPadded: true,
        minWidth: 100,
        maxWidth: 300,
        onRender: (a: PlacementRowItem) => (
          <span title={a.title}>{a.title}</span>
        ),
      },
      {
        key: 'publishDate',
        name: 'Publish Date',
        fieldName: 'creationDate',
        minWidth: 100,
        maxWidth: 100,
        isPadded: true,
        onRender: (a: PlacementRowItem) => (
          <span>{moment(a.creationDate).format('DD/MM/YYYY')}</span>
        ),
      },
      {
        key: 'tags',
        name: 'Tags',
        fieldName: 'tagsView',
        minWidth: 100,
        maxWidth: 400,
        isPadded: true,
        onRender: (a: PlacementRowItem) => <span>{a.tagsView.join(', ')}</span>,
      },
      {
        key: 'timing',
        name: 'Timing',
        fieldName: 'timingSort',
        minWidth: 100,
        maxWidth: 400,
        isPadded: true,
        onRender: (a: PlacementRowItem) => <span>{a.timingView}</span>,
      },
      {
        key: 'menu',
        name: '',
        fieldName: '',
        minWidth: 20,
        maxWidth: 20,
        isIconOnly: true,
        onRender: (a: PlacementRowItem) => (
          <IconButton
            styles={{
              root: {
                height: 18,
              },
              menuIcon: {
                fontWeight: 'bold',
              },
            }}
            menuProps={{
              coverTarget: true,
              directionalHint: DirectionalHint.topCenter,
              items: [
                {
                  key: 'edit',
                  iconProps: { iconName: 'edit' },
                  text: 'Edit',
                  onClick: () => this.editPlacement(a),
                },
                {
                  key: 'delete',
                  iconProps: { iconName: 'delete' },
                  text: 'Delete',
                  onClick: () => this.deletePlacement(a),
                },
              ],
            }}
          />
        ),
      },
    ]
  }
  editPlacement(placement: InstreamPlacementInfo) {
    this.setState({
      openModal: {
        modalType: 'update',
        placement,
      },
    })
  }
  deletePlacement(placement: InstreamPlacementInfo) {
    this.setState({
      openModal: {
        modalType: 'delete',
        placement,
      },
    })
  }
  renderDeletePlacementDialog(refetch: () => void) {
    if (!this.state.openModal || this.state.openModal!.modalType !== 'delete') {
      return <React.Fragment />
    }
    return (
      <DeletePlacementDialog
        type="instream"
        title={this.state.openModal!.placement.title}
        deletePlacement={() =>
          deleteInstreamPlacement(
            this.props.collectionId,
            this.state.openModal!.placement.id
          )
        }
        onUpdated={() => refetch()}
        onClose={() => this.setState({ openModal: undefined })}
      />
    )
  }
  renderUpdatePlacementDialog(refetch: () => void) {
    if (!this.state.openModal || this.state.openModal!.modalType !== 'update') {
      return <React.Fragment />
    }
    return (
      <CreateInstreamPlacementDialog
        collectionId={this.props.collectionId}
        modifiedPlacement={this.state.openModal!.placement}
        onUpdated={() => refetch()}
        onClose={() => this.setState({ openModal: undefined })}
      />
    )
  }
}

const withGql = graphql<InputProps, InstreamPlacementResponse>(
  getInstreamPlacementsQuery,
  {
    options: ({ collectionId, cursor }) => ({
      variables: {
        collectionId,
        startFromId: cursor,
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
    }),
  }
)
const InstreamPlacementList = withGql(InstreamPlacementListView)
export { InstreamPlacementList }
