import React from 'react'
import {
  Dialog,
  DefaultButton,
  TextField,
  PrimaryButton,
  DialogFooter,
  DialogType,
} from 'office-ui-fabric-react'
import { submitFeedback } from '../Api/session'

interface State {
  message: string
}

interface InputProps {
  onClose: () => void
}

export class FeedbackDialog extends React.Component<InputProps, State> {
  constructor(props: any) {
    super(props)
    this.state = {
      message: '',
    }
  }
  render() {
    return (
      <Dialog
        hidden={false}
        modalProps={{
          isDarkOverlay: true,
          isBlocking: true,
        }}
        onDismiss={this.props.onClose}
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: 'Feedback',
        }}
      >
        <TextField
          label="If you could change or fix something about the platform, what would it be?"
          description="Be our product manager for a minute.
                We will consider any suggestions 
                regarding UX improvements, ad delivery and data 
                reporting features. We'll take your suggestion 
                in any language. Thanks :)"
          disabled={false}
          multiline
          autoAdjustHeight
          resizable={false}
          defaultValue={''}
          onChange={(evt: any, value?: string) =>
            this.setState({ message: value || '' })
          }
          onKeyDown={(evt) =>
            evt.keyCode === 13 && evt.shiftKey && this.submit()
          }
        />
        <DialogFooter>
          <DefaultButton onClick={() => this.close()} text={'Cancel'} />
          <PrimaryButton
            onClick={() => this.submit()}
            disabled={this.state.message.trim().length === 0}
            text={'Submit'}
          />
        </DialogFooter>
      </Dialog>
    )
  }
  submit() {
    const message = this.state.message
    this.close()
    submitFeedback(message)
  }
  close() {
    if (document.activeElement) {
      ;(document.activeElement as HTMLElement).blur()
    }
    this.props.onClose()
  }
}
