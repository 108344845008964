import React from 'react'
import { ComboBox, IDropdownOption, Stack } from 'office-ui-fabric-react'
import InfoBox from '../CampaignCollectionScreen/CampaignDialog/InfoBox'
import { Seller } from '../../Api/schain'

interface InputProps {
  disabled: boolean
  ownership?: string
  onUpdated: (ownership?: string) => void
  sellers: Seller[]
}

export function Ownership({
  disabled,
  ownership,
  onUpdated,
  sellers,
}: InputProps) {
  const gap = 32
  const cryptoAsAny = crypto as any
  const defaultKey = cryptoAsAny.randomUUID()
  const getOwnershipOptions = () =>
    [{ key: defaultKey, text: 'Own Website' } as IDropdownOption].concat(
      sellers.map(
        (seller) =>
          ({
            key: seller.seller_id,
            text: `${seller.name || seller.seller_id} - ${seller.seller_type}`,
          }) as IDropdownOption
      )
    )

  return (
    <Stack gap={gap}>
      <ComboBox
        label="Ownership"
        selectedKey={ownership || defaultKey}
        options={getOwnershipOptions()}
        disabled={disabled}
        onChange={(_, value) => {
          if (!value) return
          onUpdated(
            value.key === defaultKey ? undefined : (value.key as string)
          )
        }}
        allowFreeform={true}
        styles={{
          optionsContainerWrapper: { maxHeight: 400 },
        }}
        required
      />
      <InfoBox>
        Setting the ownership will automatically add the schain node to ad
        requests supporting it.
      </InfoBox>
    </Stack>
  )
}
