import React, { useState } from 'react'
import { updateReferenceChain } from '../../../../Api/references'
import AddReferencesForm from './AddReferencesForm'
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Stack,
} from 'office-ui-fabric-react'

interface InputProps {
  onClose: () => void
  onUpdated: () => void
  uniqueBuyerDomains: string[]
  ownDeclaration: string | null
}

interface Reference {
  buyerDomain: string
  sellerId: string
  isConfidential?: boolean
}

const AddReferenceDialogView: React.FC<InputProps> = (props) => {
  const gap = 16
  const { onClose, onUpdated, uniqueBuyerDomains, ownDeclaration } = props
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [buyerDomainErrorMessage, setBuyerDomainErrorMessage] = useState('')
  const [sellerIdErrorMessage, setSellerIdErrorMessage] = useState('')
  const [submitErrorMessage, setSubmitErrorMessage] = useState('')
  const [references, setReferences] = useState<Reference[]>([
    { buyerDomain: '', sellerId: '', isConfidential: false },
  ])

  async function submit() {
    try {
      const referencesData = references.map((reference) => ({
        buyerDomain: reference.buyerDomain,
        seller_id: reference.sellerId,
      }))
      setIsSubmitting(true)
      await updateReferenceChain({
        references: referencesData,
      })
      setIsSubmitting(false)
      onUpdated()
      onClose()
    } catch (e) {
      console.error(e)
      setSubmitErrorMessage(
        `Could not add reference. If the problem persists, please contact support.`
      )
      setIsSubmitting(false)
    }
  }

  return (
    <Dialog
      hidden={false}
      modalProps={{
        isDarkOverlay: true,
        isBlocking: true,
        className: 'add-reference-dialog',
      }}
      onDismiss={onClose}
      dialogContentProps={{
        type: DialogType.largeHeader,
        title: 'Add Reference',
      }}
    >
      {references.map((reference, index) => (
        <Stack gap={gap} key={index}>
          <AddReferencesForm
            disabled={isSubmitting || index !== references.length - 1}
            sellerIdLabel={
              index === 0
                ? 'Select the entry containing your business information'
                : 'Select the entry containing the previous buyer'
            }
            onSellerIdChange={(sellerId, seller) => {
              setReferences((currentReferences) => {
                if (
                  index > 0 &&
                  seller.domain !== references[index - 1].buyerDomain &&
                  !seller.is_confidential
                ) {
                  setSellerIdErrorMessage(
                    'Selected entity does not match the last link on the Supply Chain.'
                  )
                } else {
                  setSellerIdErrorMessage('')
                }

                const newReferences = [...currentReferences]
                newReferences[index].sellerId = sellerId
                newReferences[index].isConfidential = !!seller.is_confidential
                return newReferences
              })
            }}
            onBuyerDomainChange={(buyerDomain) => {
              setReferences((currentReferences) => {
                if (
                  index === 0 &&
                  buyerDomain !== ownDeclaration &&
                  ownDeclaration !== null
                ) {
                  setBuyerDomainErrorMessage(
                    'The selected entry does not match your represented business entity, as configured on the Own Domain screen.'
                  )
                } else {
                  setBuyerDomainErrorMessage('')
                }
                const newReferences = [...currentReferences]
                newReferences[index].buyerDomain = buyerDomain
                return newReferences
              })
            }}
            reference={reference}
            suggestedBuyerDomains={uniqueBuyerDomains}
            errorMessage={
              index === references.length - 1
                ? buyerDomainErrorMessage || sellerIdErrorMessage
                : ''
            }
          />
          {index === 0 &&
            !buyerDomainErrorMessage &&
            !reference.isConfidential && (
              <MessageBar
                messageBarType={MessageBarType.info}
                isMultiline={true}
              >
                Only nodes connected to your represented business entity can be
                the first node in a supply chain of a 3rd party campaign
                configuration.
              </MessageBar>
            )}
          {reference.isConfidential && (
            <MessageBar messageBarType={MessageBarType.info} isMultiline={true}>
              Confidential reference selected. Please make sure that you are
              selecting the right entry based on the publisher ID.
            </MessageBar>
          )}
          {!reference.isConfidential && !sellerIdErrorMessage && index > 0 && (
            <MessageBar messageBarType={MessageBarType.info} isMultiline={true}>
              The selected buyer domain will be linked to the Supply Chain.
            </MessageBar>
          )}
        </Stack>
      ))}
      <Stack gap={gap} styles={{ root: { marginTop: gap } }}>
        <PrimaryButton
          onClick={() =>
            setReferences((currentReferences) => [
              ...currentReferences,
              { buyerDomain: '', sellerId: '' },
            ])
          }
          disabled={
            isSubmitting ||
            !!buyerDomainErrorMessage ||
            !!sellerIdErrorMessage ||
            !references[references.length - 1].buyerDomain ||
            !references[references.length - 1].sellerId
          }
          text="Add Node"
        />
        <DefaultButton
          onClick={() => {
            setReferences(references.slice(0, references.length - 1))
            setSellerIdErrorMessage('')
            setBuyerDomainErrorMessage('')
          }}
          disabled={isSubmitting || references.length === 1}
          text="Delete Node"
        />
        {!!submitErrorMessage && (
          <MessageBar
            messageBarType={MessageBarType.error}
            isMultiline={true}
            styles={{ root: { marginTop: 15 } }}
          >
            {submitErrorMessage}
          </MessageBar>
        )}
      </Stack>
      <DialogFooter>
        <DefaultButton
          onClick={onClose}
          disabled={isSubmitting}
          text="Cancel"
        />
        <PrimaryButton
          onClick={submit}
          disabled={
            isSubmitting ||
            !!buyerDomainErrorMessage ||
            !!sellerIdErrorMessage ||
            !references[references.length - 1].buyerDomain ||
            !references[references.length - 1].sellerId
          }
          text="Save"
        />
      </DialogFooter>
    </Dialog>
  )
}

export const AddReferenceDialog = AddReferenceDialogView
