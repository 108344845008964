import React from 'react'
import ReactDOM from 'react-dom'
import { FluentCustomizations } from '@uifabric/fluent-theme'
import { Customizer, mergeStyles } from 'office-ui-fabric-react'
import { initializeIcons } from 'new-icons'
import { client, ensureAuthentication } from './Api/api'
import { ApolloProvider } from 'react-apollo'
import { App } from './Layout/App'
import './index.css'
import { SessionContextProvider } from './Components/SessionContextProvider'

ensureAuthentication()

initializeIcons()

mergeStyles({
  selectors: {
    ':global(body), :global(html), :global(#root)': {
      margin: 0,
      padding: 0,
      height: '100vh',
    },
  },
})

// 03a9f4

FluentCustomizations.settings.theme.palette = {
  themePrimary: '#0297dc',
  themeLighterAlt: '#f4fafe',
  themeLighter: '#d2edf9',
  themeLight: '#acddf4',
  themeTertiary: '#5fbeea',
  themeSecondary: '#1da2e0',
  themeDarkAlt: '#0287c5',
  themeDark: '#0272a7',
  themeDarker: '#01547b',
  neutralLighterAlt: '#f8f8f8',
  neutralLighter: '#f4f4f4',
  neutralLight: '#e2e2e2',
  neutralQuaternaryAlt: '#dadada',
  neutralQuaternary: '#d0d0d0',
  neutralTertiaryAlt: '#c8c8c8',
  neutralTertiary: '#bab8b7',
  neutralSecondary: '#a3a2a0',
  neutralPrimaryAlt: '#8d8b8a',
  neutralPrimary: '#323130',
  neutralDark: '#605e5d',
  black: '#494847',
  white: '#ffffff',
}

ReactDOM.render(
  <Customizer {...FluentCustomizations}>
    <ApolloProvider client={client}>
      <SessionContextProvider>
        <App />
      </SessionContextProvider>
    </ApolloProvider>
  </Customizer>,
  document.getElementById('root')
)

console.log(`
Third-Party Software Licenses

This software includes third-party libraries and components.
Full license information for these can be found in our licenses file accessible at:
https://client.caroda.io/licenses.txt

We encourage all users of this library to review the licensing terms of the included third-party software.
`)
