import ApolloClient from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { gql } from 'apollo-boost'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { onError } from 'apollo-link-error'
import { getEncodedOrigin } from './origin'

const httpLink = createHttpLink({
  uri: 'https://platform.caroda.io/api/gql',
  credentials: 'include',
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (
    graphQLErrors ||
    !networkError ||
    !networkError.message.match(/status code 401$/)
  ) {
    return
  }
  window.location.href = `https://platform.caroda.io/api/login?redirect=${getEncodedOrigin()}`
})

const client = new ApolloClient({
  link: errorLink.concat(httpLink),
  cache: new InMemoryCache({
    addTypename: false,
  }),
})

async function ensureAuthentication() {
  try {
    const res = await client.query({
      query: gql`
        {
          session {
            email
            integrationToken
            accountDisplayName
          }
        }
      `,
      fetchPolicy: 'network-only',
    })
    const { email, integrationToken, accountDisplayName } = res.data.session
    loadCarodaScript(integrationToken)
    authenticateToBeacon(email, accountDisplayName)
  } catch (e) {
    if ((e as Error).message.match(/status code 401$/)) {
      window.location.href = `https://platform.caroda.io/api/login?redirect=${getEncodedOrigin()}`
      await new Promise(() => {})
    }
    throw e
  }
}

function authenticateToBeacon(email: string, name: string) {
  ;(window as any).Beacon('identify', { name, email })
}

async function loadCarodaScript(integrationToken: string) {
  const postscribe = (window as any).postscribe as any
  postscribe(
    document.head,
    `<script
        crossorigin="anonymous"
        src="https://publisher.caroda.io/videoPlayer/caroda.min.js?ctok=${encodeURIComponent(
          integrationToken
        )}"
    ></script>`
  )
}

async function logout() {
  await ensureAuthentication()
  const logoutUrl = `https://platform.caroda.io/api/logout?redirect=${getEncodedOrigin()}`
  window.location.href = logoutUrl
}

export { client, ensureAuthentication, logout }
