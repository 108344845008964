import React from 'react'
import {
  TextField,
  Stack,
  Dropdown,
  Toggle,
  MessageBar,
  MessageBarType,
} from 'office-ui-fabric-react'
import { CampaignDirectAssetInfo } from '../../../Api/campaigns'
import { migrateUrl } from '../../../Api/videoAssets'
import { getAllPrebidParams } from '../../../Constants/PrebidParams'
import { BlockingLoadingDialog } from '../../../Components/BlockingLoadingDialog'
import { ErrorDialog } from '../../../Components/ErrorDialog'
import { UploadButton } from '../../../Components/Input/UploadButton'
import { NumberInput } from '../../../Components/Input/NumberInput'

getAllPrebidParams()

const migrationCache: { [key: string]: any } = {}
type DirectAssetType =
  | 'customVast3'
  | 'customVast3:imageTimed'
  | 'customVast3:imageWithAudio'
type AspectRatio = '16:9' | '9:16' | '4:3' | '3:4' | '1:1' | 'native'
const videoAspectRatios = [
  '16:9',
  '9:16',
  '4:3',
  '3:4',
  '1:1',
  'native',
] as AspectRatio[]
const timedImageOptions = [
  { key: '5', text: '00:05' },
  { key: '10', text: '00:10' },
  { key: '15', text: '00:15' },
  { key: '20', text: '00:20' },
  { key: '30', text: '00:30' },
  { key: '45', text: '00:45' },
  { key: '60', text: '01:00' },
  { key: '90', text: '01:30' },
]

interface Uploads {
  audioPosterUrl: string
  assetUploadUrl: string
  noAudioDuration?: number
  suggestedVideoName?: string
}

interface OptionalUploads {
  audioPosterUrl?: string
  assetUploadUrl?: string
}

interface State {
  blockingModal?:
    | 'uploading'
    | 'transcoding'
    | 'transcodingError'
    | 'incompatibleVideoFormat'
  uploads: { [seed: number]: Uploads }
}

interface InputProps {
  gap: number
  errorMessages: { [k: string]: string }
  disabled: boolean
  asset: CampaignDirectAssetInfo
  seed: number
  onChange: (asset: CampaignDirectAssetInfo) => any
}

export class DirectSourceForm extends React.Component<InputProps, State> {
  constructor(props: InputProps) {
    super(props)
    this.state = {
      blockingModal: undefined,
      uploads: {},
    }
  }
  getAssetType() {
    const asset = this.props.asset
    const isDirectAudioCampaign = asset.videoId.match(/au$/)
    const isDirectAudioTimedCampaign =
      isDirectAudioCampaign && /^no audio/.test(asset.videoName)
    const assetType: DirectAssetType = isDirectAudioCampaign
      ? isDirectAudioTimedCampaign
        ? 'customVast3:imageTimed'
        : 'customVast3:imageWithAudio'
      : 'customVast3'
    return assetType
  }
  getNoAudioUrl(duration: number) {
    return `https://cdn4.caroda.io/noaudio/${duration}s.mp3`
  }
  getUploads() {
    const ret = this.state.uploads[this.props.seed] || {
      audioPosterUrl: '',
      assetUploadUrl: '',
    }
    if (this.getAssetType() === 'customVast3:imageTimed') {
      const durationMatch = this.props.asset.videoName.match(/([0-9]+)s/)
      const duration = durationMatch ? Number(durationMatch[1]) : 15
      const selectedOption = timedImageOptions.reduce(
        (ret, o) =>
          Math.abs(duration - Number(o.key)) <
          Math.abs(duration - Number(ret.key))
            ? o
            : ret,
        timedImageOptions[0]
      )
      ret.noAudioDuration = +selectedOption.key
      ret.assetUploadUrl = this.getNoAudioUrl(ret.noAudioDuration)
      ret.suggestedVideoName = `no audio ${selectedOption.key}s`
    }
    return ret
  }
  setUploads(uploads: OptionalUploads) {
    const oldUploads = this.getUploads()
    this.setState({
      uploads: {
        ...this.state.uploads,
        [this.props.seed]: {
          audioPosterUrl: uploads.audioPosterUrl || oldUploads.audioPosterUrl,
          assetUploadUrl: uploads.assetUploadUrl || oldUploads.assetUploadUrl,
        },
      },
    })
  }
  render() {
    const assetType = this.getAssetType()
    return (
      <React.Fragment>
        {assetType === 'customVast3' && this.renderVideoUpload()}
        {assetType === 'customVast3:imageWithAudio' &&
          this.renderImageWithAudioUpload()}
        {assetType === 'customVast3:imageTimed' &&
          this.renderTimedImageUpload()}
        {this.state.blockingModal === 'uploading' && (
          <BlockingLoadingDialog label="Uploading" />
        )}
        {this.state.blockingModal === 'transcoding' && (
          <BlockingLoadingDialog label="Setting transcoding metadata" />
        )}
        {this.state.blockingModal === 'transcodingError' && (
          <ErrorDialog
            title={'Network error'}
            message="Could not schedule transcoding job. Please try again later."
            onClose={() => this.setState({ blockingModal: undefined })}
          />
        )}
        {this.state.blockingModal === 'incompatibleVideoFormat' && (
          <ErrorDialog
            title={'Incompatible format'}
            message="The uploaded file is not a valid video file. Please try again with a different file."
            onClose={() => this.setState({ blockingModal: undefined })}
          />
        )}
      </React.Fragment>
    )
  }
  renderVideoUpload() {
    const asset = this.props.asset
    const disableAspectRatio =
      !!asset.videoId && !this.getUploads().assetUploadUrl
    return (
      <React.Fragment>
        <Stack gap={this.props.gap} horizontal>
          <UploadButton
            label="Video File"
            buttonLabel="Upload"
            errorMessage={this.props.errorMessages['customVast3File']}
            disabled={this.props.disabled}
            accept="video/*"
            fileName={asset.videoName}
            required={true}
            onClick={(file) =>
              this.setState({ blockingModal: file ? 'uploading' : undefined })
            }
            onUploaded={async (file, assetUploadUrl) => {
              if (!/video/.test(file.type)) {
                this.props.onChange({
                  ...asset,
                  videoName: '',
                  audioPosterName: '',
                })
                this.setState({ blockingModal: 'incompatibleVideoFormat' })
                this.setUploads({ audioPosterUrl: '', assetUploadUrl: '' })
                return
              }
              this.setUploads({ assetUploadUrl })
              this.transcodeAsset({
                assetUrl: assetUploadUrl,
                videoAspectRatio: asset.videoAspectRatio as AspectRatio,
              })
            }}
            style={{ width: `calc(50% - ${this.props.gap / 2}px)` }}
          />
          <Dropdown
            label="Aspect Ratio"
            selectedKey={asset.videoAspectRatio || '16:9'}
            onChange={async (evt, value?) => {
              if (!value) {
                return
              }
              const videoAspectRatio = value!.key.toString() as AspectRatio
              this.props.onChange({ ...asset, videoAspectRatio })
              if (!this.getUploads().assetUploadUrl) {
                return
              }
              this.transcodeAsset({
                assetUrl: this.getUploads().assetUploadUrl,
                videoAspectRatio: videoAspectRatio,
              })
            }}
            required={true}
            options={videoAspectRatios.map((key) => ({
              key,
              text: key === 'native' ? 'keep original' : key,
            }))}
            disabled={this.props.disabled || disableAspectRatio}
            styles={{ root: { width: `calc(50% - ${this.props.gap / 2}px)` } }}
          />
        </Stack>
        {asset.videoAspectRatio === 'native' && this.renderAspectRatioInfoBox()}
        {disableAspectRatio && (
          <MessageBar messageBarType={MessageBarType.info} isMultiline={true}>
            For changing the aspect ratio, the ad video needs to be re-uploaded
          </MessageBar>
        )}
        {this.renderDirectSourceBehavior()}
      </React.Fragment>
    )
  }
  renderImageWithAudioUpload() {
    const asset = this.props.asset
    const errors = this.props.errorMessages
    const audioError = errors['customVast3AudioFile']
    const canChangeAspect =
      asset.videoId === 'au' ||
      (!!this.getUploads().assetUploadUrl && !!this.getUploads().audioPosterUrl)
    return (
      <React.Fragment>
        <Stack gap={this.props.gap} horizontal>
          {this.renderImageUploadButton()}
          <UploadButton
            label="Audio File"
            buttonLabel="Upload"
            errorMessage={audioError}
            disabled={this.props.disabled}
            accept="audio/*"
            fileName={asset.videoName}
            required={true}
            onClick={(file) =>
              this.setState({ blockingModal: file ? 'uploading' : undefined })
            }
            onUploaded={async (_, assetUploadUrl) => {
              this.setState({ blockingModal: undefined })
              this.setUploads({ assetUploadUrl })
              const urlParts = assetUploadUrl.split('/')
              const change = {
                ...asset,
                videoName: urlParts[urlParts.length - 1],
              }
              if (!this.getUploads().audioPosterUrl) {
                change.audioPosterName = ''
              }
              this.props.onChange(change)
              if (!this.getUploads().audioPosterUrl) {
                return
              }
              this.transcodeAsset({
                assetUrl: assetUploadUrl,
                videoAspectRatio: asset.videoAspectRatio as AspectRatio,
                posterUrl: this.getUploads().audioPosterUrl,
              })
            }}
            style={{ width: `calc(50% - ${this.props.gap / 2}px)` }}
          />
        </Stack>
        {!canChangeAspect && (
          <MessageBar messageBarType={MessageBarType.info} isMultiline={true}>
            For changing the aspect ratio, the ad image and audio track need to
            be re-uploaded
          </MessageBar>
        )}
        {this.renderImageAspectRatioDropdown()}
        {asset.videoAspectRatio === 'native' && this.renderAspectRatioInfoBox()}
        {this.renderDirectSourceBehavior()}
      </React.Fragment>
    )
  }
  renderTimedImageUpload() {
    const asset = this.props.asset
    const duration = this.getUploads().noAudioDuration || 15
    const canChangeDuration =
      asset.videoId === 'au' || !!this.getUploads().audioPosterUrl
    return (
      <React.Fragment>
        {this.renderImageUploadButton()}
        {!canChangeDuration && (
          <MessageBar messageBarType={MessageBarType.info} isMultiline={true}>
            For changing the aspect ratio or duration, the ad image needs to be
            re-uploaded
          </MessageBar>
        )}
        <Stack gap={this.props.gap} horizontal>
          {this.renderImageAspectRatioDropdown()}
          <Dropdown
            label="Duration"
            selectedKey={duration.toString()}
            onChange={async (_, value?) => {
              if (!value) {
                return
              }
              const assetUploadUrl = this.getNoAudioUrl(+value.key)
              const videoName = `no audio ${value.key}s`
              this.setUploads({ assetUploadUrl })
              this.props.onChange({ ...asset, videoName })
              if (!this.getUploads().audioPosterUrl) {
                return
              }
              this.transcodeAsset({
                assetUrl: assetUploadUrl,
                videoAspectRatio: asset.videoAspectRatio as AspectRatio,
                posterUrl: this.getUploads().audioPosterUrl,
                newVideoName: videoName,
              })
            }}
            required={true}
            options={timedImageOptions}
            disabled={this.props.disabled || !canChangeDuration}
            styles={{ root: { width: `calc(50% - ${this.props.gap / 2}px)` } }}
          />
        </Stack>
        {asset.videoAspectRatio === 'native' && this.renderAspectRatioInfoBox()}
        {this.renderDirectSourceBehavior()}
      </React.Fragment>
    )
  }
  renderImageUploadButton() {
    const asset = this.props.asset
    return (
      <UploadButton
        label="Image File"
        buttonLabel="Upload"
        errorMessage={this.props.errorMessages['customVast3AudioPoster']}
        disabled={this.props.disabled}
        accept="image/jpg, image/jpeg, image/png"
        fileName={asset.audioPosterName || ''}
        required={true}
        onClick={(file) =>
          this.setState({ blockingModal: file ? 'uploading' : undefined })
        }
        onUploaded={async (_, audioPosterUrl) => {
          this.setState({ blockingModal: undefined })
          this.setUploads({ audioPosterUrl })
          const urlParts = audioPosterUrl.split('/')
          const change = {
            ...asset,
            audioPosterName: urlParts[urlParts.length - 1],
          }
          const assetUploadUrl = this.getUploads().assetUploadUrl
          if (!assetUploadUrl) {
            change.videoId = 'au'
            change.videoName = ''
          }
          this.props.onChange(change)
          if (!assetUploadUrl) {
            return
          }
          const newVideoName =
            this.getUploads().suggestedVideoName || asset.videoName
          this.transcodeAsset({
            assetUrl: this.getUploads().assetUploadUrl,
            videoAspectRatio: asset.videoAspectRatio as AspectRatio,
            posterUrl: audioPosterUrl,
            newVideoName,
          })
        }}
        style={{ width: `calc(50% - ${this.props.gap / 2}px)` }}
      />
    )
  }
  renderImageAspectRatioDropdown() {
    const asset = this.props.asset
    const canChangeAspect =
      asset.videoId === 'au' ||
      (!!this.getUploads().assetUploadUrl && !!this.getUploads().audioPosterUrl)
    return (
      <Dropdown
        label="Aspect Ratio"
        selectedKey={asset.videoAspectRatio || '16:9'}
        onChange={async (evt, value?) => {
          if (!value) {
            return
          }
          const videoAspectRatio = value!.key.toString() as AspectRatio
          this.props.onChange({ ...asset, videoAspectRatio })
          if (
            !this.getUploads().assetUploadUrl ||
            !this.getUploads().audioPosterUrl
          ) {
            return
          }
          const newVideoName =
            this.getUploads().suggestedVideoName || asset.videoName
          this.transcodeAsset({
            assetUrl: this.getUploads().assetUploadUrl,
            videoAspectRatio,
            posterUrl: this.getUploads().audioPosterUrl,
            newVideoName,
          })
        }}
        required={true}
        options={videoAspectRatios.map((key) => ({
          key,
          text: key === 'native' ? 'keep original' : key,
        }))}
        disabled={this.props.disabled || !canChangeAspect}
        styles={{ root: { width: `calc(50% - ${this.props.gap / 2}px)` } }}
      />
    )
  }
  renderAspectRatioInfoBox() {
    return (
      <MessageBar messageBarType={MessageBarType.info} isMultiline={true}>
        The aspect ratio displayed in outstream positions will be the closest
        aspect ratio of 16:9, 4:3, 1:1, 3:4, 9:16. For instream positions, the
        used aspect ratio is always 16:9.
      </MessageBar>
    )
  }
  renderDirectSourceBehavior() {
    const asset = this.props.asset
    return (
      <React.Fragment>
        <TextField
          label={'Landing Page Url'}
          errorMessage={this.props.errorMessages['customVast3LandingPage']}
          value={asset.landingPageUrl}
          autoComplete={'off'}
          spellCheck={false}
          onChange={(evt: any, value?: string) =>
            this.props.onChange({ ...asset, landingPageUrl: value || '' })
          }
          disabled={this.props.disabled}
          multiline
          resizable={false}
          autoAdjustHeight
          required
        />
        <Stack gap={this.props.gap} horizontal>
          <Toggle
            styles={{ root: { width: `calc(50% - ${this.props.gap / 2}px)` } }}
            label="Skippable"
            onText="On"
            offText="Off"
            checked={asset.skipTime !== null}
            onChange={(ev, value) =>
              this.props.onChange({ ...asset, skipTime: value ? 5 : null })
            }
          />
          <NumberInput
            style={{ width: `calc(50% - ${this.props.gap / 2}px)` }}
            label="Skip Time (seconds)"
            value={asset.skipTime}
            onChange={(value) =>
              this.props.onChange({ ...asset, skipTime: value || 0 })
            }
            disabled={this.props.disabled || asset.skipTime === null}
            required={asset.skipTime !== null}
            min={0}
            max={30}
          />
        </Stack>
      </React.Fragment>
    )
  }
  async transcodeAsset({
    assetUrl,
    videoAspectRatio,
    posterUrl,
    newVideoName,
  }: {
    assetUrl: string
    videoAspectRatio: AspectRatio
    posterUrl?: string
    newVideoName?: string
  }) {
    this.setState({ blockingModal: 'transcoding' })
    const asset = this.props.asset
    const cacheKey = JSON.stringify([assetUrl, videoAspectRatio, posterUrl])
    try {
      const videoInfo =
        migrationCache[cacheKey] ||
        (await migrateUrl('ADS', assetUrl, videoAspectRatio, posterUrl))
      migrationCache[cacheKey] = videoInfo
      const videoName = newVideoName || videoInfo.Title
      const videoId = videoInfo.VID
      this.props.onChange({
        ...asset,
        videoName,
        videoId,
        videoAspectRatio,
      })
      this.setState({ blockingModal: undefined })
    } catch (e) {
      this.props.onChange({
        ...asset,
        videoName: '',
        audioPosterName: '',
      })
      this.setState({ blockingModal: 'transcodingError' })
      this.setUploads({ audioPosterUrl: '', assetUploadUrl: '' })
    }
  }
}
