import { client } from './api'
import gql from 'graphql-tag'
import { VideoCollectionSummary } from './videoAssets'
import { getOrigin } from './origin'

export const getDelegatedAccountsQuery = gql`
  query {
    session {
      delegatedAccounts {
        id
        displayName
      }
    }
  }
`

export const getAllCollectionSummariesQuery = gql`
  query {
    session {
      groupNames
    }
    videoCollections {
      id
      title
    }
    outstreamPlacementCollections_v2 {
      id
      title
    }
    campaignCollections {
      id
      title
    }
    instreamPlacementCollections_v2 {
      id
      title
    }
    integrations {
      integratedWithInview
    }
  }
`

export const getAccountDisplayInfoQuery = gql`
  query {
    session {
      accountName
      accountDisplayName
      groupNames
      delegatedAccounts {
        id
        displayName
      }
    }
  }
`

export const getNewsItemsQuery = gql`
  query ($offset: Float) {
    cms {
      news(offset: $offset) {
        id
        title
        body
        date
        posterUrl
      }
    }
  }
`

const setAccountDisplayNameMutation = gql`
  mutation ($displayName: String!) {
    session(accountDisplayName: $displayName)
  }
`

const createAccountMutation = gql`
  mutation (
    $emailAddress: String!
    $accountName: String!
    $accountType: AccountType
    $isNewAccount: Boolean!
  ) {
    createAccount(
      emailAddress: $emailAddress
      accountName: $accountName
      accountType: $accountType
      isNewAccount: $isNewAccount
    )
  }
`

export interface DelegatedAccount {
  id: string
  displayName: string
}

export interface AccountDisplayInfo {
  accountName: string
  accountDisplayName: string
  groupNames: string[]
  delegatedAccounts?: DelegatedAccount[]
}

export interface NewsItem {
  id: string
  title: string
  body: string
  date: string
  posterUrl: string
}

export interface DelegatedAccountsResponse {
  session: {
    delegatedAccounts: DelegatedAccount[]
  }
}

export interface AccountDisplayInfoResponse {
  session: AccountDisplayInfo
}

export interface NewsItemsResponse {
  cms: {
    news: NewsItem[]
  }
}

export interface AllCollectionSummariesResponse {
  session: { groupNames: string[] }
  videoCollections: VideoCollectionSummary[]
  outstreamPlacementCollections_v2: VideoCollectionSummary[]
  instreamPlacementCollections_v2: VideoCollectionSummary[]
  campaignCollections: VideoCollectionSummary[]
  integrations: IntegrationSummary[]
}

export interface IntegrationSummary {
  integratedWithInview?: boolean
}

export async function setAccountDisplayName(displayName: string) {
  return client.mutate({
    mutation: setAccountDisplayNameMutation,
    variables: {
      displayName,
    },
  })
}

export async function createAccount(
  emailAddress: string,
  isNewAccount: boolean,
  accountName: string,
  accountType?: 'web'
) {
  return client.mutate({
    mutation: createAccountMutation,
    variables: {
      emailAddress,
      isNewAccount,
      accountName,
      accountType,
    },
  })
}

export async function getDelegatedAccounts() {
  return client.query({
    query: getDelegatedAccountsQuery,
    fetchPolicy: 'network-only',
  })
}

function hashCode(str: string) {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = ~~((hash << 5) - hash + str.charCodeAt(i))
  }
  return hash
}

export async function submitFeedback(message: string) {
  const account = await client.query({ query: getAccountDisplayInfoQuery })
  const accountDisplayName = account.data!.session.accountDisplayName
  const emojiId = (Math.abs(hashCode(accountDisplayName)) % 11) + 1
  const emoji = `crd-000`.slice(0, -emojiId.toString().length) + emojiId
  const xhr = new XMLHttpRequest()
  xhr.open(
    'POST',
    `https://hooks.slack.com/services/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`
  )
  xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded')
  xhr.send(
    `payload=${encodeURIComponent(
      JSON.stringify({
        username: accountDisplayName,
        icon_emoji: ':' + emoji + ':',
        text: message,
      })
    )}`
  )
}

export function isAccountManager(
  accountDisplayInfo: AccountDisplayInfo | { groupNames: string[] }
): boolean {
  return accountDisplayInfo.groupNames.indexOf('Account Managers') !== -1
}

export function isAdmin(
  accountDisplayInfo: AccountDisplayInfo | { groupNames: string[] }
): boolean {
  return accountDisplayInfo.groupNames.indexOf('Admins') !== -1
}

export function loginAs({
  account,
  ctok,
  redirectPath = '',
}: {
  account?: string
  ctok?: string
  redirectPath?: string
}): void {
  const origin = getOrigin()
  const redirect = `${origin}${redirectPath}`
  let baseUrl = `https://platform.caroda.io/api/loginAs?&redirect=${encodeURIComponent(redirect)}`
  if (account) {
    baseUrl += `&account=${encodeURIComponent(account)}`
  } else if (ctok) {
    baseUrl += `&ctok=${encodeURIComponent(ctok)}`
  }
  window.location.href = baseUrl
}
