import { Campaign, Interval } from '../../../../Api/campaigns'

import LimitByEvent from './CappingFields/LimitByEvent'
import { NumberInput } from '../../../../Components/Input/NumberInput'
import React from 'react'
import { ShownTagInput } from '../../../../Components/Input/ShownTagInput'
import { Stack } from 'office-ui-fabric-react'
import {
  HourlyDistributionInput,
  convertGMTToHourlyDistribution,
  normalizeHourlyDistribution,
} from '../../../../Components/Input/HourlyDistributionInput'
import {
  HourlyDistribution,
  defaultWorkingHours,
} from '../../../../Api/campaigns'
import { removeNullFields } from '../../../../Utils/object'

export interface Change {
  eventLimit?: number | null
  timedEventLimit?: number | null
  timedEventInterval?: Interval | null
  eventsPerUser?: number | null
  eventsPerUserInterval?: Interval | null
  maximumAdLength?: number | null
  cappingEventType?: string | null
  schedule?: HourlyDistribution
}

type CappingType =
  | 'LimitByEvent'
  | 'TotalLimit'
  | 'TimedLimit'
  | 'UserLimit'
  | 'MaximumAdLength'
  | 'Schedule'

interface State {
  selectedCappingTypes: CappingType[]
}

interface InputProps {
  gap: number
  errorMessages: { [vastEvent: string]: string }
  disabled: boolean
  campaign: Campaign
  onChange: (campaignChange: Change, hasError?: boolean) => any
}

export class CappingForm extends React.Component<InputProps, State> {
  constructor(props: InputProps) {
    super(props)
    this.state = {
      selectedCappingTypes: [],
    }
  }
  render() {
    const campaign = this.props.campaign
    const selectedCappingTypes = this.state.selectedCappingTypes
    const cappingEventType = campaign.cappingEventType || 'impression'
    const schedule = removeNullFields(campaign.schedule) || defaultWorkingHours
    const localSchedule = convertGMTToHourlyDistribution(schedule)
    if (shouldDisplayLimitByEventToogle(campaign, selectedCappingTypes)) {
      selectedCappingTypes.push('LimitByEvent')
    }
    if (
      JSON.stringify(schedule) !== JSON.stringify(defaultWorkingHours) &&
      selectedCappingTypes.indexOf('Schedule') === -1
    ) {
      selectedCappingTypes.push('Schedule')
    }
    if (
      campaign.maximumAdLength != null &&
      selectedCappingTypes.indexOf('MaximumAdLength') === -1
    ) {
      selectedCappingTypes.push('MaximumAdLength')
    }
    return (
      <Stack gap={this.props.gap}>
        <ShownTagInput
          label=""
          selectedKeys={selectedCappingTypes}
          onChange={(value?) => {
            if (!value) {
              return
            }
            const newCappingTypes = value.map(({ key }) => key as CappingType)
            const change = {} as Change
            this.setState({ selectedCappingTypes: newCappingTypes })
            if (newCappingTypes.indexOf('LimitByEvent') === -1) {
              change.cappingEventType = 'impression'
              change.eventLimit = null
              change.timedEventLimit = null
              change.timedEventInterval = null
              change.eventsPerUser = null
              change.eventsPerUserInterval = null
            }
            if (newCappingTypes.indexOf('Schedule') === -1) {
              change.schedule = defaultWorkingHours
            }
            if (newCappingTypes.indexOf('MaximumAdLength') === -1) {
              change.maximumAdLength = null
            }
            if (!Object.keys(change).length) {
              return
            }
            this.props.onChange(change)
          }}
          disabled={this.props.disabled}
          options={[
            { key: 'LimitByEvent', name: 'Limit by Event' },
            { key: 'MaximumAdLength', name: 'Maximum ad length' },
            { key: 'Schedule', name: 'Schedule' },
          ]}
        />
        {selectedCappingTypes.indexOf('LimitByEvent') !== -1 && (
          <LimitByEvent
            {...this.props}
            errorMessage={this.props.errorMessages['limitByEvent']}
            cappingEventType={cappingEventType}
          />
        )}
        {selectedCappingTypes.indexOf('Schedule') !== -1 && (
          <HourlyDistributionInput
            label={'Schedule'}
            hourlyDistribution={localSchedule}
            weighted={false}
            disabled={this.props.disabled}
            showErrors={true}
            isStoredInGMT={true}
            onUpdate={(
              hourlyDistribution: HourlyDistribution,
              hasError: boolean
            ) => {
              const normalizedHourlyDistribution =
                normalizeHourlyDistribution(hourlyDistribution)
              this.props.onChange(
                {
                  schedule: normalizedHourlyDistribution,
                },
                hasError
              )
            }}
          ></HourlyDistributionInput>
        )}

        {selectedCappingTypes.indexOf('MaximumAdLength') !== -1 && (
          <NumberInput
            label={'Maximum ad length (seconds)'}
            errorMessage={undefined}
            style={{ maxWidth: `calc(50% - ${this.props.gap / 2}px)` }}
            name={'maximumAdLength'}
            value={campaign.maximumAdLength || null}
            onChange={(value?: number) =>
              this.props.onChange({ maximumAdLength: value ? value : null })
            }
            min={0}
            max={120}
            precision={3}
            disabled={this.props.disabled}
            required={true}
          />
        )}
      </Stack>
    )
  }
}

const shouldDisplayLimitByEventToogle = (
  campaign: Campaign,
  selectedCappingTypes: CappingType[]
): boolean => {
  const isCappingEventType =
    campaign.cappingEventType !== null &&
    campaign.cappingEventType !== 'impression'
  const isCapping =
    !!campaign.eventLimit ||
    !!campaign.eventsPerUser ||
    !!campaign.timedEventLimit

  return (
    (isCappingEventType || isCapping) &&
    selectedCappingTypes.indexOf('LimitByEvent') === -1
  )
}
