import gql from 'graphql-tag'

export interface ConnectToWebsiteResponse {
  session: { integrationToken: string }
}

export const getConnectToWebsiteQueryQuery = gql`
  query ($collectionId: String) {
    session {
      integrationToken
    }
  }
`
