import React from 'react'
import { VideoAssetInfo, renameVideoAsset } from '../../Api/videoAssets'
import { TextPromptDialog } from '../../Components/TextPromptDialog'

interface State {
  pendingNameChange?: string
  isSubmitting: boolean
  hasError: boolean
}

interface InputProps {
  collectionId: string
  videoAsset: VideoAssetInfo
  isOpen: boolean
  onClose: () => void
  onUpdated: () => void
}

export class RenameVideoAssetDialog extends React.Component<InputProps, State> {
  constructor(props: any) {
    super(props)
    this.state = {
      pendingNameChange: undefined,
      isSubmitting: false,
      hasError: false,
    }
  }
  render() {
    let title = this.state.pendingNameChange || this.props.videoAsset.title
    return (
      <TextPromptDialog
        dialogTitle="Rename Video Asset"
        fieldTitle="New Title"
        confirmButtonText="Save"
        initialValue={title}
        isLoading={this.state.isSubmitting}
        onUpdated={() => this.setState({ hasError: false })}
        onSubmit={(value) => this.submit(value)}
        onClose={this.props.onClose}
        errorMessage={
          this.state.hasError
            ? 'Could not rename video asset. If the problem persist, please contact support.'
            : undefined
        }
      />
    )
  }
  submit(title: string) {
    title = title.trim()
    if (!title) {
      this.setState({ hasError: true })
      return
    }
    this.setState({ pendingNameChange: title, isSubmitting: true })
    renameVideoAsset(this.props.collectionId, this.props.videoAsset.id, title)
      .then(() => this.close())
      .then(this.props.onUpdated)
      .catch((e) => {
        console.error(e)
        this.setState({ hasError: true, isSubmitting: false })
      })
    return false
  }
  close() {
    this.props.onClose()
  }
}
