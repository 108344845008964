import React, { useEffect, useState } from 'react'
import { DefaultButton, Icon } from 'office-ui-fabric-react'
import './UploadButton.css'
import { blobUpload } from '../../Api/videoAssets'

const UPLOAD_FILE_SIZE_LIMIT_IN_MB = 150
const UPLOAD_FILE_SIZE_LIMIT_IN_BYTES = UPLOAD_FILE_SIZE_LIMIT_IN_MB * 1e6

interface InputProps {
  label: string
  buttonLabel: string
  accept: string
  onClick: (value: File) => any
  onUploaded: (value: File, url: string) => any
  fileName?: string
  disabled?: boolean
  required?: boolean
  errorMessage?: string
  style?: React.CSSProperties
}

export function UploadButton({
  label,
  buttonLabel,
  accept,
  onClick,
  onUploaded,
  fileName,
  disabled,
  required,
  errorMessage: errorMessageFromProp,
  style,
}: InputProps): JSX.Element {
  const [buttonId] = useState<string>(
    `uploadbutton-${Math.floor(Math.random() * 1e9)}`
  )
  const [errorMessage, setErrorMessage] = useState<string>(
    errorMessageFromProp || ''
  )
  useEffect(() => {
    setErrorMessage(errorMessageFromProp || '')
  }, [setErrorMessage, errorMessageFromProp])
  const iconType = fileName ? 'Attach' : 'CloudUpload'
  return (
    <div
      className={'upload-button' + (errorMessage ? ' has-error' : '')}
      style={style}
    >
      <div
        style={{
          marginTop: 5,
          marginBottom: 5,
          fontWeight: 600,
          color: 'rgb(50, 49, 48)',
        }}
      >
        {label}
        {required && <span style={{ color: 'rgb(164, 38, 44)' }}> *</span>}
      </div>
      <DefaultButton
        style={{ width: '100%' }}
        disabled={disabled}
        onClick={() =>
          (document.getElementById(buttonId) as HTMLElement).click()
        }
      >
        <Icon iconName={iconType} />
        &nbsp;
        <span
          style={{
            maxWidth: 'calc(100% - 32px)',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {fileName || buttonLabel}
        </span>
      </DefaultButton>
      <input
        type="file"
        id={buttonId}
        onChange={async (evt) => {
          const file = (evt.target as HTMLInputElement).files![0]
          if (!file) {
            return
          }
          if (file.size > UPLOAD_FILE_SIZE_LIMIT_IN_BYTES) {
            setErrorMessage(
              `File size exceeds ${UPLOAD_FILE_SIZE_LIMIT_IN_MB}MB limit`
            )
            return
          }
          setErrorMessage('')
          onClick(file)
          try {
            const url = await blobUpload(file)
            onUploaded(file, url)
          } catch (e) {
            setErrorMessage((e as any).message)
          }
        }}
        accept={accept}
        style={{
          display: 'none',
          position: 'absolute',
        }}
      />
      {errorMessage && (
        <span data-automation-id="error-message">{errorMessage}</span>
      )}
    </div>
  )
}
