import React from 'react'
import { Modal, Spinner, SpinnerSize } from 'office-ui-fabric-react'
import './BlockingLoadingDialog.css'

interface State {}

interface InputProps {
  label: string
}

export class BlockingLoadingDialog extends React.Component<InputProps, State> {
  constructor(props: any) {
    super(props)
    this.state = {
      isSubmitting: false,
      hasError: false,
    }
  }
  render() {
    return (
      <Modal
        isOpen={true}
        isDarkOverlay={true}
        isBlocking={true}
        styles={{
          main: {
            padding: 24,
            maxWidth: 240,
          },
        }}
      >
        <Spinner size={SpinnerSize.large} label={this.props.label} />
      </Modal>
    )
  }
}
