import {
  AssetTagsResponse,
  Campaign,
  CampaignAssetInfo,
} from '../Api/campaigns'

interface StringToStringMap {
  [x: string]: string
}

interface StringToArrayMap {
  [x: string]: string[]
}

interface StringToFunctionMap {
  [x: string]: () => string
}

function isTemplateTitle(
  title: string,
  campaign: Campaign,
  assetTagInfo: AssetTagsResponse
) {
  const templateTitle = renderedTitleToTemplateTitle(
    title,
    campaign,
    assetTagInfo
  )
  const hasMacro = (title: string) => /\[.*?\]/g.test(title)
  return hasMacro(templateTitle)
}

function renderedTitleToTemplateTitle(
  title: string,
  campaign: Campaign,
  assetTagInfo: AssetTagsResponse
): string {
  const macroMapping = getValueToMacroMap(campaign, assetTagInfo)
  const regex = new RegExp(Object.keys(macroMapping).join('|'), 'g')
  return title.replace(regex, (match) => macroMapping[match])
}

/**
 * Warning: if two macros returns the same output we will only use the first one
 */
function getValueToMacroMap(
  campaign: Campaign,
  assetTagInfo: AssetTagsResponse
): StringToStringMap {
  const macroActions = getMacroActions(campaign, assetTagInfo)

  return Object.entries(macroActions).reduce((acc, [macro, action]) => {
    const text = action()
    if (!text) return acc
    return addMacroMappingIfNotExists(acc, macro, text)
  }, {} as StringToStringMap)
}

function addMacroMappingIfNotExists(
  textToMacro: StringToStringMap,
  macro: string,
  text: string
) {
  return text && !textToMacro[text]
    ? { ...textToMacro, [text]: `[${macro}]` }
    : textToMacro
}

function templateTitleToRenderedTitle(
  title: string,
  campaign: Campaign,
  assetTagInfo: AssetTagsResponse
) {
  const replace = (full: string, arg: string): string =>
    macroToText(full, arg, campaign, assetTagInfo)
  return title.replace(/\[([^\]]+)\]/g, replace)
}

function macroToText(
  full: string,
  macro: string,
  campaign: Campaign,
  assetTagInfo: AssetTagsResponse
): string {
  const macroActions = getMacroActions(campaign, assetTagInfo)
  const result = macroActions[macro] ? macroActions[macro]() : null
  return result === null ? full : result
}

function getMacroActions(
  campaign: Campaign,
  assetTagInfo: AssetTagsResponse
): StringToFunctionMap {
  const macroActions = {
    browser: () => getTargetingRules('Browser/', campaign),
    country: () => getTargetingRules('Country/', campaign),
    device: () => getTargetingRules('Device/', campaign),
    os: () => getTargetingRules('OS/', campaign),
    adtype: () => campaign.adType || '',
    adsource: () => mapAdSourceToString(assetToString(campaign.asset)),
    bidder: () => getCampaignBidder(campaign),
    domain: () => assetTagsToDomains(campaign.targetedAssets, assetTagInfo),
  } as StringToFunctionMap
  return macroActions
}

function getTargetingRules(type: string, campaign: Campaign): string {
  return campaign.targetingRules
    .filter((rule) => rule.startsWith(type))
    .map((rule) => rule.replace(type, ''))
    .sort()
    .join(', ')
}

function getCampaignBidder(campaign: Campaign): string {
  if (campaign.asset.headerBiddingSource) {
    try {
      return (
        JSON.parse(campaign.asset.headerBiddingSource.bidderParamsJson)
          .bidder || ''
      )
    } catch (error) {
      return ''
    }
  }
  return ''
}

function mapAdSourceToString(adSource: string): string {
  if (adSource === 'headerBiddingSource') {
    return 'HB'
  }
  return adSource
}

function assetToString(asset: CampaignAssetInfo): string {
  return (
    Object.keys(asset).find((key: string) => {
      const value = (asset as any)[key]
      return !!value
    }) || ''
  )
}

function assetTagsToDomains(
  campaignTargetedAssets: string[],
  assetTagInfo: AssetTagsResponse
): string {
  const assetTags = getAssetTagsDomains(assetTagInfo)
  return removeDuplicateAndFalsy(
    campaignTargetedAssets.flatMap(
      (targetedAsset: string) => assetTags[targetedAsset]
    )
  ).join(', ')
}

function getAssetTagsDomains(
  assetTagInfo: AssetTagsResponse
): StringToStringMap {
  const outstreamTags = mapCollectionsToTags(
    assetTagInfo.outstreamPlacementCollections_v2
  )
  const instreamTags = mapCollectionsToTags(
    assetTagInfo.instreamPlacementCollections_v2
  )

  return Object.assign({}, ...outstreamTags, ...instreamTags)
}

function mapCollectionsToTags(
  collections:
    | {
        id: string
        title: string
        assets: {
          id: string
          title: string
          integration: string
          tags: string[]
        }[]
      }[]
    | undefined
): StringToArrayMap[] {
  if (!collections) return []

  return collections.flatMap((collection) => {
    const collectionTags = {
      [`${collection.id}/*`]: removeDuplicateAndFalsy(
        collection.assets.map((a) => a.integration)
      ),
    }

    const assetTags = collection.assets.flatMap((asset) =>
      asset.tags.map((tag) => transformTag(tag, asset, collection))
    )

    return [collectionTags, ...assetTags]
  })
}

function removeDuplicateAndFalsy(array: string[]): string[] {
  return Array.from(new Set(array)).filter(Boolean)
}

function transformTag(
  tag: string,
  asset: { integration: string; title: string },
  collection: { id: string; title: string }
): StringToArrayMap {
  return { [`${collection.id}/${tag}`]: [asset.integration] }
}

export {
  renderedTitleToTemplateTitle,
  templateTitleToRenderedTitle,
  isTemplateTitle,
}
