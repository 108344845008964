import React, { useEffect, useRef, useState, useCallback } from 'react'
import JSONEditor from 'jsoneditor'
import 'jsoneditor/dist/jsoneditor.css'
import './JSONEditorComponent.css'
import { scrollIntoFirstError } from '../../../Components/Utils'

interface JSONEditorComponentProps {
  value: string
  onChange: (value: string) => void
  errorMessage?: string
  label: string
  disabled: boolean
}

const JSONEditorComponent: React.FC<JSONEditorComponentProps> = ({
  value,
  onChange,
  errorMessage,
  label,
  disabled,
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const editorRef = useRef<JSONEditor | null>(null)
  const initialValueRef = useRef(value)
  const errorRef = useRef<HTMLDivElement | null>(null)
  const [isFocused, setIsFocused] = useState(false)

  const triggerRepairButton = useCallback(() => {
    const repairButton = document.querySelector('.jsoneditor-repair')
    if (repairButton) {
      ;(repairButton as HTMLButtonElement).click()
      const repairedValue = editorRef.current!.getText()
      if (repairedValue) {
        onChange(repairedValue)
      }
    }
  }, [onChange])

  const handleBlur = useCallback(() => {
    setIsFocused(false)
    triggerRepairButton()
  }, [triggerRepairButton])

  useEffect(() => {
    if (containerRef.current) {
      editorRef.current = new JSONEditor(containerRef.current, {
        mode: 'code',
        onChange: () => {
          const updatedValue = editorRef.current!.getText()
          if (updatedValue !== undefined) {
            onChange(updatedValue)
          }
        },
      })
      editorRef.current.setText(initialValueRef.current)

      const editorElement = containerRef.current.querySelector('.jsoneditor')
      if (editorElement) {
        editorElement.addEventListener('focus', () => setIsFocused(true), true)
        editorElement.addEventListener('blur', handleBlur, true)
      }
    }

    return () => {
      if (editorRef.current) {
        editorRef.current.destroy()
      }
    }
  }, [onChange, handleBlur])

  useEffect(() => {
    if (editorRef.current && value !== editorRef.current.getText()) {
      const editor = editorRef.current.aceEditor
      const cursorPosition = editor.getCursorPosition()

      editorRef.current.updateText(value)
      editor.moveCursorTo(cursorPosition.row, cursorPosition.column)
    }
  }, [value])

  useEffect(() => {
    async function asyncScrollIntoFirstError() {
      await new Promise((resolve) => setTimeout(resolve, 0))
      scrollIntoFirstError()
    }

    if (errorMessage && errorRef.current) {
      asyncScrollIntoFirstError()
    }
  }, [errorMessage])

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.aceEditor.setReadOnly(disabled)
    }
  }, [disabled])

  return (
    <div>
      <div className="jsoneditor-label">
        {label} <span className="jsoneditor-label-star">*</span>
      </div>
      <div className={`jsoneditor-overlay ${disabled ? 'disabled' : ''}`}>
        <div
          ref={containerRef}
          className={`jsoneditor-container ${errorMessage ? 'error' : ''} ${disabled ? 'disabled' : ''} ${isFocused ? 'focused' : ''}`}
          style={{ height: 300 }}
        />
        {errorMessage && (
          <div
            className="error"
            ref={errorRef}
            data-automation-id="error-message"
          >
            {errorMessage}
          </div>
        )}
      </div>
    </div>
  )
}

export default JSONEditorComponent
