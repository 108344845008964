import { GraphQLError } from 'graphql'
import gql from 'graphql-tag'
import { client } from './api'
import { Seller } from './schain'

export interface Integration {
  publishedDate: string
  domain: string
  whitelist: string[]
  blacklist: string[]
  syncScript?: string
  ownership?: string
  integratedWithInview?: boolean
}

export interface GetIntegrationsQueryResponse {
  session: {
    groupNames: string[]
  }
  integrations: Integration[]
  sellers: Seller[]
}

export const getIntegrationsQuery = gql`
  query {
    session {
      groupNames
    }
    integrations {
      publishedDate
      domain
      whitelist
      blacklist
      syncScript
      ownership
      integratedWithInview
    }
    sellers {
      seller_id
      seller_type
      name
    }
  }
`

export async function getIntegrations() {
  return client.query({
    query: getIntegrationsQuery,
    variables: {},
    fetchPolicy: 'network-only',
  })
}

export const getAllIntegrationsAndIntegrationsUsedByOutstreamPlacementsQuery = gql`
  query {
    integrations {
      publishedDate
      domain
      whitelist
      blacklist
      ownership
      integratedWithInview
    }
  }
`

export interface GetAllIntegrationsAndIntegrationsUsedByOutstreamPlacementsResponse {
  integrations: Integration[]
}

export async function deleteIntegration(domain: string) {
  return client
    .mutate({
      mutation: gql`
        mutation ($domain: String!) {
          integrations(byDomain: $domain) {
            delete
          }
        }
      `,
      variables: { domain },
    })
    .catch(toReadableError(`Cannot delete integration for ${domain}`))
}

export async function createIntegration(integration: Integration) {
  return client
    .mutate({
      mutation: gql`
        mutation (
          $domain: String!
          $whitelist: [String!]
          $blacklist: [String!]
          $ownership: String
          $integratedWithInview: Boolean
        ) {
          integrations {
            create(
              domain: $domain
              whitelist: $whitelist
              blacklist: $blacklist
              ownership: $ownership
              integratedWithInview: $integratedWithInview
            )
          }
        }
      `,
      variables: {
        domain: integration.domain,
        whitelist: integration.whitelist || [],
        blacklist: integration.blacklist || [],
        ownership: integration.ownership || undefined,
        integratedWithInview: integration.integratedWithInview || false,
      },
    })
    .catch(
      toReadableError(`Cannot create integration for "${integration.domain}"`)
    )
}

export interface CreateMultipleIntegrationError {
  integration: Integration
  error: string
}

export async function createIntegrations(
  integrations: Integration[]
): Promise<CreateMultipleIntegrationError[]> {
  const stringify = (x: string) => JSON.stringify(x)
  const createStatement = (integration: Integration, index: number): string => `
    i${index}: create(
      domain: "${integration.domain}"
      whitelist: [${(integration.whitelist || []).map(stringify).join(', ')}]
      blacklist: [${(integration.blacklist || []).map(stringify).join(', ')}]
    )
  `
  const createStatements = integrations.map(createStatement).join('\n')
  try {
    await client.mutate({
      mutation: gql`
        mutation {
          integrations {
            ${createStatements}
          }
        }
      `,
    })
    return []
  } catch (error) {
    const getIntegrationIndex = (path: string[]) =>
      parseInt(path[path.length - 1].replace('i', ''))
    const getIntegration = (graphQLError: GraphQLError) =>
      integrations[getIntegrationIndex(graphQLError.path as string[])]

    const { graphQLErrors } = error as { graphQLErrors: GraphQLError[] }
    return graphQLErrors.map((error) => ({
      integration: getIntegration(error),
      error:
        error.message.indexOf('The specified entity already exists') >= 0
          ? 'There is already an integration with this domain'
          : error.message,
    }))
  }
}

export async function updateIntegration(
  domain: string,
  integration: Integration
) {
  return client
    .mutate({
      mutation: gql`
        mutation (
          $domain: String!
          $whitelist: [String!]
          $blacklist: [String!]
          $ownership: String
          $integratedWithInview: Boolean
        ) {
          integrations(byDomain: $domain) {
            update(
              whitelist: $whitelist
              blacklist: $blacklist
              ownership: $ownership
              integratedWithInview: $integratedWithInview
            )
          }
        }
      `,
      variables: {
        domain: integration.domain,
        whitelist: integration.whitelist || [],
        blacklist: integration.blacklist || [],
        ownership: integration.ownership || undefined,
        integratedWithInview: integration.integratedWithInview || false,
      },
    })
    .catch(toReadableError(`Cannot update integration for "${domain}"`))
}

function toReadableError(defaultMessage: string) {
  return (error: Error) => {
    const message = error.message
    console.error(message)
    if (!message) {
      throw new Error(defaultMessage)
    }
    if (message.indexOf('GraphQL') >= 0) {
      throw new Error(defaultMessage)
    }
    if (message.indexOf('The specified entity already exists') >= 0) {
      throw new Error('There is already an integration with this domain')
    }
    throw error
  }
}
