function parseSafeJsObjectCb(raw: string, resolve: (o?: any) => void) {
  try {
    const script = `<script>
            try {
                var o = eval('a=' + ${JSON.stringify(raw.replace(/[`()]/g, ''))})
                var msg = JSON.stringify(o)
                window.parent.postMessage('prebidinput://' + msg, '*')
            } catch(e) {
                console.warn(e)
                window.parent.postMessage('prebidinput://{}', '*')
            }
        </script>`
    const blob = new Blob([script], { type: 'text/html' })
    const iframe = document.createElement('iframe')
    iframe.setAttribute('sandbox', 'allow-scripts')
    iframe.src = window.URL.createObjectURL(blob)
    iframe.style.display = 'none'
    document.body.appendChild(iframe)
    const f = (evt: MessageEvent) => {
      let isCorrectEvent =
        typeof evt.data === 'string' && evt.data.indexOf('prebidinput://') === 0
      if (!isCorrectEvent) {
        return
      }
      let msg = JSON.parse(evt.data.replace(/^prebidinput:\/\//, ''))
      if (Object.keys(msg).length) {
        resolve(msg)
      } else {
        resolve()
      }
      iframe.remove()
      window.removeEventListener('message', f)
    }
    window.addEventListener('message', f)
  } catch (e) {
    resolve()
  }
}

export async function parseSafeJsObject(raw: string): Promise<any> {
  return new Promise((resolve) => parseSafeJsObjectCb(raw, resolve))
}

const MAXIMUM_ASPECT_RATIO = 40
const numbers = Array(MAXIMUM_ASPECT_RATIO)
  .fill(0)
  .map((v, i) => i + 1)
const possibleFractions = numbers.reduce(
  (ret, n) => [...ret, ...numbers.map((n2) => [n, n2])],
  [] as number[][]
)
const cachedFractions = {} as { [k: number]: number[] }
export function getClosestAspectRatio(n: number) {
  if (cachedFractions[n]) {
    return cachedFractions[n]
  }
  const r = possibleFractions.reduce(
    (ret, f) => {
      const delta = Math.abs(ret[0] / ret[1] - n) - Math.abs(f[0] / f[1] - n)
      if (!delta) {
        return ret[0] < f[0] ? ret : f
      }
      if (delta < 0) {
        return ret
      }
      return f
    },
    [1, MAXIMUM_ASPECT_RATIO]
  )
  cachedFractions[n] = r
  return r
}

export function getDiagonalInchesFromDimensions(
  heightInCm: number,
  widthInCm: number
): number {
  return Math.sqrt(Math.pow(heightInCm, 2) + Math.pow(widthInCm, 2)) / 2.54
}

export function getDimensionsFromDiagonalInchesAndAspectRatio(
  diagonalInch: number,
  aspectX: number,
  aspectY: number
): number[] {
  const ratio = aspectX / aspectY
  const heightInCm =
    2.54 * Math.sqrt(Math.pow(diagonalInch, 2) / (1 + Math.pow(ratio, 2)))
  const widthInCm = heightInCm * ratio
  return [widthInCm, heightInCm]
}

export function scrollIntoFirstError() {
  setTimeout(() => {
    const errorElements = [].slice.call(
      document.querySelectorAll(
        '[data-automation-id="error-message"],[id*=errorMessage]'
      )
    ) as HTMLElement[]
    const firstError = errorElements.find(
      (el) => !!el.innerText && el.innerText.length > 0
    )
    if (firstError) {
      firstError.scrollIntoView({ block: 'center' })
    }
  }, 50)
}
