import React from 'react'
import { ConfirmationDialog } from '../../Components/ConfirmationDialog'
import { VideoAssetInfo, deleteVideoAsset } from '../../Api/videoAssets'

interface State {
  isSubmitting: boolean
  hasError: boolean
}

interface InputProps {
  collectionId: string
  videoAsset: VideoAssetInfo
  isOpen: boolean
  onClose: () => void
  onUpdated: () => void
}

export class DeleteVideoAssetDialog extends React.Component<InputProps, State> {
  constructor(props: any) {
    super(props)
    this.state = {
      isSubmitting: false,
      hasError: false,
    }
  }
  render() {
    return (
      <ConfirmationDialog
        dialogTitle="Delete Video Asset"
        confirmationText={`Are you sure you want to delete video asset "${this.props.videoAsset.title}"?`}
        errorMessage={
          this.state.hasError
            ? 'Could not delete asset. If the problem persist, please contact support.'
            : undefined
        }
        yesButtonText="Yes"
        noButtonText="No"
        isLoading={this.state.isSubmitting}
        onCancel={this.props.onClose}
        onConfirm={() => this.submit()}
      />
    )
  }
  submit() {
    this.setState({ isSubmitting: true })
    deleteVideoAsset(this.props.collectionId, this.props.videoAsset.id)
      .then(this.props.onUpdated)
      .then(this.props.onClose)
      .catch((e) => {
        console.error(e)
        this.setState({ isSubmitting: false, hasError: true })
      })
    return false
  }
}
