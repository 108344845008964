import React, { useEffect } from 'react'
import { ChildProps, graphql } from 'react-apollo'
import {
  Seller,
  SellersByDomainResponse,
  getSellersByDomainQuery,
} from '../../../../Api/schain'
import { ComboBox, IComboBoxOption } from 'office-ui-fabric-react'

interface InputProps {
  domain: string
  disabled: boolean
  selectedSellerId: string
  onChange: (change: Change) => any

  label: string
}

interface Change {
  selectedSellerId?: string | null
  error?: string
  isPending?: boolean
  seller?: Seller
}

function SellersComboBoxView(
  props: ChildProps<InputProps, SellersByDomainResponse>
) {
  const { data, onChange, selectedSellerId, disabled, domain, label } = props
  const { error, loading } = data!
  const hasValidEntries = !error && !loading && data
  const entries = hasValidEntries ? data!.sellersByDomain : []
  const hasError = error || !entries || entries.length === 0

  useEffect(() => {
    if (hasError && !!domain && !loading) {
      onChange({ error: `No sellers found for this domain` })
    } else {
      onChange({ error: undefined })
    }
  }, [hasError, domain, loading, onChange])

  const toOption = (entry: Seller) => ({
    key: entry.seller_id,
    text: entry.is_confidential
      ? `Confidential Seller #${entry.seller_id}`
      : `${entry.name} / ${entry.domain}`,
  })

  const alphabeticalSort = (a: IComboBoxOption, b: IComboBoxOption) =>
    a.text.localeCompare(b.text, undefined, { numeric: true })
  const entriesOptions = entries
    ? entries.map(toOption).sort(alphabeticalSort)
    : []

  return (
    <ComboBox
      required
      label={label}
      options={entriesOptions}
      disabled={disabled || !hasValidEntries}
      selectedKey={selectedSellerId}
      onChange={(event, value) => {
        if (!value) {
          onChange({ selectedSellerId: null, isPending: false })

          return
        }
        const selectedEntry = entries!.find(
          (entry) => entry.seller_id === value!.key
        )
        onChange({
          selectedSellerId: value!.key.toString(),
          seller: selectedEntry,
        })
      }}
      onPendingValueChanged={(option, index, value) => {
        if (!value && !option && !index) {
          return
        }
        onChange({ isPending: true })
      }}
      allowFreeform={true}
    />
  )
}

const withGql = graphql<InputProps, SellersByDomainResponse>(
  getSellersByDomainQuery,
  {
    options: ({ domain }) => ({
      notifyOnNetworkStatusChange: true,
      variables: {
        domain,
      },
    }),
  }
)
export const SellersComboBox = withGql(SellersComboBoxView)
