import React from 'react'
import { ConfirmationDialog } from '../../Components/ConfirmationDialog'
import { ChildProps, graphql } from 'react-apollo'
import {
  getAllOutstreamPlacementCollectionSummariesQuery,
  OutstreamPlacementCollectionSummaryResponse,
  deleteOutstreamPlacementCollection,
} from '../../Api/outstreamPlacements_v2'
import { get } from 'lodash'
import { SessionContext } from '../../Contexts/SessionContext'

interface State {
  isSubmitting: boolean
  hasError: boolean
}

interface InputProps {
  collectionId: string
  onClose: () => void
  onUpdated: () => void
}

class DeleteOutstreamPlacementCollectionDialogView extends React.Component<
  ChildProps<InputProps, OutstreamPlacementCollectionSummaryResponse>,
  State
> {
  static contextType = SessionContext
  context!: React.ContextType<typeof SessionContext>

  constructor(props: any) {
    super(props)
    this.state = {
      isSubmitting: false,
      hasError: false,
    }
  }
  render() {
    if (!this.getCollection()) {
      return <React.Fragment />
    }
    return (
      <ConfirmationDialog
        dialogTitle="Delete Outstream Collection"
        confirmationText={`Are you sure you want to delete outstream placement collection "${this.getCollection()!.title}"?`}
        errorMessage={
          this.state.hasError
            ? 'Could not delete collection. If the problem persist, please contact support.'
            : undefined
        }
        yesButtonText="Yes"
        noButtonText="No"
        isLoading={this.state.isSubmitting}
        onCancel={this.props.onClose}
        onConfirm={() => this.submit()}
      />
    )
  }
  submit() {
    this.setState({ isSubmitting: true })
    deleteOutstreamPlacementCollection(this.props.collectionId)
      .then(this.props.onUpdated)
      .then(() => {
        window.location.href = `${process.env.PUBLIC_URL}/#/${this.context.ctok}/outstream-placements/#/nav/outstream-placements_v2/open`
        this.props.onClose()
      })
      .catch((e) => {
        console.error(e)
        this.setState({ isSubmitting: false, hasError: true })
      })
    return false
  }
  getCollection() {
    return get(this.props.data!, 'outstreamPlacementCollections_v2', []).find(
      (c) => c.id === this.props.collectionId
    )
  }
}

const withGql = graphql<
  InputProps,
  OutstreamPlacementCollectionSummaryResponse
>(getAllOutstreamPlacementCollectionSummariesQuery, {
  options: () => ({ notifyOnNetworkStatusChange: true }),
})
export const DeleteOutstreamPlacementCollectionDialog = withGql(
  DeleteOutstreamPlacementCollectionDialogView
)
