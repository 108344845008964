import React from 'react'
import { capitalize } from 'lodash'
import { ConfirmationDialog } from './ConfirmationDialog'

interface State {
  isSubmitting: boolean
  hasError: boolean
}

interface InputProps {
  type: 'instream' | 'outstream'
  title: string
  deletePlacement: () => Promise<any>
  onClose: () => void
  onUpdated: () => void
}

export class DeletePlacementDialog extends React.Component<InputProps, State> {
  constructor(props: any) {
    super(props)
    this.state = {
      isSubmitting: false,
      hasError: false,
    }
  }
  render() {
    return (
      <ConfirmationDialog
        dialogTitle={`Delete ${capitalize(this.props.type)} Placement`}
        confirmationText={`Are you sure you want to delete placement "${this.props.title}"?`}
        errorMessage={
          this.state.hasError
            ? 'Could not delete placement. If the problem persist, please contact support.'
            : undefined
        }
        yesButtonText="Yes"
        noButtonText="No"
        isLoading={this.state.isSubmitting}
        onCancel={this.props.onClose}
        onConfirm={() => this.submit()}
      />
    )
  }
  submit() {
    this.setState({ isSubmitting: true })
    this.props
      .deletePlacement()
      .then(this.props.onUpdated)
      .then(this.props.onClose)
      .catch((e) => {
        console.error(e)
        this.setState({ isSubmitting: false, hasError: true })
      })
    return false
  }
}
