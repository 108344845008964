import React from 'react'
import { Dropdown, IDropdownOption, Stack } from 'office-ui-fabric-react'
import { Interval, IntervalUnit } from '../../../Api/campaigns'
import { NumberInput } from '../../../Components/Input/NumberInput'

interface IntervalInputProps {
  disabled: boolean
  interval: Interval
  onChange: (interval: Interval | null) => void
}

const intervalUnitDropdownOptions = ['Hour', 'Day', 'Month'].map((unit) => ({
  key: unit,
  text: unit,
}))

const IntervalInput: React.FC<IntervalInputProps> = ({
  disabled,
  interval,
  onChange,
}) => (
  <Stack style={{ flexGrow: 1 }}>
    <NumberInput
      label="for time interval"
      style={{ width: '100%' }}
      errorMessage={undefined}
      name="intervalUnit"
      value={interval !== null ? interval.value : 1}
      onChange={(value?: number) => {
        if (value == null) {
          return
        }
        onChange({ unit: interval !== null ? interval.unit : 'Hour', value })
      }}
      min={1}
      max={100}
      disabled={disabled}
      required={true}
    />
    <Dropdown
      label="time interval unit"
      style={{ width: '100%' }}
      selectedKey={interval !== null ? interval.unit : 'Hour'}
      onChange={(_, value?: IDropdownOption) => {
        if (!value) {
          return
        }
        onChange({
          value: interval !== null ? interval.value : 1,
          unit: value.key as IntervalUnit,
        })
      }}
      options={intervalUnitDropdownOptions}
      disabled={disabled}
      required={true}
    />
  </Stack>
)

export default IntervalInput
