export function removeTimezoneOffset(
  value: string | number | Date | undefined | null
) {
  if (!value) {
    return
  }
  const currentDate = new Date(value)
  const withoutOffsetTimestamp =
    currentDate.valueOf() + currentDate.getTimezoneOffset() * 60000
  return new Date(withoutOffsetTimestamp)
}

export function addTimezoneOffset(
  value: string | number | Date | undefined | null
) {
  if (!value) {
    return
  }
  const currentDate = new Date(value)
  const withOffsetTimestamp =
    currentDate.valueOf() - currentDate.getTimezoneOffset() * 60000
  return new Date(withOffsetTimestamp)
}
