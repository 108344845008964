import React from 'react'
import { TextPromptDialog } from '../../Components/TextPromptDialog'
import { ChildProps, graphql } from 'react-apollo'
import {
  getAllInstreamPlacementCollectionSummariesQuery,
  InstreamPlacementCollectionSummaryResponse,
  renameInstreamPlacementCollection,
} from '../../Api/instreamPlacements_v2'
import { get } from 'lodash'

interface State {
  isSubmitting: boolean
  hasError: boolean
}

interface InputProps {
  collectionId: string
  onClose: () => void
  onUpdated: () => void
}

export class RenameInstreamPlacementCollectionDialogView extends React.Component<
  ChildProps<InputProps, InstreamPlacementCollectionSummaryResponse>,
  State
> {
  constructor(props: any) {
    super(props)
    this.state = {
      hasError: false,
      isSubmitting: false,
    }
  }
  render() {
    if (!this.getCollection()) {
      return <React.Fragment />
    }
    return (
      <TextPromptDialog
        dialogTitle="Rename Instream Placement Collection"
        fieldTitle="New Title"
        confirmButtonText="Save"
        initialValue={this.getCollection()!.title}
        isLoading={this.state.isSubmitting}
        onUpdated={() => this.setState({ hasError: false })}
        onSubmit={(value) => this.submit(value)}
        onClose={this.props.onClose}
        errorMessage={
          this.state.hasError
            ? 'Could not rename collection. If the problem persist, please contact support.'
            : undefined
        }
      />
    )
  }
  submit(title: string) {
    title = title.trim()
    if (!title) {
      this.setState({ hasError: true })
      return
    }
    this.setState({ isSubmitting: true })
    renameInstreamPlacementCollection(this.props.collectionId, title)
      .then(this.props.onUpdated)
      .then(this.props.onClose)
      .catch((e) => {
        console.error(e)
        this.setState({
          hasError: true,
          isSubmitting: false,
        })
      })
    return false
  }
  getCollection() {
    return get(this.props.data!, 'instreamPlacementCollections_v2', []).find(
      (c) => c.id === this.props.collectionId
    )
  }
}

const withGql = graphql<InputProps, InstreamPlacementCollectionSummaryResponse>(
  getAllInstreamPlacementCollectionSummariesQuery,
  { options: () => ({ notifyOnNetworkStatusChange: true }) }
)
export const RenameInstreamPlacementCollectionDialog = withGql(
  RenameInstreamPlacementCollectionDialogView
)
