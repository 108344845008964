import {
  DirectionalHint,
  Icon,
  IconButton,
  Stack,
  Text,
  TooltipHost,
} from 'office-ui-fabric-react'
import React from 'react'
import { TableView } from '../../../../Components/TableView'
import { ApolloError } from 'apollo-client'
import { ErrorScreen } from '../../../../Components/ErrorScreen'
import { ReferenceChain, chainToLabel } from '../../../../Api/references'
import { DeleteReferenceDialog } from './DeleteReferenceDialog'
import './ReferencesListScreen.css'

interface InputProps {
  referencesChains?: ReferenceChain[]
  error?: ApolloError
  onUpdated: () => void
}

interface OpenModal {
  modalType: 'delete'
  buyerDomain: string
  concatBuyerDomains: string
}

const ReferencesListView: React.FC<InputProps> = (props) => {
  const { referencesChains, error, onUpdated } = props
  const [openModal, setOpenModal] = React.useState<OpenModal | null>(null)

  if (error) {
    return renderError()
  }

  if (!referencesChains || referencesChains.length === 0) {
    return renderEmpty()
  }

  const getColumns = () => {
    return [
      {
        key: 'type',
        name: '',
        fieldName: 'type',
        minWidth: 20,
        maxWidth: 20,
        isRowHeader: true,
        isIconOnly: true,
        isPadded: false,
        onRender: (chain: ReferenceChain) => (
          <TooltipHost
            styles={{
              root: {
                position: 'relative',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                left: 0,
                right: '5px',
              },
            }}
            tooltipProps={{
              delay: 0,
              directionalHint: DirectionalHint.leftCenter,
            }}
            content={
              chain.references[0].status !== 'ok' ? 'not up to date' : ''
            }
          >
            <Icon
              iconName={
                chain.references[0].status !== 'ok'
                  ? 'Warning'
                  : chain.references[0].name === 'PUBLISHER'
                    ? 'BranchShelveset'
                    : 'BranchPullRequest'
              }
              style={{
                fontSize: 14,
                paddingLeft: 5,
                paddingTop: 3,
              }}
            />
          </TooltipHost>
        ),
      },
      {
        key: 'buyerDomain',
        name: 'Buyer Domain',
        fieldName: 'buyerDomain',
        isRowHeader: true,
        isPadded: true,
        minWidth: 150,
        maxWidth: 400,
        onRender: chainToLabel,
      },
      {
        key: 'name',
        name: 'Ref. Name',
        fieldName: 'name',
        isRowHeader: true,
        isPadded: true,
        minWidth: 150,
        maxWidth: 400,
        onRender: (chain: ReferenceChain) =>
          renderCellWithToolTip(
            chain.references[0].is_confidential
              ? `Confidential reference #${chain.references[0].seller_id}`
              : chain.references[0].name || ''
          ),
      },

      {
        key: 'seller_type',
        name: 'Ref. Type',
        fieldName: 'seller_type',
        isRowHeader: true,
        isPadded: true,
        minWidth: 100,
        maxWidth: 150,
        onRender: (chain: ReferenceChain) => (
          <span>{chain.references[0].seller_type}</span>
        ),
      },

      {
        key: 'seller_id',
        name: 'Seller ID',
        fieldName: 'seller_id',
        isRowHeader: true,
        isPadded: true,
        minWidth: 150,
        maxWidth: 400,
        onRender: (chain: ReferenceChain) =>
          renderCellWithToolTip(chain.references[0].seller_id),
      },
      {
        key: 'menu',
        name: '',
        fieldName: '',
        minWidth: 20,
        maxWidth: 20,
        isIconOnly: true,
        onRender: (chain: ReferenceChain) => (
          <IconButton
            styles={{
              root: {
                height: 18,
              },
              menuIcon: {
                fontWeight: 'bold',
              },
            }}
            menuProps={{
              coverTarget: true,
              directionalHint: DirectionalHint.topCenter,
              items: [
                {
                  key: 'delete',
                  text: 'Delete',
                  iconProps: { iconName: 'delete' },
                  onClick: () => deleteReference(chain),
                },
              ],
            }}
          />
        ),
      },
    ]
  }

  return (
    <React.Fragment>
      <TableView
        cacheKey={'sellers'}
        columns={getColumns()}
        rowToCacheKey={(_) => 'sellers'}
        rows={referencesChains}
        onRowClick={(_) => {}}
      />
      {renderDeleteReferenceDialog(onUpdated)}
    </React.Fragment>
  )

  function deleteReference(chain: ReferenceChain) {
    setOpenModal({
      modalType: 'delete',
      buyerDomain: chain.id,
      concatBuyerDomains: chainToLabel(chain),
    })
  }

  function renderDeleteReferenceDialog(refetch: () => void) {
    if (!openModal) {
      return <React.Fragment />
    }
    return (
      <DeleteReferenceDialog
        buyerDomain={openModal.buyerDomain}
        concatBuyerDomains={openModal.concatBuyerDomains}
        onUpdated={() => refetch()}
        onClose={() => setOpenModal(null)}
      />
    )
  }
}
function renderEmpty() {
  return (
    <Stack
      horizontalAlign="center"
      verticalAlign="center"
      verticalFill
      style={{ width: '100%' }}
    >
      <Text
        variant="xLarge"
        style={{
          maxWidth: 600,
          padding: 20,
          textAlign: 'center',
        }}
      >
        No references are connected. In order to be able to add your schain
        references to your campaigns, connect your references.
      </Text>
    </Stack>
  )
}

function renderError() {
  return (
    <ErrorScreen errorText="Error while fetching references. If the problem persists, please contact support." />
  )
}

function renderCellWithToolTip(text: string) {
  return (
    <TooltipHost
      styles={{
        root: {
          position: 'absolute',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          left: 0,
          right: '5px',
        },
      }}
      tooltipProps={{
        delay: 0,
        directionalHint: DirectionalHint.leftCenter,
      }}
      content={text}
    >
      <span title={text}>{text}</span>
    </TooltipHost>
  )
}

export const ReferencesList = ReferencesListView
