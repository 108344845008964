import React, { useState } from 'react'
import { Nav, INavLink } from 'office-ui-fabric-react'
import { LargeDialog, InputProps } from './LargeDialog'
import { throttle } from 'lodash'

interface LargeDialogWithSidebarProps extends InputProps {
  sidebarWidth: number
  categories: INavLink[]
}

const LargeDialogWithSidebar: React.FC<LargeDialogWithSidebarProps> = ({
  sidebarWidth,
  categories,
  children,
  ...props
}) => {
  const [lastSelectedCategory, setLastSelectedCategory] = useState(
    categories[0].key!
  )
  const [lastSelectedCategoryMs, setLastSelectedCategoryMs] = useState(0)
  const onScrollCapture = throttle((el: HTMLDivElement) => {
    if (Date.now() - lastSelectedCategoryMs < 500) {
      return
    }
    const hEls = [].slice.call(el.querySelectorAll('h2')) as HTMLElement[]
    const viewHeight = el.getBoundingClientRect().height
    const scrollHeight = el.scrollHeight - viewHeight
    const progress = el.scrollTop / scrollHeight
    const candidates = hEls.map((e) => {
      const score = Math.abs(el.scrollTop + viewHeight * progress - e.offsetTop)
      const category = e.id
      return { category, score }
    })
    const selectedCategory = candidates.reduce(
      (ret, c) => (c.score < ret.score ? c : ret),
      candidates[0]
    )
    if (selectedCategory.category === lastSelectedCategory) {
      return
    }
    setLastSelectedCategory(selectedCategory.category)
  }, 300)
  return (
    <LargeDialog {...props} width={900}>
      <div
        style={{
          maxHeight: `calc(100vh - ${sidebarWidth}px)`,
          height: 700,
          position: 'relative',
          margin: -24,
        }}
      >
        <Sidebar
          width={sidebarWidth}
          selectedCategory={lastSelectedCategory}
          categories={categories}
          onSelectedCategory={(category: string) => {
            setLastSelectedCategory(category)
            setLastSelectedCategoryMs(Date.now())
          }}
        />
        <div
          style={{
            position: 'absolute',
            left: sidebarWidth,
            top: 0,
            right: 0,
            bottom: 0,
            overflow: 'auto',
            paddingTop: 36,
            paddingLeft: 72,
            paddingRight: 118,
            paddingBottom: 118,
          }}
          onScrollCapture={(ev) => onScrollCapture(ev.currentTarget)}
        >
          {children}
        </div>
      </div>
    </LargeDialog>
  )
}

export default LargeDialogWithSidebar

interface SidebarProps {
  width: number
  selectedCategory: string
  onSelectedCategory: (category: string) => void
  categories: INavLink[]
}

const Sidebar: React.FC<SidebarProps> = ({
  width,
  selectedCategory,
  categories,
  onSelectedCategory,
}) => (
  <Nav
    styles={{
      root: {
        width,
        position: 'absolute',
        top: -13,
        left: 0,
        bottom: 0,
      },
      navItem: { height: 56 },
      link: { height: 56, paddingLeft: 16 },
      linkText: { fontSize: 15, paddingLeft: 8 },
    }}
    selectedKey={selectedCategory}
    onLinkClick={(_, item) => {
      if (!item) {
        return
      }
      onSelectedCategory(item.key!)
      document
        .querySelector(`[id="${item.key}"]`)!
        .scrollIntoView({ block: 'center' })
    }}
    groups={[
      {
        links: categories.map((category) => ({
          ...category,
          url: window.location.href,
        })) as INavLink[],
      },
    ]}
  />
)
