import React from 'react'
import { Stack, Text, Icon } from 'office-ui-fabric-react'
import './ScreenHeader.css'
import { SharedColors } from '@uifabric/fluent-theme'

interface State {}
interface InputProps {
  text: string
}

class CollectionIndicationScreen extends React.Component<InputProps, State> {
  render() {
    return (
      <Stack
        className="layout"
        horizontalAlign="center"
        verticalAlign="center"
        gap="20"
        verticalFill
        style={{
          color: SharedColors.gray30,
          width: '100%',
        }}
      >
        <Icon iconName="Back" style={{ fontSize: 80 }} />
        <Text
          variant="xLarge"
          style={{
            maxWidth: 600,
            padding: 20,
            textAlign: 'center',
            fontWeight: 'bolder',
          }}
        >
          {this.props.text}
        </Text>
      </Stack>
    )
  }
}

export { CollectionIndicationScreen }
