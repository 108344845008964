import React, { useEffect, useState } from 'react'
import {
  Checkbox,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Separator,
  Stack,
  TextField,
} from 'office-ui-fabric-react'
import { Integration } from '../../Api/integrations'
import { IntegrationLimitingForm } from '../../Components/Input/IntegrationLimitingForm'
import isValidDomain from 'is-valid-domain'
import { SyncScript } from './SyncScript'
import { Seller } from '../../Api/schain'
import { Ownership } from './Ownership'

interface Props {
  disabled: boolean
  integration?: Integration
  onSubmit: (integration: Integration) => void
  onCancel: () => void
  error: string
  sellers: Seller[]
  isAdmin: boolean
}

const defaultIntegration: Integration = {
  publishedDate: '',
  domain: '',
  whitelist: [],
  blacklist: [],
  ownership: '',
  integratedWithInview: false,
}

interface Error {
  field: string
  message: string
}

export function EditIntegrationDialog({
  disabled,
  integration = defaultIntegration,
  error,
  onSubmit,
  onCancel,
  sellers,
  isAdmin,
}: Props) {
  const [isNewIntegration] = useState(!integration.domain)
  const [errors, setErrors] = useState<Error[]>([])
  const [editIntegration, setEditIntegration] = useState<Integration>({
    ...integration,
  })
  useEffect(() => {
    setEditIntegration({ ...integration })
  }, [integration])
  return (
    <Dialog
      hidden={false}
      modalProps={{
        isDarkOverlay: true,
        isBlocking: true,
      }}
      className="edit-integration-dialog"
      onDismiss={onCancel}
      dialogContentProps={{
        type: DialogType.largeHeader,
        title: !isNewIntegration
          ? `Edit ${integration.domain} Integration`
          : 'Create Integration',
      }}
    >
      <Stack gap={16}>
        {isNewIntegration && (
          <TextField
            disabled={disabled}
            label="Domain"
            value={editIntegration.domain}
            autoComplete="off"
            spellCheck={false}
            onChange={(_, domain = '') => {
              setEditIntegration({
                ...editIntegration,
                domain,
              })
            }}
            required
            errorMessage={getErrorMessage(errors, 'domain')}
          />
        )}
        <IntegrationLimitingForm
          disabled={disabled}
          errorMessages={{}}
          integrationLimits={{
            whitelistGlobs: editIntegration.whitelist,
            blacklistGlobs: editIntegration.blacklist,
          }}
          onChange={({
            whitelistGlobs: whitelist,
            blacklistGlobs: blacklist,
          }: any) => {
            setEditIntegration({
              ...editIntegration,
              whitelist: whitelist || editIntegration.whitelist,
              blacklist: blacklist || editIntegration.blacklist,
            })
          }}
        />
        {integration.syncScript && (
          <SyncScript syncScript={integration.syncScript} />
        )}
        <Ownership
          ownership={editIntegration.ownership}
          disabled={disabled}
          sellers={sellers}
          onUpdated={(ownership) => {
            setEditIntegration({
              ...editIntegration,
              ownership: ownership,
            })
          }}
        />
        {isAdmin && (
          <Checkbox
            label="Integrated With Inview"
            disabled={disabled}
            checked={editIntegration.integratedWithInview}
            onChange={(_, checked?: boolean) => {
              setEditIntegration({
                ...editIntegration,
                integratedWithInview: !!checked,
              })
            }}
            styles={{ root: { marginTop: 10 } }}
          />
        )}
      </Stack>
      <Separator />
      {error && (
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={true}
          styles={{ root: { marginTop: 15 } }}
        >
          {error}
        </MessageBar>
      )}
      <DialogFooter>
        <DefaultButton onClick={onCancel} disabled={disabled} text="Cancel" />
        <PrimaryButton
          onClick={() => {
            const errors = validate(editIntegration)
            setErrors(errors)
            if (!errors.length) {
              onSubmit(editIntegration)
            }
          }}
          disabled={disabled}
          text={!!integration ? 'Save' : 'Create'}
        />
      </DialogFooter>
    </Dialog>
  )
}

function validate(integration: Integration): Error[] {
  const errors: Error[] = []
  if (!integration.domain.trim()) {
    errors.push({ field: 'domain', message: 'domain cannot be empty' })
  } else if (!isValidDomain(integration.domain.trim())) {
    errors.push({ field: 'domain', message: 'this is not a valid domain' })
  }
  return errors
}

function getErrorMessage(errors: Error[], field: string) {
  const isSameField = (error: Error) => error.field === field
  const error = errors.find(isSameField)
  return error ? error.message : ''
}
