import React from 'react'
import {
  Icon,
  IconButton,
  DocumentCard,
  DocumentCardImage,
  List,
  Stack,
  Spinner,
  SpinnerSize,
  Text,
  ContextualMenuItemType,
} from 'office-ui-fabric-react'
import { ImageFit } from 'office-ui-fabric-react/lib/Image'
import gql from 'graphql-tag'
import { ChildProps, graphql } from 'react-apollo'
import { Depths } from '@uifabric/fluent-theme/lib/fluent/FluentDepths'
import { getTheme } from '@uifabric/styling'
import moment from 'moment'
import VisibilitySensor from 'react-visibility-sensor'
import { VideoAssetDialog } from './VideoAssetDialog'
import { RenameVideoAssetDialog } from './RenameVideoAssetDialog'
import { DeleteVideoAssetDialog } from './DeleteVideoAssetDialog'
import { VideoAssetInfo } from '../../Api/videoAssets'

const videoAssetItemWidth = 270
const videoAssetItemHeight = 233
const videoAssetRowsPerPage = 10

interface State {
  assetsView: {
    containerWidth: number
    columnCount: number
  }
  isVideoAssetModalOpen: boolean
  isVideoAssetRenameModalOpen: boolean
  isVideoAssetDeleteModalOpen: boolean
  selectedVideoAsset?: VideoAssetInfo
  isLastItemVisible: boolean
}
interface InputProps {
  collectionId: string
  cursor: string
}

interface Response {
  videoCollections: {
    assets: VideoAssetInfo[]
  }[]
}

class VideoAssetListView extends React.Component<
  ChildProps<InputProps, Response>,
  State
> {
  resizeListener: () => void

  constructor(props: any) {
    super(props)
    this.state = {
      assetsView: {
        columnCount: 0,
        containerWidth: 0,
      },
      isLastItemVisible: false,
      isVideoAssetModalOpen: false,
      isVideoAssetRenameModalOpen: false,
      isVideoAssetDeleteModalOpen: false,
      selectedVideoAsset: undefined,
    }
    this.resizeListener = () => this.refreshAssetView()
  }
  componentDidMount() {
    this.resizeListener()
    window.addEventListener('resize', this.resizeListener)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeListener)
  }
  refreshAssetView() {
    const sidebarWidth = 240
    const containerWidth = window.innerWidth - sidebarWidth - 240
    const columnCount = Math.floor(containerWidth / (videoAssetItemWidth + 24))
    this.setState({ assetsView: { containerWidth, columnCount } })
  }
  updateNextPageVisibility() {
    this.setState({ isLastItemVisible: true })
  }
  render() {
    const { error, loading } = this.props.data!
    if (error) {
      window.console.error(error)
      return this.renderError()
    }
    if (error || loading || !this.props.data!.videoCollections!) {
      if (!this.props.data!.videoCollections!) {
        this.props.data!.refetch()
      }
      return this.renderLoading()
    }
    const assets = this.getAssets()
    if (assets.length === 0) {
      return this.renderEmpty()
    }
    const isLarge = assets.length > 300
    const columnCount =
      Math.min(this.state.assetsView.columnCount, assets.length) || 1
    const assetsTrimmed = isLarge
      ? assets.slice(0, assets.length - (assets.length % columnCount))
      : assets
    const lastItem = assetsTrimmed[assetsTrimmed.length - 1]
    return (
      <React.Fragment>
        <div>
          <List
            className="video-assets-list"
            items={assetsTrimmed}
            getItemCountForPage={() => videoAssetRowsPerPage * columnCount}
            getPageHeight={() => videoAssetRowsPerPage * videoAssetItemHeight}
            renderedWindowsAhead={0}
            onRenderCell={(asset: any) =>
              this.renderAsset(asset as VideoAssetInfo)
            }
            style={{ width: columnCount * (videoAssetItemWidth + 24) }}
          />
          <VisibilitySensor
            onChange={(isVisible) =>
              isVisible && this.updateNextPageVisibility()
            }
          >
            {(this.state.isLastItemVisible && isLarge && lastItem && (
              <VideoAssetList
                collectionId={this.props.collectionId}
                cursor={lastItem.id + '.'}
              />
            )) || <div style={{ height: 64 }}></div>}
          </VisibilitySensor>
        </div>
        {this.state.isVideoAssetModalOpen && this.renderVideoAssetDialog()}
        {this.state.isVideoAssetDeleteModalOpen &&
          this.renderDeleteVideoAssetDialog(this.props.data!.refetch)}
        {this.state.isVideoAssetRenameModalOpen &&
          this.renderRenameVideoAssetDialog(this.props.data!.refetch)}
      </React.Fragment>
    )
  }
  renderAsset(a: VideoAssetInfo) {
    const theme = getTheme()
    const iconSize = 64
    return (
      <Stack
        key={a.id}
        className="video-asset"
        horizontalAlign="center"
        verticalAlign="start"
        style={{
          width: videoAssetItemWidth + 24,
          float: 'left',
          paddingBottom: 24,
          paddingRight: 24,
          maxHeight: videoAssetItemHeight,
          overflow: 'hidden',
        }}
      >
        <DocumentCard
          styles={{
            root: {
              boxShadow: Depths.depth4,
              outline: 'none',
            },
          }}
          onClick={
            a.transcodingJobStatus === 'finished'
              ? () => this.previewVideoAsset(a)
              : undefined
          }
        >
          {(a.transcodingJobStatus === 'failed' && (
            <DocumentCardImage
              imageFit={ImageFit.cover}
              width={videoAssetItemWidth}
              height={Math.floor((videoAssetItemWidth * 9) / 16)}
              iconProps={{
                iconName: 'Warning',
              }}
              styles={{
                centeredIcon: {
                  color: theme.palette.orangeLight,
                  height: iconSize,
                  width: iconSize,
                  fontSize: iconSize,
                },
              }}
            />
          )) ||
            (a.transcodingJobStatus === 'scheduled' && (
              <DocumentCardImage
                imageFit={ImageFit.cover}
                width={videoAssetItemWidth}
                height={Math.floor((videoAssetItemWidth * 9) / 16)}
                iconProps={{
                  iconName: 'BufferTimeBoth',
                }}
                styles={{
                  centeredIcon: {
                    color: theme.palette.blue,
                    height: iconSize,
                    width: iconSize,
                    fontSize: iconSize,
                  },
                }}
              />
            )) ||
            (a.type === 'video' && (
              <DocumentCardImage
                imageFit={ImageFit.cover}
                width={videoAssetItemWidth}
                height={Math.floor((videoAssetItemWidth * 9) / 16)}
                imageSrc={`https://caroda-media-storage.imgix.net/${a.id}/thumbnail/cover.jpg?fit=crop&w=${videoAssetItemWidth}&h=${Math.floor((videoAssetItemWidth * 9) / 16)}`}
              />
            )) ||
            (a.type === 'audio' && (
              <DocumentCardImage
                imageFit={ImageFit.cover}
                width={videoAssetItemWidth}
                height={Math.floor((videoAssetItemWidth * 9) / 16)}
                iconProps={{
                  iconName: 'Volume2',
                }}
                styles={{
                  centeredIcon: {
                    height: iconSize,
                    width: iconSize,
                    fontSize: iconSize,
                  },
                }}
              />
            ))}
          <Stack
            horizontalAlign="start"
            verticalAlign="center"
            verticalFill
            horizontal
            style={{
              width: '100%',
              padding: 8,
              maxHeight: 54,
            }}
          >
            <Stack horizontalAlign="start" verticalAlign="center" verticalFill>
              <Text variant="large" className="video-asset-description">
                {a.title}
              </Text>
              <Text variant="small" className="video-asset-description">
                {a.transcodingJobStatus === 'scheduled'
                  ? 'processing since '
                  : ''}
                {(Date.now() - new Date(a.creationDate).getTime()) /
                  (24 * 60 * 60e3) >
                7
                  ? moment(a.creationDate).format('Do MMM, YYYY')
                  : moment(a.creationDate).fromNow()}
              </Text>
            </Stack>
            <Stack
              horizontalAlign="end"
              verticalAlign="center"
              verticalFill
              horizontal
              style={{ flexGrow: 1 }}
            >
              {a.transcodingJobStatus !== 'scheduled' &&
                a.transcodingJobStatus !== 'failed' && (
                  <IconButton
                    menuProps={{
                      shouldFocusOnMount: true,
                      items: [
                        {
                          key: 'preview',
                          text: 'Details',
                          iconProps: { iconName: 'Info' },
                          onClick: () => this.previewVideoAsset(a),
                          filter: () => a.transcodingJobStatus === 'finished',
                        },
                        {
                          key: 'viewReferer',
                          text: 'View on site',
                          iconProps: { iconName: 'FileASPX' },
                          href: a.migrationSourceReferer,
                          target: '_blank',
                          filter: () =>
                            a.migrationSourceReferer &&
                            !a.migrationSourceReferer.match(/caroda/i),
                        },
                        {
                          key: 'divider_1',
                          itemType: ContextualMenuItemType.Divider,
                        },
                        {
                          key: 'edit',
                          iconProps: { iconName: 'edit' },
                          text: 'Rename',
                          onClick: () => this.renameVideoAsset(a),
                          filter: () => a.transcodingJobStatus === 'finished',
                        },
                        {
                          key: 'delete',
                          iconProps: { iconName: 'delete' },
                          text: 'Delete',
                          onClick: () => this.deleteVideoAsset(a),
                          filter: () => a.transcodingJobStatus !== 'scheduled',
                        },
                      ].filter((i: any) => !i.filter || i.filter!()),
                    }}
                  />
                )}
              {(a.transcodingJobStatus === 'scheduled' ||
                a.transcodingJobStatus === 'failed') && (
                <IconButton
                  iconProps={{ iconName: 'cancel' }}
                  onClick={() => this.deleteVideoAsset(a)}
                />
              )}
            </Stack>
          </Stack>
        </DocumentCard>
      </Stack>
    )
  }
  renderEmpty() {
    return (
      <Stack
        horizontalAlign="center"
        verticalAlign="center"
        verticalFill
        style={{ width: '100%' }}
      >
        <Text
          variant="xLarge"
          style={{
            maxWidth: 600,
            padding: 20,
            textAlign: 'center',
          }}
        >
          This video collection is empty. You can add videos by pressing the{' '}
          <i>Connect to website</i>{' '}
          <Icon iconName="Globe" style={{ verticalAlign: 'middle' }} />
          &nbsp;button and adding the indicated script to your site, making it a
          synchronized collection. You can also manually upload video files.
        </Text>
      </Stack>
    )
  }
  renderError() {
    return (
      <Stack
        horizontalAlign="center"
        verticalAlign="center"
        verticalFill
        style={{ width: '100%' }}
      >
        <Text
          variant="xLarge"
          style={{
            maxWidth: 600,
            padding: 20,
            textAlign: 'center',
          }}
        >
          This video collection could not be found. Most likely, you've opened
          an older link of a collection that is now deleted. If the error
          persists and your collection can not be selected from the navigation
          menu, please contact support.
        </Text>
      </Stack>
    )
  }
  renderLoading() {
    return (
      <Stack
        horizontalAlign="center"
        verticalAlign="center"
        verticalFill
        style={{ width: '100%' }}
      >
        <Spinner
          size={SpinnerSize.large}
          labelPosition={'right'}
          label="Loading"
        />
      </Stack>
    )
  }
  renderVideoAssetDialog() {
    if (!this.state.isVideoAssetModalOpen) {
      return <React.Fragment />
    }
    return (
      <VideoAssetDialog
        videoAsset={this.state.selectedVideoAsset!}
        isOpen={this.state.isVideoAssetModalOpen}
        onClose={(action: string) => {
          this.setState({ isVideoAssetModalOpen: false })
          if (action === 'delete') {
            this.deleteVideoAsset(this.state.selectedVideoAsset!)
          }
          if (action === 'rename') {
            this.renameVideoAsset(this.state.selectedVideoAsset!)
          }
        }}
      />
    )
  }
  renderRenameVideoAssetDialog(refetch: () => {}) {
    if (!this.state.isVideoAssetRenameModalOpen) {
      return <React.Fragment />
    }
    return (
      <RenameVideoAssetDialog
        collectionId={this.props.collectionId}
        videoAsset={this.state.selectedVideoAsset!}
        onUpdated={refetch}
        isOpen={this.state.isVideoAssetRenameModalOpen}
        onClose={() => this.setState({ isVideoAssetRenameModalOpen: false })}
      />
    )
  }
  renderDeleteVideoAssetDialog(refetch: () => {}) {
    if (!this.state.isVideoAssetDeleteModalOpen) {
      return <React.Fragment />
    }
    return (
      <DeleteVideoAssetDialog
        collectionId={this.props.collectionId}
        videoAsset={this.state.selectedVideoAsset!}
        onUpdated={refetch}
        isOpen={this.state.isVideoAssetDeleteModalOpen}
        onClose={() => this.setState({ isVideoAssetDeleteModalOpen: false })}
      />
    )
  }
  getAssets() {
    const videoCollections = this.props.data!.videoCollections!
    return (
      (videoCollections && videoCollections[0] && videoCollections[0].assets) ||
      []
    )
  }
  renameVideoAsset(a: VideoAssetInfo) {
    this.setState({
      isVideoAssetRenameModalOpen: true,
      selectedVideoAsset: a,
    })
  }
  deleteVideoAsset(a: VideoAssetInfo) {
    this.setState({
      isVideoAssetDeleteModalOpen: true,
      selectedVideoAsset: a,
    })
  }
  previewVideoAsset(a: VideoAssetInfo) {
    this.setState({
      isVideoAssetModalOpen: true,
      selectedVideoAsset: a,
    })
  }
}

const collectionsQuery = gql`
  query ($byId: String, $startFromId: String) {
    videoCollections(byId: $byId) {
      assets(startFromId: $startFromId) {
        id
        title
        creationDate
        tags
        type
        transcodingJobStatus
        durationInSeconds
        aspectRatio
        migrationSourceUrl
        migrationSourceReferer
      }
    }
  }
`
const withGql = graphql<InputProps, Response>(collectionsQuery, {
  options: ({ collectionId, cursor }) => ({
    variables: {
      byId: collectionId,
      startFromId: cursor,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  }),
})
const VideoAssetList = withGql(VideoAssetListView)

export { VideoAssetList }
