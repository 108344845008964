import React from 'react'
import { SpinButton, IconButton } from 'office-ui-fabric-react'
import './NumberInput.css'

interface State {}

interface InputProps {
  label: string
  onChange: (value?: number) => any
  value: number | null
  min: number
  max: number
  precision?: number
  errorMessage?: string
  required?: boolean
  disabled?: boolean
  incrementSize?: number
  suffix?: string
  name?: string
  style?: React.CSSProperties
}

export class NumberInput extends React.Component<InputProps, State> {
  render() {
    const suffix = this.props.suffix || ''
    const incrementSize = this.props.incrementSize || 1
    return (
      <div
        className={
          'number-input' +
          (this.props.errorMessage ? ' has-error' : '') +
          (this.props.value === null ? ' has-null-value' : '')
        }
        style={this.props.style}
      >
        <div
          style={{
            marginTop: 5,
            marginBottom: 5,
            fontWeight: 600,
            color: 'rgb(50, 49, 48)',
          }}
        >
          {this.props.label}
          {this.props.required && (
            <span style={{ color: 'rgb(164, 38, 44)' }}> *</span>
          )}
        </div>
        <SpinButton
          className="spin-button"
          min={this.props.min}
          max={this.props.max}
          value={
            this.props.value === null
              ? ''
              : `${this.normalize(this.props.value)}${suffix}`
          }
          disabled={this.props.disabled}
          onValidate={(value: string) => {
            const n = this.toNumber(value)
            this.props.onChange(this.normalize(n))

            // issue: on validate doesn't change the value in the input field!
            return this.toString(n)
          }}
          onIncrement={(value: string) => {
            const n = this.toNumber(value) + incrementSize
            this.props.onChange(this.normalize(n))
            return this.toString(n)
          }}
          onDecrement={(value: string) => {
            const n = this.toNumber(value) - incrementSize
            this.props.onChange(this.normalize(n))
            return this.toString(n)
          }}
          precision={this.props.precision}
        />
        {!this.props.required && this.props.value && !this.props.disabled && (
          <div
            style={{
              position: 'absolute',
              top: 29,
              right: 25,
            }}
          >
            <IconButton
              className="clear-button"
              iconProps={{ iconName: 'Cancel' }}
              onClick={() => this.props.onChange(undefined)}
            />
          </div>
        )}
        {this.props.errorMessage && (
          <span data-automation-id="error-message">
            {this.props.errorMessage}
          </span>
        )}
      </div>
    )
  }
  private normalize(value: number) {
    if (isNaN(value)) {
      return undefined
    }
    const ret = this.toNumber(
      this.toString(Math.max(Math.min(value, this.props.max), this.props.min))
    )
    if (isNaN(ret)) {
      return undefined
    }
    return ret
  }
  private toNumber(value: string) {
    const suffix = this.props.suffix || ''
    return Number(
      value.slice(0, value.length - suffix.length).replace(/^0+/, '')
    )
  }
  private toString(value: number) {
    if (isNaN(value)) {
      return ''
    }
    const suffix = this.props.suffix || ''
    const precision = this.props.precision || 0
    const fixed = value.toFixed(precision)
    return fixed.replace(/(\.[0-9]+)0+$/, '$1') + suffix
  }
}
