import { client } from './api'
import { requestJson } from './xhr'
import gql from 'graphql-tag'

export const getAllVideoCollectionSummaryQuery = gql`
  query {
    videoCollections {
      id
      title
    }
  }
`

export const getEncodingConfigurationQuery = gql`
  {
    encodingConfiguration {
      profiles
      allowedProfiles
    }
  }
`

export const getConnectToWebsiteQueryQuery = gql`
  query ($collectionId: String) {
    session {
      integrationToken
    }
    videoCollections(byId: $collectionId) {
      allowedMigration
      allowedExternal
    }
  }
`

const createVideoCollectionMutation = gql`
  mutation ($title: String!) {
    videoCollections {
      create(title: $title)
    }
  }
`

const renameVideoCollectionMutation = gql`
  mutation ($id: String, $title: String) {
    videoCollections(byId: $id) {
      update(title: $title)
    }
  }
`

const renameVideoAssetMutation = gql`
  mutation ($collectionId: String, $id: String, $title: String) {
    videoCollections(byId: $collectionId) {
      assets(byId: $id) {
        update(title: $title)
      }
    }
  }
`

const deleteVideoCollectionMutation = gql`
  mutation ($id: String) {
    videoCollections(byId: $id) {
      delete
    }
  }
`

const deleteVideoAssetMutation = gql`
  mutation ($collectionId: String, $id: String) {
    videoCollections(byId: $collectionId) {
      assets(byId: $id) {
        delete
      }
    }
  }
`

const setEncodingConfigurationProfilesMutation = gql`
  mutation ($profiles: [String!]!) {
    encodingConfiguration {
      update(profiles: $profiles)
    }
  }
`

const setVideoCollectionMigrationRulesMutation = gql`
  mutation (
    $collectionId: String!
    $redirectRules: [String!]!
    $migrationRules: [String!]!
  ) {
    videoCollections(byId: $collectionId) {
      update(allowedMigration: $migrationRules, allowedExternal: $redirectRules)
    }
  }
`

export interface VideoCollectionSummary {
  id: string
  title: string
}

export interface VideoAssetInfo {
  id: string
  title: string
  creationDate: string
  tags: string
  type: string
  transcodingJobStatus: string
  durationInSeconds: string
  aspectRatio: string
  migrationSourceUrl: string
  migrationSourceReferer: string
}

export interface VideoCollectionMigrationSettings {
  allowedMigration: string[]
  allowedExternal: string[]
}

export interface VideoCollectionSummaryResponse {
  videoCollections: VideoCollectionSummary[]
}

export interface EncodingConfigurationResponse {
  encodingConfiguration: {
    profiles: string[]
    allowedProfiles: string[]
  }
}

export interface ConnectToWebsiteResponse {
  session: { integrationToken: string }
  videoCollections: VideoCollectionMigrationSettings[]
}

export async function createVideoCollection(title: string) {
  return client.mutate({
    mutation: createVideoCollectionMutation,
    variables: { title },
  })
}

export async function renameVideoCollection(
  collectionId: string,
  newTitle: string
) {
  return client.mutate({
    mutation: renameVideoCollectionMutation,
    variables: {
      id: collectionId,
      title: newTitle,
    },
  })
}

export async function renameVideoAsset(
  collectionId: string,
  id: string,
  newTitle: string
) {
  return client.mutate({
    mutation: renameVideoAssetMutation,
    variables: {
      id,
      collectionId,
      title: newTitle,
    },
  })
}

export async function deleteVideoCollection(collectionId: string) {
  return client.mutate({
    mutation: deleteVideoCollectionMutation,
    variables: { id: collectionId },
  })
}

export async function deleteVideoAsset(collectionId: string, id: string) {
  return client.mutate({
    mutation: deleteVideoAssetMutation,
    variables: { id, collectionId },
  })
}

export async function setEncodingProfiles(profiles: string[]) {
  return client.mutate({
    mutation: setEncodingConfigurationProfilesMutation,
    variables: { profiles },
  })
}

export async function setVideoCollectionMigrationRules(
  collectionId: string,
  redirectRules: string[],
  migrationRules: string[]
) {
  return client.mutate({
    mutation: setVideoCollectionMigrationRulesMutation,
    variables: {
      collectionId,
      redirectRules,
      migrationRules,
    },
  })
}

export async function migrateUrl(
  collectionId: string,
  url: string,
  aspectRatio?: string,
  posterUrl?: string
) {
  const sessionResponse = await client.query({
    query: gql`
      {
        session {
          integrationToken
        }
      }
    `,
  })
  const integrationToken = sessionResponse.data!.session.integrationToken
  const migrationUrl = `https://platform.caroda.io/api-video/migrate?url=${encodeURIComponent(
    url
  )}&ctok=${encodeURIComponent(integrationToken)}&vcid=${encodeURIComponent(
    collectionId
  )}${aspectRatio ? `&aspectRatio=${encodeURIComponent(aspectRatio)}` : ''}${
    posterUrl ? `&posterUrl=${encodeURIComponent(posterUrl)}` : ''
  }`
  return requestJson((xhr) => {
    xhr.open('POST', migrationUrl)
    xhr.withCredentials = true
    xhr.send()
  })
}

export async function blobUpload(file: File): Promise<string> {
  const sessionResponse = await client.query({
    query: gql`
      {
        session {
          videoUploadSas
        }
      }
    `,
  })
  const sas = sessionResponse.data!.session.videoUploadSas
  const blobUri = 'https://mediastorage8136.blob.core.windows.net'
  const blobService = (
    window as any
  ).AzureStorage.Blob.createBlobServiceWithSas(blobUri, sas)
  const customBlockSize =
    file.size > 1024 * 1024 * 32 ? 1024 * 1024 * 4 : 1024 * 512
  blobService.singleBlobPutThresholdInBytes = customBlockSize
  const cryptoAsAny = crypto as any
  const blobname = Date.now().toString(16) + '/' + cryptoAsAny.randomUUID()
  const url =
    'https://mediastorage8136.blob.core.windows.net/platformupload/' + blobname
  return new Promise((resolve, reject) =>
    blobService.createBlockBlobFromBrowserFile(
      'platformupload',
      blobname,
      file,
      { blockSize: customBlockSize },
      (error: Error, result: any, response: any) => {
        console.log({ error, result, response })
        if (error) {
          reject(error)
          return
        }
        resolve(url)
      }
    )
  )
}
