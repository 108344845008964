import React from 'react'
import { Stack, TextField } from 'office-ui-fabric-react'
import { CampaignAniviewAssetConfig } from '../../../Api/campaigns'

interface AniviewFormProps {
  publisherIdErrorMessage: string | undefined
  channelIdErrorMessage: string | undefined
  aniviewConfig: CampaignAniviewAssetConfig
  onChange: (aniviewConfig: CampaignAniviewAssetConfig) => void
  disabled: boolean
}

const gap = 28
const AniviewForm: React.FC<AniviewFormProps> = ({
  publisherIdErrorMessage,
  channelIdErrorMessage,
  aniviewConfig,
  onChange,
  disabled,
}: AniviewFormProps) => (
  <Stack horizontal>
    <Field
      label="Publisher ID"
      value={aniviewConfig.publisherId}
      onChange={(publisherId) =>
        onChange({
          ...aniviewConfig,
          publisherId,
        })
      }
      errorMessage={publisherIdErrorMessage}
      disabled={disabled}
    />
    <div style={{ width: gap }} />
    <Field
      label="Channel ID"
      value={aniviewConfig.channelId}
      onChange={(channelId) =>
        onChange({
          ...aniviewConfig,
          channelId,
        })
      }
      errorMessage={channelIdErrorMessage}
      disabled={disabled}
    />
  </Stack>
)

export default AniviewForm

interface FieldProps {
  label: string
  value: string
  onChange: (newValue: string) => void
  errorMessage: string | undefined
  disabled: boolean
}

const Field: React.FC<FieldProps> = ({
  label,
  value,
  onChange,
  errorMessage,
  disabled,
}: FieldProps) => (
  <div
    style={{
      flexGrow: 1,
      maxWidth: `calc(50% - ${gap / 2}px)`,
    }}
  >
    <TextField
      label={label}
      errorMessage={errorMessage}
      value={value}
      autoComplete={'off'}
      spellCheck={false}
      onChange={(evt: any, newValue?: string) => onChange(newValue || '')}
      disabled={disabled}
      resizable={false}
      required
    />
  </div>
)
