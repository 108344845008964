import React, { useCallback } from 'react'
import { Stack } from 'office-ui-fabric-react'
import { ChildProps } from 'react-apollo'
import { NewsItemsResponse } from '../../Api/session'
import { ScreenHeader } from '../../Components/ScreenHeader'
import { ErrorScreen } from '../../Components/ErrorScreen'
import './HomeScreen.css'
import { NewsList } from './NewsList'

interface InputProps {}

type Props = ChildProps<InputProps, NewsItemsResponse>

function HomeScreenView(props: Props) {
  const [isError, setIsError] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(true)
  const onNewsListChange = useCallback(({ isLoading, isError }) => {
    setIsError(isError)
    setIsLoading(isLoading)
  }, [])
  if (isError) {
    return <ErrorScreen errorText="Could not load news items." />
  }
  return (
    <Stack
      className="screen"
      horizontalAlign="start"
      verticalAlign="start"
      verticalFill
      style={{ width: '100%' }}
    >
      <ScreenHeader path={[isLoading ? 'Loading...' : 'News']} buttons={[]} />
      <Stack
        className="news-container"
        horizontalAlign="center"
        verticalAlign="start"
        horizontal
        wrap
        data-is-scrollable="true"
        styles={{
          root: {
            width: '100%',
            overflow: 'auto',
          },
          inner: {
            padding: 60,
          },
        }}
      >
        <NewsList offset={0} onChange={onNewsListChange} />
      </Stack>
    </Stack>
  )
}

export const HomeScreen = HomeScreenView
