export function removeNullFields(obj: any): any {
  if (!obj) {
    return obj
  }
  return Object.keys(obj)
    .filter((key) => !!obj[key])
    .reduce(
      (newObj, key) => ({
        ...newObj,
        [key]: obj[key],
      }),
      {} as any
    )
}
