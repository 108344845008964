import React from 'react'

import {
  Stack,
  TextField,
  MessageBar,
  MessageBarType,
  Link,
  ITag,
} from 'office-ui-fabric-react'

import { ShownTagInput } from '../../Components/Input/ShownTagInput'
import { NumberInput } from './NumberInput'

interface State {
  whitelistGlobs: GlobState
  blacklistGlobs: GlobState
  maximumAdLength: {
    selected: boolean
    value: number | null
    error: string
  }
}

interface GlobState {
  selected: boolean
  values: string[]
}

interface IntegrationLimits {
  whitelistGlobs?: string[]
  blacklistGlobs?: string[]
  maximumAdLength?: number | null
}

interface InputProps {
  errorMessages: { [fieldName: string]: string }
  disabled: boolean
  integrationLimits: IntegrationLimits
  label?: string
  gap?: number
  onChange: (args: IntegrationLimits, error: boolean) => any
  showMaximumAdLength?: boolean
}

const filterInputInfo = {
  whitelistGlobs: {
    label: 'Glob URL Whitelist',
    placehoder: 'https://acme.com/articles/**',
  },
  blacklistGlobs: {
    label: 'Glob URL Blacklist',
    placehoder: 'https://acme.com/articles/**/*ugly*/**',
  },
  maximumAdLength: {
    label: 'Maximum Ad Length',
  },
}

export class IntegrationLimitingForm extends React.Component<
  InputProps,
  State
> {
  constructor(props: InputProps) {
    super(props)
    const { whitelistGlobs, blacklistGlobs, maximumAdLength } =
      props.integrationLimits
    this.state = {
      whitelistGlobs: {
        values: whitelistGlobs!.slice(),
        selected: !!whitelistGlobs!.length,
      },
      blacklistGlobs: {
        values: blacklistGlobs!.slice(),
        selected: !!blacklistGlobs!.length,
      },
      maximumAdLength: {
        value: maximumAdLength || null,
        error: '',
        selected: !!maximumAdLength && maximumAdLength > 0,
      },
    }
  }
  getSelected() {
    return Object.keys(this.state).filter(
      (key) => this.state[key as keyof State].selected
    )
  }
  onChange(values?: ITag[]) {
    if (!values) {
      return
    }
    const selectedKeys = values.map((it) => it.key)
    const updatedState = { ...this.state }
    let updatesParentState = false
    Object.keys(updatedState).forEach((key) => {
      const isSelected = selectedKeys.includes(key)
      updatedState[key as keyof State].selected = isSelected
    })
    if (!selectedKeys.includes('whitelistGlobs')) {
      updatesParentState =
        updatesParentState || !!updatedState.whitelistGlobs.values.length
      updatedState.whitelistGlobs.values = []
    }
    if (!selectedKeys.includes('blacklistGlobs')) {
      updatesParentState =
        updatesParentState || !!updatedState.blacklistGlobs.values.length
      updatedState.blacklistGlobs.values = []
    }
    if (!selectedKeys.includes('maximumAdLength')) {
      updatesParentState =
        updatesParentState ||
        !!updatedState.maximumAdLength.value ||
        !!updatedState.maximumAdLength.error
      updatedState.maximumAdLength.value = null
      updatedState.maximumAdLength.error = ''
    }
    this.setState(updatedState)
    if (updatesParentState) {
      this.props.onChange(
        {
          whitelistGlobs: this.state.whitelistGlobs.values,
          blacklistGlobs: this.state.blacklistGlobs.values,
          maximumAdLength: this.state.maximumAdLength.value,
        },
        !!this.state.maximumAdLength.error
      )
    }
  }
  render() {
    const { label = 'Limiting', gap = 0 } = this.props
    const options = Object.keys(this.state)
      .filter(
        (key) => key !== 'maximumAdLength' || !!this.props.showMaximumAdLength
      )
      .map((key) => ({ key, name: filterInputInfo[key as keyof State].label }))
    return (
      <Stack gap={gap}>
        <ShownTagInput
          label={label}
          selectedKeys={this.getSelected()}
          onChange={(values?) => this.onChange(values)}
          disabled={this.props.disabled}
          options={options}
        />
        {this.getSelected().map((selected) =>
          this.renderFilterInput(selected as keyof State)
        )}
        {['whitelistGlobs', 'blacklistGlobs'].some((key) =>
          this.getSelected().includes(key)
        ) && this.renderGlobInfo()}
      </Stack>
    )
  }
  renderFilterInput(selected: keyof State) {
    if (selected === 'maximumAdLength') {
      return (
        <React.Fragment key={selected}>
          {this.renderMaximumAdLengthInput()}
        </React.Fragment>
      )
    }
    const inputText = this.state[selected].values.join('\n')
    return (
      <React.Fragment key={selected}>
        <TextField
          name={selected}
          label={filterInputInfo[selected].label}
          placeholder={filterInputInfo[selected].placehoder}
          value={inputText}
          multiline
          spellCheck={false}
          autoAdjustHeight
          resizable={false}
          autoComplete={'off'}
          onChange={(evt: any, value?: string) => {
            const pathGlobs = (value || '').split(/\n|\r/).map((s) => s.trim())
            const updatedState = {
              ...this.state,
              [selected]: {
                ...this.state[selected],
                values: pathGlobs,
              },
            }
            this.setState(updatedState)
            this.props.onChange(
              {
                [selected]: pathGlobs,
              },
              !!this.state.maximumAdLength.error
            )
          }}
          disabled={this.props.disabled}
        />
      </React.Fragment>
    )
  }
  renderMaximumAdLengthInput() {
    return (
      <NumberInput
        label="Maximum ad length (seconds)"
        errorMessage={this.state.maximumAdLength.error}
        name="maximumAdLength"
        value={this.state.maximumAdLength.value || null}
        onChange={(value: number | null = null) => {
          const isValid = !!value && value > 0
          this.setState({
            ...this.state,
            maximumAdLength: {
              ...this.state.maximumAdLength,
              error: isValid ? '' : 'value must be a natural number',
              value: value,
            },
          })
          this.props.onChange(
            {
              maximumAdLength: value,
            },
            !isValid
          )
        }}
        min={0}
        max={120}
        precision={0}
        disabled={this.props.disabled}
        required={true}
      />
    )
  }
  renderGlobInfo() {
    return (
      <React.Fragment>
        <MessageBar
          messageBarType={MessageBarType.info}
          isMultiline={true}
          className={'limitGlobInfo'}
          styles={{ root: { marginTop: 10 } }}
        >
          Glob is a pattern matching language to help match more than one url.
          In order for the pattern to work, make sure that the glob includes the
          full url matched, not only a part of it.
          <Link
            href="https://en.wikipedia.org/wiki/Glob_(programming)"
            target="_blank"
          >
            More info
          </Link>
          ,
          <Link href="https://codepen.io/mrmlnc/full/OXQjrZ" target="_blank">
            Test your pattern
          </Link>
        </MessageBar>
      </React.Fragment>
    )
  }
}
