import React from 'react'
import {
  TextField,
  MessageBar,
  MessageBarType,
  Separator,
  ITag,
  INavLink,
  Stack,
} from 'office-ui-fabric-react'
import { ChildProps, graphql } from 'react-apollo'
import {
  createOutstreamPlacement,
  updateOutstreamPlacement,
  OutstreamPlacementInfo,
  OutstreamPlacementCollectionTagsResponse,
  getOutstreamPlacementCollectionTagsQuery,
} from '../../Api/outstreamPlacements_v2'
import { ErrorDialog } from '../../Components/ErrorDialog'
import { TagInput } from '../../Components/Input/TagInput'
import { cloneDeep, uniq } from 'lodash'
import { IntegrationLimitingForm } from '../../Components/Input/IntegrationLimitingForm'
import { isDefined } from '../../Utils/Fp'
import { IntegrationDropdown } from '../../Components/Input/IntegrationDropdown'
import LargeDialogWithSidebar from '../../Components/LargeDialogWithSidebar'

type FormErrorSet = { [fieldName: string]: string }

interface State {
  modifiedPlacement: OutstreamPlacementInfo
  isLoading: boolean
  hasError: boolean
  childComponentErrors: {
    limiting: boolean
  }
  form: {
    errors: FormErrorSet
  }
}

interface InputProps {
  modifiedPlacement?: OutstreamPlacementInfo
  collectionId: string
  onClose: () => void
  onUpdated: () => void
}

const now = new Date().toISOString()

const defaultOutstreamPlacement: OutstreamPlacementInfo = {
  id: '',
  creationDate: now,
  title: '',
  integration: '',
  tags: [],
  whitelistGlobs: [],
  blacklistGlobs: [],
  maximumAdLength: undefined,
}

export class CreateOutstreamPlacementDialogView extends React.Component<
  ChildProps<InputProps, OutstreamPlacementCollectionTagsResponse>,
  State
> {
  constructor(props: any) {
    super(props)
    const state = {
      modifiedPlacement: cloneDeep(
        this.props.modifiedPlacement || defaultOutstreamPlacement
      ),
      hasError: false,
      isLoading: false,
      form: {
        errors: {},
      },
      childComponentErrors: {
        limiting: false,
      },
    } as State
    this.state = state
    if (!this.props.modifiedPlacement) {
      return
    }
    this.state = state
  }
  render() {
    if (this.props.data!.error) {
      return (
        <ErrorDialog
          onClose={this.props.onClose}
          title="Network error"
          message="Could not fetch collection parameters. "
        />
      )
    }
    if (!this.props.data!.outstreamPlacementCollections_v2) {
      return <React.Fragment />
    }
    const gap = 28
    const titleDescription = this.state.modifiedPlacement.title
    const isEdited =
      JSON.stringify(this.props.modifiedPlacement) !==
      JSON.stringify(this.state.modifiedPlacement)
    const dialogTitle =
      (this.props.modifiedPlacement
        ? 'Update Outstream Placement'
        : 'Create Outstream Placement') +
      (titleDescription ? ` "${titleDescription}"` : '') +
      (isEdited ? '*' : '')
    const shouldDisplayIntegration =
      this.props.modifiedPlacement && this.props.modifiedPlacement.id
    return (
      <LargeDialogWithSidebar
        title={dialogTitle}
        isBlocking={true}
        buttons={[
          {
            name: 'Cancel',
            action: () => this.close(),
            disabled: this.state.isLoading,
          },
          {
            name: this.props.modifiedPlacement ? 'Save' : 'Create',
            action: () => {
              this.submit()
            },
            disabled: this.state.isLoading,
            primary: true,
          },
        ]}
        onClose={() => this.close()}
        sidebarWidth={170}
        categories={
          [
            {
              name: 'General',
              key: 'general',
              icon: 'FileASPX',
            },
            shouldDisplayIntegration
              ? {
                  name: 'Integration',
                  key: 'integration',
                  icon: 'Globe',
                }
              : undefined,
            {
              name: 'Targeting',
              key: 'targeting',
              icon: 'Bullseye',
            },
          ].filter(Boolean) as INavLink[]
        }
      >
        {[
          {
            title: 'General',
            content: this.getGeneralForm(gap),
          },
          shouldDisplayIntegration
            ? {
                title: 'Integration',
                content: this.getIntegrationForm(gap),
              }
            : undefined,
          {
            title: 'Targeting',
            content: this.getTargetingForm(gap),
          },
        ]
          .filter(Boolean)
          .map((item: any, index, a) => (
            <div key={index}>
              {index !== 0 && (
                <Separator styles={{ root: { margin: 32 } }}></Separator>
              )}
              <div>
                <h2
                  id={item.title.toLowerCase()}
                  style={{ marginTop: 0, marginBottom: 42 }}
                >
                  {item.title}
                </h2>
                {item.content}
              </div>
            </div>
          ))}
        {this.state.hasError && (
          <MessageBar
            messageBarType={MessageBarType.error}
            isMultiline={true}
            styles={{ root: { marginTop: 15 } }}
          >
            Could not create outstream placement. If the problem persist, please
            contact support.
          </MessageBar>
        )}
      </LargeDialogWithSidebar>
    )
  }
  getGeneralForm(gap: number) {
    return (
      <Stack gap={gap}>
        <TextField
          label={'Name'}
          errorMessage={this.state.form.errors.title}
          name={'title'}
          value={this.state.modifiedPlacement.title}
          autoComplete={'off'}
          onChange={(evt: any, value?: string) => {
            this.clearErrors()
            this.setState({
              modifiedPlacement: {
                ...this.state.modifiedPlacement,
                title: value!,
              },
            })
          }}
          disabled={this.state.isLoading}
          required
        />
        <IntegrationDropdown
          style={{
            flexGrow: 1,
            maxWidth: `calc(50% - ${gap / 2}px)`,
          }}
          value={this.state.modifiedPlacement.integration || ''}
          onChange={(integration) => {
            this.clearErrors()
            this.setState({
              modifiedPlacement: {
                ...this.state.modifiedPlacement,
                integration,
              },
            })
          }}
          disabled={this.state.isLoading}
          required={true}
          errorMessage={this.state.form.errors.integration}
        />
        <TagInput
          label="Tags"
          onResolveSuggestions={(filter, tagList): ITag[] => {
            const tagCandidates = [
              ...this.props.data!.outstreamPlacementCollections_v2![0]!.tags,
            ]
            let isNew = false
            if (tagCandidates.indexOf(filter) === -1) {
              tagCandidates.push(filter)
              isNew = true
            }
            const filteredTags = tagCandidates.filter(
              (tag) =>
                !tag.startsWith('opz') &&
                !(tagList || []).find((t) => t.key === tag) &&
                tag.indexOf(filter) !== -1
            )
            return filteredTags.filter(isDefined).map((key) => ({
              key,
              name: key === filter && isNew ? `Create new tag "${key}"` : key,
            }))
          }}
          pickerLabels={{
            suggestionsHeader: 'Suggested Tags',
            noResults: 'Tag already exists',
          }}
          onChange={(value?: ITag[]) => {
            this.clearErrors()
            this.setState({
              modifiedPlacement: {
                ...this.state.modifiedPlacement,
                tags: uniq((value || []).map(({ key }) => key)),
              },
            })
          }}
          itemLimit={50}
          selectedItems={uniq(this.state.modifiedPlacement.tags)
            .filter((tag) => !tag.startsWith('opz'))
            .map((key) => ({ key, name: key }))}
          disabled={this.state.isLoading}
        />
      </Stack>
    )
  }
  getIntegrationForm(gap: number) {
    return (
      <Stack gap={gap}>
        {this.props.modifiedPlacement && this.props.modifiedPlacement.id && (
          <div
            style={{
              flexGrow: 1,
              maxWidth: `calc(50% - ${gap / 2}px)`,
            }}
          >
            <TextField
              label={'Placement ID'}
              value={this.props.modifiedPlacement.id}
              autoComplete={'off'}
              readOnly={true}
            />
          </div>
        )}
      </Stack>
    )
  }
  getTargetingForm(gap: number) {
    return (
      <IntegrationLimitingForm
        label={''}
        gap={gap}
        integrationLimits={{
          whitelistGlobs: this.state.modifiedPlacement.whitelistGlobs,
          blacklistGlobs: this.state.modifiedPlacement.blacklistGlobs,
          maximumAdLength: this.state.modifiedPlacement.maximumAdLength,
        }}
        disabled={this.state.isLoading}
        errorMessages={{}}
        onChange={(integrationLimits, hasError) => {
          const {
            whitelistGlobs = this.state.modifiedPlacement.whitelistGlobs,
            blacklistGlobs = this.state.modifiedPlacement.blacklistGlobs,
            maximumAdLength = this.state.modifiedPlacement.maximumAdLength,
          } = integrationLimits
          this.setState({
            modifiedPlacement: {
              ...this.state.modifiedPlacement,
              whitelistGlobs,
              blacklistGlobs,
              maximumAdLength: maximumAdLength || undefined,
            },
            childComponentErrors: {
              limiting: hasError,
            },
          })
        }}
        showMaximumAdLength={true}
      />
    )
  }
  clearErrors() {
    this.setState({
      hasError: false,
      form: {
        errors: {
          title: '',
          integration: '',
        },
      },
    })
  }
  submit() {
    if (Object.values(this.state.childComponentErrors).some(Boolean)) {
      return
    }
    const title = this.state.modifiedPlacement.title
    if (!title || !title.trim()) {
      this.setState({
        form: {
          errors: {
            title: 'field is mandatory',
          },
        },
      })
      return
    }
    const integration = this.state.modifiedPlacement.integration
    if (!integration) {
      this.setState({
        form: {
          errors: {
            integration: 'field is mandatory',
          },
        },
      })
      return
    }
    this.setState({ isLoading: true })
    const isCreation = !this.props.modifiedPlacement
    const tags = this.state.modifiedPlacement.tags
    if (!isCreation) {
      tags.push(this.props.modifiedPlacement!.id)
    }
    const whitelistGlobs = this.state.modifiedPlacement.whitelistGlobs
      ? this.state.modifiedPlacement.whitelistGlobs.filter(Boolean)
      : []
    const blacklistGlobs = this.state.modifiedPlacement.blacklistGlobs
      ? this.state.modifiedPlacement.blacklistGlobs.filter(Boolean)
      : []
    const maximumAdLength =
      this.state.modifiedPlacement.maximumAdLength &&
      this.state.modifiedPlacement.maximumAdLength > 0
        ? this.state.modifiedPlacement.maximumAdLength
        : null

    const mutationPromise = !isCreation
      ? updateOutstreamPlacement(
          this.props.collectionId,
          this.props.modifiedPlacement!.id,
          title,
          integration,
          uniq(tags),
          whitelistGlobs,
          blacklistGlobs,
          maximumAdLength
        )
      : createOutstreamPlacement(
          this.props.collectionId,
          title,
          integration,
          uniq(tags),
          whitelistGlobs,
          blacklistGlobs,
          maximumAdLength
        )
    mutationPromise
      .then((id) =>
        isCreation
          ? updateOutstreamPlacement(
              this.props.collectionId,
              id,
              title,
              integration,
              uniq([...tags, id]),
              whitelistGlobs,
              blacklistGlobs,
              maximumAdLength
            )
          : id
      )
      .then(() => this.close())
      .then(this.props.onUpdated)
      .catch((e) => {
        console.error(e)
        this.setState({
          hasError: true,
          isLoading: false,
        })
      })
    return false
  }
  close() {
    this.props.onClose()
  }
}

const withGql = graphql<InputProps, OutstreamPlacementCollectionTagsResponse>(
  getOutstreamPlacementCollectionTagsQuery,
  {
    options: ({ collectionId }) => ({
      notifyOnNetworkStatusChange: true,
      variables: { collectionId },
    }),
  }
)
export const CreateOutstreamPlacementDialog = withGql(
  CreateOutstreamPlacementDialogView
)
