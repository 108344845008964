import React from 'react'
import {
  Separator,
  TextField,
  Stack,
  ITag,
  INavLink,
  Dropdown,
  IDropdownOption,
} from 'office-ui-fabric-react'
import isNil from 'lodash/isNil'
import {
  AssetTagsResponse,
  AssetType,
  Campaign,
  CampaignAssetInfo,
  CampaignCollectionSummary,
  CampaignTrackingHook,
  deleteCampaign,
  getNextTitle,
  isValidGamBannerSize,
} from '../../../Api/campaigns'
import LargeDialogWithSidebar from '../../../Components/LargeDialogWithSidebar'
import { TagInput } from '../../../Components/Input/TagInput'
import { NumberInput } from '../../../Components/Input/NumberInput'
import { SimpleDateInput } from '../../../Components/Input/SimpleDateInput'
import { cloneDeep, uniqBy } from 'lodash'
import { VastEvents } from '../../../Constants/VastEvents'
import {
  getAllPrebidParams,
  PrebidBidderDescriptor,
  PrebidParamDescriptor,
} from '../../../Constants/PrebidParams'
import { Currencies } from '../../../Constants/Currencies'
import {
  parseSafeJsObject,
  scrollIntoFirstError,
} from '../../../Components/Utils'
import { CappingForm } from './CappingForm/CappingForm_v2'
import { BidderParams, SourceForm } from './SourceForm'
import { TrackingForm } from './TrackingForm'
import { TargetingForm } from './TargetingForm'
import './CampaignDialogView.css'
import { removeNullFields } from '../../../Utils/object'
import { CreativeForm } from './CreativeForm/CreativeForm'
import { OverrideSkipBehavior } from '../../../Api/instreamPlacements_v2'
import { toast } from 'react-toastify'
import TemplateTitle from '../../../Components/Input/TemplateTitle'
import {
  isTemplateTitle,
  renderedTitleToTemplateTitle,
  templateTitleToRenderedTitle,
} from '../../../Functions/templateTitle'
import { isValidUrl } from '../../../Utils/urls'
import SupplyChain from './SupplyChain/SupplyChain'
import { not } from '../../../Utils/Fp'

type AdSourceType =
  | 'vastRedirect'
  | 'staticVast'
  | 'hbAdUnit'
  | 'bannerScript'
  | 'teadsUrl'
  | 'aniviewConfig'
  | 'directImage'
  | 'directImageAudio'
  | 'directVideo'

export type FieldType =
  | 'string'
  | 'number'
  | 'integer'
  | 'boolean'
  | 'object'
  | 'array'
  | 'native'
  | 'float'
  | 'int'
  | 'uint64'
  | 'array<integers>'

type FormErrorSet = { [fieldName: string]: string }

interface State {
  isSubmitting: boolean
  templateTitle?: string
  editedCampaign: Campaign
  previousPrebidRawError?: string
  form: {
    adSourceType: AdSourceType
    newTrackingHook: CampaignTrackingHook
    selectedTargetingTypes: string[]
    selectedTrackingTypes: string[]
    samePriorityBehaviour: SamePriorityBehaviour
    errors: FormErrorSet
  }
  childComponentErrors: {
    capping: boolean
  }
  isAlreadySubmitted: boolean
  campaignsSubmittedStack: Campaign[]
  bidderParams: BidderParams
  jsonError: string | undefined
}

interface InputProps {
  dialogTitle: string
  actionTitle: string
  collectionId: string
  campaignCollections?: CampaignCollectionSummary[]
  assetTagInfo: AssetTagsResponse
  disabled?: boolean
  campaign: Campaign
  isAdminOrAccountManager: boolean
  isTemplateMode?: boolean
  isSubmitDisabled?: boolean
  onClose: () => void
  onUpdated: (campaign: Campaign) => Promise<any>
  onUndo?: () => void
}

enum SamePriorityBehaviour {
  DEFAULT,
  FIXED_BID,
}

export class CampaignDialogView extends React.Component<InputProps, State> {
  constructor(props: any) {
    super(props)
    const samePriorityBehaviour =
      typeof this.props.campaign.fixedPrebidValue === 'number' ||
      this.props.campaign.fixedPrebidCurrency
        ? SamePriorityBehaviour.FIXED_BID
        : SamePriorityBehaviour.DEFAULT
    const hasTemplateTitle =
      this.props.isTemplateMode &&
      isTemplateTitle(
        this.props.campaign.title,
        this.props.campaign,
        this.props.assetTagInfo
      )
    const templateTitle = hasTemplateTitle
      ? renderedTitleToTemplateTitle(
          this.props.campaign.title,
          this.props.campaign,
          this.props.assetTagInfo
        )
      : undefined
    const state = {
      previousPrebidRawError: undefined,
      isSubmitting: false,
      isAlreadySubmitted: false,
      editedCampaign: cloneDeep(this.props.campaign),
      campaignsSubmittedStack: [],
      templateTitle: templateTitle,
      jsonError: undefined,
      form: {
        adSourceType: 'vastRedirect' as AdSourceType,
        newTrackingHook: {
          vastEvent: 'impression',
          trackingUrl: '',
        },
        selectedTargetingTypes: [] as string[],
        selectedTrackingTypes: [] as string[],
        samePriorityBehaviour,
        errors: {},
      },
      childComponentErrors: {
        capping: false,
      },
      bidderParams: {} as BidderParams,
    }
    if (
      this.props.campaign.asset.headerBiddingSource &&
      this.props.campaign.asset.headerBiddingSource!.bidderParamsJson
    ) {
      const cleanValue =
        this.props.campaign.asset.headerBiddingSource!.bidderParamsJson
      let parsedValue = {} as any
      try {
        parsedValue = JSON.parse(cleanValue)
      } catch {}
      state.bidderParams = {
        bidParamsJson: JSON.stringify(parsedValue.params, null, 4),
        bidAdapterType: parsedValue.bidder,
        bidAdapterAlias: parsedValue.alias,
      } as BidderParams
    }
    this.state = state
  }
  isDisabled() {
    return this.props.disabled || this.state.isSubmitting
  }
  allowUserToSkipValidation(newError: string | undefined) {
    const previousPrebidRawError = this.state.previousPrebidRawError

    if (this.state.jsonError) {
      this.setState({ previousPrebidRawError: this.state.jsonError })
      return this.state.jsonError
    }

    if (previousPrebidRawError !== newError) {
      this.setState({ previousPrebidRawError: newError })
      return newError
    }

    this.setState({ previousPrebidRawError: undefined })
    return undefined
  }
  render() {
    const isLoading = this.state.isSubmitting
    const renderedTitle = this.state.templateTitle
      ? templateTitleToRenderedTitle(
          this.state.templateTitle,
          this.state.editedCampaign,
          this.props.assetTagInfo
        )
      : undefined
    const titleDescription =
      renderedTitle ||
      this.state.editedCampaign.title ||
      this.props.campaign.title
    const isRestricted = this.props.campaign.restricted
    const isEdited =
      JSON.stringify(this.state.editedCampaign) !==
      JSON.stringify(this.props.campaign)
    const dialogTitle =
      this.props.dialogTitle +
      (titleDescription ? ` "${titleDescription}"` : '') +
      (isEdited ? '*' : '') +
      (isRestricted ? ' [restricted]' : '')
    const submitText = this.props.actionTitle
    const gap = 28
    return (
      <LargeDialogWithSidebar
        title={dialogTitle}
        largeButtons={true}
        isBlocking={isEdited}
        buttons={[
          ...(this.props.isTemplateMode &&
          this.state.campaignsSubmittedStack.length > 0
            ? [
                {
                  name: 'Undo previous',
                  action: () => {
                    this.undoPrevious()
                  },
                  disabled: isLoading,
                },
              ]
            : []),
          {
            name: this.state.isAlreadySubmitted ? 'Finish' : 'Cancel',
            action: () => {
              this.close()
            },
            disabled: isLoading,
          },
          {
            name: submitText,
            action: () => {
              this.props.isTemplateMode ? this.templateSubmit() : this.submit()
            },
            disabled: isLoading || this.props.isSubmitDisabled,
            primary: true,
          },
        ]}
        width={900}
        onClose={() => this.close()}
        sidebarWidth={170}
        categories={
          [
            {
              name: 'General',
              key: 'general',
              icon: 'FileASPX',
            },
            {
              name: 'Ad source',
              key: 'ad source',
              icon: 'Media',
            },
            this.shouldDisplayCreativesSection() && {
              name: 'Creative',
              key: 'creative',
              icon: 'Slider',
            },
            {
              name: 'Supply Chain',
              key: 'supply chain',
              icon: 'BranchMerge',
            },
            {
              name: 'Capping',
              key: 'capping',
              icon: 'MaximumValue',
            },
            {
              name: 'Targeting',
              key: 'targeting',
              icon: 'Bullseye',
            },
            {
              name: 'Tracking',
              key: 'tracking',
              icon: 'AreaChart',
            },
          ].filter(Boolean) as INavLink[]
        }
      >
        {[
          {
            title: 'General',
            content: this.getGeneralForm(gap),
          },
          {
            title: 'Ad Source',
            content: this.getSourceForm(gap),
          },
          this.shouldDisplayCreativesSection() && {
            title: 'Creative',
            content: this.getCreativeForm(gap),
          },
          {
            title: 'Supply Chain',
            content: this.getSupplyChainForm(gap),
          },
          {
            title: 'Capping',
            content: this.getCappingForm(gap),
          },
          {
            title: 'Targeting',
            content: this.getTargetingForm(gap),
          },
          {
            title: 'Tracking',
            content: this.getTrackingForm(gap),
          },
        ]
          .filter(Boolean)
          .map((item: any, index, a) => (
            <div key={index}>
              {index !== 0 && (
                <Separator styles={{ root: { margin: 32 } }}></Separator>
              )}
              <div>
                <h2
                  id={item.title.toLowerCase()}
                  style={{ marginTop: 0, marginBottom: 42 }}
                >
                  {item.title}
                </h2>
                {item.content}
              </div>
            </div>
          ))}
      </LargeDialogWithSidebar>
    )
  }
  getSourceForm(gap: number) {
    return (
      <SourceForm
        gap={gap}
        campaign={this.state.editedCampaign}
        disabled={this.isDisabled()}
        errorMessages={this.errorsBySuffix('source')}
        isAdminOrAccountManager={this.props.isAdminOrAccountManager}
        onCampaignAssetChange={(asset) =>
          this.setState((state) => ({
            editedCampaign: {
              ...state.editedCampaign,
              asset,
              overrideSkipBehavior: OverrideSkipBehavior.None,
            },
          }))
        }
        onRunContextChange={(runContext) =>
          this.setState((state) => ({
            editedCampaign: {
              ...state.editedCampaign,
              runContext,
            },
          }))
        }
        onPrebidChange={async (bidderParams) => {
          this.setState({
            bidderParams,
          })
        }}
        bidderParams={this.state.bidderParams}
        setJsonError={(error) => this.setState({ jsonError: error })} // Pass setJsonError prop
      />
    )
  }
  getSupplyChainForm(gap: number) {
    return (
      <SupplyChain
        gap={gap}
        disabled={this.isDisabled()}
        errors={this.errorsBySuffix('supplyChain')}
        thirdPartyReferenceChainId={
          this.state.editedCampaign.thirdPartyReferenceChainId
        }
        onChange={({ thirdPartyReferenceChainId }) => {
          this.setState({
            editedCampaign: {
              ...this.state.editedCampaign,
              thirdPartyReferenceChainId,
            },
          })
        }}
      />
    )
  }
  getCreativeForm(gap: number) {
    return (
      <CreativeForm
        gap={gap}
        assetType={getAssetType(this.state.editedCampaign.asset)}
        disabled={this.isDisabled()}
        errors={this.errorsBySuffix('creative')}
        onChange={({ adType, overrideSkipBehavior, fixedSkipOffset }) => {
          this.setState({
            editedCampaign: {
              ...this.state.editedCampaign,
              adType: adType || this.state.editedCampaign.adType,
              overrideSkipBehavior:
                overrideSkipBehavior ||
                this.state.editedCampaign.overrideSkipBehavior,
              fixedSkipOffset:
                fixedSkipOffset || this.state.editedCampaign.fixedSkipOffset,
            },
          })
        }}
        adType={this.state.editedCampaign.adType}
        fixedSkipOffset={this.state.editedCampaign.fixedSkipOffset || undefined}
        overrideSkipBehavior={
          this.state.editedCampaign.overrideSkipBehavior || undefined
        }
      />
    )
  }
  getGeneralForm(gap: number) {
    const tags = this.getAssetTags()
    const collectionOptions = this.props.campaignCollections
      ? this.props.campaignCollections.map((campaignCollection) => ({
          key: campaignCollection.id,
          text: campaignCollection.title,
        }))
      : [{ key: this.props.collectionId, text: this.props.collectionId }]
    return (
      <Stack gap={gap}>
        {!this.props.isTemplateMode && (
          <TextField
            label={'Title'}
            errorMessage={this.state.form.errors['title']}
            name={'title'}
            value={this.state.editedCampaign.title}
            autoComplete={'off'}
            spellCheck={false}
            onChange={(evt: any, value?: string) =>
              this.setState({
                editedCampaign: {
                  ...this.state.editedCampaign,
                  title: (value || '').slice(0, 100),
                },
              })
            }
            disabled={this.isDisabled()}
            required
          />
        )}
        {this.props.isTemplateMode && (
          <TemplateTitle
            campaign={this.state.editedCampaign}
            onChange={(templateTitle) => {
              this.setState({
                templateTitle,
              })
            }}
            templateTitle={this.state.templateTitle}
            assetTagInfo={this.props.assetTagInfo}
            disabled={this.isDisabled()}
            gap={gap}
          />
        )}
        {this.props.isTemplateMode && (
          <Dropdown
            label="Collection"
            selectedKey={this.state.editedCampaign.collectionId}
            options={collectionOptions}
            disabled={this.isDisabled()}
            onChange={(
              event: React.FormEvent<HTMLDivElement>,
              item: IDropdownOption | undefined
            ): void =>
              this.setState({
                editedCampaign: {
                  ...this.state.editedCampaign,
                  collectionId: item
                    ? String(item.key)
                    : this.state.editedCampaign.collectionId,
                },
              })
            }
          />
        )}
        <TagInput
          label="Targeted Assets"
          errorMessage={this.state.form.errors['targetedAssets']}
          pickerLabels={{
            noResults: 'Placement tags not found',
            suggestionsHeader: 'Placement tags',
          }}
          required={true}
          onResolveSuggestions={(filter, tagList): ITag[] => {
            const filterRank = (t: ITag) =>
              t.name.toLowerCase().indexOf(filter.toLowerCase())
            return tags
              .filter((t) => filterRank(t) !== -1)
              .filter(
                (t) =>
                  !(tagList || []).find(
                    (t2) => t2.key === removeIntegration(t).key
                  )
              )
              .sort((a, b) => filterRank(a) - filterRank(b))
          }}
          onChange={async (value?: ITag[]) =>
            this.setState({
              editedCampaign: {
                ...this.state.editedCampaign,
                targetedAssets: (value || [])
                  .map(removeIntegration)
                  .map((t) => t.key),
              },
            })
          }
          itemLimit={50}
          selectedItems={
            this.state.editedCampaign.targetedAssets
              .map((s) => tags.find((t) => t.key === s))
              .filter(Boolean) as ITag[]
          }
          disabled={this.isDisabled()}
        />
        <Stack horizontal>
          <NumberInput
            label={'Priority (lower is more important)'}
            errorMessage={this.state.form.errors['priority']}
            name={'priority'}
            value={this.state.editedCampaign.priority}
            style={{
              flexGrow: 1,
              maxWidth: `calc(50% - ${gap / 2}px)`,
            }}
            onChange={(value?: number) =>
              this.setState({
                editedCampaign: {
                  ...this.state.editedCampaign,
                  priority: value === undefined ? 5 : value!,
                },
              })
            }
            disabled={this.isDisabled()}
            required
            min={0}
            max={1e9}
            precision={5}
          />
          <div style={{ width: gap }} />
          <Dropdown
            label="Same Priority Behaviour"
            selectedKey={this.state.form.samePriorityBehaviour}
            onChange={(evt, value?) => {
              if (!value) {
                return
              }
              const samePriorityBehaviour = value.key as SamePriorityBehaviour
              if (
                this.state.form.samePriorityBehaviour === samePriorityBehaviour
              ) {
                return
              }
              this.setState({
                form: {
                  ...this.state.form,
                  samePriorityBehaviour,
                },
                editedCampaign: {
                  ...this.state.editedCampaign,
                  ...(samePriorityBehaviour === SamePriorityBehaviour.DEFAULT
                    ? {
                        fixedPrebidValue: null,
                        fixedPrebidCurrency: null,
                      }
                    : {
                        fixedPrebidCurrency: Currencies.EUR,
                      }),
                },
              })
            }}
            placeholder="Select an ad source type"
            required={true}
            options={[
              { key: SamePriorityBehaviour.DEFAULT, text: 'Random' },
              {
                key: SamePriorityBehaviour.FIXED_BID,
                text: 'Fixed Prebid Bid',
              },
            ]}
            disabled={this.isDisabled()}
            styles={{
              root: {
                flexGrow: 1,
                position: 'relative',
              },
            }}
          />
        </Stack>
        {this.state.form.samePriorityBehaviour ===
          SamePriorityBehaviour.FIXED_BID && this.getFixedBidForm(gap)}
        <Stack horizontal>
          <SimpleDateInput
            label="Start Date"
            required={true}
            disabled={this.isDisabled()}
            errorMessage={this.state.form.errors['startDate']}
            style={{ flexGrow: 1, maxWidth: `calc(50% - ${gap / 2}px)` }}
            value={this.state.editedCampaign.startDate}
            onChange={(value?) =>
              this.setState({
                editedCampaign: {
                  ...this.state.editedCampaign,
                  startDate: value!.toISOString(),
                },
              })
            }
            storeAs={'day-start'}
            min={this.state.editedCampaign.creationDate}
            max={this.state.editedCampaign.endDate || undefined}
          />
          <div style={{ width: gap }} />
          <SimpleDateInput
            label="End Date"
            disabled={this.isDisabled()}
            errorMessage={this.state.form.errors['endDate']}
            style={{ flexGrow: 1 }}
            value={this.state.editedCampaign.endDate || undefined}
            onChange={(value?) =>
              this.setState({
                editedCampaign: {
                  ...this.state.editedCampaign,
                  endDate: value ? value.toISOString() : null,
                },
              })
            }
            storeAs={'day-end'}
            min={Math.max(
              new Date(this.state.editedCampaign.startDate).getTime(),
              Date.now()
            )}
          />
        </Stack>
      </Stack>
    )
  }
  getFixedBidForm(gap: number) {
    return (
      <Stack horizontal>
        <NumberInput
          label={'Fixed Prebid Bid Value'}
          errorMessage={this.state.form.errors['fixedPrebid']}
          name={'priority'}
          value={this.state.editedCampaign.fixedPrebidValue || null}
          style={{
            flexGrow: 1,
            maxWidth: `calc(50% - ${gap / 2}px)`,
          }}
          onChange={(value?: number) =>
            this.setState({
              editedCampaign: {
                ...this.state.editedCampaign,
                fixedPrebidValue: value === undefined ? 0 : value!,
              },
            })
          }
          disabled={this.isDisabled()}
          required
          min={0}
          max={1e9}
          precision={5}
        />
        <div style={{ width: gap }} />
        <Dropdown
          label="Currency"
          selectedKey={this.state.editedCampaign.fixedPrebidCurrency}
          placeholder={'Select a currency'}
          onChange={(event, value?) => {
            if (!value) {
              return
            }
            const currency = value.key
            if (this.state.editedCampaign.fixedPrebidCurrency === currency) {
              return
            }
            this.setState({
              editedCampaign: {
                ...this.state.editedCampaign,
                fixedPrebidCurrency: value.key as Currencies,
              },
            })
          }}
          required={true}
          options={Object.keys(Currencies).map((currency) => ({
            key: currency,
            text: currency,
          }))}
          calloutProps={{ calloutMaxHeight: 300 }}
          disabled={this.isDisabled()}
          styles={{
            root: {
              flexGrow: 1,
              position: 'relative',
            },
          }}
        />
      </Stack>
    )
  }
  getCappingForm(gap: number) {
    return (
      <CappingForm
        gap={gap}
        campaign={this.state.editedCampaign}
        disabled={this.isDisabled()}
        errorMessages={this.errorsBySuffix('capping')}
        onChange={(args, hasError) =>
          this.setState({
            editedCampaign: { ...this.state.editedCampaign, ...args },
            childComponentErrors: {
              ...this.state.childComponentErrors,
              capping: hasError || false,
            },
          })
        }
      />
    )
  }
  getTargetingForm(gap: number) {
    return (
      <TargetingForm
        gap={gap}
        campaign={this.state.editedCampaign}
        disabled={this.isDisabled()}
        errorMessages={this.errorsBySuffix('targeting')}
        onChange={(args) =>
          this.setState({
            editedCampaign: { ...this.state.editedCampaign, ...args },
          })
        }
      />
    )
  }
  getTrackingForm(gap: number) {
    return (
      <TrackingForm
        gap={gap}
        campaign={this.state.editedCampaign}
        disabled={this.isDisabled() || this.props.campaign.restricted!}
        errorMessages={this.errorsBySuffix('tracking')}
        onChange={(trackingHooks) =>
          this.setState({
            editedCampaign: { ...this.state.editedCampaign, trackingHooks },
          })
        }
      />
    )
  }
  errorsBySuffix(
    suffix:
      | 'capping'
      | 'tracking'
      | 'targeting'
      | 'source'
      | 'creative'
      | 'supplyChain'
  ) {
    const fullSuffix = `${suffix}-`
    return Object.keys(this.state.form.errors)
      .filter((e) => e.indexOf(fullSuffix) === 0)
      .reduce(
        (ret, k) => ({
          ...ret,
          [k.replace(fullSuffix, '')]: this.state.form.errors[k],
        }),
        {}
      )
  }
  async normalize() {
    let renderedTitle = ''
    if (this.state.templateTitle) {
      renderedTitle = templateTitleToRenderedTitle(
        this.state.templateTitle,
        this.state.editedCampaign,
        this.props.assetTagInfo
      )
    }
    const editedCampaign = {
      ...this.state.editedCampaign,
      title: renderedTitle.trim() || this.state.editedCampaign.title.trim(),
      trackingHooks: this.state.editedCampaign.trackingHooks.filter(
        (h) => h.trackingUrl
      ),
      pathGlobs: this.state.editedCampaign.pathGlobs
        .map((p) => p.trim())
        .filter(Boolean),
      pathBlacklistGlobs: this.state.editedCampaign.pathBlacklistGlobs
        .map((p) => p.trim())
        .filter(Boolean),
    } as Campaign
    if (!editedCampaign.eventsPerUser) {
      editedCampaign.eventsPerUserInterval = null
    }
    if (!isCappingEvent(editedCampaign)) {
      editedCampaign.cappingEventType = 'impression'
    }
    if (!editedCampaign.timedEventLimit) {
      editedCampaign.timedEventInterval = null
    }
    if (editedCampaign.asset.teads) {
      const tagMatch = editedCampaign.asset.teads.scriptUrl.match(
        /(https?:)?(\/\/a\.teads\.tv\/page\/[0-9]+\/tag)(.*)/i
      )
      if (tagMatch) {
        editedCampaign.asset.teads.scriptUrl = `https:${tagMatch[2]}${tagMatch[3]}`
      }
    }
    if (editedCampaign.asset.headerBiddingSource) {
      const params = await parseSafeJsObject(
        this.state.bidderParams.bidParamsJson
      )
      const bidderParams = {
        bidder: this.state.bidderParams.bidAdapterType,
        alias: this.state.bidderParams.bidAdapterAlias,
        params,
      }
      editedCampaign.asset.headerBiddingSource.bidderParamsJson =
        JSON.stringify(bidderParams, null, 4)
    }
    editedCampaign.schedule = removeNullFields(editedCampaign.schedule)
    this.setState({ editedCampaign })
  }

  async validate() {
    this.clearErrors()
    const errors = {} as FormErrorSet
    const campaign = this.state.editedCampaign

    if (!campaign.title.trim().length) {
      errors['title'] = 'The title cannot be empty'
    }
    if (campaign.targetedAssets.length === 0) {
      errors['targetedAssets'] =
        'There should be at least one placement tag set as a target'
    }
    if (!campaign.priority) {
      errors['priority'] = 'The value should be greater than 0'
    }
    if (
      campaign.startDate !== this.props.campaign.startDate &&
      campaign.startDate < new Date(Date.now() - 24 * 60 * 60e3).toISOString()
    ) {
      errors['startDate'] = 'The start date cannot be modified to a past date'
    }

    if (campaign.endDate !== null && campaign.startDate > campaign.endDate) {
      errors['startDate'] = 'The start date cannot be set after the end date'
    }

    for (const vastEvent of VastEvents) {
      const hooks = campaign.trackingHooks.filter(
        (h) => vastEvent.key === h.vastEvent
      )
      if (hooks.some((h) => !isValidUrl(h.trackingUrl))) {
        errors[`tracking-${vastEvent.key}`] =
          'The tracking urls must be valid http(s) urls'
      }
    }
    const asset = campaign.asset
    if (
      asset.vastRedirect &&
      (!asset.vastRedirect.url || !isValidUrl(asset.vastRedirect.url))
    ) {
      errors['source-vastUrl'] = 'The vast url should be a valid http(s) url'
    }
    if (
      asset.staticVast &&
      (!asset.staticVast.content || !asset.staticVast.content.trim())
    ) {
      errors['source-staticVast'] = 'The static vast should not be empty'
    }
    if (asset.banner) {
      const domParser = new DOMParser()
      const doc = domParser.parseFromString(asset.banner.script, 'text/html')
      const childCount = doc.head.children.length + doc.body.children.length
      const isValidBannerScript = childCount > 0
      if (!isValidBannerScript) {
        errors['source-bannerScript'] =
          'The banner tags script must be a valid HTML snippet with at least one element'
      } else {
        const usesPassback = asset.banner.script.includes('passbackToCaroda')
        if (usesPassback && asset.banner.executionTiming === 'whenInView') {
          errors['source-bannerExecutionTiming'] =
            'passbackToCaroda() function cannot be used with "When in view" Script Execution Timing. Please remove the function or select "As soon as possible"'
        }
      }
    }
    if (asset.gamBanner) {
      if (!asset.gamBanner.slotId || !asset.gamBanner.slotId.trim()) {
        errors['source-gamSlotId'] = 'Slot ID is required'
      }
      if (
        asset.gamBanner.sizes.some(not(isValidGamBannerSize)) ||
        asset.gamBanner.sizes.length === 0
      ) {
        errors['source-gamSizes'] = 'At least one size is required'
      }
      if (asset.gamBanner.autoRefreshEverySeconds != null) {
        if (
          isNaN(asset.gamBanner.autoRefreshEverySeconds) ||
          asset.gamBanner.autoRefreshEverySeconds < 10 ||
          asset.gamBanner.autoRefreshEverySeconds > 120
        ) {
          errors['source-gamAutoRefreshEverySeconds'] =
            'Auto refresh must be between 10 and 120 seconds'
        }
      }
    }
    if (
      asset.teads &&
      !asset.teads.scriptUrl.match(
        /^(https?:)?\/\/a\.teads\.tv\/page\/[0-9]+\/tag(.*)$/
      )
    ) {
      errors['source-teads'] =
        "The script url doesn't seem to be a valid Teads.tv tag."
    }
    if (asset.aniview) {
      if (!asset.aniview.publisherId) {
        errors['source-aniview-publisherId'] = 'Publisher ID is required'
      }
      if (!asset.aniview.channelId) {
        errors['source-aniview-channelId'] = 'Channel ID is required'
      }
    }
    if (asset.smartclip && !asset.smartclip.campaignId.match(/^[0-9]+$/)) {
      errors['source-smartclip'] =
        'The campaign id must have a numerical value.'
    }
    if (
      asset.adformStaticTimed &&
      !asset.adformStaticTimed.bannerJsonUrl.match(
        /^(https?:)?\/\/adx\.adform\.net/i
      )
    ) {
      errors['source-adformStaticTimed'] =
        'The url must be a valid Adform static banner url.'
    }
    if (asset.headerBiddingSource) {
      await this.validateHeaderBidding(errors)
    }
    if (asset.customVast3) {
      this.validateCustomVast3(errors)
    }
    if (
      (typeof campaign.fixedPrebidValue === 'number' &&
        campaign.fixedPrebidValue > 0) !== !!campaign.fixedPrebidCurrency
    ) {
      errors['fixedPrebid'] = 'Both prebid value and currency must be set.'
    }
    if (asset.seznamOpenRtb) {
      this.validateSeznamOpenRtb(errors)
    }
    this.setState({ form: { ...this.state.form, errors } })
    const ret =
      Object.keys(errors).length === 0 &&
      !Object.values(this.state.childComponentErrors).some(Boolean)
    scrollIntoFirstError()
    return ret
  }

  validateJson(errors: { [k: string]: string }) {
    if (this.state.jsonError) {
      errors['jsonError'] = this.state.jsonError
      scrollIntoFirstError()
      return false
    }
    return true
  }

  async validateHeaderBidding(errors: { [k: string]: string }) {
    const asset = this.state.editedCampaign.asset

    if (!asset.headerBiddingSource) {
      return
    }

    const hbObject = await parseSafeJsObject(
      asset.headerBiddingSource.bidderParamsJson
    )

    if (!hbObject) {
      errors['source-prebidRaw'] = 'Ad unit must be a valid JavaScript object.'
      errors['source-prebidRawType'] = 'Bid adapter type cannot be empty.'
      errors['source-prebidRawAdapterAlias'] = '...'
      return
    }

    if (!hbObject.bidder) {
      errors['source-prebidRaw'] = 'Ad unit must have a "bidder" field.'
      errors['source-prebidRawType'] = 'Bid adapter type cannot be empty.'
      errors['source-prebidRawAdapterAlias'] = '...'
      return
    }

    const allHbParams = await getAllPrebidParams()
    const hbParams = allHbParams.find((b) => b.bidderCode === hbObject.bidder)

    if (!hbParams) {
      errors['source-prebidRaw'] =
        `Bidder with code "${hbObject.bidder}" not supported.`
      return
    }

    const isOptionalParameter = this.filterOptionalParameters(
      hbParams.bidderName,
      hbObject.params
    )
    const requiredParams = hbParams.params.filter(
      (p) => p.required && !isOptionalParameter(p)
    )

    const validationErrors: string[] = []

    for (const field of requiredParams) {
      const params = hbObject.params || {}
      const value = params[field.name]
      let expectedType = field.type

      if (
        hbObject.bidder === 'criteo' ||
        hbObject.bidder === 'etarget' ||
        hbObject.bidder === 'oftmedia'
      ) {
        continue // Skip further validation for criteo, etarget and oftmedia bidders as the data is incorrect in the bidders.json
      }

      if (isNil(value)) {
        validationErrors.push(
          `${hbObject.bidder} bidder requires "params.${field.name}" to be present.`
        )
      } else if (!isValidType(value, expectedType)) {
        validationErrors.push(
          `${hbObject.bidder} bidder requires "params.${field.name}" to be of type ${expectedType}.`
        )
      }
    }

    if (validationErrors.length > 0) {
      const error = validationErrors.join('; ')
      if (this.allowUserToSkipValidation(error)) {
        errors['source-prebidRaw'] = error
      } else {
        this.clearErrors()
      }
    }

    this.validateSpecificBidders(hbObject, hbParams, errors)

    if (typeof asset.headerBiddingSource.prebidMinimumPriceFloor !== 'number') {
      errors['source-prebidMinimumPriceFloor'] = 'Price floor must be a number'
      return
    }

    if (asset.headerBiddingSource.prebidMinimumPriceFloor < 0) {
      errors['source-prebidMinimumPriceFloor'] =
        "Price floor can't be less than 0"
      return
    }

    if (!this.state.editedCampaign.adType) {
      errors['creative-adType'] = 'Ad type must be selected'
      return
    }
  }

  validateSpecificBidders(
    hbObject: any,
    hbParams: PrebidBidderDescriptor | undefined,
    errors: { [k: string]: string }
  ) {
    const bidderCode = hbParams ? hbParams.bidderCode : ''
    switch (bidderCode) {
      case 'appnexus':
        this.validateAppNexus(hbObject, errors)
        break
      case 'adbookpsp':
        this.validateAdbookpsp(hbObject, errors)
        break
      case 'criteo':
        this.validateCriteo(hbObject, errors)
        break
      case 'ix':
        this.validateIx(hbObject, errors)
        break
      case 'adf':
        this.validateAdf(hbObject, errors)
        break
      case 'etarget':
        this.validateEtarget(hbObject, errors)
        break
      case 'oftmedia':
        this.validateOftmedia(hbObject, errors)
        break
    }
  }

  validateAppNexus(hbObject: any, errors: { [k: string]: string }) {
    const params = hbObject.params || {}
    const { placementId, placement_id } = params

    if (!placementId && !placement_id) {
      const error =
        'appnexus bidder requires either "params.placementId" or "params.placement_id" parameter'
      if (this.allowUserToSkipValidation(error)) {
        errors['source-prebidRaw'] = error
      }
      return
    }

    if (placementId && placement_id) {
      const error =
        'appnexus bidder requires either "params.placementId" or "params.placement_id" parameter, but not both.'
      if (this.allowUserToSkipValidation(error)) {
        errors['source-prebidRaw'] = error
      }
      return
    }

    if (placementId && !isValidType(placementId, 'integer')) {
      const error =
        'appnexus bidder requires "params.placementId" to be an integer'
      if (this.allowUserToSkipValidation(error)) {
        errors['source-prebidRaw'] = error
      }
      return
    }

    if (placement_id && !isValidType(placement_id, 'integer')) {
      const error =
        'appnexus bidder requires "params.placement_id" to be an integer'
      if (this.allowUserToSkipValidation(error)) {
        errors['source-prebidRaw'] = error
      }
      return
    }
  }

  validateAdbookpsp(hbObject: any, errors: { [k: string]: string }) {
    const params = hbObject.params || {}
    const { orgId, placementId } = params

    if (!orgId && !placementId) {
      const error =
        'adbookpsp bidder requires either "params.orgId" or "params.placementId" parameter'
      if (this.allowUserToSkipValidation(error)) {
        errors['source-prebidRaw'] = error
      }
      return
    }

    if (orgId && placementId) {
      const error =
        'adbookpsp bidder requires either "params.orgId" or "params.placementId" parameter, but not both.'
      if (this.allowUserToSkipValidation(error)) {
        errors['source-prebidRaw'] = error
      }
      return
    }

    if (orgId && !isValidType(orgId, 'string')) {
      const error = 'adbookpsp bidder requires "params.orgId" to be a string'
      if (this.allowUserToSkipValidation(error)) {
        errors['source-prebidRaw'] = error
      }
      return
    }

    if (placementId && !isValidType(placementId, 'string')) {
      const error =
        'adbookpsp bidder requires "params.placementId" to be a string'
      if (this.allowUserToSkipValidation(error)) {
        errors['source-prebidRaw'] = error
      }
      return
    }
  }

  validateCriteo(hbObject: any, errors: { [k: string]: string }) {
    const params = hbObject.params || {}
    const { zoneId, networkId } = params

    if (zoneId && networkId) {
      const error =
        'criteo bidder requires either "params.zoneId" or "params.networkId" parameter, but not both.'
      if (this.allowUserToSkipValidation(error)) {
        errors['source-prebidRaw'] = error
      }
      return
    }

    if (!zoneId && !networkId) {
      const error =
        'criteo bidder requires either "params.zoneId" (deprecated) or "params.networkId" parameter to be present'
      if (this.allowUserToSkipValidation(error)) {
        errors['source-prebidRaw'] = error
      }
      return
    }

    if (zoneId && !isValidType(zoneId, 'integer')) {
      const error = 'criteo bidder requires "params.zoneId" to be an integer'
      if (this.allowUserToSkipValidation(error)) {
        errors['source-prebidRaw'] = error
      }
      return
    }

    if (networkId && !isValidType(networkId, 'integer')) {
      const error = 'criteo bidder requires "params.networkId" to be an integer'
      if (this.allowUserToSkipValidation(error)) {
        errors['source-prebidRaw'] = error
      }
      return
    }
  }

  validateIx(hbObject: any, errors: { [k: string]: string }) {
    const params = hbObject.params || {}
    const siteId = params.siteId

    if (!siteId) {
      const error = 'ix bidder requires "params.siteId" parameter'
      if (this.allowUserToSkipValidation(error)) {
        errors['source-prebidRaw'] = error
      }
      return
    }

    if (!isValidType(siteId, 'string')) {
      const error = 'ix bidder requires "params.siteId" to be a string'
      if (this.allowUserToSkipValidation(error)) {
        errors['source-prebidRaw'] = error
      }
      return
    }
  }

  validateAdf(hbObject: any, errors: { [k: string]: string }) {
    const params = hbObject.params || {}
    const { mid, inv, mname } = params

    if (!mid && !(inv && mname)) {
      const error =
        'adf bidder requires "params.mid" or ("params.inv" and "params.mname") parameters'
      if (this.allowUserToSkipValidation(error)) {
        errors['source-prebidRaw'] = error
      }
      return
    }

    if (inv && !isValidType(inv, 'integer')) {
      const error = 'adf bidder requires "params.inv" to be an integer'
      if (this.allowUserToSkipValidation(error)) {
        errors['source-prebidRaw'] = error
      }
      return
    }

    if (mname && !isValidType(mname, 'string')) {
      const error = 'adf bidder requires "params.mname" to be a string'
      if (this.allowUserToSkipValidation(error)) {
        errors['source-prebidRaw'] = error
      }
      return
    }

    if (mid && !isValidType(mid, 'integer')) {
      const error = 'adf bidder requires "params.mid" to be an integer'
      if (this.allowUserToSkipValidation(error)) {
        errors['source-prebidRaw'] = error
      }
      return
    }
  }

  validateEtarget(hbObject: any, errors: { [k: string]: string }) {
    const params = hbObject.params || {}
    const { refid, country } = params

    if (!refid || !country) {
      const error =
        'etarget bidder requires "params.refid" and "params.country" parameter'
      if (this.allowUserToSkipValidation(error)) {
        errors['source-prebidRaw'] = error
      }
      return
    }

    if (refid && !isValidType(refid, 'integer')) {
      const error = 'etarget bidder requires "params.refid" to be an integer'
      if (this.allowUserToSkipValidation(error)) {
        errors['source-prebidRaw'] = error
      }
      return
    }

    if (country && !isValidType(country, 'integer')) {
      const error = 'etarget bidder requires "params.country" to be an integer'
      if (this.allowUserToSkipValidation(error)) {
        errors['source-prebidRaw'] = error
      }
      return
    }
  }

  validateOftmedia(hbObject: any, errors: { [k: string]: string }) {
    const params = hbObject.params || {}
    const { placementId } = params

    if (!placementId) {
      const error = 'oftmedia bidder requires "params.placementId" parameter'
      if (this.allowUserToSkipValidation(error)) {
        errors['source-prebidRaw'] = error
      }
      return
    }

    if (placementId && !isValidType(placementId, 'string')) {
      const error =
        'oftmedia bidder requires "params.placementId" to be a string'
      if (this.allowUserToSkipValidation(error)) {
        errors['source-prebidRaw'] = error
      }
      return
    }
  }

  filterOptionalParameters(bidderName: string, params: any) {
    if (bidderName === 'smartx') {
      return (p: PrebidParamDescriptor) => p.name === 'outstream_options'
    }
    return () => false
  }

  validateCustomVast3(errors: { [k: string]: string }) {
    const asset = this.state.editedCampaign.asset.customVast3
    if (!asset) {
      return
    }
    if (asset.videoId === '') {
      errors['source-customVast3File'] = 'A video ad asset is required'
    }
    if (asset.videoId === 'au' && asset.videoName === '') {
      errors['source-customVast3AudioFile'] = 'An audio ad asset is required'
    }
    if (asset.videoId.match(/au$/) && !asset.audioPosterName) {
      errors['source-customVast3AudioPoster'] = 'An image ad asset is required'
    }
    if (!isValidUrl(asset.landingPageUrl)) {
      errors['source-customVast3LandingPage'] =
        'A valid landing page is required'
    }
  }
  validateSeznamOpenRtb(errors: { [k: string]: string }) {
    const asset = this.state.editedCampaign.asset.seznamOpenRtb
    if (!asset) {
      return
    }
    if (!asset.tagId) {
      errors['source-seznamOpenRtbTagId'] = 'Tag id is required'
    }
    if (asset.priceFloor < 0) {
      errors['source-seznamOpenRtbPriceFloor'] =
        "Price floor can't be less than 0"
    }
    if (!this.state.editedCampaign.adType) {
      errors['creative-adType'] = 'Ad type must be selected'
      return
    }
  }
  clearErrors() {
    this.setState({
      form: {
        ...this.state.form,
        errors: {},
      },
    })
  }
  async submit() {
    const submitCallback = async () => {
      await this.props.onUpdated(this.state.editedCampaign)
    }
    await this.commonSubmit(submitCallback)
  }

  async templateSubmit() {
    const templateCallback = async () => {
      const postProcess = this.getPostProcessingFn()
      this.props.onUpdated(this.state.editedCampaign).then(postProcess)
      this.setState({ isAlreadySubmitted: true })
      if (
        !isTemplateTitle(
          this.state.editedCampaign.title,
          this.state.editedCampaign,
          this.props.assetTagInfo
        )
      ) {
        this.setState({
          editedCampaign: {
            ...this.state.editedCampaign,
            title: getNextTitle(this.state.editedCampaign.title),
          },
        })
      }
    }
    this.commonSubmit(templateCallback)
  }

  async commonSubmit(callback: () => void) {
    this.setState({ isSubmitting: true })
    await this.normalize()
    const errors: { [k: string]: string } = {}
    const isJsonValid = this.validateJson(errors)
    if (!isJsonValid) {
      this.setState({
        isSubmitting: false,
      })
      return
    }
    const isValid = await this.validate()
    if (!isValid) {
      this.setState({
        isSubmitting: false,
      })
      return
    }

    await callback()
    this.setState({ isSubmitting: false })
  }

  getPostProcessingFn = () => {
    const campaign = cloneDeep(this.state.editedCampaign)
    return (campaignId: string) => {
      campaign.id = campaignId
      const campaignsSubmittedStack = [
        ...this.state.campaignsSubmittedStack,
        campaign,
      ]
      this.setState({ campaignsSubmittedStack: campaignsSubmittedStack })
    }
  }

  async undoPrevious() {
    const campaignsCopy = [...this.state.campaignsSubmittedStack]
    const campaignToDelete = campaignsCopy.pop()
    this.setState({ campaignsSubmittedStack: campaignsCopy })
    this.setState({ isSubmitting: true })
    if (!campaignToDelete) {
      this.setState({ isSubmitting: false })
      return
    }
    deleteCampaign(campaignToDelete.collectionId, campaignToDelete.id).then(
      () => {
        if (this.props.onUndo) {
          this.props.onUndo()
        }
      }
    )
    toast.info(`Campaign ${campaignToDelete.title} was deleted`)
    this.setState({ editedCampaign: campaignToDelete })
    this.setState({ isSubmitting: false })
  }
  close() {
    this.props.onClose()
  }
  private getAssetTags(): ITag[] {
    const res = this.props.assetTagInfo
    const transformTag = (
      tag: string,
      asset: { integration: string; title: string },
      collection: { id: string; title: string }
    ) => {
      if (tag.startsWith('opz') || tag.startsWith('ipz')) {
        return [
          {
            key: `${collection.id}/${tag}`,
            name: `${asset.integration}/${asset.title}`,
          },
          {
            // Different keys are required as keys should be unique
            key: `${collection.id}/${asset.integration}/${tag}`,
            name: `${collection.title}/${asset.integration}/${asset.title}`,
          },
        ]
      } else {
        return [
          {
            key: `${collection.id}/${tag}`,
            name: `${collection.title}/${tag}`,
          },
        ]
      }
    }

    const outstreamCollectionTags: ITag[] =
      res.outstreamPlacementCollections_v2!.map((collection) => ({
        key: `${collection.id}/*`,
        name: `${collection.title}/*`,
      }))
    const outstreamTags: ITag[] = res.outstreamPlacementCollections_v2!.flatMap(
      (collection) =>
        collection.assets.flatMap((asset) =>
          asset.tags.flatMap((tag) => transformTag(tag, asset, collection))
        )
    )
    const instreamCollectionTags: ITag[] =
      res.instreamPlacementCollections_v2!.map((collection) => ({
        key: `${collection.id}/*`,
        name: `${collection.title}/*`,
      }))
    const instreamTags: ITag[] = res.instreamPlacementCollections_v2!.flatMap(
      (collection) =>
        collection.assets.flatMap((asset) =>
          asset.tags.flatMap((tag) => transformTag(tag, asset, collection))
        )
    )
    const partnershipTags =
      this.props.assetTagInfo.publisherPartnerships_v2.reduce(
        (ret, p) => [
          ...ret,
          ...p.sharedAssetTags.map((t) => ({
            key: `${t.collectionId}/${t.tagName}`,
            name: `${t.collectionTitle}/${t.tagName}`,
          })),
        ],
        [] as ITag[]
      )
    const assetTags = [
      ...outstreamCollectionTags,
      ...outstreamTags,
      ...instreamCollectionTags,
      ...instreamTags,
      ...partnershipTags,
    ]
    return uniqBy(assetTags, ({ key, name }) => `${key}/${name}`).sort(sortTags)
  }

  shouldDisplayCreativesSection(): boolean {
    return [
      'headerBiddingSource',
      'seznamOpenRtb',
      'staticVast',
      'vastRedirect',
    ].includes(getAssetType(this.state.editedCampaign.asset) || '')
  }
}

function getAssetType(asset: CampaignAssetInfo): AssetType | undefined {
  const entries = Object.entries(asset)
  const firstNonNullEntry = entries.find(([key, value]) => value !== null)
  const firstNonNullKey = firstNonNullEntry ? firstNonNullEntry[0] : undefined
  return (firstNonNullKey as AssetType) || undefined
}

function removeIntegration(tag: ITag): ITag {
  const containsIntegrationLevel = (key: string) => key.split('/').length > 2
  const removeIntegrationLevel = (key: string) => {
    const chunks = key.split('/')
    return [chunks[0], ...chunks.slice(2)].join('/')
  }
  return {
    ...tag,
    key: containsIntegrationLevel(tag.key)
      ? removeIntegrationLevel(tag.key)
      : tag.key,
  }
}

function sortTags(tagA: ITag, tagB: ITag): number {
  return tagA.name.localeCompare(tagB.name)
}

function isCappingEvent(campaign: Campaign): boolean {
  return (
    !!campaign.eventLimit ||
    !!campaign.eventsPerUser ||
    !!campaign.timedEventLimit
  )
}

function isValidType(value: any, expectedType: FieldType | undefined): boolean {
  const valueType = typeof value
  const normalizedExpectedType: FieldType | undefined = expectedType
    ? (expectedType.replace(/^'|'$/g, '').toLowerCase() as FieldType)
    : undefined

  switch (normalizedExpectedType) {
    case 'integer':
    case 'int':
      return valueType === 'number' && Number.isInteger(value)
    case 'array':
      return Array.isArray(value)
    case 'uint64':
      return (
        valueType === 'number' &&
        Number.isInteger(value) &&
        value >= 0 &&
        value <= Number.MAX_SAFE_INTEGER
      )
    case 'float':
      return (
        valueType === 'number' &&
        !Number.isNaN(value) &&
        !Number.isInteger(value)
      )
    case 'array<integers>':
      return (
        Array.isArray(value) &&
        value.every(
          (item: any) => typeof item === 'number' && Number.isInteger(item)
        )
      )
    case 'object':
      return valueType === 'object' && !Array.isArray(value)
    default:
      console.log(
        `Debug: Value: ${value}, ValueType: ${valueType}, NormalizedExpectedType: ${normalizedExpectedType}`
      )
      return valueType === normalizedExpectedType
  }
}
