import { request } from '../Api/xhr'

export interface PrebidParamDescriptor {
  name: string
  required: boolean
  type: 'string' | 'native'
  example?: string
  description?: string
}

export interface PrebidBidderDescriptor {
  bidderName: string
  bidderCode: string
  params: PrebidParamDescriptor[]
}

const CustomBidders: PrebidBidderDescriptor[] = [
  {
    bidderName: 'R2B2',
    bidderCode: 'r2b2',
    params: [
      {
        name: 'd',
        required: true,
        type: 'string',
        description: 'The target domain for the bidder',
      },
      {
        name: 'g',
        required: true,
        type: 'string',
        example: 'hb',
      },
      {
        name: 'p',
        required: true,
        type: 'string',
        example: 'video',
      },
      {
        name: 'm',
        required: true,
        type: 'native',
        example: '0',
      },
    ],
  },
  {
    bidderCode: 'oftmediahb',
    bidderName: '152MediaHB',
    params: [
      {
        name: 'host',
        required: true,
        type: 'string',
        example: "'cpm.152media.com'",
      },
      {
        name: 'zoneId',
        required: true,
        type: 'string',
        example: "'30164'",
      },
    ],
  },
]

async function getDefaultBidders(): Promise<PrebidBidderDescriptor[]> {
  const cachedBidders = (global as any)
    .defaultBidders as PrebidBidderDescriptor[]
  if (cachedBidders) {
    return cachedBidders
  }
  const raw = await request((xhr) => {
    xhr.open('GET', `${process.env.PUBLIC_URL}/bidders.json`)
    xhr.send()
  })
  const bidders = JSON.parse(raw) as any[]
  patchCriteoZoneId(bidders)
  setParamsWithSpaceAsOptional(bidders)
  Object.assign(global, { defaultBidders: bidders })
  return bidders as PrebidBidderDescriptor[]
}

function patchCriteoZoneId(bidders: any[]) {
  const criteoBidder = bidders.find(({ bidderCode }) => bidderCode === 'criteo')
  const criteoBidderZoneIdParam = criteoBidder.params.find(
    (p: any) => p.name === 'zoneId'
  )
  if (criteoBidderZoneIdParam) {
    criteoBidderZoneIdParam.required = false
  }
  const criteoBidderNetworkIdParam = criteoBidder.params.find(
    (p: any) => p.name === 'networkId'
  )
  if (criteoBidderNetworkIdParam) {
    criteoBidderNetworkIdParam.required = false
  }
}

function setParamsWithSpaceAsOptional(bidders: any[]) {
  bidders.forEach((bidder) => {
    const paramsWithSpace = bidder.params.filter((param: any) =>
      /\s/.test(param.name)
    )
    paramsWithSpace.forEach((param: any) => (param.required = false))
  })
}

export async function getAllPrebidParams(): Promise<PrebidBidderDescriptor[]> {
  return [...CustomBidders, ...(await getDefaultBidders())]
}
