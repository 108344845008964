import React from 'react'
import { TextPromptDialog } from './TextPromptDialog'
import { createOutstreamPlacementCollection } from '../Api/outstreamPlacements_v2'
import { createInstreamPlacementCollection } from '../Api/instreamPlacements_v2'
import { get, capitalize } from 'lodash'
import { SessionContext } from '../Contexts/SessionContext'

interface State {
  isSubmitting: boolean
  pendingNameChange?: string
  hasError: boolean
}

interface InputProps {
  type: 'instream' | 'outstream'
  onClose: () => void
  onUpdated: () => void
}

export class CreatePlacementCollectionDialog extends React.Component<
  InputProps,
  State
> {
  static contextType = SessionContext
  context!: React.ContextType<typeof SessionContext>

  constructor(props: any) {
    super(props)
    this.state = {
      isSubmitting: false,
      pendingNameChange: undefined,
      hasError: false,
    }
  }
  render() {
    return (
      <TextPromptDialog
        dialogTitle={`Create ${capitalize(this.props.type)} Placement Collection`}
        fieldTitle="Title"
        confirmButtonText="Create"
        isLoading={this.state.isSubmitting}
        onUpdated={() => this.setState({ hasError: false })}
        onSubmit={(value) => this.submit(value)}
        onClose={this.props.onClose}
        errorMessage={
          this.state.hasError
            ? 'Could not create collection. If the problem persist, please contact support.'
            : undefined
        }
      />
    )
  }
  submit(title: string) {
    this.setState({ pendingNameChange: title, isSubmitting: true })
    let videoCollectionId = ''
    let createPlacementCollection
    let collectionsName: string
    if (this.props.type === 'outstream') {
      createPlacementCollection = createOutstreamPlacementCollection
      collectionsName = 'outstreamPlacementCollections_v2'
    } else {
      createPlacementCollection = createInstreamPlacementCollection
      collectionsName = 'instreamPlacementCollections_v2'
    }
    createPlacementCollection(title)
      .then((ret) => {
        videoCollectionId = get(ret, `data.${collectionsName}.create`, '')
      })
      .then(this.props.onUpdated)
      .then(() => {
        this.props.onClose()
        window.location.href = `${process.env.PUBLIC_URL}/#/${this.context.ctok}/${this.props.type}-placements_v2/${videoCollectionId}`
      })
      .catch((e) => {
        console.error(e)
        this.setState({ hasError: true, isSubmitting: false })
      })
    return false
  }
}
