import React from 'react'
import AceEditor from 'react-ace'
import { SharedColors } from '@uifabric/fluent-theme'
import { Label } from 'office-ui-fabric-react'

import 'ace-builds/src-noconflict/mode-xml'
import 'ace-builds/src-noconflict/theme-textmate'

interface VastEditorProps {
  error: string
  value: string
  onChange: (vastContent: string) => void
}

interface VastEditorState {
  isFocused: boolean
}

export class VastEditor extends React.Component<
  VastEditorProps,
  VastEditorState
> {
  constructor(props: VastEditorProps) {
    super(props)
    this.state = {
      isFocused: false,
    }
  }
  render() {
    let aceEditorBorder: string
    if (!!this.props.error) {
      aceEditorBorder = `1px solid ${SharedColors.red10}`
    } else if (!this.state.isFocused) {
      aceEditorBorder = `1px solid ${SharedColors.gray10}`
    } else {
      aceEditorBorder = `1px solid ${SharedColors.blue10}`
    }
    return (
      <>
        <Label required htmlFor="vast-editor">
          Static VAST Content
        </Label>
        <AceEditor
          mode="xml"
          theme="textmate"
          value={this.props.value}
          onChange={(value: string = '') => {
            this.props.onChange(value)
          }}
          name="vast-editor"
          width="100%"
          showPrintMargin={false}
          editorProps={{
            id: 'vast-editor',
          }}
          style={{
            border: aceEditorBorder,
            borderRadius: '2px',
            padding: '6px',
          }}
          wrapEnabled={true}
          onLoad={(editor) => {
            editor.renderer.setPadding(6)
            editor.renderer.setScrollMargin(6, 6, 0, 0)
          }}
          onFocus={() => this.setState({ isFocused: true })}
          onBlur={() => this.setState({ isFocused: false })}
        />
        <Label
          htmlFor="vast-editor"
          styles={{
            root: {
              color: SharedColors.red10,
              fontSize: '12px',
              fontWeight: 'normal',
            },
          }}
        >
          {this.props.error ? this.props.error : <span>&nbsp;</span>}
        </Label>
      </>
    )
  }
}

export default VastEditor
