import React from 'react'
import { ConfirmationDialog } from '../../Components/ConfirmationDialog'
import { ChildProps, graphql } from 'react-apollo'
import {
  getAllCampaignCollectionSummariesQuery,
  CampaignCollectionSummaryResponse,
  deleteCampaignCollection,
} from '../../Api/campaigns'
import { get } from 'lodash'
import { SessionContext } from '../../Contexts/SessionContext'

interface State {
  isSubmitting: boolean
  hasError: boolean
}

interface InputProps {
  collectionId: string
  onClose: () => void
  onUpdated: () => void
}

class DeleteCampaignsCollectionDialogView extends React.Component<
  ChildProps<InputProps, CampaignCollectionSummaryResponse>,
  State
> {
  static contextType = SessionContext
  context!: React.ContextType<typeof SessionContext>

  constructor(props: any) {
    super(props)
    this.state = {
      isSubmitting: false,
      hasError: false,
    }
  }
  render() {
    if (!this.getCollection()) {
      return <React.Fragment />
    }
    return (
      <ConfirmationDialog
        dialogTitle="Delete Campaign Collection"
        confirmationText={`Are you sure you want to delete campaign collection "${this.getCollection()!.title}"?`}
        errorMessage={
          this.state.hasError
            ? 'Could not delete collection. If the problem persist, please contact support.'
            : undefined
        }
        yesButtonText="Yes"
        noButtonText="No"
        isLoading={this.state.isSubmitting}
        onCancel={this.props.onClose}
        onConfirm={() => this.submit()}
      />
    )
  }
  submit() {
    this.setState({ isSubmitting: true })
    deleteCampaignCollection(this.props.collectionId)
      .then(this.props.onUpdated)
      .then(() => {
        window.location.href = `${process.env.PUBLIC_URL}/#/${this.context.ctok}/campaigns/#/nav/campaigns/open`
        this.props.onClose()
      })
      .catch((e) => {
        console.error(e)
        this.setState({ isSubmitting: false, hasError: true })
      })
    return false
  }
  getCollection() {
    return get(this.props.data!, 'campaignCollections', []).find(
      (c) => c.id === this.props.collectionId
    )
  }
}

const withGql = graphql<InputProps, CampaignCollectionSummaryResponse>(
  getAllCampaignCollectionSummariesQuery,
  { options: () => ({ notifyOnNetworkStatusChange: true }) }
)
export const DeleteCampaignsCollectionDialog = withGql(
  DeleteCampaignsCollectionDialogView
)
