import { Icon, Stack, Text } from 'office-ui-fabric-react'
import React, { useState } from 'react'
import { DataValue, graphql } from 'react-apollo'
import {
  createIntegration,
  createIntegrations,
  CreateMultipleIntegrationError,
  deleteIntegration,
  getIntegrations,
  getIntegrationsQuery,
  GetIntegrationsQueryResponse,
  Integration,
  updateIntegration,
} from '../../Api/integrations'
import { ConfirmationDialog } from '../../Components/ConfirmationDialog'
import { ScreenHeader } from '../../Components/ScreenHeader'
import { CreateMultipleDialog } from './CreateMultipleDialog'
import { EditIntegrationDialog } from './EditIntegrationDialog'
import { IntegrationsTable } from './IntegrationsTable'
import { Seller } from '../../Api/schain'
import { isAdmin } from '../../Api/session'

enum DialogType {
  Edit,
  Create,
  CreateMultiple,
  ConfirmDelete,
}

interface Dialog {
  type: DialogType
  integration?: Integration
}

function IntegrationsScreen({
  data,
}: {
  data?: DataValue<GetIntegrationsQueryResponse, {}>
}) {
  const integrations = data && data.integrations ? data.integrations : []
  const sellers = data && data.sellers ? data.sellers : []
  const [dialog, setDialog] = useState<Dialog | null>(null)
  if (!data || data.loading) {
    return <React.Fragment />
  }
  return (
    <>
      <Stack
        className="screen"
        horizontalAlign="start"
        verticalAlign="start"
        verticalFill
        style={{ width: '100%' }}
      >
        <ScreenHeader
          path={['Integrations']}
          buttons={[
            {
              name: 'Create Integration',
              icon: 'Add',
              disabled: false,
              onClick: () => {
                setDialog({ type: DialogType.Create })
              },
            },
            {
              name: 'Create Multiple',
              icon: 'BuildQueueNew',
              disabled: false,
              onClick: () => {
                setDialog({ type: DialogType.CreateMultiple })
              },
            },
          ]}
        />
        <Stack
          className="integrations-screen"
          horizontalAlign="center"
          verticalAlign="start"
          horizontal
          wrap
          styles={{
            root: {
              width: '100%',
              overflow: 'auto',
            },
            inner: {
              padding: 60,
            },
          }}
        >
          {!integrations.length && <Empty />}
          {integrations.length && (
            <IntegrationsTable
              integrations={integrations}
              onEdit={(integration: Integration) => {
                setDialog({ type: DialogType.Edit, integration })
              }}
              onDelete={async (integration: Integration) => {
                setDialog({ type: DialogType.ConfirmDelete, integration })
              }}
            />
          )}
        </Stack>
      </Stack>
      {dialog &&
        (dialog.type === DialogType.Create ||
          dialog.type === DialogType.Edit) && (
          <CreateOrEditDialog
            editIntegration={dialog.integration}
            onClose={() => {
              setDialog(null)
            }}
            sellers={sellers}
            isAdmin={isAdmin(data.session!)}
          />
        )}
      {dialog && dialog.type === DialogType.CreateMultiple && (
        <CreateMultipleDialogHOC
          onClose={() => {
            setDialog(null)
          }}
        />
      )}
      {dialog && dialog.type === DialogType.ConfirmDelete && (
        <ConfirmDelete
          integration={dialog.integration!}
          onClose={() => {
            setDialog(null)
          }}
        />
      )}
    </>
  )
}

const withGql = graphql<{}, GetIntegrationsQueryResponse>(
  getIntegrationsQuery,
  {
    options: () => ({
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
    }),
  }
)
const IntegrationsScreenWithGql = withGql(IntegrationsScreen)

export { IntegrationsScreenWithGql as IntegrationsScreen }

function Empty() {
  return (
    <Stack
      horizontalAlign="center"
      verticalAlign="center"
      verticalFill
      style={{ width: '100%' }}
    >
      <Text
        variant="xLarge"
        style={{
          maxWidth: 600,
          padding: 20,
          textAlign: 'center',
        }}
      >
        You have no integrations configured yet. You can add an integration by
        pressing the
        <i>Create integration</i>{' '}
        <Icon iconName="Add" style={{ verticalAlign: 'middle' }} />
        &nbsp;button.
      </Text>
    </Stack>
  )
}

function CreateOrEditDialog({
  editIntegration,
  onClose,
  sellers,
  isAdmin,
}: {
  editIntegration?: Integration
  onClose: () => void
  sellers: Seller[]
  isAdmin: boolean
}) {
  const [disabled, setDisabled] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  return (
    <EditIntegrationDialog
      disabled={disabled}
      integration={editIntegration}
      onSubmit={async (integration: Integration) => {
        setDisabled(true)
        try {
          await (editIntegration
            ? updateIntegration(editIntegration.domain, integration)
            : createIntegration(integration))
          await getIntegrations()
          onClose()
          return
        } catch (err) {
          setError((err as any).message)
        }
        setDisabled(false)
      }}
      onCancel={onClose}
      error={error}
      sellers={sellers}
      isAdmin={isAdmin}
    />
  )
}

function CreateMultipleDialogHOC({ onClose }: { onClose: () => void }) {
  const getReadableError = ({
    integration,
    error,
  }: CreateMultipleIntegrationError) => `${integration.domain}: ${error}`
  const getReadableErrors = (
    errors: CreateMultipleIntegrationError[]
  ): string[] => errors.map(getReadableError)

  const [disabled, setDisabled] = useState<boolean>(false)
  const [errors, setErrors] = useState<string[]>([])
  return (
    <CreateMultipleDialog
      disabled={disabled}
      errors={errors}
      onClose={onClose}
      onSubmit={async (integrations: Integration[]) => {
        setDisabled(true)
        try {
          const errors = await createIntegrations(integrations)
          if (errors.length) {
            setErrors(getReadableErrors(errors))
            setDisabled(false)
            return
          }
          await getIntegrations()
          onClose()
          return
        } catch (err) {
          setErrors([(err as any).message])
        }
        setDisabled(false)
      }}
    />
  )
}

function ConfirmDelete({
  integration,
  onClose,
}: {
  integration: Integration
  onClose: () => void
}) {
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  return (
    <ConfirmationDialog
      dialogTitle={`Delete ${integration.domain} Integration`}
      confirmationText={`Are you sure you want to delete integration "${integration.domain}"?`}
      isLoading={loading}
      errorMessage={error}
      yesButtonText="Yes"
      noButtonText="No"
      onCancel={onClose}
      onConfirm={async () => {
        setLoading(true)
        try {
          await deleteIntegration(integration.domain)
          await getIntegrations()
          onClose()
        } catch (err) {
          setError((err as any).message)
          setLoading(false)
        }
      }}
    />
  )
}
