import gql from 'graphql-tag'

export interface ReadOnlyModeResponse {
  session: {
    readOnlyMode: boolean
  }
}

export const isReadOnlyModeEnabledQuery = gql`
  query {
    session {
      readOnlyMode
    }
  }
`
