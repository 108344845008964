import {
  Dropdown,
  IDropdownOption,
  MessageBar,
  MessageBarType,
  Stack,
} from 'office-ui-fabric-react'
import React from 'react'
import { OverrideSkipBehavior } from '../Api/instreamPlacements_v2'
import { NumberInput } from './Input/NumberInput'

interface InputProps {
  gap: number
  disabled: boolean
  errors?: { [fieldName: string]: string }
  onChange: (change: Change) => any
  overrideSkipBehavior?: OverrideSkipBehavior
  fixedSkipOffset?: number
}

interface SkipBehaviorFieldsProps extends InputProps {
  options?: IDropdownOption[]
}

interface Change {
  overrideSkipBehavior?: OverrideSkipBehavior
  fixedSkipOffset?: number
}

const SkipBehaviorFieldsInterface: React.FC<SkipBehaviorFieldsProps> = (
  props
) => {
  const {
    gap,
    disabled,
    errors,
    onChange,
    overrideSkipBehavior,
    fixedSkipOffset,
    options,
  } = props

  const defaultOptions = [
    {
      key: OverrideSkipBehavior.None,
      text: 'None - conform to skip offset set by VAST creative',
    },
    {
      key: OverrideSkipBehavior.Fixed,
      text: 'Fixed Offset - change the skip offset to a fixed offset',
    },
    {
      key: OverrideSkipBehavior.Hide,
      text: 'Hide - hides the skip button',
    },
  ]

  return (
    <Stack gap={gap}>
      <Dropdown
        placeholder="Select an option"
        label="Override Skip Behavior"
        selectedKey={overrideSkipBehavior}
        options={options || defaultOptions}
        onChange={(
          event: React.FormEvent<HTMLDivElement>,
          item: IDropdownOption | undefined
        ): void => {
          if (!item) {
            return
          }
          const newOverrideSkipBehavior = item.key as OverrideSkipBehavior
          const newFixedSkipOffset =
            !fixedSkipOffset &&
            newOverrideSkipBehavior === OverrideSkipBehavior.Fixed
              ? 0
              : fixedSkipOffset
          onChange({
            overrideSkipBehavior: newOverrideSkipBehavior,
            fixedSkipOffset: newFixedSkipOffset,
          })
        }}
        className="override-skip-behavior-dropdown"
        disabled={disabled}
      />
      {overrideSkipBehavior !== OverrideSkipBehavior.None && (
        <MessageBar styles={{ root: { marginTop: '5px' } }}>
          {overrideSkipBehavior !== OverrideSkipBehavior.OverrideInstream && (
            <>
              The change will only affect VAST creatives that do not run through
              IMA sdk or any other 3rd party player.
              <br />
              <br />
            </>
          )}
          The behavior set at campaign level will override instream skip
          behavior override.
        </MessageBar>
      )}
      {overrideSkipBehavior === OverrideSkipBehavior.Fixed && (
        <React.Fragment>
          <Stack horizontal style={{ position: 'relative' }}>
            <NumberInput
              style={{
                width: `calc(50% - ${gap / 2}px)`,
              }}
              label="Fixed Skip Offset (seconds)"
              errorMessage={errors ? errors['fixedSkipOffset'] : ''}
              name="fixedSkipOffset"
              value={Number(fixedSkipOffset || 1)}
              onChange={(value?: number) => {
                const defaultValue = 1
                onChange({
                  fixedSkipOffset: value === undefined ? defaultValue : value!,
                })
              }}
              disabled={disabled}
              required
              max={1000}
              min={1}
            />
          </Stack>
          <MessageBar messageBarType={MessageBarType.info}>
            {'When the skip button should be placed.'}
          </MessageBar>
        </React.Fragment>
      )}
    </Stack>
  )
}

export const SkipBehaviorFields: React.FC<InputProps> = (props) => {
  return <SkipBehaviorFieldsInterface {...props} />
}

export const SkipBehaviorFieldsWithOverrideInstream: React.FC<InputProps> = (
  props
) => {
  const options: IDropdownOption[] = [
    {
      key: OverrideSkipBehavior.None,
      text: 'None - conform to skip offset set by VAST creative',
    },
    {
      key: OverrideSkipBehavior.Fixed,
      text: 'Fixed Offset - change the skip offset to a fixed offset',
    },
    {
      key: OverrideSkipBehavior.Hide,
      text: 'Hide - hides the skip button',
    },
    {
      key: OverrideSkipBehavior.OverrideInstream,
      text: 'Override Instream Placement - uses the value set by the ad source, with higher priority than the placement config',
    },
  ]
  return <SkipBehaviorFieldsInterface {...props} options={options} />
}
