import React from 'react'
import gql from 'graphql-tag'
import { ChildProps, graphql } from 'react-apollo'
import { SessionContext } from '../Contexts/SessionContext'
import { isAdmin } from '../Api/session'

interface InputProps {
  children: React.ReactNode
}

interface SessionResponse {
  session: {
    integrationToken: string
    groupNames: string[]
  }
}

const withGql = graphql<InputProps, SessionResponse>(
  gql`
    {
      session {
        integrationToken
        groupNames
      }
    }
  `,
  { options: () => ({}) }
)
export const SessionContextProvider = withGql(
  ({
    children,
    data,
  }: ChildProps<InputProps, SessionResponse>): JSX.Element => {
    if (!data || data.loading || !data.session) {
      return <React.Fragment />
    }
    const value = {
      ctok: data.session.integrationToken,
      isAdmin: isAdmin(data.session),
    }
    return (
      <SessionContext.Provider value={value}>
        {children}
      </SessionContext.Provider>
    )
  }
)
