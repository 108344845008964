import React from 'react'
import { Depths } from '@uifabric/fluent-theme/lib/fluent/FluentDepths'
import {
  Stack,
  Spinner,
  SpinnerSize,
  IconButton,
  DefaultButton,
  ContextualMenuItemType,
  TooltipHost,
  DirectionalHint,
} from 'office-ui-fabric-react'
import { Sidebar, SidebarState } from './Sidebar'
import { ChangeDisplayNameDialog } from './ChangeDisplayNameDialog'
import { LoginAsDialog } from './LoginAsDialog'
import { FeedbackDialog } from './FeedbackDialog'
import { logout } from '../Api/api'
import {
  getAccountDisplayInfoQuery,
  AccountDisplayInfoResponse,
  loginAs,
} from '../Api/session'
import { ChildProps, graphql } from 'react-apollo'
import { OutstreamPlacementCollectionScreen as OutstreamPlacementCollectionScreen_v2 } from '../Screens/OutstreamPlacementCollectionScreen_v2/OutstreamPlacementCollectionScreen'
import { InstreamPlacementCollectionScreen as InstreamPlacementCollectionScreen_v2 } from '../Screens/InstreamPlacementCollectionScreen_v2/InstreamPlacementCollectionScreen'
import { VideoCollectionScreen } from '../Screens/VideoCollectionScreen/VideoCollectionScreen'
import { CampaignCollectionScreen } from '../Screens/CampaignCollectionScreen/CampaignCollectionScreen'
import { HomeScreen } from '../Screens/HomeScreen/HomeScreen'
import { SharedInventoryScreen } from '../Screens/SharedInventoryScreen/SharedInventoryScreen'
import { ErrorScreen } from '../Components/ErrorScreen'
import { get } from 'lodash'
import {
  isAdmin as isAdminF,
  isAccountManager as isAccountManagerF,
} from '../Api/session'
import { CreateAccountDialog } from './CreateAccountDialog'
import './App.css'
import HelpHeaderButton from './HelpHeaderButton'
import { IntegrationsScreen } from '../Screens/IntegrationsScreen/IntegrationsScreen'
import { ReadOnlyModeModal } from './ReadOnlyModeModal'
import { OwnDeclarationScreen } from '../Screens/SupplyChainScreen/SellersJsonScreen/OwnDeclarationScreen/OwnDeclarationScreen'
import { ReferencesScreen } from '../Screens/SupplyChainScreen/SellersJsonScreen/ReferencesScreen/ReferencesScreen'
import { DOOHAccountDetectedPopup } from '../Components/DOOHAccountDetectedPopup'
import { SessionContext } from '../Contexts/SessionContext'

type ModalType =
  | 'change-account'
  | 'change-display-name'
  | 'submit-feedback'
  | 'create-account'

interface State {
  redirecting: boolean
  selectedPath: string
  selectedPathPage: string
  selectedPathIdentifier: string
  selectedPathSidebar: string
  selectedPathSidebarState: SidebarState
  modal?: { type: ModalType }
}
interface InputProps {}

class AppView extends React.Component<
  ChildProps<InputProps, AccountDisplayInfoResponse>,
  State
> {
  static contextType = SessionContext
  context!: React.ContextType<typeof SessionContext>

  constructor(props: InputProps) {
    super(props)
    this.state = {
      ...this.getPathFromLocation(),
      modal: undefined,
    }
  }
  getPathFromLocation() {
    const selectedPath = window.location.hash
    const pathParts = selectedPath
      .split('#')
      .filter(Boolean)
      .map((p) => p.split('/').filter(Boolean))
    const mainParts = pathParts[0]
    const { ctok, isAdmin } = this.context || {
      ctok: undefined,
      isAdmin: false,
    }
    const selectedCtok = get(mainParts, '[0]', ctok)
    if (ctok && selectedCtok !== ctok && isAdmin) {
      loginAs({ ctok: selectedCtok, redirectPath: selectedPath })
      return {
        redirecting: true,
        selectedPath: '',
        selectedPathPage: '',
        selectedPathIdentifier: '',
        selectedPathSidebar: '',
        selectedPathSidebarState: 'closed' as SidebarState,
      }
    }
    const selectedPathPage = get(mainParts, '[1]', '') || 'home'
    const selectedPathIdentifier = get(mainParts, '[2]', '')
    const navParts = pathParts.find((p) => p[0] === 'nav')
    const selectedPathSidebar = get(navParts, '[1]', '')
    const selectedPathSidebarState = (
      get(navParts, '[2]') === 'open' ? 'open' : 'closed'
    ) as SidebarState
    return {
      redirecting: false,
      selectedPath,
      selectedPathPage,
      selectedPathIdentifier,
      selectedPathSidebar,
      selectedPathSidebarState,
    }
  }
  componentDidMount() {
    window.addEventListener('hashchange', () =>
      this.setState(this.getPathFromLocation())
    )
  }
  componentDidUpdate(
    _prevProps: Readonly<ChildProps<InputProps, AccountDisplayInfoResponse>>,
    _prevState: Readonly<State>,
    _snapshot?: any
  ): void {
    const potentiallyNewState = this.getPathFromLocation()
    if (potentiallyNewState.redirecting && !this.state.redirecting) {
      this.setState(potentiallyNewState)
    }
  }
  render() {
    const { error, loading } = this.props.data!
    if (error && !error.message.match(/401$/)) {
      console.error(error)
      return this.renderError()
    }
    if (loading || error || this.state.redirecting) {
      return this.renderLoading()
    }
    return this.renderLayout(this.props.data! as AccountDisplayInfoResponse)
  }
  renderLayout(props: AccountDisplayInfoResponse) {
    return (
      <Stack
        className="layout"
        horizontalAlign="start"
        verticalAlign="start"
        verticalFill
      >
        {this.renderHeader(props.session.accountDisplayName)}
        <Stack
          className="body"
          horizontal
          horizontalAlign="start"
          verticalFill
          style={{ width: '100%', height: 'calc(100vh - 64px)' }}
        >
          {this.renderSidebar(240)}
          {this.renderScreen()}
        </Stack>
        {this.renderLoginAsDialog()}
        {this.renderChangeDisplayNameDialog()}
        {this.renderFeedbackDialog()}
        {this.renderCreateAccountDialog()}
        <ReadOnlyModeModal />
        <DOOHAccountDetectedPopup />
      </Stack>
    )
  }
  renderSidebar(minWidth: number) {
    return (
      <Sidebar
        minWidth={minWidth}
        mainPath={[
          this.state.selectedPathPage,
          this.state.selectedPathIdentifier,
        ]}
        selectedPath={
          this.state.selectedPathSidebar || this.state.selectedPathPage
        }
        selectedState={this.state.selectedPathSidebarState}
        account={this.props.data!.session}
      />
    )
  }
  renderScreen() {
    return (
      <Stack
        className="screen"
        horizontalAlign="start"
        verticalAlign="start"
        verticalFill
        style={{
          flexGrow: 1,
          backgroundColor: 'rgba(0, 0, 0, 0.08)',
          position: 'relative',
        }}
      >
        {this.state.selectedPathPage === 'video-assets' && (
          <VideoCollectionScreen
            collectionId={this.state.selectedPathIdentifier}
          />
        )}
        {this.state.selectedPathPage === 'campaigns' && (
          <CampaignCollectionScreen
            collectionId={this.state.selectedPathIdentifier}
            isAdminOrAccountManager={
              isAdminF(this.props.data!.session!) ||
              isAccountManagerF(this.props.data!.session!)
            }
          />
        )}
        {this.state.selectedPathPage === 'shared-inventory' && (
          <SharedInventoryScreen />
        )}
        {this.state.selectedPathPage === 'integrations' && (
          <IntegrationsScreen />
        )}
        {this.state.selectedPathPage === 'sellers-json' &&
          this.state.selectedPathIdentifier === 'own-declaration' && (
            <OwnDeclarationScreen />
          )}
        {this.state.selectedPathPage === 'sellers-json' &&
          this.state.selectedPathIdentifier === 'references' && (
            <ReferencesScreen />
          )}
        {this.state.selectedPathPage === 'outstream-placements_v2' && (
          // eslint-disable-next-line react/jsx-pascal-case
          <OutstreamPlacementCollectionScreen_v2
            collectionId={this.state.selectedPathIdentifier}
          />
        )}
        {this.state.selectedPathPage === 'instream-placements_v2' && (
          // eslint-disable-next-line react/jsx-pascal-case
          <InstreamPlacementCollectionScreen_v2
            collectionId={this.state.selectedPathIdentifier}
          />
        )}
        {(this.state.selectedPathPage === 'home' ||
          this.state.selectedPathPage === '') && <HomeScreen />}
      </Stack>
    )
  }
  renderHeader(accountDisplayName: string) {
    const delegatedAccounts = this.props.data!.session!.delegatedAccounts || []
    const isAdmin = isAdminF(this.props.data!.session!)
    const isAccountManager = isAccountManagerF(this.props.data!.session!)
    return (
      <Stack
        className="header"
        horizontal
        horizontalAlign="start"
        style={{
          boxShadow: Depths.depth8,
          height: 64,
          minHeight: 64,
          width: '100%',
          zIndex: 3,
        }}
      >
        <div
          className="logo"
          style={{
            minWidth: 240,
            backgroundImage: `url('${process.env.PUBLIC_URL}/logo.svg')`,
          }}
        ></div>
        <Stack
          className="header-toolbar"
          horizontal
          horizontalAlign="end"
          style={{
            width: '100%',
          }}
        >
          <TooltipHost
            styles={{ root: { height: '100%' } }}
            content={'Submit Feedback'}
            tooltipProps={{
              delay: 0,
              className: 'screen-header-tooltip',
              directionalHint: DirectionalHint.leftCenter,
            }}
          >
            <IconButton
              iconProps={{ iconName: 'Emoji2' }}
              style={{ height: '100%' }}
              onClick={() => this.openFeedback()}
              onKeyDown={(e) => e.preventDefault()}
              onKeyPress={(e) => e.preventDefault()}
            />
          </TooltipHost>
          <HelpHeaderButton />
          <DefaultButton
            text={accountDisplayName}
            menuProps={{
              shouldFocusOnMount: true,
              items: [
                {
                  key: 'help',
                  text: 'Help',
                  iconProps: {
                    iconName: 'Help',
                  },
                  href: 'http://caroda.helpscoutdocs.com/',
                  target: '_blank',
                },
                {
                  key: 'feedback',
                  text: 'Submit Feedback',
                  iconProps: {
                    iconName: 'Emoji2',
                  },
                  onClick: () => this.openFeedback(),
                },
                ...(isAdmin || delegatedAccounts.length > 0
                  ? [
                      {
                        key: 'divider_1',
                        itemType: ContextualMenuItemType.Divider,
                      },
                    ]
                  : []),
                ...(isAdmin
                  ? [
                      {
                        key: 'rename',
                        text: 'Change Display Name',
                        iconProps: {
                          iconName: 'edit',
                        },
                        onClick: () => this.openChangeDisplayName(),
                      },
                    ]
                  : []),
                ...(delegatedAccounts.length > 0
                  ? [
                      {
                        key: 'loginAs',
                        text: 'Switch Account',
                        iconProps: {
                          iconName: 'ContactList',
                        },
                        onClick: () => this.openLoginAs(),
                      },
                    ]
                  : []),
                ...(isAdmin || isAccountManager
                  ? [
                      {
                        key: 'createAccount',
                        text: 'Create an Account',
                        iconProps: {
                          iconName: 'AccountManagement',
                        },
                        onClick: () => this.openCreateAccount(),
                      },
                    ]
                  : []),
                ...(isAdmin
                  ? [
                      {
                        key: 'divider_2',
                        itemType: ContextualMenuItemType.Divider,
                      },
                    ]
                  : []),
                {
                  key: 'logout',
                  text: 'Logout',
                  iconProps: {
                    iconName: 'PlugDisconnected',
                  },
                  onClick: logout,
                },
              ],
            }}
            onKeyDown={(e) => e.preventDefault()}
            onKeyPress={(e) => e.preventDefault()}
            style={{
              height: '100%',
              border: 'none',
            }}
          />
        </Stack>
      </Stack>
    )
  }
  renderLoginAsDialog() {
    if (!this.state.modal || this.state.modal.type !== 'change-account') {
      return
    }
    return <LoginAsDialog onClose={() => this.setState({ modal: undefined })} />
  }
  renderFeedbackDialog() {
    if (!this.state.modal || this.state.modal.type !== 'submit-feedback') {
      return
    }
    return (
      <FeedbackDialog onClose={() => this.setState({ modal: undefined })} />
    )
  }
  renderChangeDisplayNameDialog() {
    if (!this.state.modal || this.state.modal.type !== 'change-display-name') {
      return
    }
    return (
      <ChangeDisplayNameDialog
        onClose={() => {
          this.setState({ modal: undefined })
          this.props.data!.refetch()
        }}
      />
    )
  }
  renderCreateAccountDialog() {
    if (!this.state.modal || this.state.modal.type !== 'create-account') {
      return
    }
    return (
      <CreateAccountDialog
        onClose={() => this.setState({ modal: undefined })}
        onCreated={() => this.setState({ modal: undefined })}
      />
    )
  }
  renderLoading() {
    return (
      <Stack
        className="layout"
        horizontalAlign="center"
        verticalAlign="center"
        verticalFill
      >
        <Spinner size={SpinnerSize.large} label="Loading" />
      </Stack>
    )
  }
  renderError() {
    return (
      <ErrorScreen errorText="The platform is temporarily unavailable. If the problem persist, please contact support." />
    )
  }
  openFeedback() {
    this.setState({ modal: { type: 'submit-feedback' } })
  }
  openLoginAs() {
    this.setState({ modal: { type: 'change-account' } })
  }
  openChangeDisplayName() {
    this.setState({ modal: { type: 'change-display-name' } })
  }
  openCreateAccount() {
    this.setState({ modal: { type: 'create-account' } })
  }
}

const withSession = graphql<InputProps, AccountDisplayInfoResponse>(
  getAccountDisplayInfoQuery,
  { options: () => ({}) }
)
const App = withSession(AppView)

export { App }
