import React from 'react'
import { ITag, MessageBar, MessageBarType } from 'office-ui-fabric-react'
import { TagInput } from '../../../../Components/Input/TagInput'
import { SellersComboBox } from './SellersComboBox'
import { Seller } from '../../../../Api/schain'

interface AddReferencesFormProps {
  disabled: boolean
  reference: { buyerDomain: string; sellerId: string }
  suggestedBuyerDomains: string[]
  errorMessage: string
  sellerIdLabel: string
  onSellerIdChange: (sellerId: string, seller: Seller) => void
  onBuyerDomainChange: (buyerDomain: string) => void
}

export const AddReferencesForm: React.FC<AddReferencesFormProps> = ({
  disabled,
  onSellerIdChange,
  onBuyerDomainChange,
  reference,
  suggestedBuyerDomains,
  errorMessage,
  sellerIdLabel,
}) => {
  return (
    <>
      <TagInput
        label={'Domain containing the 3rd party sellers.json'}
        itemLimit={1}
        selectedItems={
          reference.buyerDomain
            ? [{ key: reference.buyerDomain, name: reference.buyerDomain }]
            : []
        }
        onChange={async (value?: ITag[]) => {
          if (!value || value.length === 0) {
            onBuyerDomainChange('')
            return
          }
          onBuyerDomainChange(value[0].key)
        }}
        disabled={disabled}
        onResolveSuggestions={(domain, tagList): ITag[] => {
          const suggestions: ITag[] = []
          suggestedBuyerDomains.forEach((domain) => {
            suggestions.push({
              key: domain,
              name: domain,
            })
          })
          if (domain) {
            suggestions.unshift({
              key: domain,
              name: `Connect to "${domain}"`,
            })
          }
          return suggestions
        }}
        pickerLabels={{ suggestionsHeader: '', noResults: '' }}
      />
      <SellersComboBox
        domain={reference.buyerDomain}
        disabled={!reference.buyerDomain || disabled}
        onChange={({ selectedSellerId, seller }) => {
          if (selectedSellerId && seller) {
            onSellerIdChange(selectedSellerId, seller)
          }
        }}
        selectedSellerId={reference.sellerId}
        label={sellerIdLabel}
      />

      {errorMessage && (
        <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
          {errorMessage}
        </MessageBar>
      )}
    </>
  )
}

export default AddReferencesForm
