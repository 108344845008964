import {
  Dropdown,
  IDropdownOption,
  IDropdownProps,
} from 'office-ui-fabric-react'
import React, { useEffect, useMemo } from 'react'
import { ChildProps, graphql } from 'react-apollo'
import {
  getAllIntegrationsAndIntegrationsUsedByOutstreamPlacementsQuery,
  GetAllIntegrationsAndIntegrationsUsedByOutstreamPlacementsResponse,
  Integration,
} from '../../Api/integrations'

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
type InputProps = Omit<IDropdownProps, 'options' | 'onChange'> & {
  value: string
  onChange: (integration: string) => void
}

const withGql = graphql<
  InputProps,
  GetAllIntegrationsAndIntegrationsUsedByOutstreamPlacementsResponse
>(getAllIntegrationsAndIntegrationsUsedByOutstreamPlacementsQuery, {
  options: () => ({ fetchPolicy: 'network-only' }),
})

export const IntegrationDropdown = withGql(
  ({
    value,
    onChange,
    data,
    ...dropdownProps
  }: ChildProps<
    InputProps,
    GetAllIntegrationsAndIntegrationsUsedByOutstreamPlacementsResponse
  >): JSX.Element => {
    const integrations = data && data.integrations ? data.integrations : []
    const options = useOptions({ integrations })
    const firstEnabledOption = options.find((option) => !option.disabled)
    const firstEnabledOptionKey = firstEnabledOption
      ? firstEnabledOption.key
      : null
    useEffect(() => {
      if (!value && firstEnabledOptionKey) {
        onChange(String(firstEnabledOptionKey))
      }
    }, [value, onChange, firstEnabledOptionKey])
    const selectedKey = value || firstEnabledOptionKey
    return (
      <Dropdown
        {...dropdownProps}
        label="Integration"
        selectedKey={selectedKey}
        options={options}
        onChange={(
          event: React.FormEvent<HTMLDivElement>,
          item: IDropdownOption | undefined
        ): void => {
          if (!item) {
            return
          }
          onChange(String(item.key))
        }}
      />
    )
  }
)

const useOptions = ({ integrations }: { integrations: Integration[] }) => {
  return useMemo(() => {
    const toOption = (integration: Integration): IDropdownOption => ({
      key: integration.domain,
      text: integration.domain,
    })

    return integrations.map(toOption)
  }, [integrations])
}
