import { get } from 'lodash'
import { List, Separator, Stack } from 'office-ui-fabric-react'
import React, { useEffect } from 'react'
import {
  NewsItem,
  NewsItemsResponse,
  getNewsItemsQuery,
} from '../../Api/session'
import { ChildProps, graphql } from 'react-apollo'
import { Depths } from '@uifabric/fluent-theme/lib/fluent/FluentDepths'
import moment from 'moment'
import VisibilitySensor from 'react-visibility-sensor'

interface Change {
  isLoading: boolean
  isError: boolean
}

interface InputProps {
  offset: number
  onChange: (change: Change) => void
}

type Props = ChildProps<InputProps, NewsItemsResponse>

function NewsListView(props: Props) {
  const { data, offset, onChange } = props
  const { loading, error } = data!
  const items = get(props, 'data.cms.news', [])
  const lastItem = items[items.length - 1]
  const [isLastItemVisible, setLastItemVisibility] = React.useState(false)
  useEffect(() => {
    onChange({ isLoading: loading, isError: !!error })
  }, [loading, error, onChange])
  return (
    <div>
      <List items={items} onRenderCell={renderNewsItem} />
      <VisibilitySensor
        onChange={(isVisible) => isVisible && setLastItemVisibility(true)}
      >
        {(isLastItemVisible && lastItem && (
          <NewsList offset={items.length + offset} onChange={props.onChange} />
        )) || <div style={{ height: 64 }}></div>}
      </VisibilitySensor>
    </div>
  )

  function renderNewsItem(n?: NewsItem) {
    if (!n) return
    const imgUrl = `${n.posterUrl.replace(
      'https://uploads-ssl.webflow.com/5dc12bf1f29bfc3fba048ead/',
      'https://caroda-webflow.imgix.net/'
    )}?w=210&fit=clip&fm=jpeg&trim=color&trim-color=fff&trim-tol=50`
    const titleSection = (
      <Stack horizontalAlign="center" verticalAlign="center" verticalFill>
        <Separator styles={{ root: { width: '100%' } }}></Separator>
        <h1 style={{ fontSize: 22, paddingTop: 20 }}>
          {n.title.toUpperCase()}
        </h1>
        <Separator styles={{ root: { width: '100%' } }}>
          <h3
            style={{
              color: '#666',
              textAlign: 'left',
              fontSize: 12,
            }}
          >
            {moment(n.date).format('Do MMM, YYYY')}
          </h3>
        </Separator>
      </Stack>
    )
    const bodyWithImage = `<img src="${imgUrl}" style="
      float: right;
      padding: 40px;
      padding-top: 10px;
      padding-right: 0;
    " />${n.body}`
    return (
      <Stack
        className="news-item"
        horizontalAlign="start"
        verticalAlign="start"
        verticalFill
        style={{
          width: '100%',
          maxWidth: 1000,
          background: '#fff',
          marginBottom: 30,
          boxShadow: Depths.depth4,
          overflow: 'hidden',
        }}
        key={n.id}
      >
        <Stack.Item
          styles={{ root: { padding: 42, width: 'calc(100% - 84px)' } }}
        >
          {titleSection}
        </Stack.Item>
        <Stack
          style={{
            flexGrow: 1,
            padding: 42,
            paddingTop: 0,
            paddingBottom: 0,
            marginBottom: 42,
            minHeight: 140,
            borderRight: '1px solid #eee',
          }}
          horizontalAlign="start"
          verticalAlign="start"
          verticalFill
        >
          <div
            style={{
              fontSize: 15,
              lineHeight: '22px',
            }}
            dangerouslySetInnerHTML={{ __html: bodyWithImage }}
          />
          <Separator styles={{ root: { width: '100%' } }} />
        </Stack>
      </Stack>
    )
  }
}

const withGql = graphql<InputProps, NewsItemsResponse>(getNewsItemsQuery, {
  options: ({ offset }) => ({
    variables: {
      offset: offset,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  }),
})
const NewsList = withGql(NewsListView)

export { NewsList }
