import { Stack } from 'office-ui-fabric-react'

import React from 'react'
import { ScreenHeader } from '../../../../Components/ScreenHeader'
import { SellersList } from './SellersListScreen'
import { SetSellersJSONDomainDialog } from './Dialog/SetSellersJsonDomain'
import './OwnDeclarationScreen.css'
import { ChildProps } from 'react-apollo/types'
import { SchainResponse, getSchainQuery } from '../../../../Api/schain'
import { graphql } from 'react-apollo'
import { BlockingLoadingDialog } from '../../../../Components/BlockingLoadingDialog'

export interface InputProps {}

function OwnDeclarationScreenView(
  props: ChildProps<InputProps, SchainResponse>
) {
  const { data } = props
  const isLoading = data && data!.loading
  const { sellers, sellerDomain, error } = data!
  const [isSetSellersModalOpen, setIsSetSellersModalOpen] =
    React.useState(false)

  if (isLoading) {
    return <BlockingLoadingDialog label="Loading" />
  }

  return (
    <Stack
      className="screen"
      horizontalAlign="start"
      verticalAlign="start"
      verticalFill
      style={{ width: '100%' }}
    >
      <ScreenHeader
        path={['Sellers.json', 'Own declaration']}
        buttons={[
          {
            name: 'Set Sellers.json domain',
            icon: 'Settings',
            disabled: isLoading,
            onClick: () => setIsSetSellersModalOpen(true),
          },
        ]}
      />
      <Stack
        className="own-declaration-screen"
        horizontalAlign="center"
        verticalAlign="start"
        horizontal
        wrap
        styles={{
          root: {
            width: '100%',
            overflow: 'auto',
          },
          inner: {
            padding: 60,
          },
        }}
      >
        <SellersList sellers={sellers!} error={error} />
      </Stack>
      {isSetSellersModalOpen && (
        <SetSellersJSONDomainDialog
          onClose={() => setIsSetSellersModalOpen(false)}
          onUpdated={() => {
            data!.refetch()
          }}
          sellerDomain={sellerDomain!}
        />
      )}
    </Stack>
  )
}

const withGql = graphql<InputProps, SchainResponse>(getSchainQuery, {
  options: () => ({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  }),
})

export const OwnDeclarationScreen = withGql(OwnDeclarationScreenView)
