import { ITag } from 'office-ui-fabric-react'
import { AssetTagsResponse } from '../Api/campaigns'
import { uniqBy } from 'lodash'

export function getAssetTags(assetTagInfo: AssetTagsResponse): ITag[] {
  const res = assetTagInfo
  const transformTag = (
    tag: string,
    asset: { integration: string; title: string },
    collection: { id: string; title: string }
  ) => {
    if (tag.startsWith('opz') || tag.startsWith('ipz')) {
      return [
        {
          key: `${collection.id}/${tag}`,
          name: `${asset.integration}/${asset.title}`,
        },
        {
          // Different keys are required as keys should be unique
          key: `${collection.id}/${asset.integration}/${tag}`,
          name: `${collection.title}/${asset.integration}/${asset.title}`,
        },
      ]
    } else {
      return [
        {
          key: `${collection.id}/${tag}`,
          name: `${collection.title}/${tag}`,
        },
      ]
    }
  }

  const outstreamCollectionTags: ITag[] =
    res.outstreamPlacementCollections_v2!.map((collection) => ({
      key: `${collection.id}/*`,
      name: `${collection.title}/*`,
    }))
  const outstreamTags: ITag[] = res.outstreamPlacementCollections_v2!.flatMap(
    (collection) =>
      collection.assets.flatMap((asset) =>
        asset.tags.flatMap((tag) => transformTag(tag, asset, collection))
      )
  )
  const instreamCollectionTags: ITag[] =
    res.instreamPlacementCollections_v2!.map((collection) => ({
      key: `${collection.id}/*`,
      name: `${collection.title}/*`,
    }))
  const instreamTags: ITag[] = res.instreamPlacementCollections_v2!.flatMap(
    (collection) =>
      collection.assets.flatMap((asset) =>
        asset.tags.flatMap((tag) => transformTag(tag, asset, collection))
      )
  )
  const partnershipTags = assetTagInfo.publisherPartnerships_v2.reduce(
    (ret, p) => [
      ...ret,
      ...p.sharedAssetTags.map((t) => ({
        key: `${t.collectionId}/${t.tagName}`,
        name: `${t.collectionTitle}/${t.tagName}`,
      })),
    ],
    [] as ITag[]
  )
  const assetTags = [
    ...outstreamCollectionTags,
    ...outstreamTags,
    ...instreamCollectionTags,
    ...instreamTags,
    ...partnershipTags,
  ]
  return uniqBy(assetTags, ({ key, name }) => `${key}/${name}`).sort(sortTags)
}

function sortTags(tagA: ITag, tagB: ITag): number {
  return tagA.name.localeCompare(tagB.name)
}
