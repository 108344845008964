import { client } from './api'
import gql from 'graphql-tag'
import { AccountDisplayInfo } from './session'

export const getAllOutstreamPlacementCollectionSummariesQuery = gql`
  query {
    outstreamPlacementCollections_v2 {
      id
      title
    }
  }
`

export const getOutstreamPlacementCollectionTagsQuery = gql`
  query ($collectionId: String) {
    outstreamPlacementCollections_v2(byId: $collectionId) {
      tags
    }
    session {
      groupNames
    }
  }
`

export interface OutstreamPlacementCollectionSummary {
  id: string
  title: string
}

export interface OutstreamPlacementInfo {
  id: string
  title: string
  creationDate: string
  tags: string[]
  integration?: string
  whitelistGlobs?: string[]
  blacklistGlobs?: string[]
  maximumAdLength?: number
}

export interface OutstreamPlacementCollectionSummaryResponse {
  outstreamPlacementCollections_v2: OutstreamPlacementCollectionSummary[]
}

export interface OutstreamPlacementCollectionTagsResponse {
  outstreamPlacementCollections_v2: { tags: string[] }[]
  session: AccountDisplayInfo
}

export const getOutstreamPlacementsQuery = gql`
  query ($collectionId: String, $startFromId: String) {
    outstreamPlacementCollections_v2(byId: $collectionId) {
      id
      tags
      assets(startFromId: $startFromId) {
        id
        title
        creationDate
        tags
        maximumAdLength
        integration
        whitelistGlobs
        blacklistGlobs
      }
    }
  }
`

export interface OutstreamPlacementResponse {
  outstreamPlacementCollections_v2: {
    id: string
    assets: OutstreamPlacementInfo[]
  }[]
}

const createOutstreamPlacementCollectionMutation = gql`
  mutation ($title: String!) {
    outstreamPlacementCollections_v2 {
      create(title: $title)
    }
  }
`

const renameOutstreamPlacementCollectionMutation = gql`
  mutation ($id: String, $title: String!) {
    outstreamPlacementCollections_v2(byId: $id) {
      update(title: $title)
    }
  }
`

const deleteOutstreamPlacementCollectionMutation = gql`
  mutation ($id: String) {
    outstreamPlacementCollections_v2(byId: $id) {
      delete
    }
  }
`

const deleteOutstreamPlacementMutation = gql`
  mutation ($collectionId: String, $id: String) {
    outstreamPlacementCollections_v2(byId: $collectionId) {
      assets(byId: $id) {
        delete
      }
    }
  }
`

const createOutstreamPlacementMutation = gql`
  mutation (
    $collectionId: String!
    $title: String!
    $integration: String!
    $tags: [String!]!
    $whitelistGlobs: [String]!
    $blacklistGlobs: [String]!
    $maximumAdLength: Int
  ) {
    outstreamPlacementCollections_v2(byId: $collectionId) {
      assets {
        create(
          title: $title
          integration: $integration
          tags: $tags
          whitelistGlobs: $whitelistGlobs
          blacklistGlobs: $blacklistGlobs
          maximumAdLength: $maximumAdLength
        )
      }
    }
  }
`

const updateOutstreamPlacementMutation = gql`
  mutation (
    $collectionId: String!
    $id: String!
    $title: String!
    $integration: String!
    $tags: [String!]!
    $whitelistGlobs: [String]!
    $blacklistGlobs: [String]!
    $maximumAdLength: Int
  ) {
    outstreamPlacementCollections_v2(byId: $collectionId) {
      assets(byId: $id) {
        update(
          title: $title
          integration: $integration
          tags: $tags
          whitelistGlobs: $whitelistGlobs
          blacklistGlobs: $blacklistGlobs
          maximumAdLength: $maximumAdLength
        )
      }
    }
  }
`

export async function createOutstreamPlacementCollection(title: string) {
  return client.mutate({
    mutation: createOutstreamPlacementCollectionMutation,
    variables: { title },
  })
}

export async function renameOutstreamPlacementCollection(
  collectionId: string,
  newTitle: string
) {
  return client.mutate({
    mutation: renameOutstreamPlacementCollectionMutation,
    variables: {
      id: collectionId,
      title: newTitle,
    },
  })
}

export async function deleteOutstreamPlacementCollection(collectionId: string) {
  return client.mutate({
    mutation: deleteOutstreamPlacementCollectionMutation,
    variables: { id: collectionId },
  })
}

export async function createOutstreamPlacement(
  collectionId: string,
  title: string,
  integration: string,
  tags: string[],
  whitelistGlobs: string[],
  blacklistGlobs: string[],
  maximumAdLength: number | null
): Promise<string> {
  const mutationResult = await client.mutate({
    mutation: createOutstreamPlacementMutation,
    variables: {
      collectionId,
      title,
      integration,
      tags,
      whitelistGlobs,
      blacklistGlobs,
      maximumAdLength,
    },
  })
  return mutationResult.data.outstreamPlacementCollections_v2.assets.create
}

export async function updateOutstreamPlacement(
  collectionId: string,
  id: string,
  title: string,
  integration: string,
  tags: string[],
  whitelistGlobs: string[],
  blacklistGlobs: string[],
  maximumAdLength: number | null
): Promise<string> {
  const mutationResult = await client.mutate({
    mutation: updateOutstreamPlacementMutation,
    variables: {
      collectionId,
      id,
      title,
      integration,
      tags,
      whitelistGlobs,
      blacklistGlobs,
      maximumAdLength,
    },
  })
  return mutationResult.data.outstreamPlacementCollections_v2.assets.update
}

export async function deleteOutstreamPlacement(
  collectionId: string,
  id: string
) {
  return client.mutate({
    mutation: deleteOutstreamPlacementMutation,
    variables: { id, collectionId },
  })
}
