import React from 'react'
import { Dropdown } from 'office-ui-fabric-react'

import { ChildProps, graphql } from 'react-apollo'
import {
  CampaignSummaryResponse,
  getAllCampaignCollectionSummariesQuery,
} from '../../../Api/campaigns'

interface Props {
  isDisabled: boolean
  selectedCollectionId: string
  selectedAccount: string
  onUpdated: (campaignCollection: CampaignCollection) => void
}

interface CampaignCollection {
  collectionId: string
}

const CampaignCollectionFormView: React.FC<
  ChildProps<Props, CampaignSummaryResponse>
> = ({ onUpdated, data, selectedCollectionId, isDisabled }) => {
  const { error, loading } = data!

  if (error) {
    return <React.Fragment />
  }

  const collectionOptions = !loading
    ? data!.campaignCollections!.map((c) => ({
        text: c.title,
        key: c.id,
      }))
    : []
  const defaultOption = {
    text: 'Create Campaigns Collection',
    key: 'newCampaignCollection',
  }

  return (
    <Dropdown
      label="Collection"
      selectedKey={selectedCollectionId}
      onChange={(evt, value?) => {
        if (!value) {
          return
        }
        onUpdated({
          collectionId: value.key.toString(),
        })
      }}
      placeholder="Select a collection"
      required={true}
      options={collectionOptions.concat([defaultOption])}
      disabled={isDisabled || loading}
    />
  )
}

const withGql = graphql<Props, CampaignSummaryResponse>(
  getAllCampaignCollectionSummariesQuery,
  {
    options: ({ selectedAccount }) => ({
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
      variables: { selectedAccount },
      context: { headers: { impersonate: selectedAccount } },
    }),
  }
)

export default withGql(CampaignCollectionFormView)
