export const VastEvents = [
  'impression',
  'start',
  'first_quartile',
  'midpoint',
  'third_quartile',
  'complete',
  'click_tracking',
  'creative_view',
  'skip',
  'mute',
  'unmute',
  'pause',
  'resume',
].map((key) => ({
  key,
  label: key.replace(/(^|_)([a-z])/g, (str) =>
    str.toUpperCase().replace(/_/g, ' ')
  ),
}))
