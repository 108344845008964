import { Link, Stack, TextField } from 'office-ui-fabric-react'
import React from 'react'
import { AssetTagsResponse, Campaign } from '../../Api/campaigns'
import { renderedTitleToTemplateTitle } from '../../Functions/templateTitle'
import InfoBox from '../../Screens/CampaignCollectionScreen/CampaignDialog/InfoBox'

interface Props {
  campaign: Campaign
  assetTagInfo: AssetTagsResponse
  templateTitle?: string
  disabled: boolean
  onChange: (templateTitle: string) => void
  gap: number
  errorMessage?: string
}

const TemplateTitle: React.FC<Props> = ({
  campaign,
  assetTagInfo,
  onChange,
  templateTitle,
  disabled,
  gap,
  errorMessage,
}) => {
  return (
    <Stack gap={gap}>
      <TextField
        label={'Title'}
        value={
          templateTitle !== undefined
            ? templateTitle
            : renderedTitleToTemplateTitle(
                campaign.title,
                campaign,
                assetTagInfo
              )
        }
        onChange={(event, templateTitle) => {
          if (templateTitle === undefined) return null
          onChange(templateTitle)
        }}
        disabled={disabled}
        errorMessage={errorMessage}
      />
      <InfoBox>
        Titles in template mode support macros such as [bidder], [device] and
        [domain]. Full description
        <Link
          href="https://caroda.helpscoutdocs.com/article/42-title-templates"
          target="_blank"
        >
          here
        </Link>
        .
      </InfoBox>
    </Stack>
  )
}

export default TemplateTitle
