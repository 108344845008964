import React from 'react'
import {
  Dialog,
  DefaultButton,
  PrimaryButton,
  Text,
  Checkbox,
  DialogFooter,
  DialogType,
  MessageBar,
  MessageBarType,
} from 'office-ui-fabric-react'
import { ErrorDialog } from '../../Components/ErrorDialog'
import { ChildProps, graphql } from 'react-apollo'
import {
  getEncodingConfigurationQuery,
  EncodingConfigurationResponse,
  setEncodingProfiles,
} from '../../Api/videoAssets'
import { uniq } from 'lodash'

interface State {
  isSubmitting: boolean
  hasError: boolean
  profiles: string[] | null
}

interface InputProps {
  isOpen: boolean
  onClose: () => void
}

const getInitialState = () => ({
  isSubmitting: false,
  hasError: false,
  profiles: null,
})

class GlobalTranscodingSettingsDialogView extends React.Component<
  ChildProps<InputProps, EncodingConfigurationResponse>,
  State
> {
  constructor(props: any) {
    super(props)
    this.state = getInitialState()
  }
  render() {
    if (this.props.data!.error) {
      return (
        <ErrorDialog
          onClose={this.props.onClose}
          title="Network error"
          message="Could not fetch encoding profiles. "
        />
      )
    }
    if (!this.props.data!.encodingConfiguration) {
      return <React.Fragment />
    }
    const { profiles, allowedProfiles } =
      this.props.data!.encodingConfiguration!
    const selectedProfiles = this.state.profiles || profiles
    return (
      <Dialog
        hidden={!this.props.isOpen}
        modalProps={{
          isDarkOverlay: true,
          isBlocking: true,
        }}
        onDismiss={this.props.onClose}
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: 'Select encoding profiles',
        }}
      >
        <Text>
          Selecting quality profiles here will affect all future transcoded
          assets. This has a major efect on both user experience and transcoding
          cost, so please double-check.
        </Text>
        {allowedProfiles
          .filter((p) => !p.match(/video:/i))
          .map((profile) => (
            <Checkbox
              key={profile}
              label={profile}
              disabled={this.state.isSubmitting}
              checked={selectedProfiles.indexOf(profile) !== -1}
              onChange={(evt: any, checked?: boolean) =>
                this.onCheckboxChange(profile, profiles, checked || false)
              }
              styles={{ root: { marginTop: 10 } }}
            />
          ))}
        {this.state.hasError && (
          <MessageBar
            messageBarType={MessageBarType.error}
            isMultiline={true}
            styles={{ root: { marginTop: 10 } }}
          >
            Could not update encoding settings. If the problem persist, please
            contact support.
          </MessageBar>
        )}
        <DialogFooter>
          <DefaultButton
            onClick={() => this.close()}
            disabled={this.state.isSubmitting}
            text={'Cancel'}
          />
          <PrimaryButton
            onClick={() => this.submit(this.props.data!.refetch)}
            disabled={
              !this.state.profiles ||
              this.state.profiles.length === 0 ||
              this.state.isSubmitting
            }
            text={'Save'}
          />
        </DialogFooter>
      </Dialog>
    )
  }
  onCheckboxChange(
    profile: string,
    initialProfiles: string[],
    checked: boolean
  ) {
    if (!this.state.profiles) {
      this.setState({ profiles: initialProfiles })
    }
    const selectedProfiles = this.state.profiles || initialProfiles
    if (checked) {
      this.setState({ profiles: uniq([...selectedProfiles, profile]) })
    } else {
      this.setState({ profiles: selectedProfiles.filter((p) => p !== profile) })
    }
  }
  submit(refetch: () => void) {
    this.setState({ isSubmitting: true })
    setEncodingProfiles(this.state.profiles!)
      .then(() => this.close())
      .then(refetch)
      .catch((e) => {
        console.error(e)
        this.setState({ isSubmitting: false, hasError: true })
      })
    return false
  }
  close() {
    this.props.onClose()
  }
}

const withGql = graphql<InputProps, EncodingConfigurationResponse>(
  getEncodingConfigurationQuery,
  {
    options: () => ({
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
    }),
  }
)
export const GlobalTranscodingSettingsDialog = withGql(
  GlobalTranscodingSettingsDialogView
)
