import React from 'react'
import {
  Stack,
  Text,
  TooltipHost,
  IconButton,
  DirectionalHint,
} from 'office-ui-fabric-react'
import { Depths } from '@uifabric/fluent-theme/lib/fluent/FluentDepths'
import './ScreenHeader.css'

interface State {}
interface InputProps {
  path: string[]
  buttons: {
    name: string
    icon: string
    isUpload?: boolean
    uploadAccept?: string
    disabled?: boolean
    onClick?: (...opts: any) => void
    menu?: {
      name: string
      icon?: string
      onClick: () => void
    }[]
  }[]
}

class ScreenHeader extends React.Component<InputProps, State> {
  render() {
    const iconStyles = {
      icon: { color: '#fff' },
      root: { height: '100%' },
      rootHovered: { background: 'rgba(255, 255, 255, 0.16)' },
      rootPressed: { background: 'rgba(255, 255, 255, 0.32)' },
      rootDisabled: { background: 'none' },
    }
    return (
      <Stack
        className="screen-header"
        horizontalAlign="start"
        verticalAlign="start"
        verticalFill
        horizontal
        style={{
          paddingLeft: 15,
          width: '100%',
          minHeight: '48px',
          height: '48px',
          backgroundColor: '#03a9f4',
          boxShadow: Depths.depth4,
        }}
      >
        <Stack
          className="screen-header-title"
          horizontalAlign="start"
          verticalAlign="center"
          horizontal
          style={{ height: '100%' }}
        >
          {this.props.path.map((p, i) => {
            const isLast = i === this.props.path.length - 1
            return (
              <Text
                variant="large"
                key={p + i}
                style={{
                  paddingLeft: 5,
                  color: '#fff',
                  fontWeight: isLast ? 'normal' : 'lighter',
                  verticalAlign: 'middle',
                }}
              >
                {p + (isLast ? '' : ' / ')}
              </Text>
            )
          })}
        </Stack>
        <Stack
          className="screen-header-toolbar"
          verticalAlign="center"
          horizontalAlign="end"
          style={{ height: '100%', flexGrow: 1 }}
          verticalFill
          horizontal
        >
          {this.props.buttons.map((b) => (
            <TooltipHost
              key={b.name}
              styles={{
                root: { height: '100%' },
              }}
              content={b.name}
              tooltipProps={{
                delay: 0,
                className: 'screen-header-tooltip',
                directionalHint: DirectionalHint.bottomCenter,
              }}
            >
              {(b.menu && (
                <IconButton
                  disabled={b.disabled}
                  styles={iconStyles}
                  menuProps={{
                    shouldFocusOnMount: true,
                    items: b.menu.map((i) => ({
                      key: i.name,
                      text: i.name,
                      iconProps: { iconName: i.icon },
                      onClick: i.onClick,
                    })),
                  }}
                />
              )) ||
                (b.isUpload && (
                  <React.Fragment>
                    <IconButton
                      iconProps={{ iconName: b.icon }}
                      disabled={b.disabled}
                      onClick={() =>
                        (
                          document.querySelector(
                            '#file' + b.icon
                          ) as HTMLElement
                        ).click()
                      }
                      styles={iconStyles}
                    />
                    <input
                      type="file"
                      id={'file' + b.icon}
                      onChange={(evt) => {
                        const file = (evt.target as HTMLInputElement).files![0]
                        b.onClick && b.onClick(file)
                      }}
                      accept={b.uploadAccept}
                      style={{
                        display: 'none',
                      }}
                    />
                  </React.Fragment>
                )) || (
                  <IconButton
                    iconProps={{ iconName: b.icon }}
                    disabled={b.disabled}
                    onClick={b.onClick}
                    styles={iconStyles}
                  />
                )}
            </TooltipHost>
          ))}
        </Stack>
      </Stack>
    )
  }
}

export { ScreenHeader }
