import React from 'react'
import { Depths } from '@uifabric/fluent-theme/lib/fluent/FluentDepths'
import { SharedColors } from '@uifabric/fluent-theme/lib/fluent/FluentColors'
import { getTheme, ITheme } from '@uifabric/styling'
import {
  ActionButton,
  Stack,
  Spinner,
  SpinnerSize,
  Separator,
  INavLinkGroup,
  Nav,
  IRenderFunction,
  Text,
} from 'office-ui-fabric-react'
import { ChildProps, graphql } from 'react-apollo'
import { CreateVideoCollectionDialog } from '../Screens/VideoCollectionScreen/CreateVideoCollectionDialog'
import { CreatePlacementCollectionDialog as CreatePlacementCollectionDialogv2 } from '../Components/CreatePlacementCollectionDialog_v2'
import { CreateCampaignCollectionDialog } from '../Screens/CampaignCollectionScreen/CreateCampaignCollectionDialog'
import {
  getAllCollectionSummariesQuery,
  AllCollectionSummariesResponse,
  AccountDisplayInfo,
  isAdmin,
} from '../Api/session'
import { SessionContext } from '../Contexts/SessionContext'

type ModalType =
  | 'create-videos-collection'
  | 'create-outstream-placements-collection'
  | 'create-outstream-placements-collection_v2'
  | 'create-campaigns-collection'
  | 'create-instream-placements-collection'
  | 'create-instream-placements-collection_v2'

interface State {
  openModal?: ModalType
}

interface InputProps {
  minWidth: number
  mainPath: string[]
  selectedPath: string
  selectedState: SidebarState
  account?: AccountDisplayInfo
}

export type SidebarState = 'open' | 'closed'

class SidebarView extends React.Component<
  ChildProps<InputProps, AllCollectionSummariesResponse>,
  State
> {
  static contextType = SessionContext
  context!: React.ContextType<typeof SessionContext>
  theme: ITheme

  constructor(props: InputProps) {
    super(props)
    this.theme = getTheme()
    this.state = {
      openModal: undefined,
    }
  }
  shouldRenderVideoCollectionsSidebar() {
    return (
      this.props.selectedPath === 'video-assets' &&
      this.props.selectedState === 'open'
    )
  }
  shouldRenderOutstreamPlacementCollectionsSidebar() {
    return (
      this.props.selectedPath === 'outstream-placements' &&
      this.props.selectedState === 'open'
    )
  }
  shouldRenderOutstreamPlacementCollectionsSidebar_v2() {
    return (
      this.props.selectedPath === 'outstream-placements_v2' &&
      this.props.selectedState === 'open'
    )
  }
  shouldRenderInstreamPlacementCollectionsSidebar() {
    return (
      this.props.selectedPath === 'instream-placements' &&
      this.props.selectedState === 'open'
    )
  }
  shouldRenderInstreamPlacementCollectionsSidebar_v2() {
    return (
      this.props.selectedPath === 'instream-placements_v2' &&
      this.props.selectedState === 'open'
    )
  }
  shouldRenderCampaignsCollectionsSidebar() {
    return (
      this.props.selectedPath === 'campaigns' &&
      this.props.selectedState === 'open'
    )
  }
  shouldRenderSellersJsonSidebar() {
    return (
      this.props.selectedPath === 'sellers-json' &&
      this.props.selectedState === 'open'
    )
  }
  render(): JSX.Element {
    const { error, loading } = this.props.data!
    if (error) {
      window.console.error(error)
    }
    if (error || loading) {
      return this.renderLoading()
    }
    return (
      <Stack
        className="sidebar-container"
        horizontalAlign="start"
        verticalAlign="start"
        horizontal
        verticalFill
      >
        <Stack
          className="sidebar"
          horizontalAlign="start"
          verticalAlign="start"
          horizontal
          verticalFill
          style={{
            minWidth: this.props.minWidth,
            boxShadow: Depths.depth8,
            backgroundColor: this.theme.palette.white,
            paddingTop: 20,
            zIndex: 1,
            overflow: 'auto',
          }}
        >
          {this.renderMainNav()}
        </Stack>
        {this.shouldRenderVideoCollectionsSidebar() &&
          this.renderVideoCollectionsSidebar()}
        {this.shouldRenderOutstreamPlacementCollectionsSidebar_v2() &&
          this.renderOutstreamPlacementCollectionsSidebar_v2()}
        {this.shouldRenderInstreamPlacementCollectionsSidebar_v2() &&
          this.renderInstreamPlacementCollectionsSidebar_v2()}
        {this.shouldRenderCampaignsCollectionsSidebar() &&
          this.renderCampaignsCollectionsSidebar()}
        {this.shouldRenderSellersJsonSidebar() &&
          this.renderSellersJsonSidebar()}
        {this.state.openModal === 'create-videos-collection' &&
          this.renderCreateVideoCollectionDialog(this.props.data!.refetch)}
        {this.state.openModal === 'create-outstream-placements-collection_v2' &&
          this.renderCreateOutstreamPlacementCollectionDialog_v2(
            this.props.data!.refetch
          )}
        {this.state.openModal === 'create-instream-placements-collection_v2' &&
          this.renderCreateInstreamPlacementCollectionDialog_v2(
            this.props.data!.refetch
          )}
        {this.state.openModal === 'create-campaigns-collection' &&
          this.renderCreateCampaignsCollectionDialog(this.props.data!.refetch)}
      </Stack>
    )
  }
  renderMainNav(): JSX.Element {
    return (
      <Nav
        selectedKey={this.props.selectedPath}
        onRenderGroupHeader={
          this.renderHeader as IRenderFunction<INavLinkGroup>
        }
        styles={{
          root: {
            width: this.props.minWidth,
          },
        }}
        groups={
          [
            this.getHomeNavigationGroup(),
            this.getAdvertisingTargetsNavigationGroup(),
            this.getAdvertisingSourcesNavigationGroup(),
            this.getUsageReportNavigationGroup(),
            this.getSupplyChainNavigationGroup(),
            this.getServiceReportNavigationGroup(),
          ].filter(Boolean) as INavLinkGroup[]
        }
      />
    )
  }
  renderVideoCollectionsSidebar(): JSX.Element {
    return this.renderCollectionSidebar(
      this.getVideoCollectionGroups(),
      'Create Video Assets Collection',
      () => this.setState({ openModal: 'create-videos-collection' })
    )
  }
  renderOutstreamPlacementCollectionsSidebar_v2(): JSX.Element {
    return this.renderCollectionSidebar(
      this.getOutstreamPlacementCollectionGroups_v2(),
      'Create Placements Collection',
      () =>
        this.setState({
          openModal: 'create-outstream-placements-collection_v2',
        })
    )
  }
  renderInstreamPlacementCollectionsSidebar_v2(): JSX.Element {
    return this.renderCollectionSidebar(
      this.getInstreamPlacementCollectionGroups_v2(),
      'Create Placements Collection',
      () =>
        this.setState({ openModal: 'create-instream-placements-collection_v2' })
    )
  }
  renderCampaignsCollectionsSidebar(): JSX.Element {
    return this.renderCollectionSidebar(
      this.getCampaignsCollectionGroups(),
      'Create Campaigns Collection',
      () => this.setState({ openModal: 'create-campaigns-collection' })
    )
  }
  renderSellersJsonSidebar(): JSX.Element {
    return this.renderCollectionSidebar(
      this.getSellersJsonCollectionGroups(),
      ''
    )
  }
  renderCollectionSidebar(
    group: INavLinkGroup,
    creationLabel: string,
    onCreate?: () => void
  ): JSX.Element {
    return (
      <Stack
        className="sidebar-collection"
        horizontalAlign="start"
        verticalAlign="start"
        verticalFill
        style={{
          minWidth: this.props.minWidth,
          boxShadow: Depths.depth8,
          paddingTop: 20,
          zIndex: 0,
          overflow: 'auto',
        }}
      >
        <Nav
          selectedKey={this.props.mainPath[1]}
          onRenderGroupHeader={
            this.renderHeader as IRenderFunction<INavLinkGroup>
          }
          styles={{
            root: {
              width: this.props.minWidth,
              borderLeft: 1,
              borderLeftColor: this.theme.palette.blackTranslucent40,
            },
            groupContent: {
              marginBottom: 0,
            },
            linkText: {
              fontStyle: 'italic',
              paddingRight: 20,
            },
          }}
          groups={[group]}
        />
        {!!onCreate && (
          <>
            <Separator styles={{ root: { width: '100%' } }}></Separator>
            <Stack
              horizontalAlign="center"
              verticalAlign="start"
              horizontal
              style={{ width: '100%' }}
            >
              <ActionButton
                iconProps={{ iconName: 'Add' }}
                onClick={onCreate}
                text={creationLabel}
              />
            </Stack>
          </>
        )}
      </Stack>
    )
  }
  renderCreateVideoCollectionDialog(refetch: () => void) {
    return (
      <CreateVideoCollectionDialog
        onUpdated={() => refetch()}
        onClose={() => this.setState({ openModal: undefined })}
      />
    )
  }
  renderCreateOutstreamPlacementCollectionDialog_v2(refetch: () => void) {
    // eslint-disable-next-line react/jsx-pascal-case
    return (
      <CreatePlacementCollectionDialogv2
        type="outstream"
        onUpdated={() => refetch()}
        onClose={() => this.setState({ openModal: undefined })}
      />
    )
  }
  renderCreateInstreamPlacementCollectionDialog_v2(refetch: () => void) {
    // eslint-disable-next-line react/jsx-pascal-case
    return (
      <CreatePlacementCollectionDialogv2
        type="instream"
        onUpdated={() => refetch()}
        onClose={() => this.setState({ openModal: undefined })}
      />
    )
  }
  renderCreateCampaignsCollectionDialog(refetch: () => void) {
    return (
      <CreateCampaignCollectionDialog
        onUpdated={() => refetch()}
        onClose={() => this.setState({ openModal: undefined })}
      />
    )
  }
  renderHeader(group: INavLinkGroup): JSX.Element {
    return (
      <Separator alignContent="start">
        <Text
          variant="small"
          style={{
            color: SharedColors.gray20,
          }}
        >
          {group.name}
        </Text>
      </Separator>
    )
  }
  getVideoCollectionGroups(): INavLinkGroup {
    return {
      name: 'Video Content Collections',
      links: [
        ...this.props.data!.videoCollections!.map((collection) => ({
          name: collection.title,
          url: `#/${this.context.ctok}/video-assets/${collection.id}`,
          key: collection.id,
        })),
      ],
    }
  }
  getOutstreamPlacementCollectionGroups_v2(): INavLinkGroup {
    return {
      name: 'Outstream Placement Collections',
      links: [
        ...this.props.data!.outstreamPlacementCollections_v2!.map(
          (collection) => ({
            name: collection.title,
            url: `#/${this.context.ctok}/outstream-placements_v2/${collection.id}`,
            key: collection.id,
          })
        ),
      ],
    }
  }
  getInstreamPlacementCollectionGroups_v2(): INavLinkGroup {
    return {
      name: 'Instream Placement Collections',
      links: [
        ...this.props.data!.instreamPlacementCollections_v2!.map(
          (collection) => ({
            name: collection.title,
            url: `#/${this.context.ctok}/instream-placements_v2/${collection.id}`,
            key: collection.id,
          })
        ),
      ],
    }
  }
  getCampaignsCollectionGroups(): INavLinkGroup {
    return {
      name: 'Campaign Collections',
      links: [
        ...this.props.data!.campaignCollections!.map((collection) => ({
          name: collection.title,
          url: `#/${this.context.ctok}/campaigns/${collection.id}`,
          key: collection.id,
        })),
      ],
    }
  }
  getSellersJsonCollectionGroups(): INavLinkGroup {
    return {
      name: 'Sellers.json',
      links: [
        {
          name: 'Own Declaration',
          url: `#/${this.context.ctok}/sellers-json/own-declaration`,
          key: 'own-declaration',
        },
        {
          name: 'References from 3rd Party buyers',
          url: `#/${this.context.ctok}/sellers-json/references`,
          key: 'references',
        },
      ],
    }
  }
  getHomeNavigationGroup() {
    return {
      links: [
        {
          name: 'Home',
          url: `#/${this.context.ctok}`,
          key: 'home',
          icon: 'News',
        },
      ],
    }
  }
  getAdvertisingTargetsNavigationGroup() {
    const advertisingTargetsNavigationGroupLinks = []
    advertisingTargetsNavigationGroupLinks.push(
      {
        name: 'Integrations',
        url: `#/${this.context.ctok}/integrations`,
        key: 'integrations',
        icon: 'WebComponents',
      },
      {
        name: 'Outstream Placements',
        url: `#/${this.context.ctok}/${this.props.mainPath.join('/')}/${
          this.shouldRenderOutstreamPlacementCollectionsSidebar_v2()
            ? ''
            : '#/nav/outstream-placements_v2/open'
        }`,
        key: 'outstream-placements_v2',
        icon: 'tiles',
      },
      {
        name: 'Instream Placements',
        url: `#/${this.context.ctok}/${this.props.mainPath.join('/')}/${
          this.shouldRenderInstreamPlacementCollectionsSidebar_v2()
            ? ''
            : '#/nav/instream-placements_v2/open'
        }`,
        key: 'instream-placements_v2',
        icon: 'Slideshow',
      }
    )
    advertisingTargetsNavigationGroupLinks.push({
      name: 'Video Content',
      url: `#/${this.context.ctok}/${this.props.mainPath.join('/')}/${
        this.shouldRenderVideoCollectionsSidebar()
          ? ''
          : '#/nav/video-assets/open'
      }`,
      key: 'video-assets',
      icon: 'video',
    })
    advertisingTargetsNavigationGroupLinks.push({
      name: '3rd Party Inventory',
      url: `#/${this.context.ctok}/shared-inventory`,
      key: 'shared-inventory',
      icon: 'EngineeringGroup',
    })
    const advertisingTargetsNavigationGroup = {
      name: 'Advertising Targets',
      links: advertisingTargetsNavigationGroupLinks,
    }
    return advertisingTargetsNavigationGroup
  }
  getAdvertisingSourcesNavigationGroup() {
    if (!this.props.account) {
      return undefined
    }
    return {
      name: 'Advertising Sources',
      links: [
        {
          name: 'Campaigns',
          url: `#/${this.context.ctok}/${this.props.mainPath.join('/')}/${
            this.shouldRenderCampaignsCollectionsSidebar()
              ? ''
              : '#/nav/campaigns/open'
          }`,
          key: 'campaigns',
          icon: 'InternetSharing',
        },
      ],
    }
  }
  getUsageReportNavigationGroup() {
    const getDashboardLink = (name: string): string =>
      `https://platform.caroda.io/api/forwardAuth?redirect=${encodeURIComponent(
        `https://grafana.caroda.io/api/auth?token=%TOKEN%&dashboard=${name}`
      )}`
    const shouldRenderInviewRevenueLink =
      isAdmin(this.props.data!.session!) ||
      this.props.data!.integrations!.some(
        (integration) => !!integration.integratedWithInview
      )
    const inviewDashboardUrl =
      'https://inviewapi.caroda.io/api/auth?token=%TOKEN%&dashboard=revenue'
    const redirectToInviewDashboardUrl = `https://platform.caroda.io/api/forwardAuth?redirect=${encodeURIComponent(
      inviewDashboardUrl
    )}`
    return {
      name: 'Usage Reports',
      links: [
        {
          name: 'Advertising',
          url: getDashboardLink('advertising'),
          target: '_blank',
          icon: 'AreaChart',
        },
        {
          name: 'Player',
          url: getDashboardLink('player'),
          target: '_blank',
          icon: 'AreaChart',
        },
        {
          name: 'Video Usage',
          url: getDashboardLink('video-usage'),
          target: '_blank',
          icon: 'AreaChart',
        },
        shouldRenderInviewRevenueLink
          ? {
              name: 'Inview Revenue',
              url: redirectToInviewDashboardUrl,
              target: '_blank',
              icon: 'AreaChart',
            }
          : undefined,
      ].filter(Boolean),
    }
  }
  getSupplyChainNavigationGroup() {
    return {
      name: 'Supply Chain',
      links: [
        {
          name: 'Sellers.json',
          url: `#/${this.context.ctok}/${this.props.mainPath.join('/')}/${
            this.shouldRenderSellersJsonSidebar()
              ? ''
              : '#/nav/sellers-json/open'
          }`,
          key: 'sellers-json',
          icon: 'BranchMerge',
        },
      ],
    }
  }
  getServiceReportNavigationGroup() {
    return {
      name: 'Service Reports',
      links: [
        {
          name: 'Uptime',
          url: 'https://uptime.statuscake.com/?TestID=eUi8XugNTB',
          target: '_blank',
          icon: 'CloudUpload',
        },
      ],
    }
  }
  renderLoading() {
    return (
      <Stack
        className="sidebar"
        horizontalAlign="center"
        verticalAlign="center"
        verticalFill
        style={{
          minWidth: this.props.minWidth,
          boxShadow: Depths.depth8,
        }}
      >
        <Spinner size={SpinnerSize.large} />
      </Stack>
    )
  }
}

const withGql = graphql<InputProps, AllCollectionSummariesResponse>(
  getAllCollectionSummariesQuery,
  { options: () => ({}) }
)
const Sidebar = withGql(SidebarView)

export { Sidebar }
