import React from 'react'
import {
  ComboBox,
  IDropdownOption,
  ITag,
  MessageBar,
  MessageBarType,
  Stack,
} from 'office-ui-fabric-react'
import {
  AccountDisplayInfoResponse,
  getAccountDisplayInfoQuery,
} from '../../../Api/session'
import { ChildProps, graphql } from 'react-apollo'
import CampaignCollectionForm from './CampaignCollectionForm'
import CampaignSelectedForm, {
  CampaignsWithInitialTargetedAssets,
} from './CampaignSelectedForm'
import {
  AssetTagsResponse,
  Campaign,
  createCampaign,
  createCampaignCollection,
} from '../../../Api/campaigns'
import { removeNullFields } from '../../../Utils/object'
import { LargeDialog } from '../../../Components/LargeDialog'
import { HalfWidthStyle } from '../../../Components/HalfWidthStyle'

interface Success {
  campaignTitle: string
  user: string
}

interface Props {
  assetTagInfo: AssetTagsResponse
  campaignsToMigrate: Campaign[]
  originalTags: ITag[]
  originalCollectionTitle: string
  onUpdated: () => void
  onClose: () => void
  onSuccess: (success: Success) => void
}

const MigrationCampaignDialogView: React.FC<
  ChildProps<Props, AccountDisplayInfoResponse>
> = ({
  assetTagInfo,
  originalTags,
  originalCollectionTitle,
  campaignsToMigrate,
  onUpdated,
  onClose,
  onSuccess,
  data,
}) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [selectedAccount, setSelectedAccount] = React.useState('')
  const [selectedCollectionId, setSelectedCollectionId] = React.useState(
    'newCampaignCollection'
  )
  const initializeCampaign = (c: Campaign) => ({
    ...c,
    targetedAssets: [] as string[],
    initialTitle: c.title,
    initialTargetedAssets: c.targetedAssets,
    error: {},
  })
  const [campaignsReady, setCampaignsReady] = React.useState(
    campaignsToMigrate.map(initializeCampaign)
  )
  const gap = 16
  const accountOptions = data!.session!.delegatedAccounts!.map((a) => ({
    text: a.displayName,
    key: a.id,
  }))
  const accountName = data!.session!.accountDisplayName

  return (
    <LargeDialog
      isBlocking={true}
      title="Migrate campaigns"
      onClose={onClose}
      width={900}
      buttons={[
        {
          name: 'Cancel',
          action: onClose,
          disabled: isSubmitting,
          primary: false,
        },
        {
          name: 'Save',
          action: submit,
          disabled: isSubmitting || !selectedAccount,
          primary: true,
        },
      ]}
    >
      <Stack gap={gap}>
        <Stack horizontal>
          <HalfWidthStyle gap={gap}>
            <ComboBox
              required
              label="Customer to export to"
              options={accountOptions}
              disabled={isSubmitting}
              onChange={(evt: any, value?: IDropdownOption) => {
                setCampaignsReady(campaignsToMigrate.map(initializeCampaign))
                if (!value) {
                  setSelectedAccount('')
                  return
                }
                setSelectedAccount(String(value!.key))
              }}
              selectedKey={selectedAccount}
              allowFreeform={true}
              styles={{
                optionsContainerWrapper: {
                  maxHeight: 400,
                },
              }}
            />
          </HalfWidthStyle>
          <div style={{ width: gap }} />
          <HalfWidthStyle gap={gap}>
            <CampaignCollectionForm
              isDisabled={isSubmitting || !selectedAccount}
              selectedCollectionId={selectedCollectionId}
              selectedAccount={selectedAccount}
              onUpdated={(campaignCollection) => {
                setSelectedCollectionId(campaignCollection.collectionId)
              }}
            />
          </HalfWidthStyle>
        </Stack>
        {selectedAccount && selectedCollectionId && (
          <CampaignSelectedForm
            campaignsToMigrate={campaignsReady}
            originalTags={originalTags}
            originalAssetTagsInfo={assetTagInfo}
            selectedAccount={selectedAccount}
            isDisabled={isSubmitting}
            onUpdated={(campaigns) => setCampaignsReady(campaigns)}
            gap={gap}
          />
        )}
        <MessageBar messageBarType={MessageBarType.warning} isMultiline={true}>
          If the new title is empty, the campaign name will remain the same.
          Titles in template mode support macros such as [bidder], [device] and
          [domain]. Full description
          <a href="https://caroda.helpscoutdocs.com/article/42-title-templates">
            here
          </a>
          <br />
          Once saving the changes, the selected campaigns will be copied into
          the selected customer account and the copy will remain paused.
          <br />
          Start and end date will be removed from the copies.
        </MessageBar>
      </Stack>
    </LargeDialog>
  )
  async function submit() {
    setIsSubmitting(true)
    const campaignsWithErrors = campaignsReady.map(validateCampaign)
    setCampaignsReady(campaignsWithErrors)
    const isCampaignsValid = campaignsWithErrors.every(
      (c) => !Object.keys(c.error).length
    )
    if (!isCampaignsValid) {
      setIsSubmitting(false)
      return
    }
    let collectionId = selectedCollectionId
    try {
      if (selectedCollectionId === 'newCampaignCollection') {
        const collectionTitle = `[migrated] ${accountName}/${originalCollectionTitle}`
        collectionId = await createCampaignCollection(
          collectionTitle,
          selectedAccount
        )
      }
    } catch {
      setIsSubmitting(false)
      return
    }
    const setCollectionId = (c: Campaign) => ({
      ...c,
      collectionId,
    })
    const normalizedCampaign = campaignsReady
      .map(normalize)
      .map(setCollectionId) as Campaign[]
    await Promise.all(
      normalizedCampaign.map((c) =>
        createCampaign(c).then((r) => {
          onSuccess({ campaignTitle: c.title, user: selectedAccount })
        })
      )
    )
    onUpdated()
    setIsSubmitting(false)
    onClose()
  }

  function validateCampaign(
    campaign: CampaignsWithInitialTargetedAssets
  ): CampaignsWithInitialTargetedAssets {
    let error = {}
    if (campaign.targetedAssets.length === 0) {
      error = {
        ...error,
        missingTargetedAssets: 'Missing targeted assets',
      }
    }
    if (campaign.title.trim() === '') {
      error = {
        ...error,
        missingTitle: 'Missing title',
      }
    }
    if (error) {
      return {
        ...campaign,
        error,
      }
    }
    return { ...campaign, error: {} }
  }

  function normalize(campaign: Campaign) {
    const renderedTitle = ''
    const normalizedCampaign = {
      ...campaign,
      startDate: new Date().toISOString(),
      endDate: null,
      title: renderedTitle.trim() || campaign.title.trim(),
      trackingHooks: campaign.trackingHooks.filter((h) => h.trackingUrl),
      pathGlobs: campaign.pathGlobs.map((p) => p.trim()).filter(Boolean),
      pathBlacklistGlobs: campaign.pathBlacklistGlobs
        .map((p) => p.trim())
        .filter(Boolean),
      initialStatus: 'paused',
      user: selectedAccount,
      collectionId: selectedCollectionId,
      initialTargetedAssets: undefined,
      error: undefined,
    } as Campaign
    normalizedCampaign.schedule = removeNullFields(normalizedCampaign.schedule)
    return normalizedCampaign
  }
}

const withGql = graphql<Props, AccountDisplayInfoResponse>(
  getAccountDisplayInfoQuery,
  { options: () => ({ notifyOnNetworkStatusChange: true }) }
)
export default withGql(MigrationCampaignDialogView)
