import React, { useState } from 'react'
import { ChildProps, graphql } from 'react-apollo'
import {
  isReadOnlyModeEnabledQuery,
  ReadOnlyModeResponse,
} from '../Api/readOnlyMode'
import { get } from 'lodash'
import {
  DialogFooter,
  Modal,
  PrimaryButton,
  Separator,
  Stack,
  Text,
} from 'office-ui-fabric-react'

const withGql = graphql<{}, ReadOnlyModeResponse>(isReadOnlyModeEnabledQuery, {
  options: () => ({ fetchPolicy: 'network-only' }),
})

export const ReadOnlyModeModal = withGql(
  ({ data }: ChildProps<{}, ReadOnlyModeResponse>): JSX.Element => {
    const readOnly = get(data, 'session.readOnlyMode', false)
    const [modalDisplayed, setModalDisplayed] = useState(false)
    return (
      <Modal
        isOpen={readOnly && !modalDisplayed}
        onDismiss={() => setModalDisplayed(true)}
      >
        <Stack>
          <Stack
            styles={{
              root: {
                background: 'rgb(2, 151, 220)',
                height: 67,
                paddingLeft: 24,
                paddingTop: 16,
                paddingRight: 48,
                zIndex: 1e6,
              },
            }}
          >
            <Text style={{ color: '#fff', fontWeight: 600, fontSize: 20 }}>
              Read-only mode is active
            </Text>
          </Stack>
          <Stack styles={{ root: { padding: 24 } }} gap={10}>
            <Text>For the next few minutes, we'll be updating our system.</Text>
            <Text>
              You can still use the platform, but you won't be able to modify
              any data.
            </Text>
            <Separator />
            <DialogFooter>
              <PrimaryButton
                onClick={() => setModalDisplayed(true)}
                text="Accept"
              />
            </DialogFooter>
          </Stack>
        </Stack>
      </Modal>
    )
  }
)
