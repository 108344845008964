import React from 'react'
import { Dropdown, Stack } from 'office-ui-fabric-react'
import { AdUnitFormat, AssetType } from '../../../../Api/campaigns'
import { SkipBehaviorFieldsWithOverrideInstream } from '../../../../Components/SkipBehaviorFields'
import { OverrideSkipBehavior } from '../../../../Api/instreamPlacements_v2'

type FormErrorSet = { [fieldName: string]: string }

interface InputProps {
  gap: number
  disabled: boolean
  assetType: AssetType | undefined
  adType: AdUnitFormat | null
  overrideSkipBehavior?: OverrideSkipBehavior
  fixedSkipOffset?: number
  errors: FormErrorSet
  onChange: (change: Change) => any
}

interface Change {
  adType?: AdUnitFormat
  overrideSkipBehavior?: OverrideSkipBehavior
  fixedSkipOffset?: number
}

export const CreativeForm: React.FC<InputProps> = (props) => {
  const {
    gap,
    disabled,
    assetType,
    adType,
    overrideSkipBehavior,
    fixedSkipOffset,
    errors,
    onChange,
  } = props

  return (
    <Stack gap={gap}>
      {shouldRenderAdType(assetType) && (
        <Dropdown
          style={{
            flexGrow: 1,
            maxWidth: `calc(50% - ${gap / 2}px)`,
          }}
          label="Ad Type"
          errorMessage={errors['adType']}
          placeholder={'Select an ad type'}
          options={[AdUnitFormat.BANNER, AdUnitFormat.VIDEO].map((it) => ({
            key: it,
            text: it,
          }))}
          required
          disabled={disabled}
          selectedKey={adType}
          onChange={(event, value?) => {
            if (!value) {
              return
            }
            onChange({ adType: value.key as AdUnitFormat })
          }}
        />
      )}
      {shouldDisplaySkipBehaviorFields(adType, assetType) && (
        <SkipBehaviorFieldsWithOverrideInstream
          gap={gap}
          disabled={disabled}
          overrideSkipBehavior={overrideSkipBehavior}
          fixedSkipOffset={fixedSkipOffset}
          onChange={onChange}
        />
      )}
    </Stack>
  )
}

export function shouldRenderAdType(assetType?: AssetType): boolean {
  return (
    !!assetType && ['headerBiddingSource', 'seznamOpenRtb'].includes(assetType)
  )
}

function shouldDisplaySkipBehaviorFields(
  adType: AdUnitFormat | null,
  assetType?: AssetType
): boolean {
  return (
    (!!assetType && ['vastRedirect', 'staticVast'].includes(assetType)) ||
    adType === AdUnitFormat.VIDEO
  )
}
