import React from 'react'
import { MessageBar, TextField } from 'office-ui-fabric-react'

interface Props {
  syncScript: string
}

export function SyncScript({ syncScript }: Props) {
  return (
    <>
      <TextField
        label="Sync script"
        value={syncScript}
        multiline
        rows={10}
        resizable={false}
        readOnly={true}
      />
      <MessageBar isMultiline={true}>
        This integration requires that this script is loaded before the player
        script.
      </MessageBar>
    </>
  )
}
