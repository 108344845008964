import { createContext } from 'react'

interface ISessionContext {
  ctok: string
  isAdmin: boolean
}

export const SessionContext = createContext<ISessionContext>({
  ctok: '',
  isAdmin: false,
})
