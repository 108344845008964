import React from 'react'
import {
  Dialog,
  DefaultButton,
  PrimaryButton,
  TextField,
  DialogFooter,
  DialogType,
  MessageBar,
  MessageBarType,
} from 'office-ui-fabric-react'
import { migrateUrl } from '../../Api/videoAssets'

interface State {
  pendingUrl?: string
  hasError: boolean
}

interface InputProps {
  collectionId: string
  isOpen: boolean
  onClose: () => void
  onUpdated: () => void
}

export class TransferFileDialog extends React.Component<InputProps, State> {
  constructor(props: any) {
    super(props)
    this.state = {
      hasError: false,
    }
  }
  render() {
    let url = ''
    return (
      <Dialog
        hidden={!this.props.isOpen}
        modalProps={{
          isDarkOverlay: true,
          isBlocking: true,
        }}
        onDismiss={this.props.onClose}
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: 'Transfer from video url',
        }}
      >
        <TextField
          label={'Video source url'}
          value={this.state.pendingUrl || url}
          name={'title'}
          autoComplete={'off'}
          onKeyDown={(evt) => evt.keyCode === 13 && this.submit(url)}
          onChange={(evt: any, value?: string) => {
            url = value!
          }}
          disabled={this.state.pendingUrl !== undefined}
          required
        />
        {this.state.hasError && (
          <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
            Could not migrate video asset. If the problem persist, please
            contact support.
          </MessageBar>
        )}
        <DialogFooter>
          <DefaultButton onClick={() => this.close()} text={'Cancel'} />
          <PrimaryButton
            onClick={() => this.submit(url)}
            disabled={this.state.hasError}
            text={'Save'}
          />
        </DialogFooter>
      </Dialog>
    )
  }
  submit(url: string) {
    this.setState({ pendingUrl: url })
    migrateUrl(this.props.collectionId, url)
      .then(() => this.close())
      .then(this.props.onUpdated)
      .catch((e: Error) => {
        console.error(e)
        this.setState({ hasError: true })
      })
    return false
  }
  close() {
    this.props.onClose()
  }
}
