import React from 'react'
import {
  ComboBox,
  Dialog,
  DefaultButton,
  PrimaryButton,
  DialogFooter,
  DialogType,
  TextField,
  ChoiceGroup,
  IDropdownOption,
  MessageBar,
  MessageBarType,
  Stack,
} from 'office-ui-fabric-react'
import { ITextField } from '../Common/forms'
import { createAccount, getDelegatedAccounts } from '../Api/session'
import { ChildProps, graphql } from 'react-apollo'
import {
  getDelegatedAccountsQuery,
  DelegatedAccountsResponse,
  DelegatedAccount,
} from '../Api/session'
import { get as _get } from 'lodash'

interface State {
  type: CreateAccountDialogType
  emailAddress: ITextField
  accountName: ITextField
  loading: boolean
  errorMessage: string | null
  mainDialogHidden: boolean
  successDialogHidden: boolean
}

export enum CreateAccountDialogType {
  NEW_ACCOUNT = 'New Account',
  EXISITING_ACCOUNT = 'Existing Account',
}

interface InputProps {
  onClose: () => void
  onCreated: () => void
}

export class CreateAccountDialogView extends React.Component<
  ChildProps<InputProps, DelegatedAccountsResponse>,
  State
> {
  constructor(props: InputProps) {
    super(props)
    this.state = {
      type: CreateAccountDialogType.NEW_ACCOUNT,
      emailAddress: {
        errorMessage: '',
        value: '',
      },
      accountName: {
        errorMessage: '',
        value: '',
      },
      loading: false,
      errorMessage: null,
      mainDialogHidden: false,
      successDialogHidden: true,
    }
  }
  hasError() {
    return (
      !!this.state.emailAddress.errorMessage ||
      !!this.state.accountName.errorMessage
    )
  }
  render() {
    return (
      <React.Fragment>
        <Dialog
          hidden={this.state.mainDialogHidden}
          modalProps={{
            isDarkOverlay: true,
            isBlocking: true,
          }}
          onDismiss={this.props.onClose}
          dialogContentProps={{
            type: DialogType.largeHeader,
            title: 'Create an Account',
          }}
        >
          <Stack gap={10}>
            <TextField
              label="Email Address"
              type="email"
              name="emailaddress"
              required={true}
              errorMessage={this.state.emailAddress.errorMessage}
              value={this.state.emailAddress.value}
              onChange={(evt: any, value?: string) => {
                this.setState({
                  emailAddress: {
                    value: value!,
                    errorMessage: '',
                  },
                })
              }}
              disabled={this.state.loading}
            />
            <ChoiceGroup
              label="Type"
              name="type"
              required={true}
              defaultSelectedKey={CreateAccountDialogType.NEW_ACCOUNT}
              options={[
                {
                  key: CreateAccountDialogType.NEW_ACCOUNT,
                  text: CreateAccountDialogType.NEW_ACCOUNT,
                },
                {
                  key: CreateAccountDialogType.EXISITING_ACCOUNT,
                  text: CreateAccountDialogType.EXISITING_ACCOUNT,
                },
              ]}
              onChange={(event, value?) => {
                this.setState({ type: value!.key as CreateAccountDialogType })
              }}
              disabled={this.state.loading}
            />
            {this.state.type === CreateAccountDialogType.EXISITING_ACCOUNT
              ? this.renderExistingAccountForm()
              : this.renderNewAccountForm()}
          </Stack>
          {this.state.errorMessage && (
            <MessageBar
              messageBarType={MessageBarType.error}
              styles={{
                root: {
                  marginTop: 15,
                },
              }}
            >
              {this.state.errorMessage}
            </MessageBar>
          )}
          <DialogFooter>
            <DefaultButton
              onClick={() => this.close()}
              text="Cancel"
              disabled={this.state.loading}
            />
            <PrimaryButton
              onClick={() => this.submit()}
              disabled={this.hasError() || this.state.loading}
              text="Create Account"
            />
          </DialogFooter>
        </Dialog>
        <Dialog
          hidden={this.state.successDialogHidden}
          onDismiss={this.props.onCreated}
          dialogContentProps={{
            type: DialogType.normal,
            title: 'Account created',
            closeButtonAriaLabel: 'Close',
            subText: 'The account has been created.',
          }}
          modalProps={{
            isDarkOverlay: true,
            isBlocking: true,
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={this.props.onCreated} text="Close" />
          </DialogFooter>
        </Dialog>
      </React.Fragment>
    )
  }
  renderExistingAccountForm() {
    const hasError = Boolean(this.props.data!.error)
    const options = [{ key: '', text: '' }]
    if (!hasError) {
      options.push(
        ..._get(this.props, 'data.session.delegatedAccounts', []).map(
          (a: DelegatedAccount) => ({
            text: a.displayName,
            key: a.id,
          })
        )
      )
    }
    return (
      <React.Fragment>
        <ComboBox
          label="Select account:"
          options={options}
          disabled={this.state.loading}
          selectedKey={this.state.accountName.value}
          onChange={(evt: any, value?: IDropdownOption) => {
            this.setState({
              accountName: {
                value: String(value!.key),
                errorMessage: '',
              },
            })
          }}
          allowFreeform={true}
          styles={{
            optionsContainerWrapper: { maxHeight: 400 },
          }}
        />
        {hasError && (
          <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
            Could not load delegated account list.
          </MessageBar>
        )}
      </React.Fragment>
    )
  }
  renderNewAccountForm() {
    return (
      <React.Fragment>
        <TextField
          label="Account Name"
          type="text"
          name="accountname"
          required={true}
          errorMessage={this.state.accountName.errorMessage}
          value={this.state.accountName.value}
          onChange={(evt: any, value?: string) => {
            this.setState({
              accountName: {
                value: value!,
                errorMessage: '',
              },
            })
          }}
          disabled={this.state.loading}
        />
      </React.Fragment>
    )
  }
  close() {
    this.props.onClose()
  }
  async submit() {
    const emailAddress = this.state.emailAddress.value
    if (!emailAddress || !emailAddress.trim()) {
      this.setState({
        emailAddress: {
          value: emailAddress,
          errorMessage: 'field is mandatory',
        },
      })
      return
    }
    const accountName = this.state.accountName.value
    if (!accountName || !accountName.trim()) {
      this.setState({
        accountName: {
          value: accountName,
          errorMessage: 'field is mandatory',
        },
      })
      return
    }
    this.setState({ loading: true })
    const isNewAccount = this.state.type === CreateAccountDialogType.NEW_ACCOUNT
    try {
      await createAccount(emailAddress, isNewAccount, accountName, 'web')
      await getDelegatedAccounts()
      this.setState({
        mainDialogHidden: true,
        successDialogHidden: false,
      })
    } catch (error) {
      const err = error as any
      let errorMessage = err.message
      if (err.graphQLErrors && err.graphQLErrors.length) {
        errorMessage = err.graphQLErrors[0].message
      }
      this.setState({
        loading: false,
        errorMessage,
      })
    }
  }
}

const withGql = graphql<InputProps, DelegatedAccountsResponse>(
  getDelegatedAccountsQuery,
  { options: () => ({ notifyOnNetworkStatusChange: true }) }
)
export const CreateAccountDialog = withGql(CreateAccountDialogView)
