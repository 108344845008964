import React from 'react'
import {
  Modal,
  DefaultButton,
  Text,
  ActionButton,
  Separator,
  DetailsList,
  DetailsListLayoutMode,
  Stack,
  SelectionMode,
} from 'office-ui-fabric-react'
import { VideoAssetInfo } from '../../Api/videoAssets'
import moment from 'moment'

type CloseAction = 'delete' | 'rename' | 'none'

interface State {}

interface InputProps {
  isOpen: boolean
  videoAsset: VideoAssetInfo
  onClose: (action: CloseAction) => void
}

export class VideoAssetDialog extends React.Component<InputProps, State> {
  private loadPlayerTimeout: NodeJS.Timeout | null
  constructor(props: any) {
    super(props)
    this.loadPlayerTimeout = null
  }
  render() {
    const videoAsset = this.props.videoAsset
    this.loadCarodaPLayer()
    const detailList = [
      {
        name: 'ID',
        value: videoAsset.id,
      },
      {
        name: 'Title',
        value: videoAsset.title,
      },
      {
        name: 'Creation Date',
        value: moment(videoAsset.creationDate).format('LL'),
      },
      {
        name: 'Creation Time',
        value: moment(videoAsset.creationDate).format('LTS ZZ'),
      },
    ]
    if (Number(videoAsset.durationInSeconds) > 0) {
      detailList.push({
        name: 'Duration',
        value: moment
          .utc(Number(videoAsset.durationInSeconds) * 1e3)
          .format('HH:mm:ss'),
      })
    }
    const shouldDisplayMigration =
      videoAsset.migrationSourceReferer &&
      !videoAsset.migrationSourceReferer.match(/caroda/i)
    if (shouldDisplayMigration) {
      detailList.push({
        name: 'Migrated page url',
        value: videoAsset.migrationSourceReferer,
      })
      if (videoAsset.migrationSourceUrl) {
        detailList.push({
          name: 'Migrated video source url',
          value: videoAsset.migrationSourceUrl,
        })
      }
    }
    return (
      <Modal
        isOpen={this.props.isOpen}
        isDarkOverlay={true}
        isBlocking={true}
        onDismiss={() => this.props.onClose('none')}
        styles={{
          main: {
            width: 600,
            maxWidth: 600,
            minWidth: 120,
          },
        }}
      >
        <Stack>
          <Stack
            styles={{
              root: {
                background: 'rgb(2, 151, 220)',
                height: 67,
                paddingLeft: 24,
                paddingTop: 16,
                paddingRight: 48,
              },
            }}
          >
            <Text
              style={{
                color: '#fff',
                fontWeight: 600,
                fontSize: 20,
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}
            >
              {this.props.videoAsset.title}
            </Text>
          </Stack>
          <div id={'caroda-player-' + videoAsset.id}></div>
          <Stack styles={{ root: { padding: 24 } }} gap={10}>
            <Separator alignContent="start">
              <Text variant="large">Asset Information</Text>
            </Separator>
            <DetailsList
              items={detailList}
              columns={[
                {
                  key: 'column1',
                  name: 'Name',
                  fieldName: 'name',
                  minWidth: 100,
                  maxWidth: 200,
                  isResizable: false,
                },
                {
                  key: 'column2',
                  name: 'Value',
                  fieldName: 'value',
                  minWidth: 100,
                  maxWidth: 200,
                  isResizable: false,
                },
              ]}
              layoutMode={DetailsListLayoutMode.justified}
              selectionMode={SelectionMode.none}
              isHeaderVisible={false}
            />
            <Stack horizontal>
              {shouldDisplayMigration && (
                <ActionButton
                  iconProps={{ iconName: 'FileASPX' }}
                  href={videoAsset.migrationSourceReferer}
                  target={'_blank'}
                  text={'View on site'}
                />
              )}
              <ActionButton
                iconProps={{ iconName: 'edit' }}
                onClick={() => this.props.onClose('rename')}
                text={'Rename'}
              />
              <ActionButton
                iconProps={{ iconName: 'delete' }}
                onClick={() => this.props.onClose('delete')}
                text={'Delete'}
              />
              <Stack horizontal horizontalAlign="end" style={{ flexGrow: 1 }}>
                <DefaultButton
                  onClick={() => this.props.onClose('none')}
                  text={'Close'}
                />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Modal>
    )
  }
  loadCarodaPLayer() {
    const caroda = (window as any).caroda
    if (!caroda) {
      this.loadPlayerTimeout = setTimeout(() => this.loadCarodaPLayer(), 50)
      return
    }
    const playerDiv = document.querySelector(
      '#caroda-player-' + this.props.videoAsset.id
    ) as HTMLElement
    if (!playerDiv) {
      this.loadPlayerTimeout = setTimeout(() => this.loadCarodaPLayer(), 50)
      return
    }
    this.loadPlayerTimeout = null
    if (playerDiv.getAttribute('caroda-player')) {
      return
    }
    playerDiv.setAttribute('caroda-player', '1')
    caroda.loadInstreamWithContentAndPositions(
      playerDiv,
      this.props.videoAsset.id,
      []
    )
  }
}
