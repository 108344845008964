import React from 'react'

import {
  Stack,
  Dropdown,
  TextField,
  MessageBar,
  MessageBarType,
  Link,
} from 'office-ui-fabric-react'

import { Campaign } from '../../../Api/campaigns'

import { countryCodes, countryCodeToName } from '../../../Constants/Countries'

import { ShownTagInput } from '../../../Components/Input/ShownTagInput'
import { uniq } from 'lodash'
import InfoBox from './InfoBox'

const targetingTypes = [
  'Browser',
  'Country',
  'Device',
  'OS',
  'URL Whitelist',
  'URL Blacklist',
]

const browsers = [
  'Chrome',
  'Edge',
  'IE',
  'Firefox',
  'Mobile Safari',
  'Safari',
  'Opera',
]

const operatingSystems = [
  'Android',
  'Chromium OS',
  'iOS',
  'Linux',
  'Mac OS',
  'Windows',
]

const devices = ['Desktop', 'Tablet', 'Mobile', 'SmartTV']

interface State {
  selectedTargetingTypes: string[]
}

interface TargetingChange {
  pathGlobs?: string[]
  pathBlacklistGlobs?: string[]
  targetingRules?: string[]
}

interface InputProps {
  gap: number
  errorMessages: { [fieldName: string]: string }
  disabled: boolean
  campaign: Campaign
  onChange: (args: TargetingChange) => any
}

export class TargetingForm extends React.Component<InputProps, State> {
  constructor(props: InputProps) {
    super(props)
    this.state = {
      selectedTargetingTypes: [],
    }
  }
  render() {
    let selectedTargetingTypes = [...this.state.selectedTargetingTypes]
    for (const [ruleType] of this.props.campaign.targetingRules.map((r) =>
      r.split('/')
    )) {
      if (selectedTargetingTypes.indexOf(ruleType) !== -1) {
        continue
      }
      selectedTargetingTypes.push(ruleType)
    }
    if (this.props.campaign.pathGlobs && this.props.campaign.pathGlobs.length) {
      selectedTargetingTypes.push('URL Whitelist')
    }
    if (
      this.props.campaign.pathBlacklistGlobs &&
      this.props.campaign.pathBlacklistGlobs.length
    ) {
      selectedTargetingTypes.push('URL Blacklist')
    }
    selectedTargetingTypes = uniq(selectedTargetingTypes).sort()
    return (
      <Stack gap={this.props.gap}>
        <ShownTagInput
          label=""
          selectedKeys={selectedTargetingTypes}
          onChange={(value?) => {
            if (!value) {
              return
            }
            const keys = value.map((i) => i.key).sort()
            const rules = this.props.campaign.targetingRules
            const targetingRules = rules.filter(
              (t) =>
                keys
                  .map((s) => s.toLowerCase())
                  .indexOf(t.split('/')[0].toLowerCase()) !== -1
            )
            const update = { targetingRules }
            this.setState({ selectedTargetingTypes: keys })
            if (keys.indexOf('URL Whitelist') === -1) {
              Object.assign(update, { pathGlobs: [] })
            }
            if (keys.indexOf('URL Blacklist') === -1) {
              Object.assign(update, { pathBlacklistGlobs: [] })
            }
            this.props.onChange(update)
          }}
          disabled={this.props.disabled}
          options={targetingTypes.map((key) => ({ key, name: key }))}
        />
        {selectedTargetingTypes.indexOf('Browser') !== -1 &&
          this.renderShownTagsSelector(
            'Browser/',
            browsers,
            'Target by Browser'
          )}
        {selectedTargetingTypes.indexOf('Country') !== -1 &&
          this.renderMultiselect(
            'Country/',
            countryCodes,
            'Select targeted country',
            'Target by Country'
          )}
        {selectedTargetingTypes.indexOf('Device') !== -1 &&
          this.renderShownTagsSelector('Device/', devices, 'Target by Device')}
        {selectedTargetingTypes.indexOf('OS') !== -1 &&
          this.renderShownTagsSelector('OS/', operatingSystems, 'Target by OS')}
        {selectedTargetingTypes.indexOf('URL Whitelist') !== -1 &&
          this.renderUrlTargetingSelector()}
        {selectedTargetingTypes.indexOf('URL Blacklist') !== -1 &&
          this.renderUrlBlacklistTargetingSelector()}
        {selectedTargetingTypes.indexOf('URL Whitelist') !== -1 &&
          selectedTargetingTypes.indexOf('URL Blacklist') !== -1 &&
          this.renderTargetingSelectorWarning()}
      </Stack>
    )
  }
  renderUrlTargetingSelector() {
    const inputText = this.props.campaign.pathGlobs.join('\n')
    return (
      <React.Fragment>
        <TextField
          label="Glob patterns for whitelisted urls (one per line)"
          value={inputText}
          multiline
          spellCheck={false}
          autoAdjustHeight
          resizable={false}
          autoComplete={'off'}
          onChange={(evt: any, value?: string) => {
            const pathGlobs = (value || '').split(/\n|\r/).map((s) => s.trim())
            this.props.onChange({ pathGlobs })
          }}
          disabled={this.props.disabled}
        />
        {this.renderGlobInfoMessage()}
      </React.Fragment>
    )
  }
  renderUrlBlacklistTargetingSelector() {
    const inputText = this.props.campaign.pathBlacklistGlobs.join('\n')
    return (
      <React.Fragment>
        <TextField
          label="Glob patterns for blacklisted urls (one per line)"
          value={inputText}
          multiline
          spellCheck={false}
          autoAdjustHeight
          resizable={false}
          autoComplete={'off'}
          onChange={(evt: any, value?: string) => {
            const pathBlacklistGlobs = (value || '')
              .split(/\n|\r/)
              .map((s) => s.trim())
            this.props.onChange({ pathBlacklistGlobs })
          }}
          disabled={this.props.disabled}
        />
        {this.renderGlobInfoMessage()}
      </React.Fragment>
    )
  }
  renderGlobInfoMessage() {
    return (
      <MessageBar
        messageBarType={MessageBarType.info}
        isMultiline={true}
        styles={{ root: { marginTop: 0 } }}
      >
        Glob is a pattern matching language to help match more than one url. In
        order for the pattern to work, make sure that the glob includes the full
        url matched, not only a part of it.
        <Link
          href="https://en.wikipedia.org/wiki/Glob_(programming)"
          target="_blank"
        >
          More info
        </Link>
        ,
        <Link
          href="https://www.digitalocean.com/community/tools/glob?comments=true&glob=&matches=false"
          target="_blank"
        >
          Test your pattern
        </Link>
      </MessageBar>
    )
  }
  renderTargetingSelectorWarning() {
    return (
      <MessageBar
        messageBarType={MessageBarType.warning}
        isMultiline={true}
        styles={{ root: { marginTop: 0 } }}
      >
        Both page whitelist and blacklist are used. Only pages that pass the
        whitelisted patterns will be compared to the url blacklist. If this is
        the intent, feel free to ignore the message
      </MessageBar>
    )
  }
  renderShownTagsSelector(type: string, options: string[], label: string) {
    const getRules = (type: string) =>
      this.props.campaign.targetingRules
        .filter((r) => r.indexOf(type) === 0)
        .map((r) => r.replace(type, ''))
        .sort()
    const opts = options.map((key) => {
      if (type === 'Country/') {
        return { key, name: countryCodeToName(key) }
      }
      return { key, name: key }
    })
    return (
      <ShownTagInput
        label={label}
        selectedKeys={getRules(type)}
        onChange={(value?) => {
          if (!value) {
            return
          }
          this.props.onChange({
            targetingRules: uniq([
              ...this.props.campaign.targetingRules.filter(
                (r) => r.split('/')[0] !== type.slice(0, -1)
              ),
              ...value.map((v) => `${type}${v.key}`),
            ]),
          })
        }}
        disabled={this.props.disabled}
        options={opts}
      />
    )
  }
  renderMultiselect(
    type: string,
    options: string[],
    placeholder: string,
    label: string
  ) {
    const getRules = (type: string) =>
      this.props.campaign.targetingRules
        .filter((r) => r.indexOf(type) === 0)
        .map((r) => r.replace(type, ''))
        .sort()
    const opts = options
      .map((key) => {
        if (type === 'Country/') {
          return { key, text: countryCodeToName(key) }
        }
        return { key, text: key }
      })
      .sort((a, b) => (a.text > b.text ? 1 : -1))
    return (
      <Stack gap={this.props.gap}>
        <Dropdown
          placeholder={placeholder}
          label={label}
          selectedKeys={getRules(type)}
          onChange={(evt, value?) => {
            if (!value) {
              return
            }
            const rules = getRules(type)
            const key = value.key.toString()
            if (value.selected && !rules.find((k) => k === key)) {
              this.props.onChange({
                targetingRules: [
                  ...this.props.campaign.targetingRules,
                  `${type}${key}`,
                ].sort(),
              })
            }
            if (!value.selected && rules.find((k) => k === key)) {
              this.props.onChange({
                targetingRules: this.props.campaign.targetingRules.filter(
                  (r) => r !== `${type}${key}`
                ),
              })
            }
          }}
          styles={{ dropdownItemsWrapper: { maxHeight: 300 } }}
          disabled={this.props.disabled}
          multiSelect
          options={opts}
        />
        {type === 'Country/' && (
          <InfoBox>
            Geo targeting uses GeoLite2 data created by MaxMind, available from
            <Link href="https://www.maxmind.com" target="_blank">
              https://www.maxmind.com
            </Link>
            .
          </InfoBox>
        )}
      </Stack>
    )
  }
}
