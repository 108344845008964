import React from 'react'
import { Stack, Icon, Text, Link, IconButton } from 'office-ui-fabric-react'
import { ChildProps, graphql } from 'react-apollo'
import {
  getAssetTags,
  AssetTagsResponse,
  removePartnershipTags,
  getAssetTagsQuery_v2,
} from '../../Api/campaigns'
import { ScreenHeader } from '../../Components/ScreenHeader'
import { ErrorScreen } from '../../Components/ErrorScreen'
import { TableView } from '../../Components/TableView'
import { AddTagsDialog } from './AddTagsDialog'
import './SharedInventoryScreen.css'

interface State {
  modal?: {
    type: 'add' | 'delete'
    row?: Row
  }
}

interface InputProps {}

interface Row {
  accountName: string
  collectionTitle: string
  collectionId: string
  tagName: string
}

class SharedInventoryScreenView extends React.Component<
  ChildProps<InputProps, AssetTagsResponse>,
  State
> {
  constructor(props: any) {
    super(props)
    this.state = {}
  }
  render() {
    const { error, loading } = this.props.data!
    const isLoading = loading
    if (error) {
      window.console.error(error)
      return this.renderError()
    }
    return (
      <Stack
        className="screen"
        horizontalAlign="start"
        verticalAlign="start"
        verticalFill
        style={{ width: '100%' }}
      >
        <ScreenHeader
          path={['3rd Party Inventory']}
          buttons={
            this.isAdmin()
              ? [
                  {
                    name: 'Add Partnership',
                    icon: 'Add',
                    disabled: isLoading,
                    onClick: () => this.setState({ modal: { type: 'add' } }),
                  },
                ]
              : []
          }
        />
        <Stack
          className="shared-inventory-screen"
          horizontalAlign="center"
          verticalAlign="start"
          horizontal
          wrap
          styles={{
            root: {
              width: '100%',
              overflow: 'auto',
            },
            inner: {
              padding: 60,
            },
          }}
        >
          {this.renderTable()}
        </Stack>
        {isLoading ? <></> : <>{this.renderAddTags()}</>}
      </Stack>
    )
  }
  renderAddTags() {
    if (!this.state.modal || this.state.modal.type !== 'add') {
      return <React.Fragment />
    }
    return (
      <AddTagsDialog
        getConfig={(vcid: string) => {
          const partnerships = this.props.data!.publisherPartnerships_v2
          if (!partnerships) {
            return
          }
          const tags = partnerships.reduce(
            (ret, p) => [
              ...ret,
              ...p.sharedAssetTags.filter((t) => t.collectionId === vcid),
            ],
            [] as {
              collectionTitle: string
              collectionId: string
              tagName: string
            }[]
          )
          if (!tags.length) {
            return
          }
          return {
            title: tags[0].collectionTitle,
            tags: tags.map((t) => t.tagName),
          }
        }}
        onClose={() => this.setState({ modal: undefined })}
        onUpdated={() => this.props.data!.refetch()}
      />
    )
  }
  renderTable() {
    const { loading } = this.props.data!
    const isLoading = loading
    const rows = this.getRows()
    if (!rows.length && !isLoading) {
      return this.renderEmpty()
    }
    return (
      <TableView
        cacheKey={''}
        columns={this.getColumns()}
        rowToCacheKey={() => ''}
        rows={isLoading ? undefined : rows}
        onRowClick={(row) => {}}
      />
    )
  }
  renderEmpty() {
    return (
      <Stack
        horizontalAlign="center"
        verticalAlign="center"
        verticalFill
        style={{ width: '100%' }}
      >
        <Text
          variant="xLarge"
          style={{
            maxWidth: 600,
            padding: 20,
            textAlign: 'center',
          }}
        >
          There is no 3rd party inventory shared with this account. Contact{' '}
          <Link href="mailto:info@caroda.io" target="_blank">
            info@caroda.io
          </Link>{' '}
          for getting access to a partner's shared inventory.
        </Text>
      </Stack>
    )
  }
  renderError() {
    return (
      <ErrorScreen
        errorText={`There was a problem fetching your shared tags`}
      />
    )
  }
  isAdmin() {
    return (
      this.props.data!.session! &&
      this.props.data!.session!.groupNames.indexOf('Admins') !== -1
    )
  }
  getRows() {
    return (this.props.data!.publisherPartnerships_v2! || []).reduce(
      (ret, p) => [
        ...ret,
        ...p.sharedAssetTags.map((t) => ({ ...t, accountName: p.accountName })),
      ],
      [] as Row[]
    )
  }
  getColumns() {
    return [
      {
        key: 'icon',
        name: '',
        fieldName: 'icon',
        minWidth: 20,
        maxWidth: 20,
        isRowHeader: true,
        isIconOnly: true,
        isPadded: false,
        onRender: (r: Row) => (
          <Icon
            iconName="Remote"
            style={{
              fontSize: 14,
              paddingLeft: 5,
              paddingTop: 3,
            }}
          />
        ),
      },
      {
        key: 'collectionTitle',
        name: 'Collection Name',
        fieldName: 'collectionTitle',
        isPadded: true,
        isRowHeader: true,
        minWidth: 150,
        maxWidth: 350,
      },
      {
        key: 'tagName',
        name: 'Tag Name',
        fieldName: 'tagName',
        minWidth: 150,
        isPadded: true,
      },
      {
        key: 'menu',
        name: '',
        fieldName: '',
        minWidth: 20,
        maxWidth: 20,
        isIconOnly: true,
        onRender: (r: Row) => {
          const empty = <React.Fragment />
          const removeButton = (
            <IconButton
              iconProps={{ iconName: 'clear' }}
              onClick={() => this.removeTag(r)}
            />
          )
          if (!this.isAdmin()) {
            return empty
          }
          if (r.tagName === '*') {
            return removeButton
          }
          const rows = this.getRows()
          const wildcardExists = rows.find(
            (r2) => r2.collectionId === r.collectionId && r2.tagName === '*'
          )
          if (wildcardExists) {
            return empty
          }
          return removeButton
        },
      },
    ]
  }
  async removeTag(row: Row) {
    await removePartnershipTags(row.accountName, row.collectionId, [
      row.tagName,
    ])
    await getAssetTags()
  }
}

const withGql = graphql<InputProps, AssetTagsResponse>(getAssetTagsQuery_v2, {
  options: () => ({ notifyOnNetworkStatusChange: true }),
})
const SharedInventoryScreen = withGql(SharedInventoryScreenView)

export { SharedInventoryScreen }
