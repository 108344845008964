import React, { useEffect } from 'react'
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Stack,
  Text,
  Toggle,
} from 'office-ui-fabric-react'
import { NumberInput } from '../../../Components/Input/NumberInput'
import { ChildProps, graphql } from 'react-apollo'
import {
  GlobalCampaignSettingsResponse,
  getGlobalCampaignSettingsQuery,
  updateGlobalCampaignSettings,
} from '../../../Api/campaigns'
import { BlockingLoadingDialog } from '../../../Components/BlockingLoadingDialog'

interface InputProps {
  onClose: () => void
  onUpdated: () => void
}

interface IGlobalCampaignSettings {
  maximumImpressionsPerMonth: number | null
}

const EditGlobalCampaignSettingsDialogView: React.FC<
  ChildProps<InputProps, GlobalCampaignSettingsResponse>
> = (props) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const { onClose, onUpdated, data } = props
  const { globalCampaignSettings: initialGlobalCampaignSettings } = data!

  const initialIsLimited = initialGlobalCampaignSettings
    ? initialGlobalCampaignSettings.maximumImpressionsPerMonth !== null
    : false
  const [isLimited, setIsLimited] = React.useState(initialIsLimited)
  const defaultGlobalCampaignSettings: IGlobalCampaignSettings = {
    maximumImpressionsPerMonth: null,
  }
  const [globalCampaignSettings, setGlobalCampaignSettings] =
    React.useState<IGlobalCampaignSettings>(
      initialGlobalCampaignSettings || defaultGlobalCampaignSettings
    )
  const gap = 16

  useEffect(() => {
    if (initialGlobalCampaignSettings) {
      setGlobalCampaignSettings(initialGlobalCampaignSettings)
      setIsLimited(
        initialGlobalCampaignSettings.maximumImpressionsPerMonth !== null
      )
    }
  }, [initialGlobalCampaignSettings])

  const submit = () => {
    validate(globalCampaignSettings)
    setIsSubmitting(true)
    updateGlobalCampaignSettings(
      globalCampaignSettings.maximumImpressionsPerMonth
    )
      .then(onUpdated)
      .then(onClose)
      .catch((e) => {
        console.error(e)
        setErrorMessage(
          "Couldn't update global campaign settings. If the problem persist, please contact support."
        )
        setIsSubmitting(false)
      })
    return false
  }

  const validate = (globalCampaignSettings: IGlobalCampaignSettings) => {
    if (
      globalCampaignSettings.maximumImpressionsPerMonth !== null &&
      globalCampaignSettings.maximumImpressionsPerMonth < 1
    ) {
      throw new Error('Maximum impressions per month must be greater than 0')
    }
  }

  const isSaveDisabled = (): boolean => {
    return (
      (globalCampaignSettings.maximumImpressionsPerMonth === null &&
        !initialIsLimited) ||
      isSubmitting
    )
  }

  if (data && data.loading) {
    return <BlockingLoadingDialog label="Loading" />
  }

  return (
    <Dialog
      hidden={false}
      modalProps={{
        isDarkOverlay: true,
        isBlocking: true,
        className: 'edit-global-campaign-settings-dialog',
      }}
      onDismiss={onClose}
      dialogContentProps={{
        type: DialogType.largeHeader,
        title: 'Global Campaign Settings',
      }}
    >
      <Stack gap={gap}>
        <Text>
          Modifying any setting in here not only affects this campaign
          collection, but the entire account.
        </Text>
        <Toggle
          label="Limit maximum monthly impressions"
          onText="On"
          offText="Off"
          checked={globalCampaignSettings.maximumImpressionsPerMonth !== null}
          onChange={(ev, value) => {
            setIsLimited(value || false)
            setGlobalCampaignSettings({
              ...globalCampaignSettings,
              maximumImpressionsPerMonth: value ? 1 : null,
            })
          }}
        />
        {isLimited && (
          <NumberInput
            label="Maximum impressions per month"
            disabled={isSubmitting}
            value={globalCampaignSettings.maximumImpressionsPerMonth}
            min={1}
            max={1e9}
            required={true}
            onChange={(value) => {
              setGlobalCampaignSettings({
                ...globalCampaignSettings,
                maximumImpressionsPerMonth: value || 1,
              })
            }}
          />
        )}
        {errorMessage && (
          <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
            {errorMessage}
          </MessageBar>
        )}
      </Stack>
      <DialogFooter>
        <DefaultButton
          onClick={onClose}
          disabled={isSubmitting}
          text="Cancel"
        />
        <PrimaryButton
          onClick={() => {
            submit()
          }}
          disabled={isSaveDisabled()}
          text="Save"
        />
      </DialogFooter>
    </Dialog>
  )
}

const withGql = graphql<InputProps, GlobalCampaignSettingsResponse>(
  getGlobalCampaignSettingsQuery,
  {
    options: () => ({
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
    }),
  }
)

export const EditGlobalCampaignSettingsDialog = withGql(
  EditGlobalCampaignSettingsDialogView
)
