import React from 'react'
import { DatePicker, IconButton } from 'office-ui-fabric-react'
import './SimpleDateInput.css'
import moment from 'moment'
import { addTimezoneOffset, removeTimezoneOffset } from '../../Utils/date'

interface State {}

type DayTime = 'day-start' | 'day-end'

interface InputProps {
  label: string
  onChange: (value?: Date) => any
  value: string | Date | undefined
  min?: string | number | Date
  max?: string | number | Date
  errorMessage?: string
  required?: boolean
  disabled?: boolean
  name?: string
  style?: React.CSSProperties
  storeAs: DayTime
}

export class SimpleDateInput extends React.Component<InputProps, State> {
  render() {
    return (
      <div
        className={
          'simple-date-picker' + (this.props.errorMessage ? ' has-error' : '')
        }
        style={{
          ...this.props.style,
          position: 'relative',
        }}
      >
        <DatePicker
          style={{
            width: '100%',
          }}
          label={this.props.label}
          value={removeTimezoneOffset(
            this.toDate(this.props.value, 'day-start')
          )}
          onSelectDate={(value) => {
            if (!value) {
              this.props.onChange()
              return
            }
            this.props.onChange(addTimezoneOffset(value))
          }}
          formatDate={(date) =>
            date ? moment(date).format('Do MMMM, YYYY') : ''
          }
          allowTextInput={false}
          isRequired={this.props.required}
          showCloseButton={false}
          isMonthPickerVisible={false}
          disabled={this.props.disabled}
          showWeekNumbers={true}
          firstWeekOfYear={1}
          showMonthPickerAsOverlay={false}
          placeholder="Select a date..."
          ariaLabel="Select a date"
          minDate={removeTimezoneOffset(
            this.toDate(this.props.min, 'day-start')
          )}
          maxDate={removeTimezoneOffset(this.toDate(this.props.max, 'day-end'))}
        />
        {!this.props.required && this.props.value && !this.props.disabled && (
          <div
            style={{
              position: 'absolute',
              top: 29,
              right: 30,
            }}
          >
            <IconButton
              className="clear-button"
              iconProps={{ iconName: 'Cancel' }}
              onClick={() => this.props.onChange(undefined)}
            />
          </div>
        )}
        {this.props.errorMessage && (
          <span data-automation-id="error-message">
            {this.props.errorMessage}
          </span>
        )}
      </div>
    )
  }
  private toDate(
    value: string | number | Date | undefined | null,
    storeAs: DayTime
  ) {
    if (!value) {
      return
    }
    if (storeAs === 'day-start') {
      return this.toDayTime(value, 'T00:00:00.000Z')
    }
    return this.toDayTime(value, 'T23:59:59.999Z')
  }
  private toDayTime(value: string | number | Date, timeString: string) {
    return new Date(new Date(value).toISOString().replace(/T.*$/, timeString))
  }
}
