import React from 'react'
import { Stack } from 'office-ui-fabric-react'
import { ChildProps, graphql } from 'react-apollo'
import {
  getAllInstreamPlacementCollectionSummariesQuery,
  InstreamPlacementCollectionSummary,
  InstreamPlacementCollectionSummaryResponse,
} from '../../Api/instreamPlacements_v2'
import { ScreenHeader } from '../../Components/ScreenHeader'
import { ErrorScreen } from '../../Components/ErrorScreen'
import { ConnectWithWebsiteDialog } from '../../Components/ConnectWithWebsiteDialog'
import { RenameInstreamPlacementCollectionDialog } from './RenameInstreamPlacementCollectionDialog'
import { DeleteInstreamPlacementCollectionDialog } from './DeleteInstreamPlacementCollectionDialog'
import { CreateInstreamPlacementDialog } from './CreateInstreamPlacementDialog'
import { InstreamPlacementList } from './InstreamPlacementList'
import { CollectionIndicationScreen } from '../../Components/CollectionIndicationScreen'
import { get } from 'lodash'

interface State {
  cursor: string
  isRenameModalOpen: boolean
  isDeleteModalOpen: boolean
  isConnectWithWebsiteModalOpen: boolean
  isCreatePlacementModalOpen: boolean
}

interface InputProps {
  collectionId: string
}

class InstreamPlacementCollectionScreenView extends React.Component<
  ChildProps<InputProps, InstreamPlacementCollectionSummaryResponse>,
  State
> {
  constructor(props: any) {
    super(props)
    this.state = {
      cursor: '.',
      isRenameModalOpen: false,
      isDeleteModalOpen: false,
      isConnectWithWebsiteModalOpen: false,
      isCreatePlacementModalOpen: false,
    }
  }
  render() {
    const { error, loading } = this.props.data!
    let responseError: Error | null = null
    if (
      !error &&
      !this.getCollection() &&
      this.props.data &&
      this.props.data!.instreamPlacementCollections_v2
    ) {
      responseError = new Error('Collection not found')
    }
    if (error || responseError) {
      window.console.error(error || responseError)
      return this.renderError()
    }
    const isLoading = loading || !this.getCollection()
    return (
      <Stack
        className="screen"
        horizontalAlign="start"
        verticalAlign="start"
        verticalFill
        style={{ width: '100%' }}
      >
        <ScreenHeader
          path={[
            'Instream Placements',
            isLoading ? 'Loading...' : this.getCollection()!.title,
          ]}
          buttons={[
            {
              name: 'Create instream placement',
              icon: 'BuildQueueNew',
              disabled: isLoading,
              onClick: () => this.createPlacement(),
            },
            {
              name: 'Connect with website',
              icon: 'Globe',
              disabled: isLoading,
              onClick: () => this.connectWithWebsite(),
            },
            {
              name: 'Collection settings',
              icon: 'ChevronDown',
              disabled: isLoading,
              menu: [
                {
                  name: 'Rename collection',
                  icon: 'edit',
                  onClick: () => this.renameCollectionPopup(),
                },
                {
                  name: 'Delete collection',
                  icon: 'delete',
                  onClick: () => this.deleteCollection(),
                },
              ],
            },
          ]}
        />
        <Stack
          className="instream-placements-collection-screen"
          horizontalAlign="center"
          verticalAlign="start"
          horizontal
          wrap
          styles={{
            root: {
              width: '100%',
              overflow: 'auto',
            },
            inner: {
              padding: 60,
            },
          }}
        >
          <InstreamPlacementList
            collectionId={this.props.collectionId}
            cursor={this.state.cursor}
          />
        </Stack>
        {isLoading ? (
          <></>
        ) : (
          <>
            {this.renderConnectWithWebsiteModal()}
            {this.renderRenameModal(this.props.data!.refetch)}
            {this.renderDeleteModal(this.props.data!.refetch)}
            {this.renderCreatePlacementDialog(this.props.data!.refetch)}
          </>
        )}
      </Stack>
    )
  }
  renderError() {
    return (
      <ErrorScreen
        errorText={`This video collection could not be found. Most likely, you've opened an older link of a collection
        that is now deleted. If the error persists and your collection can not be selected from the navigation menu,
        please contact support.`}
      />
    )
  }
  getCollection(): InstreamPlacementCollectionSummary | undefined {
    return get(this.props.data!, 'instreamPlacementCollections_v2', []).find(
      (c) => c.id === this.props.collectionId
    )
  }
  connectWithWebsite() {
    this.setState({ isConnectWithWebsiteModalOpen: true })
  }
  renameCollectionPopup() {
    this.setState({ isRenameModalOpen: true })
  }
  deleteCollection() {
    this.setState({ isDeleteModalOpen: true })
  }
  createPlacement() {
    this.setState({ isCreatePlacementModalOpen: true })
  }
  renderConnectWithWebsiteModal() {
    if (!this.state.isConnectWithWebsiteModalOpen) {
      return <React.Fragment />
    }
    return (
      <ConnectWithWebsiteDialog
        onClose={() => this.setState({ isConnectWithWebsiteModalOpen: false })}
      />
    )
  }
  renderRenameModal(refetch: () => void) {
    if (!this.state.isRenameModalOpen) {
      return <React.Fragment />
    }
    return (
      <RenameInstreamPlacementCollectionDialog
        collectionId={this.props.collectionId}
        onUpdated={() => refetch()}
        onClose={() => this.setState({ isRenameModalOpen: false })}
      />
    )
  }
  renderDeleteModal(refetch: () => void) {
    if (!this.state.isDeleteModalOpen) {
      return <React.Fragment />
    }
    return (
      <DeleteInstreamPlacementCollectionDialog
        collectionId={this.props.collectionId}
        onUpdated={() => refetch()}
        onClose={() => this.setState({ isDeleteModalOpen: false })}
      />
    )
  }
  renderCreatePlacementDialog(refetch: () => void) {
    if (!this.state.isCreatePlacementModalOpen) {
      return <React.Fragment />
    }
    return (
      <CreateInstreamPlacementDialog
        collectionId={this.props.collectionId}
        onUpdated={() => {
          refetch()
          this.setState({ cursor: '.' + Date.now() })
        }}
        onClose={() => this.setState({ isCreatePlacementModalOpen: false })}
      />
    )
  }
}

const withGql = graphql<InputProps, InstreamPlacementCollectionSummaryResponse>(
  getAllInstreamPlacementCollectionSummariesQuery,
  { options: () => ({ notifyOnNetworkStatusChange: true }) }
)
const InstreamPlacementCollectionScreenContent = withGql(
  InstreamPlacementCollectionScreenView
)

class InstreamPlacementCollectionScreen extends React.Component<
  ChildProps<InputProps, InstreamPlacementCollectionSummaryResponse>,
  State
> {
  render() {
    if (!this.props.collectionId) {
      return (
        <Stack
          className="screen"
          horizontalAlign="start"
          verticalAlign="start"
          verticalFill
          style={{ width: '100%' }}
        >
          <ScreenHeader path={['Instream Placement Assets']} buttons={[]} />
          <CollectionIndicationScreen text="Please select a placement collection from the navigation menu on the left." />
        </Stack>
      )
    }
    return (
      <InstreamPlacementCollectionScreenContent
        collectionId={this.props.collectionId}
      ></InstreamPlacementCollectionScreenContent>
    )
  }
}

export { InstreamPlacementCollectionScreen }
