import React from 'react'
import { TextField, Stack } from 'office-ui-fabric-react'
import { Campaign, CampaignTrackingHook } from '../../../Api/campaigns'
import { VastEvents } from '../../../Constants/VastEvents'
import { ShownTagInput } from '../../../Components/Input/ShownTagInput'

interface State {
  selectedTrackingTypes: string[]
}

interface InputProps {
  gap: number
  errorMessages: { [vastEvent: string]: string }
  disabled: boolean
  campaign: Campaign
  onChange: (trackingHooks: CampaignTrackingHook[]) => any
}

export class TrackingForm extends React.Component<InputProps, State> {
  constructor(props: InputProps) {
    super(props)
    this.state = {
      selectedTrackingTypes: [],
    }
  }
  render() {
    const selectedTrackingTypes = this.state.selectedTrackingTypes
    const trackingHooks = this.props.campaign.trackingHooks
    trackingHooks
      .filter((h) => h.trackingUrl)
      .forEach((h) => {
        if (selectedTrackingTypes.indexOf(h.vastEvent) !== -1) {
          return
        }
        selectedTrackingTypes.push(h.vastEvent)
      })
    const options = VastEvents.map((e, index) => ({
      key: e.key,
      name: e.label,
      index,
    }))
    const optionIndexByKey = options.reduce(
      (ret, o) => ({ ...ret, [o.key]: o.index }),
      {}
    ) as { [k: string]: number }
    selectedTrackingTypes.sort((a, b) =>
      optionIndexByKey[a]! > optionIndexByKey[b]! ? 1 : -1
    )
    return (
      <Stack gap={this.props.gap}>
        <ShownTagInput
          label=""
          selectedKeys={selectedTrackingTypes}
          onChange={(value?) => {
            if (!value) {
              return
            }
            this.setState({
              selectedTrackingTypes: value.map(({ key }) => key),
            })
            const trackingHooks = this.props.campaign.trackingHooks.filter(
              ({ vastEvent }) => value.find((v) => v.key === vastEvent)
            )
            this.props.onChange(trackingHooks)
          }}
          disabled={this.props.disabled}
          options={options}
        />
        {selectedTrackingTypes.map((eventType) => (
          <TextField
            key={eventType}
            label={`${VastEvents.find((e) => eventType === e.key)!.label.replace(' Tracking', '')} Tracking Urls (one per line)`}
            errorMessage={this.props.errorMessages[eventType]}
            value={trackingHooks
              .filter((h) => h.vastEvent === eventType)
              .map((h) => h.trackingUrl)
              .join('\n')}
            multiline
            spellCheck={false}
            autoAdjustHeight
            resizable={false}
            autoComplete={'off'}
            onChange={(evt: any, value?: string) => {
              const trackingHooks = this.props.campaign.trackingHooks
                .filter((t) => t.vastEvent !== eventType)
                .concat(
                  (value || '')
                    .split(/\n|\r/g)
                    .map((r) => r.trim())
                    .map((url) => ({
                      vastEvent: eventType,
                      trackingUrl: url,
                    }))
                )
              this.props.onChange(trackingHooks)
            }}
            disabled={this.props.disabled}
          />
        ))}
      </Stack>
    )
  }
}
