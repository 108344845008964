import React from 'react'
import {
  Icon,
  Stack,
  Text,
  IconButton,
  DirectionalHint,
  IColumn,
  TooltipHost,
  ProgressIndicator,
  ContextualMenuItemType,
  DefaultButton,
} from 'office-ui-fabric-react'
import { ChildProps, graphql } from 'react-apollo'
import moment from 'moment'
import {
  setCampaignStatus,
  setCampaignRestrictedStatus,
  CampaignSummaryResponse,
  CampaignSummary,
  Campaign,
  getCampaignsQuery_v2,
  CampaignCollectionSummary,
  getNextTitle,
  AssetTagsResponse,
} from '../../Api/campaigns'
import { TableView } from '../../Components/TableView'
import './CampaignList.css'
import { CancelCampaignDialog } from './CancelCampaignDialog'
import { DeleteCampaignDialog } from './DeleteCampaignDialog'
import { EditCampaignDialog } from './CampaignDialog/EditCampaignDialog'
import { ViewCampaignDialog } from './CampaignDialog/ViewCampaignDialog'
import { CreateCampaignDialog } from './CampaignDialog/CreateCampaignDialog'
import { removeTimezoneOffset } from '../../Utils/date'
import { isTemplateTitle } from '../../Functions/templateTitle'
import MigrationCampaignDialog from './CampaignDialog/MigrationCampaignDialog'
import { getAssetTags } from '../../Functions/assetTags'
import { ToastContainer, toast } from 'react-toastify'
import { SessionContext } from '../../Contexts/SessionContext'

interface State {
  openModal?: {
    modalType: 'cancel' | 'delete' | 'template'
    campaign: CampaignRowItem
  }
  openMigrateModal?: boolean
  loadingStatusChanges: { id: string; newStatus: CampaignStatus }[]
  selectedCampaigns: Campaign[]
}

interface InputProps {
  collectionId: string
  campaignCollections?: CampaignCollectionSummary[]
  isAdminOrAccountManager: boolean
  selectCampaignMode: boolean
  onCancel: () => void
}

type CampaignStatus =
  | 'pending_live'
  | 'live'
  | 'pending_paused'
  | 'paused'
  | 'transcoding'
  | 'transcoding_failed'
  | 'not_started'
  | 'expired'
  | 'limited'
  | 'cancelled'

export interface CampaignRowItem extends CampaignSummary {
  status: CampaignStatus
  collectionId: string
  cappingEventType: string | null
  totalDays?: number
  startDate: string
  endDate: string | null
  daysSinceStarted: number
  displayedTargetedAssets: string[]
}

class CampaignListView extends React.Component<
  ChildProps<InputProps, CampaignSummaryResponse>,
  State
> {
  static contextType = SessionContext
  context!: React.ContextType<typeof SessionContext>

  constructor(params: any) {
    super(params)
    this.state = {
      openModal: undefined,
      loadingStatusChanges: [],
      selectedCampaigns: [],
    }
  }
  render() {
    const { error } = this.props.data!
    if (error) {
      window.console.error(error)
      return this.renderError()
    }
    const assets = this.getAssets()
    const columns = this.getColumns(this.props.data!.refetch)
    if (assets && assets.length === 0) {
      return this.renderEmpty()
    }

    return (
      <React.Fragment>
        <ToastContainer />
        <TableView
          cacheKey={this.props.collectionId}
          columns={columns}
          postSort={(a, b, _) => {
            if (a.status === 'cancelled' && b.status !== 'cancelled') return 1
            if (a.status !== 'cancelled' && b.status === 'cancelled') return -1
            return 0
          }}
          rowToCacheKey={(row) => row.collectionId}
          rows={assets}
          onRowClick={(row: CampaignRowItem, event: { button: number }) => {
            if (event.button === 1) {
              window.open(
                `${process.env.PUBLIC_URL}/#/${this.context.ctok}/campaigns/${row.collectionId}/#/edit/${row.id}`,
                '_blank'
              )
            } else {
              this.editCampaign(row)
            }
          }}
          isSelectable={this.props.selectCampaignMode}
          onSelectionChanged={(selection) =>
            this.setState({
              selectedCampaigns: selection.map((s) => s as Campaign),
            })
          }
          onCancel={this.props.onCancel}
        />
        {this.renderCancelCampaignDialog(this.props.data!.refetch)}
        {this.renderDeleteCampaignDialog(this.props.data!.refetch)}
        {this.renderEditCampaignDialog(this.props.data!.refetch)}
        {this.renderTemplateCampaignDialog(this.props.data!.refetch)}
        {this.renderMigrationDialog(this.props.data!.refetch)}
        {this.props.selectCampaignMode && this.renderMigrateButton()}
      </React.Fragment>
    )
  }
  renderError() {
    return (
      <Stack
        horizontalAlign="center"
        verticalAlign="center"
        verticalFill
        style={{ width: '100%' }}
      >
        <Text
          variant="xLarge"
          style={{
            maxWidth: 600,
            padding: 20,
            textAlign: 'center',
          }}
        >
          This placement collection could not be found. Most likely, you've
          opened an older link of a collection that is now deleted. If the error
          persists and your collection can not be selected from the navigation
          menu, please contact support.
        </Text>
      </Stack>
    )
  }
  renderEmpty() {
    return (
      <Stack
        horizontalAlign="center"
        verticalAlign="center"
        verticalFill
        style={{ width: '100%' }}
      >
        <Text
          variant="xLarge"
          style={{
            maxWidth: 600,
            padding: 20,
            textAlign: 'center',
          }}
        >
          This campaign collection is empty. You can add a campaign by pressing
          the <i>Create campaign</i>{' '}
          <Icon iconName="Add" style={{ verticalAlign: 'middle' }} />
          &nbsp;button.
        </Text>
      </Stack>
    )
  }
  renderCancelCampaignDialog(refetch: () => void) {
    if (!this.state.openModal || this.state.openModal!.modalType !== 'cancel') {
      return <React.Fragment />
    }
    return (
      <CancelCampaignDialog
        collectionId={this.props.collectionId}
        campaignInfo={this.state.openModal!.campaign}
        onUpdated={() => refetch()}
        onClose={() => this.setState({ openModal: undefined })}
      />
    )
  }
  renderDeleteCampaignDialog(refetch: () => void) {
    if (!this.state.openModal || this.state.openModal!.modalType !== 'delete') {
      return <React.Fragment />
    }
    return (
      <DeleteCampaignDialog
        collectionId={this.props.collectionId}
        campaignInfo={this.state.openModal!.campaign}
        onUpdated={() => refetch()}
        onClose={() => this.setState({ openModal: undefined })}
      />
    )
  }
  renderTemplateCampaignDialog(refetch: () => void) {
    if (
      !this.state.openModal ||
      this.state.openModal!.modalType !== 'template'
    ) {
      return <React.Fragment />
    }

    const getBeginningOfDayTimestamp = () => {
      const date = new Date()
      date.setUTCHours(0, 0, 0, 0)
      return date.getTime()
    }
    const getTimestamp = (date: string) => new Date(date).getTime()

    const oldCampaign = this.props.data!.campaignCollections![0]!.assets.find(
      (c) => c.id === this.state.openModal!.campaign.id
    ) as Campaign | undefined
    let newCampaign: Campaign | undefined = undefined
    if (oldCampaign) {
      newCampaign = { ...oldCampaign }
      if (
        !isTemplateTitle(
          oldCampaign.title,
          oldCampaign,
          this.props.data! as AssetTagsResponse
        )
      ) {
        newCampaign.title = getNextTitle(oldCampaign.title)
      }
      newCampaign.creationDate = new Date().toISOString()
      if (getTimestamp(oldCampaign.startDate) >= getBeginningOfDayTimestamp()) {
        newCampaign.startDate = oldCampaign.startDate
        newCampaign.endDate = oldCampaign.endDate
      } else {
        newCampaign.startDate = newCampaign.creationDate
        newCampaign.endDate = null
      }
      newCampaign.collectionId = this.props.collectionId
    }
    return (
      <CreateCampaignDialog
        collectionId={this.props.collectionId}
        isTemplateMode={true}
        campaignCollections={this.props.campaignCollections}
        initialCampaign={newCampaign}
        isAdminOrAccountManager={this.props.isAdminOrAccountManager}
        onUpdated={() => refetch()}
        onClose={() => this.setState({ openModal: undefined })}
      />
    )
  }
  renderEditCampaignDialog(refetch: () => void) {
    const id = this.getCampaignIdFromHash()
    if (!id) {
      return <React.Fragment />
    }
    const campaignCollections = this.props.data!.campaignCollections
    if (!campaignCollections || !campaignCollections[0]) {
      return <React.Fragment />
    }
    const c = campaignCollections[0].assets.find((c) => c.id === id) || {
      status: 'cancelled',
    }
    const isPaused = c.status === 'paused'
    const isLive = c.status === 'live' || c.status === 'transcoding'
    const notStarted = c.status === 'not_started'
    const isActive = isLive || isPaused || notStarted
    if (!isActive) {
      return (
        <ViewCampaignDialog
          collectionId={this.props.collectionId}
          id={id}
          onUpdated={() => this.templateCampaign(c as CampaignRowItem)}
          isAdminOrAccountManager={this.props.isAdminOrAccountManager}
          onClose={() => this.clearCampaignIdFromHash()}
        />
      )
    }
    return (
      <EditCampaignDialog
        collectionId={this.props.collectionId}
        id={id}
        isAdminOrAccountManager={this.props.isAdminOrAccountManager}
        onUpdated={() => refetch()}
        onClose={() => this.clearCampaignIdFromHash()}
      />
    )
  }
  renderMigrateButton() {
    return (
      <Stack
        horizontal
        horizontalAlign="start"
        styles={{
          root: {
            padding: 5,
            position: 'absolute',
            transition: 'all 0.3s',
            width: 'auto',
            top: 160,
            right: 20,
          },
        }}
      >
        <DefaultButton
          onClick={() => {
            this.setState({ openMigrateModal: true })
          }}
          text={'Migrate'}
          disabled={this.state.selectedCampaigns.length === 0}
          iconProps={{ iconName: 'Rocket' }}
          styles={{ root: { width: 120 } }}
        />
      </Stack>
    )
  }
  renderMigrationDialog(refetch: () => void) {
    if (this.state.openMigrateModal !== true) {
      return <React.Fragment />
    }
    const originalTags = getAssetTags(this.props.data! as AssetTagsResponse)
    const hasCorrectId = (collection: CampaignCollectionSummary) =>
      collection!.id === this.props.collectionId
    return (
      <MigrationCampaignDialog
        assetTagInfo={this.props.data! as AssetTagsResponse}
        campaignsToMigrate={this.state.selectedCampaigns}
        originalCollectionTitle={
          this.props.data!.campaignCollections!.find(hasCorrectId)!.title
        }
        originalTags={originalTags}
        onUpdated={() => refetch()}
        onClose={() => {
          this.setState({ selectedCampaigns: [] })
          this.props.onCancel()
          this.setState({ openMigrateModal: false })
        }}
        onSuccess={({ campaignTitle, user }) =>
          toast.success(`campaign ${campaignTitle} was created for ${user}`)
        }
      />
    )
  }
  getAssets(): CampaignRowItem[] | undefined {
    const campaignCollections = this.props.data!.campaignCollections!
    const campaignCollection = campaignCollections && campaignCollections[0]
    const apiAssets = campaignCollection && campaignCollection.assets
    if (!apiAssets) {
      return
    }
    return apiAssets.map((c) => {
      const dayInMs = 24 * 60 * 60e3
      const totalDays = c.endDate
        ? Math.floor(
            (new Date(c.endDate).getTime() - new Date(c.startDate).getTime()) /
              dayInMs
          )
        : undefined
      const lastActiveDay =
        c.status === 'limited'
          ? c.lastEventTs!
          : Math.min(Date.now(), new Date(c.endDate || Date.now()).getTime())
      const daysSinceStarted = Math.min(
        Math.max(
          Math.floor(
            (new Date(lastActiveDay).getTime() -
              new Date(c.startDate).getTime()) /
              dayInMs
          ),
          0
        ),
        totalDays || 1e9
      )
      return {
        ...c,
        status: c.status as CampaignStatus,
        endDate: c.endDate ? c.endDate : '',
        cappingEventType: c.cappingEventType || null,
        eventCount: c.eventCount,
        lastCappingEventType: c.lastCappingEventType,
        collectionId: campaignCollection.id,
        totalDays,
        daysSinceStarted,
        displayedTargetedAssets: c.targetedAssets
          .map((t) => t.split('/'))
          .map(([collectionId, tagName]) => {
            const {
              outstreamPlacementCollections_v2 = [],
              instreamPlacementCollections_v2 = [],
            } = this.props.data!
            const outstreamPlacementCollection_v2 =
              outstreamPlacementCollections_v2!.find(
                (c) => c.id === collectionId
              )
            if (outstreamPlacementCollection_v2) {
              const asset = outstreamPlacementCollection_v2.assets.find(
                ({ id }) => id === tagName
              )
              const displayTagName = asset ? asset.title : tagName
              return [outstreamPlacementCollection_v2.title, displayTagName]
            }
            const instreamPlacementCollection_v2 =
              instreamPlacementCollections_v2!.find(
                (c) => c.id === collectionId
              )
            if (instreamPlacementCollection_v2) {
              const asset = instreamPlacementCollection_v2.assets.find(
                ({ id }) => id === tagName
              )
              const displayTagName = asset ? asset.title : tagName
              return [instreamPlacementCollection_v2.title, displayTagName]
            }
            const partnershipTag = this.props
              .data!.publisherPartnerships_v2!.reduce(
                (ret, p) => [...ret, ...p.sharedAssetTags],
                [] as { collectionId: string; collectionTitle: string }[]
              )
              .find((p) => p.collectionId === collectionId)
            if (partnershipTag) {
              return [partnershipTag.collectionTitle, tagName]
            }
            if (collectionId === 'integrations') {
              return [tagName]
            }
            return null
          })
          .filter(Boolean)
          .map((t) => t!.join('/')),
      }
    })
  }

  getColumns(refetch: () => Promise<any>): IColumn[] {
    return [
      {
        key: 'status',
        name: '',
        fieldName: 'status',
        minWidth: 20,
        maxWidth: 20,
        isRowHeader: true,
        isIconOnly: true,
        isPadded: false,
        onRender: (a: CampaignRowItem) => this.renderStatusIcon(a, refetch),
      },
      {
        key: 'name',
        name: 'Title',
        fieldName: 'title',
        isRowHeader: true,
        isPadded: true,
        minWidth: 150,
        maxWidth: 350,
        onRender: (a: CampaignRowItem) => (
          <a
            className="title-link-class"
            href={`${window.location.hash}/#/edit/${a.id}`.replace(/\/+/g, '/')}
          >
            <span>{a.title}</span>
          </a>
        ),
      },
      {
        key: 'progress',
        name: 'Progress',
        fieldName: 'eventCount',
        minWidth: 100,
        maxWidth: 100,
        isPadded: false,
        onRender: (a: CampaignRowItem) => this.renderEventProgressCell(a),
      },
      {
        key: 'startDate',
        name: 'Start Date',
        fieldName: 'startDate',
        minWidth: 100,
        maxWidth: 100,
        isPadded: false,
        onRender: (a: CampaignRowItem) => (
          <span>{moment(a.startDate).format('DD/MM/YYYY')}</span>
        ),
      },
      {
        key: 'endDate',
        name: 'End Date',
        fieldName: 'endDate',
        minWidth: 100,
        maxWidth: 100,
        isPadded: true,
        onRender: (a: CampaignRowItem) => this.renderTimeProgressCell(a),
      },
      {
        key: 'tags',
        name: 'Tags',
        fieldName: 'displayedTargetedAssets',
        minWidth: 150,
        maxWidth: 500,
        isPadded: true,
        onRender: (a: CampaignRowItem) => (
          <span>{a.displayedTargetedAssets.join(', ')}</span>
        ),
      },
      {
        key: 'priority',
        name: 'Priority',
        fieldName: 'priority',
        minWidth: 30,
        maxWidth: 100,
        isPadded: true,
        onRender: (a: CampaignRowItem) => <span>{a.priority}</span>,
      },
      {
        key: 'menu',
        name: '',
        fieldName: '',
        minWidth: 20,
        maxWidth: 20,
        isIconOnly: true,
        onRender: (a: CampaignRowItem) => (
          <IconButton
            styles={{
              root: {
                height: 18,
              },
              menuIcon: {
                fontWeight: 'bold',
              },
            }}
            menuProps={{
              coverTarget: true,
              directionalHint: DirectionalHint.topCenter,
              items: [
                {
                  key: 'resume',
                  text: 'Resume',
                  iconProps: { iconName: 'streaming' },
                  onClick: () => this.toggleStatus(a, refetch),
                },
                {
                  key: 'pause',
                  text: 'Pause',
                  iconProps: { iconName: 'pause' },
                  onClick: () => this.toggleStatus(a, refetch),
                },
                {
                  key: 'divider_1',
                  itemType: ContextualMenuItemType.Divider,
                },
                {
                  key: 'edit',
                  text: 'Edit',
                  iconProps: { iconName: 'edit' },
                  onClick: () => this.editCampaign(a),
                },
                {
                  key: 'view',
                  text: 'View',
                  iconProps: { iconName: 'view' },
                  onClick: () => this.editCampaign(a),
                },
                {
                  key: 'template',
                  text: 'Use as template',
                  iconProps: { iconName: 'copy' },
                  onClick: () => this.templateCampaign(a),
                },
                {
                  key: 'divider_2',
                  itemType: ContextualMenuItemType.Divider,
                },
                {
                  key: 'cancel',
                  text: 'Cancel',
                  iconProps: { iconName: 'cancel' },
                  onClick: () => this.cancelCampaignPopup(a),
                },
                {
                  key: 'delete',
                  text: 'Delete',
                  iconProps: { iconName: 'delete' },
                  onClick: () => this.deleteCampaign(a),
                },
                {
                  key: 'restrict',
                  text: 'Restrict',
                  iconProps: { iconName: 'lock' },
                  onClick: () => this.toggleRestrictedStatus(a, refetch),
                },
                {
                  key: 'unrestrict',
                  text: 'Unrestrict',
                  iconProps: { iconName: 'unlock' },
                  onClick: () => this.toggleRestrictedStatus(a, refetch),
                },
              ].filter((item) => {
                const isPaused = a.status === 'paused'
                const isLive = a.status === 'live'
                const isTranscoding = a.status === 'transcoding'
                const notStarted = a.status === 'not_started'
                const isActive =
                  isLive || isPaused || notStarted || isTranscoding
                if (item.key === 'resume') {
                  return isPaused
                }
                if (item.key === 'pause') {
                  return isLive
                }
                if (item.key === 'cancel') {
                  return (
                    isActive &&
                    !(a.restricted && !this.props.isAdminOrAccountManager)
                  )
                }
                if (item.key === 'edit') {
                  return isActive
                }
                if (item.key === 'view') {
                  return !isActive
                }
                if (item.key === 'delete') {
                  return !isActive
                }
                if (item.key === 'restrict') {
                  return (
                    this.props.isAdminOrAccountManager &&
                    !(a.restricted || a.asset.customVast3)
                  )
                }
                if (item.key === 'unrestrict') {
                  return this.props.isAdminOrAccountManager && a.restricted
                }
                if (item.key === 'template') {
                  return this.isValidTemplate(a)
                }
                return true
              }),
            }}
          />
        ),
      },
    ]
  }
  renderEventProgressCell(c: CampaignRowItem) {
    if (c.restricted) {
      return <span>{'Restricted'}</span>
    }
    const eventCountAndLimitString = c.eventLimit
      ? `${this.shortNumber(c.eventCount)} / ${this.shortNumber(c.eventLimit)}`
      : `${this.shortNumber(c.eventCount)}`
    const eventString = ` ${eventTypeToDisplay(c.cappingEventType || 'impression', c.eventLimit || c.eventCount)}`
    const [r, g, b] = [255, 171, 0]
    const styles = this.getProgressStyles(r, g, b)
    const isAvailable = c.lastCappingEventType === c.cappingEventType
    const progressHidden = !c.eventLimit || !isAvailable
    if (!progressHidden) {
      styles.itemName.marginTop = -2
    }
    const label = isAvailable ? (
      eventCountAndLimitString + eventString
    ) : (
      <i>fetching updates...</i>
    )
    const percentComplete = c.eventCount / (c.eventLimit || 1e9)
    return (
      <ProgressIndicator
        label={label}
        percentComplete={percentComplete}
        progressHidden={progressHidden}
        styles={styles}
      />
    )
  }
  renderTimeProgressCell(c: CampaignRowItem) {
    const endDate = c.endDate
      ? moment(removeTimezoneOffset(c.endDate)).format('DD/MM/YYYY')
      : ''
    const [r, g, b] = [1, 87, 155]
    const styles = this.getProgressStyles(r, g, b)
    const progressHidden = !c.totalDays
    if (!progressHidden) {
      styles.itemName.marginTop = -2
    }
    return (
      <ProgressIndicator
        label={endDate}
        percentComplete={c.daysSinceStarted / (c.totalDays || 0)}
        progressHidden={progressHidden}
        styles={styles}
      />
    )
  }
  shortNumber(n: number) {
    return (
      (n < 1e3 && `${n}`) ||
      (n < 1e6 && `${(n / 1e3).toFixed(2)}K`) ||
      (n < 1e9 && `${(n / 1e6).toFixed(2)}M`) ||
      `${(n / 1e9).toFixed(2)}B`
    ).replace(/\.0{1,2}(K|M|B)$/, '$1')
  }
  getProgressStyles(r: number, g: number, b: number) {
    return {
      itemName: {
        fontSize: 12,
        paddingTop: 2,
        lineHeight: 'unset',
        marginTop: 0,
      },
      itemProgress: {
        paddingBottom: 0,
        paddingTop: 4,
      },
      progressBar: { background: `rgba(${r}, ${g}, ${b}, 1)` },
      progressTrack: { background: `rgba(${r}, ${g}, ${b}, 0.3)` },
    }
  }
  renderStatusIcon(c: CampaignRowItem, refetch: () => Promise<any>) {
    const pendingStatus = this.state.loadingStatusChanges.find(
      (s) => s.id === c.id
    )
    const status = pendingStatus ? pendingStatus.newStatus : c.status
    const isButton = pendingStatus
      ? false
      : status === 'live' || status === 'paused'
    const statusText =
      (status === 'live' && 'Live. Click to pause.') ||
      (status === 'paused' && 'Paused. Click to resume.') ||
      (status === 'not_started' &&
        `Starting on ${moment(c.startDate).format('DD/MM/YYYY')}`) ||
      (status === 'expired' &&
        `Ended on ${moment(c.startDate).format('DD/MM/YYYY')}`) ||
      (status === 'limited' &&
        `Finished with ${this.shortNumber(c.eventCount)} events`) ||
      (status === 'transcoding' && `Waiting for asset transcoding`) ||
      (status === 'transcoding_failed' &&
        `Cancelled due to transcoding failure`) ||
      (status === 'cancelled' && `Cancelled`) ||
      `Unknown status "${c.status}"`
    return (
      <TooltipHost
        styles={{
          root: {
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
          },
        }}
        className={isButton ? '' : 'table-tooltip'}
        content={statusText}
        tooltipProps={{
          delay: 0,
          directionalHint: DirectionalHint.leftCenter,
        }}
      >
        <IconButton
          iconProps={{
            iconName:
              (status === 'live' && 'Streaming') ||
              (status === 'paused' && 'CirclePause') ||
              (status === 'limited' && 'CheckMark') ||
              (status === 'expired' && 'CheckMark') ||
              (status === 'not_started' && 'DateTime2') ||
              (status === 'transcoding' && 'DateTime2') ||
              'Blocked',
          }}
          className={pendingStatus ? 'pending' : ''}
          disabled={!isButton}
          onClick={async () => this.toggleStatus(c, refetch)}
        />
      </TooltipHost>
    )
  }
  async toggleStatus(c: CampaignRowItem, refetch: () => Promise<any>) {
    const isButton = c.status === 'live' || c.status === 'paused'
    if (!isButton) {
      return
    }
    const newStatus = c.status === 'live' ? 'paused' : 'live'
    this.setState({
      loadingStatusChanges: [
        ...this.state.loadingStatusChanges,
        { id: c.id, newStatus },
      ],
    })
    await setCampaignStatus(c.collectionId, c.id, newStatus)
    await refetch()
    this.setState({
      loadingStatusChanges: this.state.loadingStatusChanges.filter(
        (s) => s.id !== c.id
      ),
    })
  }
  async toggleRestrictedStatus(
    c: CampaignRowItem,
    refetch: () => Promise<any>
  ) {
    await setCampaignRestrictedStatus(c.collectionId, c.id, !c.restricted)
    await refetch()
  }
  editCampaign(c: CampaignRowItem) {
    this.setCampaignIdToHash(c.id)
  }
  templateCampaign(c: CampaignRowItem) {
    this.setState({
      openModal: {
        campaign: c,
        modalType: 'template',
      },
    })
  }
  isValidTemplate(c: CampaignRowItem) {
    return (
      (!c.restricted || this.props.isAdminOrAccountManager) &&
      !['transcoding_failed', 'transcoding'].includes(c.status)
    )
  }
  cancelCampaignPopup(c: CampaignRowItem) {
    this.setState({
      openModal: {
        campaign: c,
        modalType: 'cancel',
      },
    })
  }
  async cancelCampaign(c: CampaignRowItem, refetch: () => Promise<any>) {
    const newStatus = 'cancelled'
    this.setState({
      loadingStatusChanges: [
        ...this.state.loadingStatusChanges,
        { id: c.id, newStatus },
      ],
    })
    await setCampaignStatus(c.collectionId, c.id, newStatus)
    await refetch()
    this.setState({
      loadingStatusChanges: this.state.loadingStatusChanges.filter(
        (s) => s.id !== c.id
      ),
    })
  }
  deleteCampaign(campaign: CampaignRowItem) {
    this.setState({
      openModal: { campaign, modalType: 'delete' },
    })
  }
  setCampaignIdToHash(id: string) {
    this.clearCampaignIdFromHash()
    window.location.hash = `${window.location.hash}/#/edit/${id}`.replace(
      /\/+/g,
      '/'
    )
  }
  getCampaignIdFromHash() {
    const hash = window.location.hash
    const pathMatch = hash.match(/\/#\/edit\/([^/]+)$/)
    if (!pathMatch) {
      return
    }
    return pathMatch[1]
  }
  clearCampaignIdFromHash() {
    const hash = window.location.hash
    window.location.hash = hash.replace(/\/#\/edit\/([^/]+)$/, '')
  }
}

function eventTypeToDisplay(eventType: string, events?: number): string {
  const eventTypeWithoutUnderscore = eventType.replace(/_/, ' ')
  if (!events || events !== 1) {
    return eventTypeWithoutUnderscore + 's'
  }
  return eventTypeWithoutUnderscore
}

const withGql = graphql<InputProps, CampaignSummaryResponse>(
  getCampaignsQuery_v2,
  {
    options: ({ collectionId }) => ({
      variables: {
        collectionId,
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
    }),
  }
)
const CampaignList = withGql(CampaignListView)

export { CampaignList }
