import React from 'react'
import { ChildProps, graphql } from 'react-apollo'
import {
  getDelegatedAccountsQuery,
  DelegatedAccountsResponse,
} from '../../Api/session'
import {
  AssetTagsResponse,
  addPartnershipTags,
  removePartnershipTags,
  getAssetTagsQuery_v2,
} from '../../Api/campaigns'
import {
  Stack,
  Dialog,
  DialogType,
  Separator,
  DialogFooter,
  DefaultButton,
  PrimaryButton,
  Dropdown,
  TextField,
  MessageBar,
  MessageBarType,
  ComboBox,
  IDropdownOption,
  ITag,
} from 'office-ui-fabric-react'
import { TagInput } from '../../Components/Input/TagInput'

interface FormState {}

interface FormInputProps {
  selectedAccount?: string
  selectedCollectionId?: string
  selectedTags?: string[]
  disabled: boolean
  onUpdated: (opts: { collectionId?: string; tags?: string[] }) => void
}

class AddTagsSelectorFormView extends React.Component<
  ChildProps<FormInputProps, AssetTagsResponse>,
  FormState
> {
  constructor(props: any) {
    super(props)
    this.state = {
      selectedCollectionId: undefined,
    }
  }
  render() {
    const { error, loading } = this.props.data!
    const collections = [
      ...(this.props.data!.outstreamPlacementCollections_v2! || []),
      ...(this.props.data!.instreamPlacementCollections_v2! || []),
    ] as Collection[]
    const selectedCollection = collections.find(
      (c) => c.id === this.props.selectedCollectionId
    )
    const collectionOptions = collections.map((c) => ({
      text: c.title,
      key: c.id,
    }))
    let tagOptions = selectedCollection
      ? [
          ...(selectedCollection.assets || []).flatMap((asset) =>
            asset.tags.flatMap((t) => ({ key: t, name: t }))
          ),
          ...(selectedCollection.tags || []).map((t) => ({ key: t, name: t })),
        ]
      : []
    tagOptions = replaceImplicitTagName(tagOptions, collections)
    const isDisabled =
      !this.props.selectedAccount || !!error || !!loading || this.props.disabled
    let selectedTags = this.props.selectedTags
      ? this.props.selectedTags.map((t) => ({ name: t, key: t }))
      : []
    selectedTags = replaceImplicitTagName(selectedTags, collections)
    return (
      <React.Fragment>
        <Dropdown
          label="Collection"
          selectedKey={this.props.selectedCollectionId || null}
          onChange={(evt, value?) => {
            if (!value) {
              return
            }
            this.props.onUpdated({
              collectionId: value.key.toString(),
              tags: [],
            })
          }}
          placeholder="Select a collection"
          required={true}
          options={collectionOptions}
          disabled={isDisabled}
        />
        <TagInput
          label="Tags Whitelist (leave empty to allow all)"
          itemLimit={100}
          selectedItems={selectedTags}
          onChange={(value) =>
            this.props.onUpdated({ tags: value ? value.map((v) => v.key) : [] })
          }
          disabled={isDisabled || !selectedCollection}
          pickerLabels={{
            noResults: 'no tags found',
            suggestionsHeader: '',
          }}
          onResolveSuggestions={(filter) =>
            tagOptions
              .filter(
                (t) =>
                  t.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1 &&
                  !selectedTags.find((t2) => t2.key === t.key)
              )
              .sort((a, b) =>
                a.key.toLowerCase() < b.key.toLowerCase() ? -1 : 1
              )
          }
        />
      </React.Fragment>
    )
  }
}

const formWithGql = graphql<FormInputProps, AssetTagsResponse>(
  getAssetTagsQuery_v2,
  {
    options: ({ selectedAccount }) => ({
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
      variables: { selectedAccount },
      context: { headers: { impersonate: selectedAccount } },
    }),
  }
)
const AddTagsSelectorForm = formWithGql(AddTagsSelectorFormView)

interface State {
  isSubmitting: boolean
  hasError: boolean
  collectionAlias: string
  selectedAccount?: string
  selectedCollectionId?: string
  selectedTags?: string[]
}

interface InputProps {
  getConfig: (vcid: string) => { tags: string[]; title: string } | undefined
  onClose: () => void
  onUpdated: () => void
}

class AddTagsDialogView extends React.Component<
  ChildProps<InputProps, DelegatedAccountsResponse>,
  State
> {
  constructor(props: any) {
    super(props)
    this.state = {
      isSubmitting: false,
      hasError: false,
      collectionAlias: '',
      selectedAccount: undefined,
    }
  }
  render() {
    if (!this.props.data!.session) {
      return <React.Fragment />
    }
    const accountOptions = this.props.data!.session!.delegatedAccounts.map(
      (a) => ({ text: a.displayName, key: a.id })
    )
    const accountOption = accountOptions.find(
      (o) => this.state.selectedAccount === o.key
    )
    const existingConfig =
      this.state.selectedCollectionId &&
      this.props.getConfig(this.state.selectedCollectionId)
    return (
      <Dialog
        hidden={false}
        modalProps={{
          isDarkOverlay: true,
          isBlocking: true,
        }}
        className="add-tags-model"
        onDismiss={this.props.onClose}
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: existingConfig ? 'Update Partnership' : 'Create Partnership',
        }}
      >
        <Stack gap={16}>
          <ComboBox
            label="Select a customer account to login as:"
            options={accountOptions}
            disabled={this.state.isSubmitting}
            onChange={(evt: any, value?: IDropdownOption) => {
              if (!value) {
                return
              }
              this.setState({
                selectedAccount: value.key.toString(),
                selectedCollectionId: undefined,
                selectedTags: undefined,
              })
            }}
            value={accountOption ? accountOption.text : ''}
            allowFreeform={true}
            styles={{
              optionsContainerWrapper: { maxHeight: 400 },
            }}
          />
          <AddTagsSelectorForm
            selectedAccount={this.state.selectedAccount}
            selectedCollectionId={this.state.selectedCollectionId}
            selectedTags={this.state.selectedTags}
            disabled={this.state.isSubmitting}
            onUpdated={(opts) => {
              if (opts.collectionId) {
                const oldConfig = this.props.getConfig(opts.collectionId)
                this.setState({
                  selectedCollectionId: opts.collectionId,
                  selectedTags: oldConfig ? oldConfig.tags : [],
                  collectionAlias: oldConfig ? oldConfig.title : '',
                })
                return
              }
              if (opts.tags) {
                this.setState({ selectedTags: opts.tags })
                return
              }
            }}
          />
          <TextField
            label={'Shared Collection Alias'}
            value={this.state.collectionAlias}
            autoComplete={'off'}
            spellCheck={false}
            onChange={(evt: any, value?: string) =>
              this.setState({ collectionAlias: value || '' })
            }
            disabled={
              this.state.isSubmitting || !this.state.selectedCollectionId
            }
            required
          />
          {this.state.hasError && (
            <MessageBar
              messageBarType={MessageBarType.error}
              isMultiline={true}
            >
              Could not save new tag config
            </MessageBar>
          )}
        </Stack>
        <Separator />
        <DialogFooter>
          <DefaultButton
            onClick={() => this.props.onClose()}
            disabled={this.state.isSubmitting}
            text={'Cancel'}
          />
          <PrimaryButton
            onClick={() => this.submit()}
            disabled={
              this.state.isSubmitting ||
              !this.state.selectedAccount ||
              !this.state.selectedCollectionId ||
              !this.state.collectionAlias ||
              this.state.hasError
            }
            text={existingConfig ? 'Save' : 'Create'}
          />
        </DialogFooter>
      </Dialog>
    )
  }
  async submit() {
    this.setState({ isSubmitting: true })
    const oldConfig = this.props.getConfig(this.state.selectedCollectionId!)
    if (oldConfig) {
      const removedTags = oldConfig.tags.filter((t) =>
        this.state.selectedTags!.find((t2) => t !== t2)
      )
      if (removedTags.length > 0) {
        await removePartnershipTags(
          this.state.selectedAccount!,
          this.state.selectedCollectionId!,
          removedTags
        )
      }
    }
    addPartnershipTags(
      this.state.selectedAccount!,
      this.state.selectedCollectionId!,
      this.state.collectionAlias.trim(),
      this.state.selectedTags && this.state.selectedTags.length
        ? this.state.selectedTags
        : ['*']
    )
      .then(() => this.props.onUpdated())
      .then(() => this.props.onClose())
      .catch((e) => {
        console.warn(e)
        this.setState({ hasError: true })
      })
      .then(() => this.setState({ isSubmitting: false }))
  }
}

const withGql = graphql<InputProps, DelegatedAccountsResponse>(
  getDelegatedAccountsQuery,
  { options: () => ({ notifyOnNetworkStatusChange: true }) }
)
export const AddTagsDialog = withGql(AddTagsDialogView)

interface Collection {
  id: string
  title: string
  tags?: []
  assets?: {
    id: string
    title: string
    tags: string[]
  }[]
}

function replaceImplicitTagName(
  selectedTags: ITag[],
  collections: Collection[]
): ITag[] {
  const assetIdToTitle = collections
    .flatMap((collection) => collection.assets || [])
    .reduce(
      (map, asset) => ({
        ...map,
        [asset.id]: asset.title,
      }),
      {} as { [assetId: string]: string }
    )
  console.log('replaceImplicitTagName', {
    selectedTags,
    collections,
    assetIdToTitle,
  })
  return selectedTags.map((selectedTag) => ({
    ...selectedTag,
    name:
      selectedTag.name.startsWith('ipz') || selectedTag.name.startsWith('opz')
        ? assetIdToTitle[selectedTag.name]
        : selectedTag.name,
  }))
}
