import React from 'react'
import {
  TextField,
  MessageBar,
  MessageBarType,
  Separator,
  ITag,
  Dropdown,
  IDropdownOption,
  INavLink,
  Stack,
} from 'office-ui-fabric-react'
import { ChildProps, graphql } from 'react-apollo'
import 'office-ui-fabric-react/dist/css/fabric.css'

import {
  createInstreamPlacement,
  updateInstreamPlacement,
  InstreamPlacementInfo,
  InstreamPlacementCollectionTagsResponse,
  getInstreamPlacementCollectionTagsQuery,
  InstreamPlacementPosition,
  InstreamPlacementMidrollPositionInfo,
  InstreamPlacementMidrollPositionUnit,
  OverrideSkipBehavior,
} from '../../Api/instreamPlacements_v2'
import LargeDialogWithSidebar from '../../Components/LargeDialogWithSidebar'
import { ErrorDialog } from '../../Components/ErrorDialog'
import { TagInput } from '../../Components/Input/TagInput'
import { uniq } from 'lodash'
import { isDefined } from '../../Utils/Fp'
import { scrollIntoFirstError } from '../../Components/Utils'
import { IntegrationLimitingForm } from '../../Components/Input/IntegrationLimitingForm'
import { NumberInput } from '../../Components/Input/NumberInput'
import { cloneDeep } from 'lodash'
import { IntegrationDropdown } from '../../Components/Input/IntegrationDropdown'
import { SkipBehaviorFields } from '../../Components/SkipBehaviorFields'

type FormErrorSet = { [fieldName: string]: string }
interface State {
  isLoading: boolean
  hasError: boolean
  modifiedInstreamPlacement: InstreamPlacementInfo
  minVideoLength: string
  maxVideoLength: string
  form: {
    errors: FormErrorSet
  }
}

interface InputProps {
  modifiedPlacement?: InstreamPlacementInfo
  collectionId: string
  onClose: () => void
  onUpdated: () => void
}

const VIDEO_LENGTH_REGEX = /^(?:(?:(\d{1,2}):)?([0-5]?\d):)?([0-5]?\d)$/ // [hh:][mm:]ss

const now = new Date().toISOString()

const defaultInstreamPlacement: InstreamPlacementInfo = {
  id: '',
  creationDate: now,
  title: '',
  integration: '',
  tags: [],
  position: 'preroll',
  priority: 5,
  overrideSkipBehavior: OverrideSkipBehavior.None,
  minVideoLength: 0,
  maxVideoLength: 0,
  whitelistGlobs: [],
  blacklistGlobs: [],
}

export class CreateInstreamPlacementDialogView extends React.Component<
  ChildProps<InputProps, InstreamPlacementCollectionTagsResponse>,
  State
> {
  constructor(props: any) {
    super(props)
    const state = {
      isLoading: false,
      hasError: false,
      form: { errors: {} },
      modifiedInstreamPlacement:
        cloneDeep(this.props.modifiedPlacement) || defaultInstreamPlacement,
    } as State
    this.state = state
    if (!this.props.modifiedPlacement) {
      return
    }
    if (!state.modifiedInstreamPlacement.whitelistGlobs) {
      state.modifiedInstreamPlacement.whitelistGlobs = []
    }
    if (!state.modifiedInstreamPlacement.blacklistGlobs) {
      state.modifiedInstreamPlacement.blacklistGlobs = []
    }

    state.minVideoLength =
      this.videoLengthSecondsToViewFormat(
        this.props.modifiedPlacement.minVideoLength
      ) || ''
    state.maxVideoLength =
      this.videoLengthSecondsToViewFormat(
        this.props.modifiedPlacement.maxVideoLength
      ) || ''
  }
  render() {
    const isLoading = this.state.isLoading
    const gap = 28
    const titleDescription = this.state.modifiedInstreamPlacement.title
    const isEdited =
      JSON.stringify(this.state.modifiedInstreamPlacement) !==
      JSON.stringify(this.props.modifiedPlacement)
    const dialogTitle =
      (this.props.modifiedPlacement
        ? 'Update Instream Placement'
        : 'Create Instream Placement') +
      (titleDescription ? ` "${titleDescription}"` : '') +
      (isEdited ? '*' : '')

    if (this.props.data!.error) {
      return (
        <ErrorDialog
          onClose={this.props.onClose}
          title="Network error"
          message="Could not fetch collection parameters. "
        />
      )
    }
    if (!this.props.data!.instreamPlacementCollections_v2) {
      return <React.Fragment />
    }
    return (
      <React.Fragment>
        <LargeDialogWithSidebar
          title={dialogTitle}
          isBlocking={true}
          buttons={[
            {
              name: 'Cancel',
              action: () => {
                this.close()
              },
              disabled: isLoading,
            },
            {
              name: this.props.modifiedPlacement ? 'Save' : 'Create',
              action: () => {
                this.submit()
                scrollIntoFirstError()
              },
              disabled: isLoading,
              primary: true,
            },
          ]}
          onClose={() => this.close()}
          sidebarWidth={170}
          categories={
            [
              {
                name: 'General',
                key: 'general',
                icon: 'FileASPX',
              },
              {
                name: 'Placement',
                key: 'placement',
                icon: 'Media',
              },
              {
                name: 'Behavior',
                key: 'behavior',
                icon: 'MaximumValue',
              },
              {
                name: 'Targeting',
                key: 'targeting',
                icon: 'Bullseye',
              },
            ].filter(Boolean) as INavLink[]
          }
        >
          {' '}
          {[
            {
              title: 'General',
              content: this.getGeneralForm(gap),
            },
            {
              title: 'Placement',
              content: this.getPlacementForm(gap),
            },
            {
              title: 'Behavior',
              content: this.getBehaviorForm(gap),
            },
            {
              title: 'Targeting',
              content: this.getTargetingForm(gap),
            },
          ]
            .filter(Boolean)
            .map((item: any, index, a) => (
              <div key={index}>
                {index !== 0 && (
                  <Separator styles={{ root: { margin: 32 } }}></Separator>
                )}
                <div>
                  <h2
                    id={item.title.toLowerCase()}
                    style={{ marginTop: 0, marginBottom: 42 }}
                  >
                    {item.title}
                  </h2>
                  {item.content}
                </div>
              </div>
            ))}
          {this.state.hasError && (
            <MessageBar
              messageBarType={MessageBarType.error}
              isMultiline={true}
              styles={{ root: { marginTop: 15 } }}
            >
              Could not create instream placement. If the problem persist,
              please contact support.
            </MessageBar>
          )}
        </LargeDialogWithSidebar>
      </React.Fragment>
    )
  }
  getGeneralForm(gap: number) {
    return (
      <Stack gap={gap}>
        <TextField
          label={'Name'}
          errorMessage={this.state.form.errors['title']}
          name={'title'}
          value={this.state.modifiedInstreamPlacement.title}
          autoComplete={'off'}
          onChange={(evt: any, value?: string) => {
            this.clearErrors()
            this.setState({
              modifiedInstreamPlacement: {
                ...this.state.modifiedInstreamPlacement,
                title: value!,
              },
            })
          }}
          disabled={this.state.isLoading}
          required
        />
        <IntegrationDropdown
          value={this.state.modifiedInstreamPlacement.integration}
          onChange={(integration) => {
            this.clearErrors()
            this.setState({
              modifiedInstreamPlacement: {
                ...this.state.modifiedInstreamPlacement,
                integration,
              },
            })
          }}
          disabled={this.state.isLoading}
          required={true}
          errorMessage={this.state.form.errors['integration']}
        />
        <TagInput
          label="Tags"
          onResolveSuggestions={(filter, tagList): ITag[] => {
            const tagCandidates = [
              ...this.props.data!.instreamPlacementCollections_v2![0]!.tags,
            ]
            let isNew = false
            if (tagCandidates.indexOf(filter) === -1) {
              tagCandidates.push(filter)
              isNew = true
            }
            const filteredTags = tagCandidates.filter(
              (tag) =>
                !tag.startsWith('ipz') &&
                !(tagList || []).find((t) => t.key === tag) &&
                tag.indexOf(filter) !== -1
            )
            return filteredTags.filter(isDefined).map((key) => ({
              key,
              name: key === filter && isNew ? `Create new tag "${key}""` : key,
            }))
          }}
          pickerLabels={{
            suggestionsHeader: 'Suggested Tags',
            noResults: 'Tag already exists',
          }}
          onChange={(value?: ITag[]) => {
            this.clearErrors()
            this.setState({
              modifiedInstreamPlacement: {
                ...this.state.modifiedInstreamPlacement,
                tags: uniq((value || []).map(({ key }) => key)),
              },
            })
          }}
          itemLimit={50}
          selectedItems={uniq(this.state.modifiedInstreamPlacement.tags)
            .filter((tag) => !tag.startsWith('ipz'))
            .map((key) => ({ key, name: key }))}
          disabled={this.state.isLoading}
        />
      </Stack>
    )
  }
  getPlacementForm(gap: number) {
    return (
      <Stack gap={gap}>
        <Dropdown
          placeholder="Select an option"
          label="Position"
          selectedKey={this.state.modifiedInstreamPlacement.position}
          options={[
            {
              key: 'preroll',
              text: 'Preroll - play at the start of the video',
            },
            { key: 'midroll', text: 'Midroll - playback during the content' },
            {
              key: 'postroll',
              text: 'Postroll - play at the end of the video',
            },
            {
              key: 'custom',
              text: 'Custom - the customer is responsible for integrating the VAST tag',
            },
          ]}
          onChange={(
            event: React.FormEvent<HTMLDivElement>,
            item: IDropdownOption | undefined
          ): void => {
            if (item) {
              this.setState({
                modifiedInstreamPlacement: {
                  ...this.state.modifiedInstreamPlacement,
                  position: item.key as InstreamPlacementPosition,
                  midrollPosition: {
                    break:
                      this.hasStateBreak() && item.key === 'midroll'
                        ? this.state.modifiedInstreamPlacement.midrollPosition!
                            .break
                        : 50,
                    unit:
                      this.hasStateUnit() && item.key === 'midroll'
                        ? this.state.modifiedInstreamPlacement.midrollPosition!
                            .unit
                        : 'percentage',
                  },
                },
              })
            }
          }}
          className="position-dropdown"
          disabled={this.state.isLoading}
        />
        {this.state.modifiedInstreamPlacement.position === 'custom' &&
          !!this.state.modifiedInstreamPlacement.id && (
            <>
              <Stack horizontal style={{ position: 'relative' }}>
                <TextField
                  label="Placement ID"
                  readOnly={true}
                  value={this.state.modifiedInstreamPlacement.id}
                  disabled={this.state.isLoading}
                />
              </Stack>
              <MessageBar messageBarType={MessageBarType.info}>
                In order to use a custom position, the “sync script” provided in
                the integration screen should be added to the page
                synchronously. To get a VAST url, call
                <pre>
                  carodaGetVastUrl("{this.state.modifiedInstreamPlacement.id}")
                </pre>
              </MessageBar>
            </>
          )}
        {this.state.modifiedInstreamPlacement.position === 'midroll' && (
          <React.Fragment>
            <Stack horizontal style={{ position: 'relative' }}>
              <NumberInput
                style={{
                  width: `calc(50% - ${gap / 2}px)`,
                }}
                label="Break position"
                errorMessage={
                  this.state.form.errors['placement-midrollPositionBreak']
                }
                name="midrollPositionBreak"
                value={
                  this.state.modifiedInstreamPlacement.midrollPosition!.break!
                }
                onChange={(value?: number) => {
                  this.clearErrors()
                  this.setState({
                    modifiedInstreamPlacement: {
                      ...this.state.modifiedInstreamPlacement,
                      midrollPosition: {
                        ...this.state.modifiedInstreamPlacement
                          .midrollPosition!,
                        break: value === undefined ? 50 : value!,
                      },
                    },
                  })
                }}
                disabled={this.state.isLoading}
                required
                min={0}
                max={1e9}
              />

              <div style={{ width: gap }} />
              <Dropdown
                styles={{
                  root: {
                    flexGrow: 1,
                    position: 'relative',
                  },
                }}
                placeholder="Select a unit"
                label="Unit"
                selectedKey={
                  this.state.modifiedInstreamPlacement.midrollPosition!.unit
                }
                options={[
                  { key: 'percentage', text: '%' },
                  { key: 'seconds', text: 'seconds' },
                ]}
                onChange={(
                  event: React.FormEvent<HTMLDivElement>,
                  item: IDropdownOption | undefined
                ): void => {
                  if (item) {
                    this.setState({
                      modifiedInstreamPlacement: {
                        ...this.state.modifiedInstreamPlacement,
                        midrollPosition: {
                          ...this.state.modifiedInstreamPlacement
                            .midrollPosition!,
                          unit: item.key as InstreamPlacementMidrollPositionUnit,
                        },
                      },
                    })
                  }
                }}
                className="midroll-position-unit"
                disabled={this.state.isLoading}
              />
            </Stack>
            <MessageBar messageBarType={MessageBarType.info}>
              {`Where to insert the midroll ad in the player`}
            </MessageBar>
          </React.Fragment>
        )}
        <Stack horizontal style={{ position: 'relative' }}>
          <NumberInput
            style={{
              width: `calc(50% - ${gap / 2}px)`,
            }}
            label={'Priority'}
            errorMessage={this.state.form.errors['priority']}
            name={'priority'}
            value={Number(this.state.modifiedInstreamPlacement.priority)}
            onChange={(value?: number) => {
              const defaultValue = 1
              this.clearErrors()
              this.setState({
                modifiedInstreamPlacement: {
                  ...this.state.modifiedInstreamPlacement,
                  priority: value === undefined ? defaultValue : value!,
                },
              })
            }}
            disabled={this.state.isLoading}
            required
            max={1000}
            min={1}
          />
        </Stack>
        <MessageBar messageBarType={MessageBarType.info}>
          {`The priority is used to decide the order of the placements are executed in when there’s more than one placement per ad break.`}
        </MessageBar>
      </Stack>
    )
  }

  hasStateBreak() {
    return (
      this.state.modifiedInstreamPlacement.midrollPosition &&
      this.state.modifiedInstreamPlacement.midrollPosition.break
    )
  }

  hasStateUnit() {
    return (
      this.state.modifiedInstreamPlacement.midrollPosition &&
      this.state.modifiedInstreamPlacement.midrollPosition.unit
    )
  }

  getBehaviorForm(gap: number) {
    return (
      <SkipBehaviorFields
        gap={gap}
        disabled={this.state.isLoading}
        errors={this.state.form.errors}
        onChange={({ overrideSkipBehavior, fixedSkipOffset }) => {
          this.clearErrors()
          this.setState({
            modifiedInstreamPlacement: {
              ...this.state.modifiedInstreamPlacement,
              overrideSkipBehavior: overrideSkipBehavior
                ? overrideSkipBehavior
                : this.state.modifiedInstreamPlacement.overrideSkipBehavior,
              fixedSkipOffset: fixedSkipOffset
                ? fixedSkipOffset
                : this.state.modifiedInstreamPlacement.fixedSkipOffset,
            },
          })
        }}
        overrideSkipBehavior={
          this.state.modifiedInstreamPlacement.overrideSkipBehavior
        }
        fixedSkipOffset={this.state.modifiedInstreamPlacement.fixedSkipOffset}
      />
    )
  }
  getTargetingForm(gap: number) {
    return (
      <Stack gap={gap}>
        <TextField
          label={'Minimum video length'}
          placeholder={'[hh:][mm:]ss'}
          errorMessage={this.state.form.errors['minVideoLength']}
          name={'minVideoLength'}
          value={this.state.minVideoLength}
          autoComplete={'off'}
          onChange={(evt: any, value?: string) => {
            this.clearErrors()
            this.setState({
              minVideoLength: value!,
            })
          }}
          disabled={this.state.isLoading}
        />
        <MessageBar messageBarType={MessageBarType.info}>
          {'Do not show position for content shorter than this number'}
        </MessageBar>

        <TextField
          label={'Maximum video length'}
          placeholder={'[hh:][mm:]ss'}
          errorMessage={this.state.form.errors['maxVideoLength']}
          name={'maxVideoLength'}
          value={this.state.maxVideoLength}
          autoComplete={'off'}
          onChange={(evt: any, value?: string) => {
            this.clearErrors()
            this.setState({
              maxVideoLength: value!,
            })
          }}
          disabled={this.state.isLoading}
        />
        <MessageBar messageBarType={MessageBarType.info}>
          {'Do not show position for content longer than this number'}
        </MessageBar>
        <IntegrationLimitingForm
          label=""
          gap={gap}
          integrationLimits={{
            whitelistGlobs:
              this.state.modifiedInstreamPlacement.whitelistGlobs || [],
            blacklistGlobs:
              this.state.modifiedInstreamPlacement.blacklistGlobs || [],
          }}
          disabled={this.state.isLoading}
          errorMessages={{}}
          onChange={(integrationLimits) => {
            const {
              whitelistGlobs = this.state.modifiedInstreamPlacement
                .whitelistGlobs,
              blacklistGlobs = this.state.modifiedInstreamPlacement
                .blacklistGlobs,
            } = integrationLimits
            this.setState({
              modifiedInstreamPlacement: {
                ...this.state.modifiedInstreamPlacement,
                blacklistGlobs: blacklistGlobs,
                whitelistGlobs: whitelistGlobs,
              },
            })
          }}
        />
      </Stack>
    )
  }
  clearErrors() {
    this.setState({
      hasError: false,
      form: {
        ...this.state.form,
        errors: {},
      },
    })
  }
  submit() {
    if (!this.validate()) {
      return
    }

    let midrollPosition: InstreamPlacementMidrollPositionInfo | null = null
    if (this.state.modifiedInstreamPlacement.position === 'midroll') {
      midrollPosition = {
        break: this.state.modifiedInstreamPlacement.midrollPosition!.break!,
        unit: this.state.modifiedInstreamPlacement.midrollPosition!.unit!,
      }
    }

    const priority = this.state.modifiedInstreamPlacement.priority

    const fixedSkipOffset =
      this.state.modifiedInstreamPlacement.fixedSkipOffset !== undefined &&
      this.state.modifiedInstreamPlacement.overrideSkipBehavior !==
        OverrideSkipBehavior.None
        ? this.state.modifiedInstreamPlacement.fixedSkipOffset
        : null

    const minVideoLength = this.videoLengthViewFormatToSeconds(
      this.state.minVideoLength
    )
    const maxVideoLength =
      this.videoLengthViewFormatToSeconds(this.state.maxVideoLength) || null
    const title = this.state.modifiedInstreamPlacement.title
    this.setState({ isLoading: true })
    const integration = this.state.modifiedInstreamPlacement.integration
    const isCreation = !this.props.modifiedPlacement
    const tags = this.state.modifiedInstreamPlacement.tags
    if (!isCreation) {
      tags.push(this.state.modifiedInstreamPlacement.id)
    }
    const whitelistGlobs =
      this.state.modifiedInstreamPlacement.whitelistGlobs!.filter(Boolean)
    const blacklistGlobs =
      this.state.modifiedInstreamPlacement.blacklistGlobs!.filter(Boolean)
    const mutationPromise = !isCreation
      ? updateInstreamPlacement(
          this.props.collectionId,
          this.props.modifiedPlacement!.id,
          title,
          integration,
          uniq(tags),
          this.state.modifiedInstreamPlacement.position,
          midrollPosition,
          priority,
          this.state.modifiedInstreamPlacement.overrideSkipBehavior!,
          fixedSkipOffset,
          minVideoLength,
          maxVideoLength,
          whitelistGlobs,
          blacklistGlobs
        )
      : createInstreamPlacement(
          this.props.collectionId,
          title,
          integration,
          uniq(tags),
          this.state.modifiedInstreamPlacement.position,
          midrollPosition,
          priority,
          this.state.modifiedInstreamPlacement.overrideSkipBehavior!,
          fixedSkipOffset,
          minVideoLength,
          maxVideoLength,
          whitelistGlobs,
          blacklistGlobs
        )
    mutationPromise
      .then((id) =>
        isCreation
          ? updateInstreamPlacement(
              this.props.collectionId,
              id,
              title,
              integration,
              uniq([...tags, id]),
              this.state.modifiedInstreamPlacement.position,
              midrollPosition,
              priority,
              this.state.modifiedInstreamPlacement.overrideSkipBehavior!,
              fixedSkipOffset,
              minVideoLength,
              maxVideoLength,
              whitelistGlobs,
              blacklistGlobs
            )
          : id
      )
      .then(() => this.close())
      .then(this.props.onUpdated)
      .catch((e) => {
        console.error(e)
        this.setState({
          hasError: true,
          isLoading: false,
        })
      })
    return false
  }
  validate() {
    const errors = {} as FormErrorSet

    Object.assign(errors, this.validateTitle())
    Object.assign(errors, this.validateIntegration())

    if (this.state.modifiedInstreamPlacement.position === 'midroll') {
      Object.assign(errors, this.validateMidrollPosition())
    }
    Object.assign(errors, this.validatePriority())
    Object.assign(errors, this.validateSkippedOffset())
    Object.assign(
      errors,
      this.validateVideoLengthText(this.state.minVideoLength, 'minVideoLength')
    )
    Object.assign(
      errors,
      this.validateVideoLengthText(this.state.maxVideoLength, 'maxVideoLength')
    )
    Object.assign(
      errors,
      this.validateVideoLengthValues(
        this.state.minVideoLength,
        this.state.maxVideoLength
      )
    )

    this.setState({ form: { errors } })
    const ret = Object.keys(errors).length === 0
    scrollIntoFirstError()
    return ret
  }

  validateTitle() {
    const errors = {} as FormErrorSet
    if (
      !this.state.modifiedInstreamPlacement.title ||
      !this.state.modifiedInstreamPlacement.title.trim()
    ) {
      errors['title'] = 'field is mandatory'
    }
    return errors
  }

  validateIntegration() {
    const errors = {} as FormErrorSet
    if (!this.state.modifiedInstreamPlacement.integration) {
      errors['integration'] = 'field is mandatory'
    }
    return errors
  }

  validateMidrollPosition() {
    const errors = {} as FormErrorSet

    if (
      !this.state.modifiedInstreamPlacement.midrollPosition!.break ||
      !this.state.modifiedInstreamPlacement.midrollPosition!.break
    ) {
      errors['placement-midrollPositionBreak'] = 'field is mandatory'
      return errors
    }
    if (
      isNaN(this.state.modifiedInstreamPlacement.midrollPosition!.break) ||
      this.state.modifiedInstreamPlacement.midrollPosition!.break < 0
    ) {
      errors['placement-midrollPositionBreak'] = 'field is numeric'
    }
    if (
      this.state.modifiedInstreamPlacement.midrollPosition!.unit ===
        'percentage' &&
      this.state.modifiedInstreamPlacement.midrollPosition!.break > 100
    ) {
      errors['placement-midrollPositionBreak'] = 'max percentage is 100'
    }
    return errors
  }

  validatePriority() {
    const errors = {} as FormErrorSet
    if (!this.state.modifiedInstreamPlacement.priority) {
      errors['priority'] = 'field is mandatory'
      return errors
    }
    if (this.state.modifiedInstreamPlacement.priority < 0) {
      errors['priority'] = 'priority must be a positive number'
    }
    return errors
  }

  validateSkippedOffset() {
    const errors = {} as FormErrorSet

    if (
      this.state.modifiedInstreamPlacement.overrideSkipBehavior ===
      OverrideSkipBehavior.Fixed
    ) {
      if (!this.state.modifiedInstreamPlacement.fixedSkipOffset) {
        errors['fixedSkipOffset'] = 'field is mandatory'
        return errors
      }
      if (this.state.modifiedInstreamPlacement.fixedSkipOffset < 0) {
        errors['fixedSkipOffset'] = 'field is positive numeric'
      }
    }
    return errors
  }

  validateVideoLengthText(videoLengthText: string, errorKey: string) {
    const errors = {} as FormErrorSet
    if (
      !(
        videoLengthText === '' ||
        videoLengthText === undefined ||
        VIDEO_LENGTH_REGEX.test(videoLengthText)
      )
    ) {
      errors[errorKey] = 'field should follow [hh:][mm:]ss pattern'
    }
    return errors
  }

  validateVideoLengthValues(minVideoLenght: string, maxVideoLength: string) {
    const errors = {} as FormErrorSet
    if (!minVideoLenght || !maxVideoLength) {
      return errors
    }
    const minVideoLenghtSeconds =
      this.videoLengthViewFormatToSeconds(minVideoLenght)
    const maxVideoLengthSeconds =
      this.videoLengthViewFormatToSeconds(maxVideoLength)
    if (minVideoLenghtSeconds >= maxVideoLengthSeconds) {
      errors['maxVideoLength'] =
        'Maximum video length should be greater than Minimum video length'
    }
    return errors
  }

  close() {
    this.props.onClose()
  }

  videoLengthViewFormatToSeconds(videoLengthText: string) {
    if (!videoLengthText) {
      return 0
    }
    const regexMatches = VIDEO_LENGTH_REGEX.exec(videoLengthText) || []
    const hours = regexMatches[1] || 0
    const minutes = regexMatches[2] || 0
    const seconds = regexMatches[3]
    return Number(hours) * 60 * 60 + Number(minutes) * 60 + Number(seconds)
  }
  videoLengthSecondsToViewFormat(videoLength: number | null) {
    if (videoLength === 0 || videoLength === null) {
      return ''
    }
    const hours = Math.floor(videoLength / 3600)
    const minutes = Math.floor((videoLength % 3600) / 60)
    const seconds = videoLength % 60

    return `${hours ? `${hours}:` : ''}${
      hours && minutes < 10 ? `0${minutes}:` : minutes ? `${minutes}:` : ''
    }${minutes && seconds < 10 ? `0${seconds}` : `${seconds}`}`
  }
}

const withGql = graphql<InputProps, InstreamPlacementCollectionTagsResponse>(
  getInstreamPlacementCollectionTagsQuery,
  {
    options: ({ collectionId }) => ({
      notifyOnNetworkStatusChange: true,
      variables: { collectionId },
    }),
  }
)
export const CreateInstreamPlacementDialog = withGql(
  CreateInstreamPlacementDialogView
)
