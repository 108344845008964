import React from 'react'
import { ConfirmationDialog } from '../../Components/ConfirmationDialog'
import { ChildProps, graphql } from 'react-apollo'
import {
  getAllVideoCollectionSummaryQuery,
  VideoCollectionSummaryResponse,
  deleteVideoCollection,
} from '../../Api/videoAssets'
import { get } from 'lodash'
import { SessionContext } from '../../Contexts/SessionContext'

interface State {
  isSubmitting: boolean
  hasError: boolean
}

interface InputProps {
  collectionId: string
  isOpen: boolean
  onClose: () => void
  onUpdated: () => void
}

class DeleteVideoCollectionDialogView extends React.Component<
  ChildProps<InputProps, VideoCollectionSummaryResponse>,
  State
> {
  static contextType = SessionContext
  context!: React.ContextType<typeof SessionContext>

  constructor(props: any) {
    super(props)
    this.state = {
      isSubmitting: false,
      hasError: false,
    }
  }
  render() {
    if (!this.getCollection()) {
      return <React.Fragment />
    }
    return (
      <ConfirmationDialog
        dialogTitle="Delete Video Collection"
        confirmationText={`Are you sure you want to delete video collection "${this.getCollection()!.title}"?`}
        errorMessage={
          this.state.hasError
            ? 'Could not delete collection. If the problem persist, please contact support.'
            : undefined
        }
        yesButtonText="Yes"
        noButtonText="No"
        isLoading={this.state.isSubmitting}
        onCancel={this.props.onClose}
        onConfirm={() => this.submit()}
      />
    )
  }
  submit() {
    this.setState({ isSubmitting: true })
    deleteVideoCollection(this.props.collectionId)
      .then(this.props.onUpdated)
      .then(() => {
        window.location.href = `${process.env.PUBLIC_URL}/#/${this.context.ctok}/video-assets/#/nav/video-assets/open`
        this.props.onClose()
      })
      .catch((e) => {
        console.error(e)
        this.setState({ isSubmitting: false, hasError: true })
      })
    return false
  }
  getCollection() {
    return get(this.props.data!, 'videoCollections', []).find(
      (c) => c.id === this.props.collectionId
    )
  }
}

const withGql = graphql<InputProps, VideoCollectionSummaryResponse>(
  getAllVideoCollectionSummaryQuery,
  { options: () => ({ notifyOnNetworkStatusChange: true }) }
)
export const DeleteVideoCollectionDialog = withGql(
  DeleteVideoCollectionDialogView
)
