import React from 'react'
import {
  TextField,
  Stack,
  Dropdown,
  DropdownMenuItemType,
  IDropdownOption,
  MessageBar,
  MessageBarType,
  IconButton,
  Link,
  ComboBox,
} from 'office-ui-fabric-react'
import { NumberInput } from '../../../Components/Input/NumberInput'
import {
  Campaign,
  CampaignAssetInfo,
  CampaignVastRedirectAssetInfo,
  CampaignStaticVastAssetInfo,
  CampaignBannerAssetInfo,
  CampaignGamBannerAssetInfo,
  CampaignTeadsAssetInfo,
  CampaignAniviewAssetConfig,
  CampaignHeaderBiddingSourceAssetInfo,
  CampaignDirectAssetInfo,
  CampaignSmartclipAssetInfo,
  CampaignAdformStaticTimedAssetInfo,
  CampaignSeznamOpenRtbAssetInfo,
  AdUnitFormat,
  BannerScriptExecutionTiming,
  RunContext,
  AssetType,
  supportsServerSideRunContext,
  isDirectCampaign,
} from '../../../Api/campaigns'
import {
  getAllPrebidParams,
  PrebidBidderDescriptor,
} from '../../../Constants/PrebidParams'
import { DirectSourceForm } from './DirectSourceForm'
import get from 'lodash/get'
import { VastEditorDialog } from './VastEditorDialog'
import InfoBox from './InfoBox'
import BannerForm from './BannerForm'
import AniviewForm from './AniviewForm'
import { Currencies } from '../../../Constants/Currencies'
import { parseSafeJsObject } from '../../../Components/Utils'
import JSONEditorComponent from './JSONEditorComponent'
import GamBannerForm from './GamBannerForm'

getAllPrebidParams()

interface State {
  assetType?: AssetType
  uploadsStateSeed: number
  openVastEditor: boolean
  errorMessages: { [k: string]: string }
  prebidParams: PrebidBidderDescriptor[]
  options: IDropdownOption[]
  jsonError: string | undefined
}

interface InputProps {
  gap: number
  errorMessages: { [k: string]: string }
  disabled: boolean
  isAdminOrAccountManager: boolean
  campaign: Campaign
  onCampaignAssetChange: (asset: CampaignAssetInfo) => any
  onRunContextChange: (runContext: RunContext) => any
  onPrebidChange: (bidderParams: BidderParams) => any
  bidderParams?: BidderParams
  setJsonError: (error?: string) => void
}

export interface BidderParams {
  bidAdapterType: string
  bidAdapterAlias: string
  bidParamsJson: string
}

const emptyChange = {
  vastRedirect: null,
  staticVast: null,
  customVast3: null,
  teads: null,
  aniview: null,
  banner: null,
  headerBiddingSource: null,
  adformStaticTimed: null,
  seznamOpenRtb: null,
} as CampaignAssetInfo
const defaultVastRedirect: CampaignVastRedirectAssetInfo = { url: '' }
const defaultStaticVast: CampaignStaticVastAssetInfo = { content: '' }
const defaultBanner: CampaignBannerAssetInfo = {
  script: '',
  executionTiming: BannerScriptExecutionTiming.AS_SOON_AS_POSSIBLE,
}
const defaultGamBanner: CampaignGamBannerAssetInfo = {
  slotId: '',
  sizes: [],
  autoRefreshEverySeconds: undefined,
}
const defaultTeads: CampaignTeadsAssetInfo = { scriptUrl: '' }
const defaultAniview: CampaignAniviewAssetConfig = {
  publisherId: '',
  channelId: '',
}
const defaultSmartclip: CampaignSmartclipAssetInfo = { campaignId: '' }
const defaultHeaderBidding: CampaignHeaderBiddingSourceAssetInfo = {
  bidderParamsJson: '',
  prebidMinimumPriceFloor: 0,
  prebidMinimumPriceFloorCurrency: Currencies.EUR,
}
const defaultCustomVast3: CampaignDirectAssetInfo = {
  videoId: '',
  landingPageUrl: '',
  videoName: '',
  audioPosterName: null,
  skipTime: null,
  videoAspectRatio: '16:9',
}
const defaultCustomVast3PosterWithAudio: CampaignDirectAssetInfo = {
  ...defaultCustomVast3,
  videoId: 'au',
  audioPosterName: '',
}
const defaultCustomVast3TimedPoster: CampaignDirectAssetInfo = {
  ...defaultCustomVast3PosterWithAudio,
  videoName: 'no audio',
}
const defaultAdformStaticTimed: CampaignAdformStaticTimedAssetInfo = {
  bannerJsonUrl: '',
  adLength: 15,
}

const defaultSeznamOpenRtb: CampaignSeznamOpenRtbAssetInfo = {
  tagId: '',
  priceFloor: 0,
  priceFloorCurrency: Currencies.EUR,
}

export class SourceForm extends React.Component<InputProps, State> {
  constructor(props: InputProps) {
    super(props)
    this.state = {
      assetType: undefined,
      uploadsStateSeed: 0,
      openVastEditor: false,
      errorMessages: {},
      prebidParams: [],
      jsonError: undefined,
      options: [
        {
          key: 'connectors',
          text: 'Connectors',
          itemType: DropdownMenuItemType.Header,
          disabled: true,
        },
        { key: 'vastRedirect', text: 'VAST URL' },
        { key: 'staticVast', text: 'Static VAST String' },
        {
          key: 'headerBiddingSource',
          text: 'Header Bidding Ad Unit',
        },
        {
          key: 'banner',
          text: 'Banner Script Passback',
        },
        {
          key: 'seznamOpenRtb',
          text: 'Seznam OpenRTB',
        },
        { key: 'adformStaticTimed', text: 'Timed Adform Static Banner' },
        {
          key: 'external-outstream-players-divider',
          text: '-',
          itemType: DropdownMenuItemType.Divider,
          disabled: true,
        },
        {
          key: 'external-outstream-players',
          text: 'External Outstream Players',
          itemType: DropdownMenuItemType.Header,
          disabled: true,
        },
        { key: 'teads', text: 'Teads.tv Player' },
        { key: 'aniview', text: 'Aniview Player' },
        {
          key: 'smartclip',
          text: 'Smartclip Player [beta]',
        },
        {
          key: 'gamBanner',
          text: 'Google Ad Manager Banner',
        },
        {
          key: 'direct-divider',
          text: '-',
          itemType: DropdownMenuItemType.Divider,
          disabled: true,
        },
        {
          key: 'direct',
          text: 'Direct',
          itemType: DropdownMenuItemType.Header,
          disabled: true,
        },
        {
          key: 'customVast3',
          text: 'Video ad from upload',
          disabled: this.props.campaign.restricted,
        },
        {
          key: 'customVast3:imageTimed',
          text: 'Image ad from upload',
          disabled: this.props.campaign.restricted,
        },
        {
          key: 'customVast3:imageWithAudio',
          text: 'Image ad from upload with an audio track',
          disabled: this.props.campaign.restricted,
        },
      ].filter((option) => !!option) as IDropdownOption[],
    }
    getAllPrebidParams().then((prebidParams) => this.setState({ prebidParams }))
  }

  handleJsonChange = (value: string) => {
    const { onPrebidChange, bidderParams, disabled } = this.props
    if (disabled) {
      return
    }

    if (!value) {
      this.setState({ jsonError: 'bidParamsJson cannot be empty' })
      this.props.setJsonError('bidParamsJson cannot be empty')
      return
    }

    try {
      JSON.parse(value)
      this.setState({ jsonError: undefined })
      this.props.setJsonError(undefined)
    } catch (error) {
      this.setState({ jsonError: 'Invalid JSON' })
      this.props.setJsonError('Invalid JSON')
      return
    }

    const { bidAdapterType = '', bidAdapterAlias = '' } = bidderParams || {}
    const updatedParams: BidderParams = {
      bidAdapterType,
      bidAdapterAlias,
      bidParamsJson: value,
    }
    onPrebidChange(updatedParams)
  }

  getAssetType(): AssetType {
    const matchedCampaignTypeOption = this.state.options.find(
      (o) => (this.props.campaign.asset as any)[o.key]
    )
    const impliedRawAssetType = matchedCampaignTypeOption
      ? matchedCampaignTypeOption.key
      : 'vastRedirect'
    const isDirectAudioCampaign =
      impliedRawAssetType === 'customVast3' &&
      this.props.campaign.asset.customVast3!.videoId.match(/au$/)
    const isDirectAudioTimedCampaign =
      isDirectAudioCampaign &&
      /^no audio/.test(this.props.campaign.asset.customVast3!.videoName)
    const impliedAssetType: AssetType = isDirectAudioCampaign
      ? isDirectAudioTimedCampaign
        ? 'customVast3:imageTimed'
        : 'customVast3:imageWithAudio'
      : (impliedRawAssetType as AssetType)
    return impliedAssetType || this.state.assetType
  }
  componentDidUpdate() {
    const assetType = this.getAssetType()
    const { runContext } = this.props.campaign
    if (isDirectCampaign(assetType)) {
      if (runContext !== 'preferBrowser') {
        this.props.onRunContextChange('preferBrowser')
      }
    } else if (!supportsServerSideRunContext(assetType)) {
      if (runContext !== 'browser') {
        this.props.onRunContextChange('browser')
      }
    }
  }
  render() {
    const campaign = this.props.campaign
    const assetType = this.getAssetType()
    return (
      <React.Fragment>
        <Stack gap={this.props.gap}>
          <Dropdown
            label="Ad Source Type"
            selectedKey={assetType}
            onChange={(evt, value?) => {
              if (!value) {
                return
              }
              const newAssetType = value.key as AssetType
              if (assetType === newAssetType) {
                return
              }
              if (newAssetType === 'vastRedirect') {
                this.props.onCampaignAssetChange({
                  ...emptyChange,
                  vastRedirect: defaultVastRedirect,
                })
              }
              if (newAssetType === 'staticVast') {
                this.props.onCampaignAssetChange({
                  ...emptyChange,
                  staticVast: defaultStaticVast,
                })
              }
              if (newAssetType === 'banner') {
                this.props.onCampaignAssetChange({
                  ...emptyChange,
                  banner: defaultBanner,
                })
              }
              if (newAssetType === 'gamBanner') {
                this.props.onCampaignAssetChange({
                  ...emptyChange,
                  gamBanner: defaultGamBanner,
                })
              }
              if (newAssetType === 'teads') {
                this.props.onCampaignAssetChange({
                  ...emptyChange,
                  teads: defaultTeads,
                })
              }
              if (newAssetType === 'aniview') {
                this.props.onCampaignAssetChange({
                  ...emptyChange,
                  aniview: defaultAniview,
                })
              }
              if (newAssetType === 'smartclip') {
                this.props.onCampaignAssetChange({
                  ...emptyChange,
                  smartclip: defaultSmartclip,
                })
              }
              if (newAssetType === 'headerBiddingSource') {
                this.props.onCampaignAssetChange({
                  ...emptyChange,
                  headerBiddingSource: defaultHeaderBidding,
                })
              }
              if (newAssetType === 'customVast3') {
                this.props.onCampaignAssetChange({
                  ...emptyChange,
                  customVast3: defaultCustomVast3,
                })
              }
              if (newAssetType === 'customVast3:imageWithAudio') {
                this.props.onCampaignAssetChange({
                  ...emptyChange,
                  customVast3: defaultCustomVast3PosterWithAudio,
                })
              }
              if (newAssetType === 'customVast3:imageTimed') {
                this.props.onCampaignAssetChange({
                  ...emptyChange,
                  customVast3: defaultCustomVast3TimedPoster,
                })
              }
              if (newAssetType === 'adformStaticTimed') {
                this.props.onCampaignAssetChange({
                  ...emptyChange,
                  adformStaticTimed: defaultAdformStaticTimed,
                })
              }
              if (newAssetType === 'seznamOpenRtb') {
                this.props.onCampaignAssetChange({
                  ...emptyChange,
                  seznamOpenRtb: defaultSeznamOpenRtb,
                })
              }
              if (isDirectCampaign(newAssetType)) {
                this.props.onRunContextChange('preferBrowser')
              } else if (!supportsServerSideRunContext(newAssetType)) {
                this.props.onRunContextChange('browser')
              }
              this.setState({
                uploadsStateSeed: Math.floor(Math.random() * 1e6),
              })
            }}
            placeholder="Select an ad source type"
            required={true}
            options={this.state.options}
            disabled={this.props.disabled}
            styles={{}}
          />
          <Dropdown
            label="Run Context"
            selectedKey={campaign.runContext}
            options={[
              {
                key: 'browser',
                text: 'Browser',
              },
              {
                key: 'preferBrowser',
                text: 'Prefer Browser',
              },
              {
                key: 'server',
                text: 'Server',
              },
            ]}
            disabled={
              isDirectCampaign(assetType) ||
              !supportsServerSideRunContext(assetType) ||
              this.props.disabled
            }
            onChange={(
              _: React.FormEvent<HTMLDivElement>,
              item: IDropdownOption | undefined
            ): void => {
              if (!item) {
                return
              }
              const runContext = item.key as RunContext
              this.props.onRunContextChange(runContext)
            }}
          />
          {isDirectCampaign(assetType) && (
            <InfoBox type={MessageBarType.info}>
              Direct campaigns can only be delivered in Prefer Browser mode.
            </InfoBox>
          )}
          {!isDirectCampaign(assetType) &&
            !supportsServerSideRunContext(assetType) && (
              <InfoBox type={MessageBarType.info}>
                Selected campaign type does not support server side mode.
              </InfoBox>
            )}
          {assetType === 'vastRedirect' && this.renderVastRedirectForm()}
          {assetType === 'staticVast' && this.renderStaticVastForm()}
          {assetType === 'banner' && (
            <BannerForm
              errors={{
                script: this.props.errorMessages['bannerScript'],
                executionTiming:
                  this.props.errorMessages['bannerExecutionTiming'],
              }}
              values={{
                script: this.props.campaign.asset.banner!.script,
                executionTiming:
                  this.props.campaign.asset.banner!.executionTiming,
              }}
              onChange={({ script, executionTiming }) =>
                this.props.onCampaignAssetChange({
                  ...emptyChange,
                  banner: { script, executionTiming },
                })
              }
              disabled={this.props.disabled}
            />
          )}
          {assetType === 'gamBanner' && (
            <GamBannerForm
              errors={{
                slotId: this.props.errorMessages['gamSlotId'],
                sizes: this.props.errorMessages['gamSizes'],
                autoRefreshEverySeconds:
                  this.props.errorMessages['gamAutoRefreshEverySeconds'],
              }}
              values={{
                slotId: this.props.campaign.asset.gamBanner!.slotId,
                sizes: this.props.campaign.asset.gamBanner!.sizes,
                autoRefreshEverySeconds:
                  this.props.campaign.asset.gamBanner!.autoRefreshEverySeconds,
              }}
              onChange={({ slotId, sizes, autoRefreshEverySeconds }) =>
                this.props.onCampaignAssetChange({
                  ...emptyChange,
                  gamBanner: {
                    slotId,
                    sizes,
                    autoRefreshEverySeconds,
                  },
                })
              }
              disabled={this.props.disabled}
            />
          )}
          {assetType === 'teads' && this.renderTeadsForm()}
          {assetType === 'aniview' && (
            <AniviewForm
              publisherIdErrorMessage={
                this.props.errorMessages['aniview-publisherId']
              }
              channelIdErrorMessage={
                this.props.errorMessages['aniview-channelId']
              }
              aniviewConfig={this.props.campaign.asset.aniview!}
              onChange={(aniviewConfig: CampaignAniviewAssetConfig) =>
                this.props.onCampaignAssetChange({
                  ...emptyChange,
                  aniview: aniviewConfig,
                })
              }
              disabled={this.props.disabled}
            />
          )}
          {assetType === 'smartclip' && this.renderSmartclipForm()}
          {assetType === 'headerBiddingSource' && this.renderPrebidForm()}
          {assetType === 'adformStaticTimed' &&
            this.renderAdformStaticTimedForm()}
          {assetType === 'seznamOpenRtb' && this.renderSeznamOpenRtbForm()}
          {assetType.indexOf('customVast3') === 0 && (
            <DirectSourceForm
              seed={this.state.uploadsStateSeed}
              asset={campaign.asset.customVast3!}
              errorMessages={this.props.errorMessages}
              gap={this.props.gap}
              onChange={(customVast3) =>
                this.props.onCampaignAssetChange({
                  ...emptyChange,
                  customVast3,
                })
              }
              disabled={this.props.disabled}
            />
          )}
        </Stack>
      </React.Fragment>
    )
  }
  renderVastRedirectForm() {
    const url = get(this.props.campaign, 'asset.vastRedirect.url', '').replace(
      /\n|\r|\s/g,
      ''
    )
    const isDoubleClickCampaign =
      url.match(/^https?:\/\/[^/]+\.doubleclick.net/) ||
      url.match(/adsafeprotected.com/) ||
      url.match(/^https?:\/\/www[0-9]*\.smartadserver\.com\/ac/)
    const isCarodaImaParameterSet = url.match(/caroda-ima=0/)
    return (
      <React.Fragment>
        <TextField
          label={'VAST URL'}
          errorMessage={this.props.errorMessages['vastUrl']}
          value={url}
          autoComplete={'off'}
          spellCheck={false}
          onChange={(evt: any, value?: string) =>
            this.props.onCampaignAssetChange({
              ...emptyChange,
              vastRedirect: { url: value || '' },
            })
          }
          disabled={this.props.disabled}
          multiline
          resizable={false}
          autoAdjustHeight
          required
        />
        <InfoBox>
          An url to a 3rd party system delivering video ads via the VAST
          standard. See supported macros
          <Link
            href="https://caroda.helpscoutdocs.com/article/15-vast-templates"
            target="_blank"
          >
            here
          </Link>
          .
        </InfoBox>
        {isDoubleClickCampaign && !isCarodaImaParameterSet && (
          <InfoBox type={MessageBarType.warning}>
            This campaign will be delivered through IMA SDK. Skip button offset
            overriding and non-tracked video looping won't work.
          </InfoBox>
        )}
      </React.Fragment>
    )
  }
  renderStaticVastForm() {
    const onClick = () => {
      if (!this.props.disabled) {
        this.setState({ openVastEditor: true })
      }
    }
    return (
      <>
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-end',
          }}
        >
          <TextField
            label="Static VAST Content"
            title="Click to edit"
            errorMessage={this.props.errorMessages['staticVast']}
            value={this.getStaticVastContentDisplayValue()}
            autoComplete={'off'}
            spellCheck={false}
            disabled={this.props.disabled}
            readOnly={true}
            resizable={false}
            required
            multiline
            autoAdjustHeight
            onClick={onClick}
            styles={{
              root: {
                flexGrow: 1,
              },
              field: {
                cursor: 'pointer',
              },
            }}
          />
          <IconButton
            iconProps={{
              iconName: 'edit',
            }}
            title="Edit VAST"
            ariaLabel="Edit VAST"
            disabled={this.props.disabled}
            onClick={onClick}
          />
        </div>
        <InfoBox>
          Use a custom VAST tag and host it and deliver it through our
          infrastructure.
        </InfoBox>
        {this.state.openVastEditor && !this.props.disabled ? (
          <VastEditorDialog
            onClose={() => this.setState({ openVastEditor: false })}
            content={this.props.campaign.asset.staticVast!.content}
            setContent={(content: string) =>
              this.props.onCampaignAssetChange({
                ...emptyChange,
                staticVast: { content },
              })
            }
          />
        ) : null}
      </>
    )
  }
  getStaticVastContentDisplayValue() {
    const vastContent = this.props.campaign.asset.staticVast!.content
    if (!vastContent) {
      return vastContent
    }
    const lineBreakPattern = /\n/g
    lineBreakPattern.exec(vastContent)
    lineBreakPattern.exec(vastContent)
    const thirdLineBreak = lineBreakPattern.exec(vastContent)
    if (!thirdLineBreak) {
      return vastContent.substring(0, 150) + '\n...'
    }
    return vastContent.substring(0, thirdLineBreak!.index) + '\n...'
  }
  renderTeadsForm() {
    return (
      <React.Fragment>
        <TextField
          label={'Script Url'}
          errorMessage={this.props.errorMessages['teads']}
          value={this.props.campaign.asset.teads!.scriptUrl}
          autoComplete={'off'}
          spellCheck={false}
          onChange={(evt: any, value?: string) =>
            this.props.onCampaignAssetChange({
              ...emptyChange,
              teads: { scriptUrl: value || '' },
            })
          }
          disabled={this.props.disabled}
          resizable={false}
          required
        />
        <InfoBox>
          Delivers a Teads campaign based on a standard Teads integration tag.
          Caroda will modify the placement of teads to match our position for
          consistency.
        </InfoBox>
      </React.Fragment>
    )
  }
  renderSmartclipForm() {
    return (
      <React.Fragment>
        <TextField
          label={'Campaign ID'}
          errorMessage={this.props.errorMessages['smartclip']}
          value={this.props.campaign.asset.smartclip!.campaignId}
          autoComplete={'off'}
          spellCheck={false}
          onChange={(evt: any, value?: string) =>
            this.props.onCampaignAssetChange({
              ...emptyChange,
              smartclip: { campaignId: value || '' },
            })
          }
          disabled={this.props.disabled}
          resizable={false}
          required
        />
        <MessageBar
          messageBarType={MessageBarType.info}
          isMultiline={true}
          styles={{ root: { marginTop: 15 } }}
        >
          The campaign id can be found as the plc parameter of the integration
          script like in the example:{' '}
          <i>
            ...//des.smartclip.net/ads?type=dyn{'&'}plc=<b>12345</b>...
          </i>
        </MessageBar>
      </React.Fragment>
    )
  }
  renderPrebidForm() {
    const { bidderParams, errorMessages } = this.props
    const cleanValue =
      this.props.campaign.asset.headerBiddingSource!.bidderParamsJson
    const { prebidMinimumPriceFloor, prebidMinimumPriceFloorCurrency } =
      this.props.campaign.asset.headerBiddingSource!
    const {
      bidAdapterType = '',
      bidAdapterAlias = '',
      bidParamsJson = '',
    } = this.props.bidderParams || {}
    const bidder = this.state.prebidParams.find(
      ({ bidderCode }) => bidderCode === bidAdapterType
    )
    const isR2B2 = !!bidder && bidder.bidderCode === 'r2b2'
    const bidderUrl =
      !!bidder && !isR2B2
        ? `https://docs.prebid.org/dev-docs/bidders/${bidder.bidderCode}`
        : undefined
    const defaultBidders = [
      'adform',
      'appnexus',
      'ix',
      'pubmatic',
      'rubicon',
      'smartadserver',
      'spotx',
      'teads',
      'r2b2',
      'adf',
      'criteo',
      'colossusssp',
      'rtbhouse',
      'medianet',
      'openx',
      'onetag',
      'mgid',
      'richaudience',
      'invibes',
      'adtelligent',
      'improvedigital',
      'smartx',
      'triplelift',
      'adkernel',
      'oftmedia',
      'stroeerCore',
      'luponmedia',
      'sovrn',
      'projectagora',
      'sharethrough',
      'connectad',
      'sspBC',
      'nextMillennium',
      'etarget',
    ]
    const isDefaultBidder =
      !bidder || defaultBidders.indexOf(bidder.bidderCode) !== -1
    const { gap, disabled } = this.props
    const tags = this.state.prebidParams
      .map(({ bidderCode }) => ({ key: bidderCode, name: bidderCode }))
      .sort((a, b) => a.key.localeCompare(b.key))
    return (
      <React.Fragment>
        <Stack horizontal>
          <ComboBox
            label={'Bid Adapter Type'}
            errorMessage={errorMessages['prebidRawType']}
            selectedKey={bidAdapterType}
            options={tags.map(({ key, name }) => ({ key, text: name }))}
            disabled={disabled}
            onChange={async (evt: any, value?: IDropdownOption) => {
              if (!value) {
                return
              }
              const bidderParamsJson =
                (await parseSafeJsObject(cleanValue)) || {}
              bidderParamsJson.bidder = value.key.toString()
              this.props.onCampaignAssetChange({
                ...emptyChange,
                headerBiddingSource: {
                  bidderParamsJson: JSON.stringify(bidderParamsJson, null, 4),
                  prebidMinimumPriceFloor,
                  prebidMinimumPriceFloorCurrency,
                },
              })
              this.props.onPrebidChange({
                bidAdapterType: value.key.toString(),
                bidAdapterAlias,
                bidParamsJson: bidParamsJson,
              })
            }}
            allowFreeform={true}
            styles={{
              optionsContainerWrapper: { maxHeight: 400 },
            }}
            style={{
              flexGrow: 1,
              maxWidth: `calc(50% - ${gap / 2}px)`,
            }}
          />
          <div style={{ width: gap }} />
          <div
            style={{
              width: `calc(50% - ${gap / 2}px)`,
            }}
          >
            <TextField
              label={'Bid Adapter Alias'}
              placeholder={bidAdapterType}
              errorMessage={errorMessages['prebidRawAdapterAlias']}
              value={bidAdapterAlias}
              onChange={(evt: any, value?: string) =>
                this.props.onPrebidChange({
                  bidAdapterType,
                  bidAdapterAlias: value || '',
                  bidParamsJson: bidParamsJson,
                })
              }
              disabled={disabled}
            />
          </div>
        </Stack>
        <JSONEditorComponent
          label={'Bid Params Object'}
          value={bidderParams!.bidParamsJson}
          onChange={this.handleJsonChange}
          errorMessage={this.state.jsonError || errorMessages['prebidRaw']}
          disabled={disabled}
        />
        {errorMessages['prebidRaw'] && (
          <InfoBox type={MessageBarType.warning}>
            The parameters don’t seem to be accurate according to our
            validation. You can still save the campaign changes by pressing
            another time on the “Save“ / “Create“ button.
          </InfoBox>
        )}
        <InfoBox>
          A Prebid bidder configuration snippet.&nbsp;
          {!!bidder && !!bidderUrl && (
            <>
              The bidder configuration for {bidder.bidderName} bidder type can
              be found at
              <Link href={bidderUrl} target="_blank">
                {bidderUrl}
              </Link>
              .
            </>
          )}
          {isR2B2 && (
            <>
              For bidder configuration parameters, please contact R2B2 support.
            </>
          )}
          {!bidder && (
            <>
              Find your bidder configuration instructions at
              <Link
                href="http://prebid.org/dev-docs/bidders.html"
                target="_blank"
              >
                http://prebid.org/dev-docs/bidders.html
              </Link>
              . Use only the bidder param configuration into the input box.
            </>
          )}
        </InfoBox>
        {!isDefaultBidder && (
          <InfoBox type={MessageBarType.warning}>
            The Prebid adapter you've selected is not deployed by default.
            Please, let us know so we can sideload it.
          </InfoBox>
        )}
        {isR2B2 && this.props.campaign.adType === AdUnitFormat.BANNER && (
          <InfoBox type={MessageBarType.warning}>
            R2B2 banners are currently not supported by the Caroda player.
          </InfoBox>
        )}
        <Stack horizontal>
          <NumberInput
            label={'Minimum Price Floor'}
            errorMessage={this.props.errorMessages['prebidMinimumPriceFloor']}
            name={'Minimum Price Floor'}
            value={prebidMinimumPriceFloor || 0}
            style={{
              flexGrow: 1,
              maxWidth: `calc(50% - ${gap / 2}px)`,
            }}
            onChange={(value?: number) =>
              value !== undefined &&
              this.props.onCampaignAssetChange({
                ...emptyChange,
                headerBiddingSource: {
                  bidderParamsJson: cleanValue,
                  prebidMinimumPriceFloor: value,
                  prebidMinimumPriceFloorCurrency,
                },
              })
            }
            disabled={disabled}
            required
            min={0}
            max={1e9}
            precision={5}
          />
          <div style={{ width: gap }} />
          <Dropdown
            label="Minimum Price Floor Currency"
            selectedKey={prebidMinimumPriceFloorCurrency || Currencies.EUR}
            placeholder={'Select a currency'}
            onChange={(event, value?) => {
              if (!value) {
                return
              }
              const currency = value.key as Currencies
              if (prebidMinimumPriceFloorCurrency === currency) {
                return
              }
              this.props.onCampaignAssetChange({
                ...emptyChange,
                headerBiddingSource: {
                  bidderParamsJson: cleanValue,
                  prebidMinimumPriceFloor,
                  prebidMinimumPriceFloorCurrency: currency,
                },
              })
            }}
            required={true}
            options={Object.keys(Currencies).map((currency) => ({
              key: currency,
              text: currency,
            }))}
            calloutProps={{ calloutMaxHeight: 300 }}
            disabled={disabled}
            styles={{
              root: {
                flexGrow: 1,
                position: 'relative',
              },
            }}
          />
        </Stack>
        <InfoBox>
          Sets the minimum allowed floor for the auction in which the campaign
          is run. Important: this is not the same as setting an individual price
          floor. A different campaign with a higher minimum allowed price floor
          running in the same auction will also increase the price floor of this
          one.
        </InfoBox>
      </React.Fragment>
    )
  }
  renderAdformStaticTimedForm() {
    return (
      <React.Fragment>
        <TextField
          label={'Adform Static Banner Url'}
          errorMessage={this.props.errorMessages['adformStaticTimed']}
          value={this.props.campaign.asset.adformStaticTimed!.bannerJsonUrl}
          autoComplete={'off'}
          spellCheck={false}
          onChange={(evt: any, value?: string) =>
            this.props.onCampaignAssetChange({
              ...emptyChange,
              adformStaticTimed: {
                ...(this.props.campaign.asset.adformStaticTimed! ||
                  defaultAdformStaticTimed),
                bannerJsonUrl: value || '',
              },
            })
          }
          disabled={this.props.disabled}
          resizable={false}
          required
        />
        <Stack horizontal gap={this.props.gap}>
          <NumberInput
            label={'Ad Duration (seconds)'}
            min={5}
            max={90}
            value={this.props.campaign.asset.adformStaticTimed!.adLength}
            onChange={(value?: number) =>
              this.props.onCampaignAssetChange({
                ...emptyChange,
                adformStaticTimed: {
                  ...(this.props.campaign.asset.adformStaticTimed! ||
                    defaultAdformStaticTimed),
                  adLength: value || 15,
                },
              })
            }
            style={{ width: `calc(50% - ${this.props.gap / 2}px)` }}
            disabled={this.props.disabled}
            required
          />
        </Stack>
      </React.Fragment>
    )
  }
  renderSeznamOpenRtbForm() {
    const { tagId, priceFloor, priceFloorCurrency } =
      this.props.campaign.asset.seznamOpenRtb!
    const { disabled, gap } = this.props
    return (
      <React.Fragment>
        <Stack horizontal>
          <div
            style={{
              flexGrow: 1,
              maxWidth: `calc(50% - ${gap / 2}px)`,
            }}
          >
            <TextField
              label={'Tag Id'}
              errorMessage={this.props.errorMessages['seznamOpenRtbTagId']}
              value={tagId}
              autoComplete={'off'}
              spellCheck={false}
              onChange={(evt: any, value?: string) =>
                this.props.onCampaignAssetChange({
                  ...emptyChange,
                  seznamOpenRtb: {
                    tagId: value || '',
                    priceFloor,
                    priceFloorCurrency,
                  },
                })
              }
              disabled={disabled}
              resizable={false}
              required
            />
          </div>
          <div style={{ width: gap }} />
        </Stack>
        <Stack horizontal>
          <NumberInput
            label={'Price Floor'}
            errorMessage={this.props.errorMessages['seznamOpenRtbPriceFloor']}
            name={'rice Floor'}
            value={priceFloor || 0}
            style={{
              flexGrow: 1,
              maxWidth: `calc(50% - ${gap / 2}px)`,
            }}
            onChange={(value?: number) =>
              value !== undefined &&
              this.props.onCampaignAssetChange({
                ...emptyChange,
                seznamOpenRtb: {
                  tagId,
                  priceFloor: value,
                  priceFloorCurrency,
                },
              })
            }
            disabled={disabled}
            required
            min={0}
            max={1e9}
            precision={5}
          />
          <div style={{ width: gap }} />
          <Dropdown
            label="Price Floor Currency"
            selectedKey={priceFloorCurrency || Currencies.EUR}
            placeholder={'Select a currency'}
            onChange={(event, value?) => {
              if (!value) {
                return
              }
              const currency = value.key as Currencies
              if (priceFloorCurrency === currency) {
                return
              }
              this.props.onCampaignAssetChange({
                ...emptyChange,
                seznamOpenRtb: {
                  tagId,
                  priceFloor,
                  priceFloorCurrency: currency,
                },
              })
            }}
            required={true}
            options={Object.keys(Currencies).map((currency) => ({
              key: currency,
              text: currency,
            }))}
            calloutProps={{ calloutMaxHeight: 300 }}
            disabled={disabled}
            styles={{
              root: {
                flexGrow: 1,
                position: 'relative',
              },
            }}
          />
        </Stack>
      </React.Fragment>
    )
  }
}
