import { Currencies } from '../Constants/Currencies'
import { client } from './api'
import gql from 'graphql-tag'
import { OverrideSkipBehavior } from './instreamPlacements_v2'

export const getAllCampaignCollectionSummariesQuery = gql`
  query {
    campaignCollections {
      id
      title
    }
  }
`

export const getGlobalCampaignSettingsQuery = gql`
  {
    globalCampaignSettings {
      maximumImpressionsPerMonth
    }
  }
`

export const getCampaignsQuery_v2 = gql`
  query ($collectionId: String!) {
    session {
      groupNames
    }
    outstreamPlacementCollections_v2 {
      id
      title
      assets {
        id
        title
        integration
        tags
      }
    }
    instreamPlacementCollections_v2 {
      id
      title
      assets {
        id
        title
        integration
        tags
      }
    }
    publisherPartnerships_v2 {
      accountName
      sharedAssetTags {
        collectionTitle
        collectionId
        tagName
      }
    }
    integrations {
      domain
    }
    campaignCollections(byId: $collectionId) {
      id
      title
      assets {
        id
        title
        status
        restricted
        priority
        targetedAssets
        creationDate
        startDate
        endDate
        editedTime
        adType
        overrideSkipBehavior
        fixedSkipOffset
        lastEventTs
        eventsPerUser
        eventsPerUserInterval {
          value
          unit
        }
        schedule {
          default
          _0
          _1
          _2
          _3
          _4
          _5
          _6
        }
        eventLimit
        eventCount
        timedEventLimit
        timedEventInterval {
          value
          unit
        }
        cappingEventType
        lastCappingEventType
        maximumAdLength
        targetingRules
        pathGlobs
        pathBlacklistGlobs
        trackingHooks {
          vastEvent
          trackingUrl
        }
        fixedPrebidValue
        fixedPrebidCurrency
        runContext
        asset {
          vastRedirect {
            url
          }
          staticVast {
            content
          }
          customVast3 {
            videoName
            audioPosterName
            videoAspectRatio
            videoId
            skipTime
            landingPageUrl
          }
          headerBiddingSource {
            bidderParamsJson
            prebidMinimumPriceFloor
            prebidMinimumPriceFloorCurrency
          }
          teads {
            scriptUrl
          }
          aniview {
            publisherId
            channelId
          }
          smartclip {
            campaignId
          }
          banner {
            script
            executionTiming
          }
          gamBanner {
            slotId
            sizes
            autoRefreshEverySeconds
          }
          adformStaticTimed {
            bannerJsonUrl
            adLength
          }
          seznamOpenRtb {
            tagId
            priceFloor
            priceFloorCurrency
          }
        }
        thirdPartyReferenceChainId
      }
    }
  }
`

export const getAssetTagsQuery_v2 = gql`
  query {
    session {
      groupNames
    }
    outstreamPlacementCollections_v2 {
      id
      title
      assets {
        id
        title
        integration
        tags
      }
    }
    instreamPlacementCollections_v2 {
      id
      title
      assets {
        id
        title
        integration
        tags
      }
    }
    integrations {
      domain
    }
    publisherPartnerships_v2 {
      accountName
      sharedAssetTags {
        collectionTitle
        collectionId
        tagName
      }
    }
  }
`

export interface CampaignCollectionSummary {
  id: string
  title: string
}

export interface CampaignSummary {
  id: string
  collectionId: string
  targetedAssets: string[]
  restricted?: boolean
  priority: number
  status: string
  title: string
  creationDate: string
  startDate: string
  endDate: string | null
  adType: AdUnitFormat | null
  overrideSkipBehavior: OverrideSkipBehavior
  fixedSkipOffset: number | null
  cappingEventType: string | null
  lastCappingEventType: string | null
  eventLimit: number | null
  eventCount: number
  lastEventTs: string | null
  asset: CampaignAssetInfo
}

export interface CampaignTrackingHook {
  vastEvent: string
  trackingUrl: string
}

export interface CampaignVastRedirectAssetInfo {
  url: string
}

export interface CampaignStaticVastAssetInfo {
  content: string
}

export interface CampaignDirectAssetInfo {
  videoName: string
  audioPosterName: string | null
  videoAspectRatio: string
  videoId: string
  skipTime: number | null
  landingPageUrl: string
}

export enum AdUnitFormat {
  VIDEO = 'video',
  BANNER = 'banner',
}

export enum BannerScriptExecutionTiming {
  AS_SOON_AS_POSSIBLE = 'asSoonAsPossible',
  WHEN_IN_VIEW = 'whenInView',
}

export interface CampaignHeaderBiddingSourceAssetInfo {
  bidderParamsJson: string
  prebidMinimumPriceFloor: number
  prebidMinimumPriceFloorCurrency: Currencies
}

export interface CampaignTeadsAssetInfo {
  scriptUrl: string
}

export interface CampaignAniviewAssetConfig {
  publisherId: string
  channelId: string
}

export interface CampaignSmartclipAssetInfo {
  campaignId: string
}

export interface CampaignBannerAssetInfo {
  script: string
  executionTiming: BannerScriptExecutionTiming
}

export interface CampaignGamBannerAssetInfo {
  slotId: string
  sizes: string[]
  autoRefreshEverySeconds: number | undefined
}

export function isValidGamBannerSize(gamBannerSize: string): boolean {
  return gamBannerSize === 'fluid' || /^\d+x\d+$/.test(gamBannerSize)
}

export interface CampaignAdformStaticTimedAssetInfo {
  bannerJsonUrl: string
  adLength: number
}

export interface CampaignSeznamOpenRtbAssetInfo {
  tagId: string
  priceFloor: number
  priceFloorCurrency: Currencies
}

export interface CampaignAssetInfo {
  vastRedirect: CampaignVastRedirectAssetInfo | null
  staticVast: CampaignStaticVastAssetInfo | null
  customVast3: CampaignDirectAssetInfo | null
  headerBiddingSource: CampaignHeaderBiddingSourceAssetInfo | null
  teads: CampaignTeadsAssetInfo | null
  aniview: CampaignAniviewAssetConfig | null
  smartclip: CampaignSmartclipAssetInfo | null
  banner: CampaignBannerAssetInfo | null
  gamBanner: CampaignGamBannerAssetInfo | null
  adformStaticTimed: CampaignAdformStaticTimedAssetInfo | null
  seznamOpenRtb: CampaignSeznamOpenRtbAssetInfo | null
}

export interface HourlyDistribution {
  default: number[]
  _0?: number[]
  _1?: number[]
  _2?: number[]
  _3?: number[]
  _4?: number[]
  _5?: number[]
  _6?: number[]
}

export const defaultWorkingHours = { default: new Array(24).fill(1) }

export interface Campaign extends CampaignSummary {
  editedTime: string
  eventsPerUser: number | null
  eventsPerUserInterval: Interval | null
  schedule: HourlyDistribution
  timedEventLimit: number | null
  timedEventInterval: Interval | null
  maximumAdLength: number | null
  targetingRules: string[]
  pathGlobs: string[]
  pathBlacklistGlobs: string[]
  trackingHooks: CampaignTrackingHook[]
  fixedPrebidValue?: number | null
  fixedPrebidCurrency?: string | null
  user?: string | null
  initialStatus?: string | null
  thirdPartyReferenceChainId?: string | null
  runContext: RunContext | null
}

export type RunContext = 'browser' | 'preferBrowser' | 'server'

export interface CampaignCollectionSummaryResponse {
  campaignCollections: CampaignCollectionSummary[]
}

export interface AssetTagsResponse {
  session: {
    groupNames: string[]
  }
  outstreamPlacementCollections_v2?: {
    id: string
    title: string
    assets: {
      id: string
      title: string
      integration: string
      tags: string[]
    }[]
  }[]
  instreamPlacementCollections_v2?: {
    id: string
    title: string
    assets: {
      id: string
      title: string
      integration: string
      tags: string[]
    }[]
  }[]
  integrations: {
    domain: string
  }[]
  publisherPartnerships_v2: {
    accountName: string
    sharedAssetTags: {
      collectionTitle: string
      collectionId: string
      tagName: string
    }[]
  }[]
}

export interface CampaignResponse extends AssetTagsResponse {
  campaignCollections: {
    assets: Campaign[]
  }[]
}

export interface CampaignSummaryResponse extends AssetTagsResponse {
  campaignCollections: {
    id: string
    title: string
    assets: CampaignSummary[]
  }[]
}

export interface GlobalCampaignSettingsResponse {
  globalCampaignSettings: {
    maximumImpressionsPerMonth: number
  }
}

export type IntervalUnit = 'Hour' | 'Day' | 'Month'

export interface Interval {
  unit: IntervalUnit
  value: number
}

const createCampaignCollectionMutation = gql`
  mutation ($title: String!, $targetUser: String) {
    campaignCollections(targetUser: $targetUser) {
      create(title: $title)
    }
  }
`

const renameCampaignCollectionMutation = gql`
  mutation ($id: String, $title: String) {
    campaignCollections(byId: $id) {
      update(title: $title)
    }
  }
`

const deleteCampaignCollectionMutation = gql`
  mutation ($id: String) {
    campaignCollections(byId: $id) {
      delete
    }
  }
`

const deleteCampaignMutation = gql`
  mutation ($collectionId: String, $id: String) {
    campaignCollections(byId: $collectionId) {
      assets(byId: $id) {
        delete
      }
    }
  }
`

const setCampaignStatusMutation = gql`
  mutation ($collectionId: String, $id: String, $status: String) {
    campaignCollections(byId: $collectionId) {
      assets(byId: $id) {
        update(status: $status)
      }
    }
  }
`

const setCampaignRestrictedStatusMutation = gql`
  mutation ($collectionId: String, $id: String, $restricted: Boolean) {
    campaignCollections(byId: $collectionId) {
      assets(byId: $id) {
        update(restricted: $restricted)
      }
    }
  }
`

const createCampaignMutation = gql`
  mutation (
    $collectionId: String!
    $title: String!
    $priority: Float!
    $targetedAssets: [String!]!
    $fixedPrebidValue: Float
    $fixedPrebidCurrency: Currency
    $startDate: String!
    $endDate: String
    $adType: AdUnitFormats
    $overrideSkipBehavior: CampaignOverrideSkipBehavior
    $fixedSkipOffset: Float
    $cappingEventType: String
    $eventsPerUser: Float
    $eventsPerUserInterval: CampaignIntervalInput
    $schedule: CampaignScheduleInput
    $eventLimit: Float
    $timedEventLimit: Float
    $timedEventInterval: CampaignIntervalInput
    $maximumAdLength: Float
    $targetingRules: [String!]!
    $pathGlobs: [String!]!
    $pathBlacklistGlobs: [String!]!
    $trackingHooks: [CampaignTrackingHookInput!]
    $asset: CampaignAssetInput!
    $user: String
    $initialStatus: String
    $thirdPartyReferenceChainId: String
    $runContext: RunContext!
  ) {
    campaignCollections(byId: $collectionId) {
      assets {
        create(
          title: $title
          priority: $priority
          fixedPrebidValue: $fixedPrebidValue
          fixedPrebidCurrency: $fixedPrebidCurrency
          targetedAssets: $targetedAssets
          startDate: $startDate
          endDate: $endDate
          adType: $adType
          overrideSkipBehavior: $overrideSkipBehavior
          fixedSkipOffset: $fixedSkipOffset
          cappingEventType: $cappingEventType
          eventsPerUser: $eventsPerUser
          eventsPerUserInterval: $eventsPerUserInterval
          schedule: $schedule
          eventLimit: $eventLimit
          timedEventLimit: $timedEventLimit
          timedEventInterval: $timedEventInterval
          maximumAdLength: $maximumAdLength
          targetingRules: $targetingRules
          pathGlobs: $pathGlobs
          pathBlacklistGlobs: $pathBlacklistGlobs
          trackingHooks: $trackingHooks
          asset: $asset
          user: $user
          initialStatus: $initialStatus
          thirdPartyReferenceChainId: $thirdPartyReferenceChainId
          runContext: $runContext
        )
      }
    }
  }
`

const editCampaignMutation = gql`
  mutation (
    $collectionId: String!
    $id: String!
    $title: String!
    $priority: Float!
    $targetedAssets: [String!]!
    $startDate: String!
    $endDate: String
    $adType: AdUnitFormats
    $overrideSkipBehavior: CampaignOverrideSkipBehavior
    $fixedSkipOffset: Float
    $cappingEventType: String
    $eventsPerUser: Float
    $eventsPerUserInterval: CampaignIntervalInput
    $schedule: CampaignScheduleInput
    $eventLimit: Float
    $timedEventLimit: Float
    $timedEventInterval: CampaignIntervalInput
    $maximumAdLength: Float
    $targetingRules: [String!]!
    $pathGlobs: [String!]!
    $pathBlacklistGlobs: [String!]!
    $trackingHooks: [CampaignTrackingHookInput!]
    $asset: CampaignAssetInput!
    $fixedPrebidValue: Float
    $fixedPrebidCurrency: Currency
    $thirdPartyReferenceChainId: String
    $runContext: RunContext
  ) {
    campaignCollections(byId: $collectionId) {
      assets(byId: $id) {
        update(
          title: $title
          priority: $priority
          targetedAssets: $targetedAssets
          startDate: $startDate
          endDate: $endDate
          adType: $adType
          overrideSkipBehavior: $overrideSkipBehavior
          fixedSkipOffset: $fixedSkipOffset
          cappingEventType: $cappingEventType
          eventsPerUser: $eventsPerUser
          eventsPerUserInterval: $eventsPerUserInterval
          schedule: $schedule
          eventLimit: $eventLimit
          timedEventLimit: $timedEventLimit
          timedEventInterval: $timedEventInterval
          maximumAdLength: $maximumAdLength
          targetingRules: $targetingRules
          pathGlobs: $pathGlobs
          pathBlacklistGlobs: $pathBlacklistGlobs
          trackingHooks: $trackingHooks
          asset: $asset
          fixedPrebidValue: $fixedPrebidValue
          fixedPrebidCurrency: $fixedPrebidCurrency
          thirdPartyReferenceChainId: $thirdPartyReferenceChainId
          runContext: $runContext
        )
      }
    }
  }
`

const addPartnershipTagsMutation = gql`
  mutation (
    $accountId: String!
    $collectionId: String!
    $collectionTitle: String!
    $tagNames: [String!]!
  ) {
    publisherPartnerships_v2(byAccountId: $accountId) {
      connectTags(
        collectionTitle: $collectionTitle
        collectionId: $collectionId
        tagNames: $tagNames
      )
    }
  }
`

const removePartnershipTagsMutation = gql`
  mutation (
    $accountId: String!
    $collectionId: String!
    $tagNames: [String!]!
  ) {
    publisherPartnerships_v2(byAccountId: $accountId) {
      disconnectTags(collectionId: $collectionId, tagNames: $tagNames)
    }
  }
`

const updateGlobalCampaignSettingsMutation = gql`
  mutation ($maximumImpressionsPerMonth: Float) {
    globalCampaignSettings {
      update(maximumImpressionsPerMonth: $maximumImpressionsPerMonth)
    }
  }
`

export async function createCampaignCollection(
  title: string,
  targetUser?: string
) {
  const mutationResult = await client.mutate({
    mutation: createCampaignCollectionMutation,
    variables: { title, targetUser },
  })
  return mutationResult.data.campaignCollections.create
}

export async function renameCampaignCollection(
  collectionId: string,
  newTitle: string
) {
  return client.mutate({
    mutation: renameCampaignCollectionMutation,
    variables: {
      id: collectionId,
      title: newTitle,
    },
  })
}

export async function deleteCampaignCollection(collectionId: string) {
  return client.mutate({
    mutation: deleteCampaignCollectionMutation,
    variables: { id: collectionId },
  })
}

export async function setCampaignStatus(
  collectionId: string,
  id: string,
  status: string
) {
  return client.mutate({
    mutation: setCampaignStatusMutation,
    variables: { collectionId, id, status },
  })
}

export async function setCampaignRestrictedStatus(
  collectionId: string,
  id: string,
  restricted: boolean
) {
  return client.mutate({
    mutation: setCampaignRestrictedStatusMutation,
    variables: { collectionId, id, restricted },
  })
}

export async function createCampaign(campaign: Campaign) {
  removeTypenames(campaign)
  return client.mutate({
    mutation: createCampaignMutation,
    variables: { ...campaign },
  })
}

export async function editCampaign(campaign: Campaign) {
  removeTypenames(campaign)
  return client.mutate({
    mutation: editCampaignMutation,
    variables: { ...campaign },
  })
}

export async function getCampaigns(collectionId: string) {
  return client.query({
    query: getCampaignsQuery_v2,
    variables: { collectionId },
    fetchPolicy: 'network-only',
  })
}

export async function deleteCampaign(collectionId: string, id: string) {
  return client.mutate({
    mutation: deleteCampaignMutation,
    variables: { id, collectionId },
  })
}

export async function addPartnershipTags(
  accountId: string,
  collectionId: string,
  collectionTitle: string,
  tagNames: string[]
) {
  return client.mutate({
    mutation: addPartnershipTagsMutation,
    variables: { accountId, collectionId, collectionTitle, tagNames },
  })
}

export async function removePartnershipTags(
  accountId: string,
  collectionId: string,
  tagNames: string[]
) {
  return client.mutate({
    mutation: removePartnershipTagsMutation,
    variables: { accountId, collectionId, tagNames },
  })
}

export async function getAssetTags() {
  return client.query({
    query: getAssetTagsQuery_v2,
    variables: {},
    fetchPolicy: 'network-only',
  })
}

export async function updateGlobalCampaignSettings(
  maximumImpressionsPerMonth: number | null
) {
  return client.mutate({
    mutation: updateGlobalCampaignSettingsMutation,
    variables: { maximumImpressionsPerMonth },
  })
}

export function getNextTitle(title: string) {
  if (title.match(/\s#[0-9]+$/)) {
    return title.replace(/\s#([0-9]+)$/, (_, b) => ` #${+b + 1}`)
  } else {
    return title + ' #2'
  }
}

function removeTypenames(o: any) {
  console.log(typeof o, o)
  if (!o || typeof o !== 'object') {
    return
  }
  delete o.__typename
  for (const k of Object.keys(o)) {
    removeTypenames(o[k])
  }
}

export type AssetType =
  | 'vastRedirect'
  | 'staticVast'
  | 'customVast3'
  | 'customVast3:imageTimed'
  | 'customVast3:imageWithAudio'
  | 'headerBiddingSource'
  | 'adformStaticTimed'
  | 'seznamOpenRtb'
  | 'teads'
  | 'aniview'
  | 'smartclip'
  | 'banner'
  | 'gamBanner'

export function supportsServerSideRunContext(assetType: AssetType): boolean {
  return ['vastRedirect', 'staticVast', 'adformStaticTimed', 'banner'].includes(
    assetType
  )
}

export function isDirectCampaign(assetType: AssetType): boolean {
  return (
    [
      'customVast3',
      'customVast3:imageTimed',
      'customVast3:imageWithAudio',
    ].indexOf(assetType) !== -1
  )
}
