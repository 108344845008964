import {
  DirectionalHint,
  Icon,
  Stack,
  Text,
  TooltipHost,
} from 'office-ui-fabric-react'
import React from 'react'
import { TableView } from '../../../../Components/TableView'
import { Seller } from '../../../../Api/schain'
import { ApolloError } from 'apollo-client'
import { ErrorScreen } from '../../../../Components/ErrorScreen'

interface InputProps {
  sellers: Seller[]
  error?: ApolloError
}

const SellersListView: React.FC<InputProps> = (props) => {
  const { sellers, error } = props

  if (error) {
    return renderError()
  }

  if (!sellers || sellers.length === 0) {
    return renderEmpty()
  }

  const getColumns = () => {
    return [
      {
        key: 'type',
        name: '',
        fieldName: 'type',
        minWidth: 20,
        maxWidth: 20,
        isRowHeader: true,
        isIconOnly: true,
        isPadded: false,
        onRender: (a: Seller) => (
          <Icon
            iconName={
              a.seller_type === 'PUBLISHER'
                ? 'BranchShelveset'
                : 'BranchPullRequest'
            }
            style={{
              fontSize: 14,
              paddingLeft: 5,
              paddingTop: 3,
            }}
          />
        ),
      },
      {
        key: 'name',
        name: 'Seller name',
        fieldName: 'name',
        isRowHeader: true,
        isPadded: true,
        minWidth: 150,
        maxWidth: 400,
        onRender: (a: Seller) =>
          renderCellWithToolTip(
            a.is_confidential ? `Confidential Seller #${a.seller_id}` : a.name!
          ),
      },
      {
        key: 'domain',
        name: 'Seller domain',
        fieldName: 'domain',
        isRowHeader: true,
        isPadded: true,
        minWidth: 150,
        maxWidth: 200,
        onRender: (a: Seller) =>
          renderCellWithToolTip(a.is_confidential ? `confidential` : a.domain!),
      },
      {
        key: 'seller_type',
        name: 'Seller type',
        fieldName: 'seller_type',
        isRowHeader: true,
        isPadded: true,
        minWidth: 100,
        maxWidth: 150,
        onRender: (a: Seller) => (
          <span title={a.seller_type}>{a.seller_type}</span>
        ),
      },
      {
        key: 'seller_id',
        name: 'Seller ID',
        fieldName: 'seller_id',
        isRowHeader: true,
        isPadded: true,
        minWidth: 150,
        maxWidth: 400,
        onRender: (a: Seller) => renderCellWithToolTip(a.seller_id),
      },
    ]
  }

  return (
    <React.Fragment>
      <TableView
        cacheKey={'sellers'}
        columns={getColumns()}
        rowToCacheKey={(_) => 'sellers'}
        rows={sellers}
        onRowClick={(_) => {}}
      />
    </React.Fragment>
  )
}

function renderCellWithToolTip(text: string) {
  return (
    <TooltipHost
      styles={{
        root: {
          position: 'absolute',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          left: 0,
          right: '5px',
        },
      }}
      tooltipProps={{
        delay: 0,
        directionalHint: DirectionalHint.leftCenter,
      }}
      content={text}
    >
      <span title={text}>{text}</span>
    </TooltipHost>
  )
}

function renderEmpty() {
  return (
    <Stack
      horizontalAlign="center"
      verticalAlign="center"
      verticalFill
      style={{ width: '100%' }}
    >
      <Text
        variant="xLarge"
        style={{
          maxWidth: 600,
          padding: 20,
          textAlign: 'center',
        }}
      >
        No own sellers.json domain is connected. In order to be able to add your
        schain references to your integrations, set a valid sellers.json domain.
      </Text>
    </Stack>
  )
}

function renderError() {
  return (
    <ErrorScreen errorText="Error while fetching sellers.json domains. The sellers.json could be invalid. If the problem persists, please contact support." />
  )
}

export const SellersList = SellersListView
