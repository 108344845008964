import React from 'react'
import { ITag, PrimaryButton } from 'office-ui-fabric-react'
import './ShownTagInput.css'

interface State {}

interface InputProps {
  label: string
  required?: boolean
  onChange: (value?: ITag[]) => any
  options: ITag[]
  selectedKeys: string[]
  disabled?: boolean
  errorMessage?: string
}

export class ShownTagInput extends React.Component<InputProps, State> {
  render() {
    return (
      <div
        className={
          'shown-tag-input' + (this.props.errorMessage ? ' has-error' : '')
        }
      >
        <div
          style={{
            marginTop: 5,
            marginBottom: 5,
            fontWeight: 600,
            color: 'rgb(50, 49, 48)',
          }}
        >
          {this.props.label}
          {this.props.required && (
            <span style={{ color: 'rgb(164, 38, 44)' }}> *</span>
          )}
        </div>
        {this.props.options.map((o) => {
          const items = this.props.selectedKeys
            .map((key) => this.props.options.find((o) => o.key === key))
            .filter(Boolean) as ITag[]
          const checked = !items.find((i) => i.key === o.key)
          return (
            <PrimaryButton
              key={o.key}
              toggle
              checked={checked}
              text={o.name}
              styles={{
                root: { marginRight: 5, marginBottom: 5 },
                rootCheckedDisabled: { background: '#fff' },
              }}
              onClick={() =>
                this.props.onChange(
                  checked ? [...items, o] : items.filter((i) => i.key !== o.key)
                )
              }
              allowDisabledFocus
              disabled={this.props.disabled}
            />
          )
        })}
        {this.props.errorMessage && (
          <span data-automation-id="error-message">
            {this.props.errorMessage}
          </span>
        )}
      </div>
    )
  }
}
