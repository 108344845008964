import { MessageBar, Stack } from 'office-ui-fabric-react'

import { Campaign } from '../../../../../Api/campaigns'
import { Change } from '../CappingForm_v2'
import { NumberInput } from '../../../../../Components/Input/NumberInput'
import React from 'react'

interface TotalLimitProps {
  gap: number
  campaign: Campaign
  disabled: boolean
  onChange: (campaignChange: Change) => any
}

export function TotalLimit(props: TotalLimitProps) {
  const { gap, campaign, disabled, onChange } = props
  return (
    <Stack gap={gap}>
      <NumberInput
        label={`Total limit (thousand)`}
        errorMessage={undefined}
        style={{ maxWidth: `calc(50% - ${gap / 2}px)` }}
        name={'eventLimit'}
        value={campaign.eventLimit && campaign.eventLimit / 1000}
        onChange={(value?: number) =>
          value !== undefined &&
          onChange({
            eventLimit: value ? value * 1000 : null,
          })
        }
        min={0}
        max={1e9}
        precision={3}
        disabled={disabled}
        required={true}
      />
      {!!campaign.endDate && (
        <MessageBar>
          The campaign will be delivered in pacing mode. If necessary, the
          campaign will be throttled to fill in the set campaign interval.
        </MessageBar>
      )}
    </Stack>
  )
}
