export async function request(f: (x: XMLHttpRequest) => void): Promise<string> {
  const xhr = new XMLHttpRequest()
  const responsePromise = new Promise<string>((resolve, reject) => {
    xhr.onreadystatechange = function () {
      if (this.readyState === 4) {
        if (this.status < 400) {
          return resolve(this.responseText)
        }
        return reject(new Error('BadStatus' + this.status))
      }
    }
  })
  f(xhr)
  return responsePromise
}

export async function requestJson(
  f: (x: XMLHttpRequest) => void
): Promise<any> {
  const response = await request(f)
  return JSON.parse(response)
}
