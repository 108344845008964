import React from 'react'
import { Stack } from 'office-ui-fabric-react'
import { ChildProps, graphql } from 'react-apollo'
import {
  getAllVideoCollectionSummaryQuery,
  VideoCollectionSummary,
  VideoCollectionSummaryResponse,
  blobUpload,
  migrateUrl,
} from '../../Api/videoAssets'
import { ScreenHeader } from '../../Components/ScreenHeader'
import { VideoAssetList } from './VideoAssetList'
import { ErrorScreen } from '../../Components/ErrorScreen'
import { CollectionIndicationScreen } from '../../Components/CollectionIndicationScreen'
import { RenameVideoCollectionDialog } from './RenameVideoCollectionDialog'
import { DeleteVideoCollectionDialog } from './DeleteVideoCollectionDialog'
import { GlobalTranscodingSettingsDialog } from './GlobalTranscodingSettingsDialog'
import { ConnectWithWebsiteDialog } from './ConnectWithWebsiteDialog'
import { TransferFileDialog } from './TransferFileDialog'
import { BlockingLoadingDialog } from '../../Components/BlockingLoadingDialog'
import { get } from 'lodash'
import './VideoCollectionScreen.css'

interface State {
  cursor: string
  isUploading: boolean
  isRenameModalOpen: boolean
  isDeleteModalOpen: boolean
  isTranscodingModalOpen: boolean
  isConnectWithWebsiteModalOpen: boolean
  isTransferFileModalOpen: boolean
  pendingNameChange?: string
}

interface InputProps {
  collectionId: string
}

class VideoCollectionScreenContentView extends React.Component<
  ChildProps<InputProps, VideoCollectionSummaryResponse>,
  State
> {
  constructor(props: any) {
    super(props)
    this.state = {
      cursor: ',',
      isUploading: false,
      isRenameModalOpen: false,
      isDeleteModalOpen: false,
      isTranscodingModalOpen: false,
      isTransferFileModalOpen: false,
      isConnectWithWebsiteModalOpen: false,
    }
  }
  render() {
    const { error, loading } = this.props.data!
    let responseError: Error | null = null
    if (
      !error &&
      !this.getCollection() &&
      this.props.data &&
      this.props.data!.videoCollections
    ) {
      responseError = new Error('Collection not found')
    }
    if (error || responseError) {
      window.console.error(error || responseError)
      return this.renderError()
    }
    const isLoading = loading || !this.getCollection()
    return (
      <Stack
        className="screen"
        horizontalAlign="start"
        verticalAlign="start"
        verticalFill
        style={{ width: '100%' }}
      >
        {
          <ScreenHeader
            path={[
              'Video Content',
              isLoading ? 'Loading...' : this.getCollection()!.title,
            ]}
            buttons={[
              {
                name: 'Upload File',
                icon: 'Upload',
                isUpload: true,
                uploadAccept: 'video/*',
                disabled: isLoading,
                onClick: (file: File) => this.uploadFile(file),
              },
              {
                name: 'Transfer from url',
                icon: 'Link',
                disabled: isLoading,
                onClick: () => this.transferFile(),
              },
              {
                name: 'Global transcoding settings',
                icon: 'Video',
                disabled: isLoading,
                onClick: () => this.configureTranscodingSettings(),
              },
              {
                name: 'Connect with website',
                icon: 'Globe',
                disabled: isLoading,
                onClick: () => this.connectWithWebsite(),
              },
              {
                name: 'Collection settings',
                icon: 'ChevronDown',
                disabled: isLoading,
                menu: [
                  {
                    name: 'Rename collection',
                    icon: 'edit',
                    onClick: () => this.renameCollectionPopup(),
                  },
                  {
                    name: 'Delete collection',
                    icon: 'delete',
                    onClick: () => this.deleteCollection(),
                  },
                ],
              },
            ]}
          />
        }
        <Stack
          className="video-assets-collection-screen"
          horizontalAlign="center"
          verticalAlign="start"
          horizontal
          wrap
          styles={{
            root: {
              width: '100%',
              overflow: 'auto',
            },
            inner: {
              padding: 60,
            },
          }}
        >
          <VideoAssetList
            collectionId={this.props.collectionId}
            cursor={this.state.cursor}
          />
        </Stack>
        {isLoading ? (
          <></>
        ) : (
          <>
            {this.renderUploadingModal()}
            {this.renderRenameModal(this.props.data!.refetch)}
            {this.renderDeleteModal(this.props.data!.refetch)}
            {this.renderTranscodingModal()}
            {this.renderConnectWithWebsiteModal()}
            {this.renderTransferFileModal(this.props.data!.refetch)}
          </>
        )}
      </Stack>
    )
  }
  renderUploadingModal() {
    if (!this.state.isUploading) {
      return <React.Fragment />
    }
    return (
      <BlockingLoadingDialog label="Uploading asset and scheduling for transcoding. Please don't navigate away or refresh the page." />
    )
  }
  renderRenameModal(refetch: () => void) {
    if (!this.state.isRenameModalOpen) {
      return <React.Fragment />
    }
    return (
      <RenameVideoCollectionDialog
        collectionId={this.props.collectionId}
        onUpdated={() => refetch()}
        onClose={() => this.setState({ isRenameModalOpen: false })}
      />
    )
  }
  renderDeleteModal(refetch: () => void) {
    if (!this.state.isDeleteModalOpen) {
      return <React.Fragment />
    }
    return (
      <DeleteVideoCollectionDialog
        collectionId={this.props.collectionId}
        isOpen={this.state.isDeleteModalOpen}
        onUpdated={() => refetch()}
        onClose={() => this.setState({ isDeleteModalOpen: false })}
      />
    )
  }
  renderTranscodingModal() {
    if (!this.state.isTranscodingModalOpen) {
      return <React.Fragment />
    }
    return (
      <GlobalTranscodingSettingsDialog
        isOpen={this.state.isTranscodingModalOpen}
        onClose={() => this.setState({ isTranscodingModalOpen: false })}
      />
    )
  }
  renderConnectWithWebsiteModal() {
    if (!this.state.isConnectWithWebsiteModalOpen) {
      return <React.Fragment />
    }
    return (
      <ConnectWithWebsiteDialog
        collectionId={this.props.collectionId}
        isOpen={this.state.isConnectWithWebsiteModalOpen}
        onClose={() => this.setState({ isConnectWithWebsiteModalOpen: false })}
      />
    )
  }
  renderTransferFileModal(refetch: () => void) {
    if (!this.state.isTransferFileModalOpen) {
      return <React.Fragment />
    }
    return (
      <TransferFileDialog
        collectionId={this.props.collectionId}
        onUpdated={() => this.setState({ cursor: '.' + Date.now() })}
        isOpen={this.state.isTransferFileModalOpen}
        onClose={() => this.setState({ isTransferFileModalOpen: false })}
      />
    )
  }
  renderError() {
    return (
      <ErrorScreen
        errorText={`This video collection could not be found. Most likely, you've opened an older link of a collection
        that is now deleted. If the error persists and your collection can not be selected from the navigation menu,
        please contact support.`}
      />
    )
  }
  getCollection(): VideoCollectionSummary | undefined {
    return get(this.props.data!, 'videoCollections', []).find(
      (c) => c.id === this.props.collectionId
    )
  }
  async uploadFile(file: File) {
    try {
      this.setState({ isUploading: true })
      const url = await blobUpload(file)
      await migrateUrl(this.props.collectionId, url)
      this.setState({ isUploading: false, cursor: '.' + Date.now() })
    } catch (e) {
      this.setState({ isUploading: false })
      // error window
    }
  }
  transferFile() {
    this.setState({ isTransferFileModalOpen: true })
  }
  connectWithWebsite() {
    this.setState({ isConnectWithWebsiteModalOpen: true })
  }
  configureTranscodingSettings() {
    this.setState({ isTranscodingModalOpen: true })
  }
  renameCollectionPopup() {
    this.setState({ isRenameModalOpen: true })
  }
  deleteCollection() {
    this.setState({ isDeleteModalOpen: true })
  }
}

const withGql = graphql<InputProps, VideoCollectionSummaryResponse>(
  getAllVideoCollectionSummaryQuery,
  { options: () => ({ notifyOnNetworkStatusChange: true }) }
)
const VideoCollectionScreenContent = withGql(VideoCollectionScreenContentView)

class VideoCollectionScreen extends React.Component<
  ChildProps<InputProps, VideoCollectionSummaryResponse>,
  State
> {
  render() {
    if (!this.props.collectionId) {
      return (
        <Stack
          className="screen"
          horizontalAlign="start"
          verticalAlign="start"
          verticalFill
          style={{ width: '100%' }}
        >
          <ScreenHeader path={['Video Content']} buttons={[]} />
          <CollectionIndicationScreen text="Please select a video collection from the navigation menu on the left." />
        </Stack>
      )
    }
    return (
      <VideoCollectionScreenContent
        collectionId={this.props.collectionId}
      ></VideoCollectionScreenContent>
    )
  }
}

export { VideoCollectionScreen }
