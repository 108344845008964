import React from 'react'
import { TextPromptDialog } from '../../Components/TextPromptDialog'
import { createVideoCollection } from '../../Api/videoAssets'
import { get } from 'lodash'
import { SessionContext } from '../../Contexts/SessionContext'

interface State {
  isSubmitting: boolean
  pendingNameChange?: string
  hasError: boolean
}

interface InputProps {
  onClose: () => void
  onUpdated: () => void
}

export class CreateVideoCollectionDialog extends React.Component<
  InputProps,
  State
> {
  static contextType = SessionContext
  context!: React.ContextType<typeof SessionContext>

  constructor(props: any) {
    super(props)
    this.state = {
      isSubmitting: false,
      pendingNameChange: undefined,
      hasError: false,
    }
  }
  render() {
    return (
      <TextPromptDialog
        dialogTitle="Create Video Collection"
        fieldTitle="Title"
        confirmButtonText="Create"
        isLoading={this.state.isSubmitting}
        onUpdated={() => this.setState({ hasError: false })}
        onSubmit={(value) => this.submit(value)}
        onClose={this.props.onClose}
        errorMessage={
          this.state.hasError
            ? 'Could not create collection. If the problem persist, please contact support.'
            : undefined
        }
      />
    )
  }
  submit(title: string) {
    this.setState({ pendingNameChange: title, isSubmitting: true })
    let videoCollectionId = ''
    createVideoCollection(title)
      .then((ret) => {
        videoCollectionId = get(ret, 'data.videoCollections.create', '')
      })
      .then(this.props.onUpdated)
      .then(() => {
        this.props.onClose()
        window.location.href = `${process.env.PUBLIC_URL}/#/${this.context.ctok}/video-assets/${videoCollectionId}`
      })
      .catch((e) => {
        console.error(e)
        this.setState({ hasError: true, isSubmitting: false })
      })
    return false
  }
}
